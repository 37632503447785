import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

import easyaccess from '../../images/home/Features/For Users/easyaccess.png';
import sharesocial from '../../images/home/Features/For Users/sharesocial.png';
import qualityimages from '../../images/home/Features/For Users/qualityimages.png';
import otherdocs from '../../images/home/Features/For Users/otherdocs.png';
import lifetimeaccess from '../../images/home/Features/For Users/lifetimeaccess.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        const { activeAnchor } = this.state;

        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>For Users</h2>
                            <p>build your identity with pronetin</p>
                        </div>
                        <div className='features-page'>
                            <div className='bg-pattern'></div>
                            <div className='navigation'>
                                <a href='#foo' className={activeAnchor === 'n1' ? 'active' : ''} data='n1' onClick={this.handleAnchorClick}>Store Certificates for lifetime</a>
                                <a href='#foo' className={activeAnchor === 'n3' ? 'active' : ''} data='n3' onClick={this.handleAnchorClick}>Download High Quality Certificates</a>
                                <a href='#foo' className={activeAnchor === 'n5' ? 'active' : ''} data='n5' onClick={this.handleAnchorClick}>Share to Social Media</a>
                                <a href='#foo' className={activeAnchor === 'n2' ? 'active' : ''} data='n2' onClick={this.handleAnchorClick}>Store Other Documents</a>
                                <a href='#foo' className={activeAnchor === 'n4' ? 'active' : ''} data='n4' onClick={this.handleAnchorClick}>Easy Access Anytime, Anywhere</a>
                                {/* <a href='#foo' className={activeAnchor === 'n6' ? 'active' : ''} data='n6' onClick={this.handleAnchorClick}></a> */}
                            </div>
                            <div className='container'>
                                <div className='col'>
                                    <div className='wrapper' id='n1'>
                                        <div className='image'><img src={lifetimeaccess} alt="Store Certificates for lifetime" /></div>
                                        <div className='content'>
                                            <h3>Store Certificates for lifetime</h3>
                                            <p>Whether it's academic certificates, professional certifications, we understand the significance of these documents in your life and it will be stored for a lifetime.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n3'>
                                        <div className='image'><img src={qualityimages} alt="Download High Quality Certificates" /></div>
                                        <div className='content'>
                                            <h3>Download High Quality Certificates</h3>
                                            <p>Easily download your certificates in high resolution, ensuring that they look professional and are ready for print or digital use.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n5'>
                                        <div className='image'><img src={sharesocial} alt="Share to Social Media" /></div>
                                        <div className='content'>
                                            <h3>Share to Social Media</h3>
                                            <p>Seamlessly share your achievements on social media platforms like LinkedIn, Facebook, and Twitter, showcasing your accomplishments to your network.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='wrapper' id='n2'>
                                        <div className='image'><img src={otherdocs} alt="Store Other Documents" /></div>
                                        <div className='content'>
                                            <h3>Store Other Documents</h3>
                                            <p>Securely store and manage other important documents such as transcripts, letters of recommendation, and awards in one convenient location.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n4'>
                                        <div className='image'><img src={easyaccess} alt="Easy Access Anytime, Anywhere" /></div>
                                        <div className='content'>
                                            <h3>Easy Access Anytime, Anywhere</h3>
                                            <p>Access your certificates and documents from any device, at any time, ensuring you have your important documents at your fingertips.</p>
                                        </div>
                                    </div>
                                    {/* <div className='wrapper' id='n6'>
                                        <div className='image'></div>
                                        <div className='content'>
                                            <h3></h3>
                                            <p></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;