import React from 'react';
import './css/signin.css';
// import SigninIcon from './resources/lf-1.png';
import GoogleButton from './googleSigin';
import axios from 'axios';

import emailDomains from '../data/email_domains';
import ReCAPTCHA from "react-google-recaptcha";

import g1 from '../images/static/g1.png';
import g2 from '../images/static/g2.png';
import b1 from '../images/static/b1.png';
import b2 from '../images/static/b2.png';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            confpassword: '',
            isError: {
                name: '',
                email: '',
                password: '',
                confpassword: '',
            },
            response: '',
            warningActive: '',
            showPassword: false,
            showConfPassword: false,
            signingup: false,
            skipModal: false,
            recaptchaToken: null,
            firstStage: true,
            recaptchaKey: Math.random().toString(36).substring(2),
            workEmailInfo: false,
        };
        this.organisationRef = React.createRef();
    }

    checkValidation = (e) => {
        var regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var regPassword = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).+$/;
        const { name, value } = e.target;
        let isError = { ...this.state.isError };
        let pass = { ...this.state };

        //Resets warning on any field input change
        this.setState({
            response: '',
            warningActive: false
        });

        switch (name) {
            case "name":
                isError.name =
                    value.length < 3 ? "Atleast 3 characaters required" : "";
                break;
            case "email":
                isError.email = value.length > 0 ? regEmail.test(value) ? "" : "Enter a valid email id" : "Email field cannot be empty";
                if (emailDomains.includes(value.split("@")[1]) && value.split("@")[1] !== this.state.userEmailDomain) {
                    this.setState({
                        skipModal: true,
                        userEmailDomain: value.split("@")[1],
                    });
                }
                break;
            case "password":
                isError.password = value.length < 8 ? "Atleast 8 characaters required" : "";
                if (isError.password === "") {
                    isError.password = !regPassword.test(value) ? "Password should contain \n 1. Alphabet \n 2. Number \n 3. Special Character" : "";
                }
                document.getElementById('confpassword').value = '';
                break;
            case "confpassword":
                isError.confpassword = pass.password === value ? "" : "Passwords do not match";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        if (this.state.isError.email.length > 0) {
            this.setState({
                response: "Invalid Email",
                warningActive: true,
                signingup: false,
                firstStage: true,
            });
        } else if (this.state.isError.name.length > 0) {
            this.setState({
                response: "Name too short",
                warningActive: true,
                signingup: false,
                firstStage: true,
            });
        } else if (this.state.isError.confpassword.length > 0) {
            this.setState({
                response: "Passwords do not match",
                warningActive: true,
                signingup: false,
                firstStage: false,
            });
        } else {
            const { recaptchaToken } = this.state;
            axios.post(this.props.node_server_axios + 'verify-recaptcha', { token: recaptchaToken })
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            signingup: true
                        })
                        localStorage.removeItem('onboarding_index');
                        if (this.state.isError.password === "") {
                            axios.defaults.withCredentials = true;
                            axios.post(this.props.node_server_axios + 'signup', {
                                params: {
                                    data: {
                                        email: event.target.email.value.toLowerCase().replace(/\s+/g, ''),
                                        name: event.target.designation.value !== '' ? event.target.designation.value + ' ' + event.target.name.value : event.target.name.value,
                                        password: event.target.password.value,
                                        confpassword: event.target.confpassword.value,
                                        organisationName: event.target.organisation.value,
                                    },
                                    error: this.state.isError
                                },
                            }).then(res => {
                                if (res.data.message !== '') {
                                    this.setState({ response: res.data.message, warningActive: true, signingup: false })
                                }
                                if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                                    window.location = res.data.redirect;
                                }
                            });
                        } else {
                            this.setState({ response: "invalid password", warningActive: true, signingup: false })
                        }
                    } else {
                        this.setState({
                            response: "reCaptcha error !",
                            warningActive: true,
                            signingup: false,
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        response: "Verify captcha to continue",
                        warningActive: true,
                        signingup: false,
                    });
                    this.resetRecaptcha();
                });
        }
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    }

    toggleConfPasswordVisibility = () => {
        this.setState(prevState => ({
            showConfPassword: !prevState.showConfPassword,
        }));
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const skipModal = searchParams.get("action");
        switch (skipModal) {
            case 'findcertificate':
                this.setState({ skipModal: 'Unlock all features using work account' });
                break;
            default:
                this.setState({ skipModal });
        }
    }

    closeSkipModal = () => {
        this.setState({
            skipModal: false
        })
    }

    handleRecaptchaChange = (token) => {
        this.setState({ recaptchaToken: token });
    };

    resetRecaptcha = () => {
        this.setState({ recaptchaKey: Math.random().toString(36).substring(2), recaptchaToken: null });
    };

    checkFirstStage = () => {
        if (this.organisationRef.current.value === "") {
            this.setState({
                warningActive: true,
                response: "If you are not part of any organisation, You may fill the field with the term 'Nil' and proceed.",
            });
            return null
        } else {
            this.setState({
                warningActive: false,
                response: '',
            });
        }

        this.setState({ firstStage: !this.state.firstStage });
    }

    toggleWorkEmailInfo = () => {
        this.setState({ workEmailInfo: !this.state.workEmailInfo });
    }

    render() {
        const { isError } = this.state;
        return (
            <React.Fragment>
                {this.state.skipModal && (
                    <div className='skip-modal-v2'>
                        <div className='container'>
                            <p className='title'>Create New Account</p>
                            <div className='features'>
                                <div className='sub'>
                                    <h1>With<span>work email</span></h1>
                                    <div className='content'>
                                        <p><img src={g1} alt="icon" className='icon'></img> Create & Send certificates instantly</p>
                                        <p><img src={g2} alt="icon" className='icon'></img> Credibility to issued certificates</p>
                                    </div>
                                </div>
                                <div className='sub alt'>
                                    <h1>Without<span>work email</span></h1>
                                    <div className='content'>
                                        <p><img src={b2} alt="icon" className='icon'></img> Verification of account takes 3-4 Days</p>
                                        <p><img src={b1} alt="icon" className='icon'></img> This email will be displayed on issued certificates</p>
                                    </div>
                                </div>
                            </div>
                            <div className='btn-container'>
                                <label onClick={this.toggleWorkEmailInfo}><i className="fa-solid fa-circle-info"></i> {this.state.workEmailInfo ? "An email address provided by a company or organization for professional purposes.(your_name@organisation.com)" : "What is work email ?"}</label>
                                <button onClick={this.closeSkipModal}>Proceed</button>
                            </div>
                        </div>
                    </div>
                )}
                <header className="wrapper">
                    <form method='POST' onSubmit={this.handleSubmit}>
                        <div className="container" style={this.state.firstStage ? { height: 'auto' } : { height: '0px', overflow: 'hidden' }} >
                            {/* <img src={SigninIcon} className="userimage" alt="user signin"></img> */}
                            < h1 > Get Started</h1>
                            <div className='relative block w-100 flex-cr'>
                                {isError.name.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.name}</span></div>
                                )}
                                <div className='name-with-designation'>
                                    <select name='designation'>
                                        <option value='' title='Nil'>Nil</option>
                                        <option value='Dr.' title='Dr. (Doctor)'>Dr.</option>
                                        <option value='Prof.' title='Prof. (Professor)'>Prof.</option>
                                        <option value='Rev.' title='Rev. (Reverend)'>Rev.</option>
                                        <option value='Hon.' title='Hon. (Honorable)'>Hon.</option>
                                        <option value='Sen.' title='Sen. (Senator)'>Sen.</option>
                                        <option value='Rep.' title='Rep. (Representative)'>Rep.</option>
                                        <option value='Gov.' title='Gov. (Governor)'>Gov.</option>
                                    </select>
                                    <input type="text" name='name' className={this.state.response !== '' && this.state.name === '' ? 'error capitalize' : 'capitalize'} placeholder="John Doe" onChange={this.checkValidation} maxLength="60" />
                                </div>
                                <label>Full Name</label>
                            </div>
                            <div className='relative block w-100 flex-cr'>
                                {isError.email.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.email}</span></div>
                                )}
                                <input type="email" name='email' className={this.state.response !== '' && this.state.email === '' ? 'error lowercase' : 'lowercase'} placeholder="johndoe@pronetin.com" onChange={this.checkValidation} />
                                <label>Email</label>
                            </div>
                            <div className='relative block w-100 flex-cr'>
                                <input type="text" name='organisation' className='capitalize' placeholder="Aerobits Developers Pvt. Ltd." ref={this.organisationRef} maxLength="120" />
                                <label>Organisation Name</label>
                            </div>
                            <p className={this.state.warningActive ? 'warning block' : 'warning none'}><i className='fa-solid fa-circle-exclamation'></i> {this.state.response}</p>
                            <button type='button' onClick={this.checkFirstStage}>Proceed <i className="fa-solid fa-arrow-right-long"></i></button>
                            <div id="googleButton"><GoogleButton node_server_axios={this.props.node_server_axios} /></div>
                            <p>Have an account?&nbsp;&nbsp;<a className='secondary' href='/signin'>Sign in <i className="fa-solid fa-arrow-right-to-bracket"></i></a></p>
                            <p><a href='foo'>Introduction</a> . <a href='/help' target='_blank'>Help</a> . <a href='./privacypolicy' target='_blank'>Privacy Policy</a></p>
                        </div>
                        <div className="container" style={!this.state.firstStage ? { height: 'auto' } : { height: '0px', overflow: 'hidden' }}>
                            <button className='back-btn' type='button' onClick={this.checkFirstStage}><i className="fa-solid fa-arrow-left-long"></i> Back</button>
                            <div className='relative block w-100 flex-cr'>
                                {isError.password.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.password}</span></div>
                                )}
                                <div className='password-visibility' onClick={this.togglePasswordVisibility}>{this.state.showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                <input type={this.state.showPassword ? 'text' : 'password'} name='password' autoComplete='new-password' placeholder="********" className={this.state.response !== '' && this.state.password === '' ? 'error' : ''} onChange={this.checkValidation} onKeyDown={(e) => e.key === " " && e.preventDefault()} />
                                <label>Password</label>
                            </div>
                            {this.state.password.length > 0 && (
                                <div className='password-warns'>
                                    <p>{isError.password === 'Atleast 8 characaters required' ? <i className="fa-solid fa-circle-xmark"></i> : <i className="fa-solid fa-circle-check"></i>} Atleast 8 Character</p>
                                    <p>{/[a-zA-Z]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Alphabet</p>
                                    <p>{/[0-9]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Number</p>
                                    <p>{/[\W_]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Special Character</p>
                                </div>
                            )}
                            <div className='relative block w-100 flex-cr'>
                                {isError.confpassword.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.confpassword}</span></div>
                                )}
                                <div className='password-visibility' onClick={this.toggleConfPasswordVisibility}>{this.state.showConfPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                <input type={this.state.showConfPassword ? 'text' : 'password'} name='confpassword' autoComplete='new-password' placeholder="********" id='confpassword' className={this.state.response !== '' && this.state.confpassword === '' ? 'error' : ''} onChange={this.checkValidation} onKeyDown={(e) => e.key === " " && e.preventDefault()} />
                                <label>Confirm Password</label>
                            </div>
                            <p className={this.state.warningActive ? 'warning block' : 'warning none'}><i className='fa-solid fa-circle-exclamation'></i> {this.state.response}</p>
                            <p className='signup-policy'>By Signing up you agree to our Terms & Privacy Policy</p>
                            <ReCAPTCHA
                                key={this.state.recaptchaKey}
                                sitekey="6Le6ccgpAAAAAHHBr4UZ0vbrHsRE-ibYi9jSvxz1"
                                onChange={this.handleRecaptchaChange}
                                className="g-recaptcha"
                            />
                            {this.state.signingup ?
                                <button type='button'>Signing Up...</button>
                                :
                                <button type='submit'>Sign Up</button>
                            }
                            <p><a href='foo'>Introduction</a> . <a href='/help' target='_blank'>Help</a> . <a href='./privacypolicy' target='_blank'>Privacy Policy</a></p>
                        </div>
                    </form>
                </header >
            </React.Fragment >
        );
    }
};

export default App;