import React from 'react';
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            old_email: this.props.old_email,
            new_email: this.props.new_email,
            code: this.props.code,
        };
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'auth-transfer-consent-verification', {
            params: {
                old_email: this.state.old_email,
                new_email: this.state.new_email,
                code: this.state.code,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                window.location = '../../../completed?o=success'
            } else {
                window.location = '../../../completed?o=failed'
            }
        });
    }

    render() {
        return null;
    }
};

export default App;