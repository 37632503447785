import React from 'react';
import './css/element.css';
import axios from 'axios';

class VerifyQRComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusArray: [],
            currentParagraphIndex: 0,
            statusLoading: true,
            statusShow: false,
        };
    }

    checkStatus = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_qr_status_data', {
            params: {
                batch_id: this.props.data.batch_id,
                org_id: this.props.data.issuer_id,
            }
        }).then((res) => {
            if (res.data !== undefined && res.data.message === 'success') {
                this.setState({
                    statusArray: res.data.statusArray,
                    pronetinVerified: res.data.pronetinVerified,
                    statusShow: true,
                });
                this.intervalId = setInterval(this.renderNextParagraph, 1500);
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    renderNextParagraph = () => {
        const { statusArray, currentParagraphIndex } = this.state;
        if (currentParagraphIndex < statusArray.length - 1) {
            this.setState((prevState) => ({
                currentParagraphIndex: prevState.currentParagraphIndex + 1,
            }));
        } else {
            // Stop the interval when all paragraphs have been rendered
            clearInterval(this.intervalId);
            this.setState({
                statusLoading: false,
            });
        }
    };

    render() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;

        const { statusArray, currentParagraphIndex } = this.state;
        const certificate_url = window.location.href.replace('/v/', '/certificate/');

        return (
            <div className='qr-verify-container'>
                <div className='qr-verify-box'>
                    <h2>QR Verification</h2>
                    <div className='wrapper'>
                        <div className='verified'>
                            <i className="fa-regular fa-square-check"></i>
                            <p>Verified</p>
                        </div>
                        <label>Certificate Title</label>
                        <p>{this.props.data.title}</p>
                        <label>Issued By</label>
                        <p>{this.props.data.issuer_name}</p>
                        <label>Issued On</label>
                        <p>{this.props.data.date}</p>
                        <label>Issued To</label>
                        <p>{this.props.data.email}</p>
                        <label>For Event</label>
                        <p>{this.props.data.event}</p>
                        <label>Views</label>
                        <p>{this.props.data.view_count}</p>
                    </div>
                    {!this.state.statusShow && this.props.data.issuer_id !== '00000000-0000-0000-0000-000000000000' && (
                        <div className='wrapper'>
                            <label>Validity</label>
                            <button onClick={this.checkStatus}>Check <i className="fa-regular fa-circle-check"></i></button>
                        </div>
                    )}
                </div>
                {this.state.statusShow && (
                    <div className='qr-verify-box'>
                        <h2>Status {this.state.pronetinVerified === 'true' ? <div className='valid'>Verified Organisation</div> : <div className='expired'>Unknown Organisation</div>}</h2>
                        <div className='wrapper status'>
                            {this.state.statusLoading ?
                                <div className='verified'>
                                    <i className="fa-solid fa-spinner"></i>
                                    <p>Fetching Data</p>
                                </div>
                                :
                                <div className='verified'>
                                    <i className="fa-solid fa-check"></i>
                                    <p>Completed</p>
                                </div>
                            }
                            {statusArray.map((element, index) => {
                                if (element.type === 'signature' && element.content === 'Not Found') {
                                    return null;
                                } else {
                                    return (
                                        <div key={index} style={{ display: index <= currentParagraphIndex ? 'block' : 'none' }}>
                                            <label>{element.type}</label>
                                            <p><i className="fa-regular fa-circle-check"></i>{element.content === 'Not Found' && element.type === 'validity' ? 'Lifetime' : element.content}{element.type === 'validity' && element.content !== 'Not Found' && (element.content.replace(/-/g, '') < formattedDate ? <b className='expired'>Expired</b> : <b className='valid'>Valid</b>)}</p>
                                        </div>
                                    )
                                }
                            })}
                            {!this.state.statusLoading && (
                                <div>
                                    <label>Certificate URL</label>
                                    <a href={certificate_url}><i className="fa-regular fa-circle-check"></i>{certificate_url}</a>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default VerifyQRComponent;