import React from 'react';
import axios from 'axios';

import Warning from '../warning';
import WarningAction from '../../components/warning_action';
import userImage from '../../images/defaultuser.png';
import Cropper from 'react-cropper';

import countryCodes from '../../data/country_codes'

import ProgressLoader from '../../components/progress_loader';

class PersonalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.cropperRef = React.createRef();
        this.state = {
            refreshKey: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            profileImageIcon: userImage,
            imagePreviewUrl: '',
            shareModal: false,
            selectedCountryCode: '+91',
            account_name: '',
            name: '',
            title: '',
            phone: '',
            dob: '',
            gender: '',
            user_category: '',
            user_position: '',
            organisation_name: '',
            progress_loader: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    industries = [
        "Aerospace and Aviation",
        "Agriculture and Agribusiness",
        "Architecture and Design",
        "Art and Creative",
        "Automotive",
        "Biotechnology",
        "Chemical and Petrochemical",
        "Civil Engineering and Construction",
        "Computer Science",
        "Consulting",
        "Education",
        "Education Technology (EdTech)",
        "Electrical",
        "Electronics",
        "Energy and Utilities",
        "Entertainment and Media",
        "Environmental and Sustainability",
        "Fashion and Apparel",
        "Financial Services",
        "Food and Beverage",
        "Gaming and Interactive Entertainment",
        "Government and Public Administration",
        "Information Technology (IT)",
        "Legal and Law Services",
        "Machine Learning (ML)",
        "Manufacturing",
        "Marine and Maritime",
        "Mechanical Engineering",
        "Medical and Healthcare",
        "Mining and Natural Resources",
        "Nonprofit and Social Services",
        "Pharmaceuticals",
        "Real Estate and Property Development",
        "Renewable Energy",
        "Research and Development",
        "Retail",
        "Robotics",
        "Social Media and Digital Marketing",
        "Sports and Fitness",
        "Telecommunications",
        "Tourism and Hospitality",
        "Transportation and Logistics",
        "Quantum Computing",
        "Play School",
        "Kindergarten",
        "Primary",
        "Upper Primary",
        "High School",
        "Higher Secondary",
        "Home Schooling",
        "Under Graduate",
        "Post Graduate",
        "Post-Doctoral Studies.",
        "Others"
    ];

    handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        // check file type
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.setState({
                warning: {
                    message: "Please select a JPG or PNG image file.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        } else if (file.size / 1024 > 3000) {
            this.setState({
                warning: {
                    message: "Max file size allowed is 3Mb.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        }

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };

    handleSubmit = async (e) => {
        this.setState({
            progress_loader: true,
        })
        e.preventDefault();
        let reader = new FileReader();
        const cropper = this.cropperRef.current.cropper;
        if (!cropper) {
            return;
        }

        const croppedCanvas = cropper.getCroppedCanvas();
        croppedCanvas.toBlob((blob) => {
            const croppedFile = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
            this.setState({ file: croppedFile });
            const file = croppedFile;
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('image', file);
            axios.post(this.props.node_server_axios + 'update_profile_image', formData
            ).then(res => {
                if (res.data.message === 'updated') {
                    this.setState((prevState) => ({
                        warning: {
                            message: "Profile Image Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        shareModal: false,
                        refreshKey: prevState.refreshKey + 1,
                        profileImageIcon: reader.result,
                        progress_loader: false,
                    }))
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        }, 'image/jpeg');
    };

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'profile_info', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            let profileComplete = 0;
            let user_category = res.data[0].user_info ? JSON.parse(res.data[0].user_info).user_category : undefined;
            let position = res.data[0].user_info ? JSON.parse(res.data[0].user_info).position : undefined;
            // console.log(position);
            if (res.data[0].user_info !== null && res.data[0].user_info !== '' && user_category !== undefined && user_category.length > 0) {
                profileComplete++;
            }
            if (res.data[0].user_info !== null && res.data[0].user_info !== '' && position !== undefined && position.length > 0) {
                profileComplete++;
            }
            if (res.data[0].phone !== null && res.data[0].phone !== '') {
                profileComplete++;
            }
            if (res.data[0].dob !== null && res.data[0].dob !== '') {
                profileComplete++;
            }
            let profilePercentage = parseInt(profileComplete / 4 * 100);
            document.documentElement.style.setProperty('--circle-percentage', (profilePercentage / 100) * 180 + "deg");

            this.setState({
                account_name: res.data[0].name,
                name: res.data[0].name,
                title: res.data[0].designation === null ? '' : res.data[0].designation,
                phone: res.data[0].phone === null ? '' : res.data[0].phone.split("-").pop(),
                selectedCountryCode: res.data[0].phone === null ? '' : res.data[0].phone.split("-")[0],
                dob: res.data[0].dob === null ? '' : res.data[0].dob,
                gender: res.data[0].gender === null ? '' : res.data[0].gender,
                user_category: res.data[0].user_info !== null && JSON.parse(res.data[0].user_info).user_category !== undefined ? JSON.parse(res.data[0].user_info).user_category : '',
                user_position: res.data[0].user_info !== null && JSON.parse(res.data[0].user_info).position !== undefined ? JSON.parse(res.data[0].user_info).position : '',
                organisation_name: res.data[0].user_info !== null && JSON.parse(res.data[0].user_info).organisation_name !== undefined ? JSON.parse(res.data[0].user_info).organisation_name : '',
                last_reset: res.data[0].last_reset,
                profilePercentage: profilePercentage,
            })
        });

        fetch("https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + Date.now())
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        profileImage: true,
                        profileImageIcon: "https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + Date.now(),
                    })
                } else {
                    this.setState({
                        profileImage: false,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if (this.state.changesDetected) {
            event.preventDefault();
            const customMessage = 'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = customMessage;
        }
    };

    toggleSharePanel = () => {
        this.setState({
            shareModal: !this.state.shareModal,
        })
    }

    changeName = (e) => {
        this.setState({
            name: e.target.value,
        })
    }
    changeDesignation = (e) => {
        this.setState({
            title: e.target.value.slice(0, 128),
            changesDetected: true,
        })
    }
    changePhone = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.setState({
            phone: numericValue,
            changesDetected: true,
        })
    }
    changeGender = (e) => {
        this.setState({
            gender: e.target.value,
            changesDetected: true,
        })
    }
    changeDob = (e) => {
        this.setState({
            dob: e.target.value,
            changesDetected: true,
        })
    }
    handleCountryCodeChange = (e) => {
        this.setState({
            selectedCountryCode: e.target.value,
            changesDetected: true,
        });
    };
    changeUserCategory = (e) => {
        this.setState({
            user_category: e.target.value,
            changesDetected: true,
        })
    }
    changeUserPosition = (e) => {
        this.setState({
            user_position: e.target.value,
            changesDetected: true,
        })
    }
    changeOrganisationName = (e) => {
        this.setState({
            organisation_name: e.target.value,
            changesDetected: true,
        })
    }

    updateProfile = () => {
        axios.post(this.props.node_server_axios + 'update_profile_info', {
            params: {
                email: this.props.email,
                title: this.state.title,
                phone: this.state.selectedCountryCode + "-" + this.state.phone,
                gender: this.state.gender,
                dob: this.state.dob,
                user_category: this.state.user_category,
                position: this.state.user_position,
                organisation_name: this.state.organisation_name,
            },
        }).then(res => {
            if (res.data.message === 'updated') {
                let profileComplete = 0
                if (this.state.user_category !== null && this.state.user_category !== '') {
                    profileComplete++;
                }
                if (this.state.user_position !== null && this.state.user_position !== '') {
                    profileComplete++;
                }
                if (this.state.phone !== null && this.state.phone !== '') {
                    profileComplete++;
                }
                if (this.state.dob !== null && this.state.dob !== '') {
                    profileComplete++;
                }
                let profilePercentage = parseInt(profileComplete / 4 * 100);
                document.documentElement.style.setProperty('--circle-percentage', (profilePercentage / 100) * 180 + "deg");

                this.setState({
                    warning: {
                        message: "Profile Updated.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    profilePercentage: profilePercentage,
                    changesDetected: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        });
    }

    requestNameChange = async () => {
        if (this.state.name !== this.state.account_name && this.state.name.length > 0) {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Change Name",
                    subtext: "The Name can be changed only once in 90 days, Are you sure you want to continue?",
                    type: "success",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: true,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
            this.setState({
                progress_loader: true,
            })
            let currentDate = new Date();
            let year = currentDate.getFullYear().toString();
            let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            let day = currentDate.getDate().toString().padStart(2, '0');
            let formattedDate = year + month + day;

            if (this.state.last_reset === null || parseInt(formattedDate) - parseInt(this.state.last_reset) > 90) {
                axios.post(this.props.node_server_axios + 'update_profile_name', {
                    params: {
                        email: this.props.email,
                        name: this.state.name,
                        last_reset: formattedDate,
                    },
                }).then(async res => {
                    if (res.data.message === 'updated') {
                        await axios.post(this.props.node_server_axios + 'force_update_session');
                        this.setState({
                            warning: {
                                message: "Profile Name Updated.",
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            progress_loader: false,
                        })
                        this.props.fetchDataUpdate({ type: "name", value: this.state.name });
                    } else {
                        this.setState({
                            warning: {
                                message: "Sorry, An error has occoured.",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            },
                            progress_loader: false,
                        })
                    }
                });
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, Name can be change only after " + (90 - (parseInt(formattedDate) - parseInt(this.state.last_reset))) + " days.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        } else {
            this.setState({
                warning: {
                    message: "Edit your name before saving.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    render() {
        let { imagePreviewUrl } = this.state;
        const today = new Date().toISOString().split('T')[0];
        return (
            <React.Fragment>
                {this.state.shareModal && (
                    <div className='element-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Upload Image</h1>
                            </div>
                            {imagePreviewUrl ?
                                <Cropper
                                    style={{ height: 400, width: "100%" }}
                                    ref={this.cropperRef}
                                    src={imagePreviewUrl}
                                    aspectRatio={1} // Square shape
                                    guides={true}
                                    dragMode="crop"
                                />
                                :
                                <p>Choose your profile image</p>
                            }
                            <div className='container'>
                                <input type='file' placeholder='Email' accept=".jpg,.png" onChange={this.handleImageChange} />
                                <button type="button" onClick={this.handleSubmit}>Upload</button>
                            </div>
                        </div>
                    </div>
                )}
                <div className='profile-icon-edit'>
                    <img key={this.state.refreshKey} src={this.state.profileImageIcon} alt="user icon"></img>
                    <button className='edit-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-pen"></i></button>
                </div>
                <h1><span>Welcome, </span>{this.state.account_name}</h1>
                <p>Manage your info, profile and username to make Pronetin work better for you.</p>
                <div className='account-container'>
                    <div className='container'>
                        <h2>Basic Info</h2>
                        <p className='small-font'>Some info may be visible to other people.</p>
                        <div className='column-container'>
                            <p>Name</p>
                            <div className='row-container'>
                                <input className='gray' type='text' placeholder='Full Name' name='name' value={this.state.name} onChange={this.changeName} maxLength="60"></input>
                                <button onClick={this.requestNameChange}>Save Name</button>
                            </div>
                            <p className='info'>Name can be only changed once in 90 days</p>
                        </div>
                        <div className='column-container'>
                            <p>Date of birth</p>
                            <div className='row-container'>
                                <input type='date' placeholder='DOB' name='dob' id='date-dob' value={this.state.dob} onChange={this.changeDob} max={today}></input>
                            </div>
                        </div>
                        <div className='column-container'>
                            <p>Gender</p>
                            <div className='row-container'>
                                <select type='text' placeholder='Gender' name='gender' value={this.state.gender} onChange={this.changeGender}>
                                    <option value={'male'}>Male</option>
                                    <option value={'female'}>Female</option>
                                    <option value={'other'}>Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='fg-1'></div>
                        <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> Save</button>
                    </div>
                    <div className='container'>
                        <h2>Contact Info</h2>
                        <p className='small-font'>Some info may be visible to other users so that they can connect with you easily.</p>
                        <div className='column-container'>
                            <p>Registered Email</p>
                            <div className='row-container'>
                                <input className='gray' type='text' placeholder='Email' name='email' value={this.props.email} readOnly></input>
                            </div>
                        </div>
                        <div className='column-container'>
                            <p>Phone</p>
                            <div className='row-container'>
                                <select
                                    value={this.state.selectedCountryCode}
                                    onChange={this.handleCountryCodeChange}
                                >
                                    <option value="" disabled>Select Country Code</option>
                                    {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {`${country.name} (${country.code})`}
                                        </option>
                                    ))}
                                </select>
                                <input type='text' inputMode="numeric" pattern="\d*" placeholder='Phone' name='phone' value={this.state.phone} onChange={this.changePhone}></input>
                            </div>
                        </div>

                        <div className='column-container'>
                            <p>Bio</p>
                            <div className='row-container'>
                                <input type='text' placeholder='Few things unique to you...' name='designation' value={this.state.title} onChange={this.changeDesignation}></input>
                            </div>
                        </div>
                        <div className='fg-1'></div>
                        <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> Save</button>
                    </div>
                    <div className='container'>
                        <h2>Professional Info</h2>
                        <p className='small-font'>This info helps to bring customised content to you.</p>
                        <div className='column-container'>
                            <p>About Me</p>
                            <div className='row-container'>
                                <select type='text' placeholder='User Category' value={this.state.user_category} onChange={this.changeUserCategory}>
                                    <option value='' disabled>Choose</option>
                                    <option value={'student'}>Student</option>
                                    <option value={'professional'}>Professional</option>
                                    <option value={'others'}>Other</option>
                                </select>
                                <input type='text' placeholder='Organisation Name' name='organisation_name' value={this.state.organisation_name} onChange={this.changeOrganisationName}></input>
                            </div>
                        </div>

                        <div className='column-container'>
                            <p>Industry / Sector</p>
                            <div className='row-container'>
                                {Array.isArray(this.industries) && (
                                    <select placeholder='Industry' value={this.state.user_position} onChange={this.changeUserPosition}>
                                        <option value='' disabled>Choose</option>
                                        {this.industries.map((element, index) =>
                                            <option value={element} key={index}>{element}</option>
                                        )}
                                    </select>
                                )}
                            </div>
                        </div>
                        <div className='fg-1'></div>
                        <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> Save</button>
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default PersonalComponent;