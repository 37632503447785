import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
// import SmartAds from '../components/smartads';
import Element from '../components/document_public';
import Loading from '../components/loading';
import SideNav from '../components/sidenav';

const Viewer = (props) => {
    const [loading, setLoading] = useState(true);
    const [authEmail, setEmail] = useState('');
    const [name, setName] = useState('');
    const [userType, setUserType] = useState('user');
    const [storage, setStorage] = useState('');
    const [header_user_data, setHeaderUserData] = useState(null);

    let { email } = useParams();
    let { slug } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            try {
                axios.defaults.withCredentials = true;
                const response = await axios.post(props.node_server_axios + 'session');
                if (response.data !== undefined && response.data.email !== undefined && response.data.email !== '') {
                    setEmail(response.data.email);
                    setName(response.data.name);
                    setUserType(response.data.user_type);
                    setStorage(response.data.storage);
                    setHeaderUserData(response.data);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchData();
    }, [props.node_server_axios]);

    return loading ? (
        <React.Fragment>
            <div className="main">
                <Header />
                <Loading />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="main">
                {authEmail ?
                    <Header login={true} user_data={header_user_data} email={authEmail} node_server_axios={props.node_server_axios} />
                    :
                    <Header login={false} node_server_axios={props.node_server_axios} />
                }
                {/* {authEmail === email ?
                    <SideNav name={name} selected="uploadedDocuments" user_type={userType} node_server_axios={props.node_server_axios} storage={storage} />
                    :
                    <SmartAds node_server_axios={props.node_server_axios} />
                } */}
                {authEmail === email && (
                    <SideNav name={name} selected="uploadedDocuments" user_type={userType} node_server_axios={props.node_server_axios} storage={storage} />
                )}
                <Element documentId={slug} email={email} authEmail={authEmail} node_server_axios={props.node_server_axios} />
            </div>
        </React.Fragment>
    );
};

export default Viewer;