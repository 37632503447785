import './css/loading.css';
import React from 'react';

class WarningComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.timeoutID = null;
    }

    handleSendToParent = () => {
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
        }
        this.props.fetchWarningState("")
    }

    componentDidMount() {
        if (this.props.data.autoclose === true) {
            this.timeoutID = setTimeout(this.handleSendToParent.bind(this), 5000)
        }
    }

    render() {
        return (
            <div className={this.props.data.type === 'success' ? 'warning-banner success' : 'warning-banner'}>
                <div className='relative'>
                    <p>{this.props.data.message}</p>
                    {this.props.data.learnmore !== false ? <a href={this.props.data.learnmore}>Learn More</a> : ""}
                </div>
                <button className='close-btn' onClick={this.handleSendToParent}><i className="fa-solid fa-x"></i></button>
            </div>
        );
    }
}

export default WarningComponent;