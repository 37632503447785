import React, { Component } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import '../components/css/invoice.css';

import signature from '../images/invoice_seal.png'

class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceData: null,
            isLoading: true,
            error: null,
            customerCountry: "Nil",
        };
    }

    getDateFromInvoice(invoiceId) {
        const datePart = invoiceId.split('/')[0];
        const year = datePart.substring(0, 4);
        const month = datePart.substring(4, 6);
        const day = datePart.substring(6, 8);
        return `${year}-${month}-${day}`;
    }

    componentDidMount = async () => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        if (id) {
            await fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        customerCountry: data.country_name
                    });
                })
                .catch(error => console.error('Error fetching location data:', error));

            axios.defaults.withCredentials = true;
            await axios.post(this.props.node_server_axios + 'get_invoice_data', {
                params: {
                    invoiceNumber: id,
                    date: this.getDateFromInvoice(id),
                },
            }).then((response) => {
                this.setState({
                    invoiceNumber: id,
                    invoiceData: response.data.invoiceData,
                    isLoading: false
                });
            }).catch((error) => {
                this.setState({
                    error: error.message,
                    isLoading: false
                });
            });
        } else {
            window.location.href = '/'
        }
    }

    generatePDF = () => {
        const invoice = document.getElementById('invoice');

        html2canvas(invoice).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    numberToWords(num) {
        const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
        const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
        const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

        if (num === 0) return "zero";
        if (num < 10) return ones[num];
        if (num < 20) return teens[num - 10];
        if (num < 100) {
            return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + ones[num % 10] : "");
        }
        if (num < 1000) {
            return ones[Math.floor(num / 100)] + " hundred" + (num % 100 !== 0 ? " and " + this.numberToWords(num % 100) : "");
        }
        if (num < 100000) {
            return this.numberToWords(Math.floor(num / 1000)) + " thousand" + (num % 1000 !== 0 ? " " + this.numberToWords(num % 1000) : "");
        }
        if (num < 1000000) {
            return this.numberToWords(Math.floor(num / 100000)) + " lakh" + (num % 100000 !== 0 ? " " + this.numberToWords(num % 100000) : "");
        }
        return "Number out of range";
    }

    renderInvoice() {
        const { invoiceData } = this.state;

        return (
            <div id="invoice" className="invoice-container">
                <h1 className="invoice-title">TAX INVOICE</h1>

                <div className="invoice-header">
                    <div className="left-column">
                        <p>Inv No : <span>{this.state.invoiceNumber}</span></p>
                        <p>Inv Date : <span>{invoiceData.date}</span></p>
                        <h3>Details of Receiver | Billed To:</h3>
                        <p>Name : <span>{invoiceData.name && invoiceData.name.length > 1 ? invoiceData.name : ""}</span></p>
                        <p>Email : <span>{invoiceData.email}</span></p>
                        <p>Address : <span>{invoiceData.address && invoiceData.address.length > 1 ? invoiceData.address : this.state.customerCountry}</span></p>
                    </div>

                    <div className="right-column">
                        <p>Aerobits Developers Pvt. Ltd.</p>
                        <p>Address: <span>Mattoor, Kalady</span></p>
                        <p>PAN : <span>AAUCA3967F</span></p>
                        <p>State Name : <span>Kerala</span></p>
                        <p>Country : <span>India</span></p>
                    </div>
                </div>

                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Service Description</th>
                            <th>Qty</th>
                            <th>Taxable Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceData.items.map((item, index) => {
                            const parsedItem = JSON.parse(item);
                            const qtyUnit = parsedItem.name && parsedItem.name.includes("Plan") ? " Month" : "";
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><b>Pronetin&reg;</b> - {parsedItem.name}</td>
                                    <td>{parsedItem.quantity + qtyUnit}</td>
                                    <td>₹ {(parsedItem.quantity * parsedItem.price).toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div className="invoice-footer">
                    <p className='total'><strong>Total :</strong> ₹{invoiceData.total.toFixed(2)}</p>
                    <hr></hr>
                    <p className='words'>Total amount in words <strong>{this.numberToWords(invoiceData.total)} Rupees Only</strong></p>
                    <hr></hr>
                    <p className="thank-you">Thank you for your business</p>
                    <div className='signature'>
                        <p>Authorised Signatory</p>
                        <img src={signature} alt='signature' />
                    </div>
                </div>
                <div className='fg-1'></div>
                <hr></hr>
                <p>This is a computer generated invoice</p>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.invoiceData && !this.state.isLoading && (this.renderInvoice())}
                <button onClick={this.generatePDF} className="pdfinvoice-download-button">
                    Download PDF
                </button>
            </div>
        );
    }
}

export default Invoice;
