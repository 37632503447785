import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';
import Draggable from 'react-draggable';

import domtoimage from 'dom-to-image';
import download from 'downloadjs';

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';

import jsPDF from 'jspdf';
import ProgressLoader from '../components/progress_loader';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            QRSize: 65,
            sliderValue: 3,
            sliderClass: "slider-3",
            elements: [],
            certView: false,
            view_type: '',
            resetX: 0,
            resetY: 0,
            scaleWidth: 1,
            url: new URL(window.location.href).hostname + `/certificate/${this.props.documentId}`,
            title: '',
            cacheTs: Date.now(),
            progress_loader: false,
            progress_opaque: false,
            progress_message: false,
            csvDataIndex: 0,
            download_type_pdf: false,
            doc_array: false,
            doc_id: false,
            printModal: false,
            printPreset: false,
            printContent: false,
            searchQuery: '',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.post(this.props.node_server_axios + 'get_certificates_by_batch', {
            params: {
                email: this.props.email,
                batch_id: this.props.batch_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'batch found') {
                    var myElement = document.querySelector('.cropper-rel');
                    var width = myElement.offsetHeight > myElement.offsetWidth ? myElement.offsetWidth : myElement.offsetHeight;

                    const updatedData = JSON.parse(res.data.elements);
                    let tableVariables = []
                    if (updatedData.filter(element => element.type === "table").length > 0) {
                        tableVariables = updatedData.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
                    }
                    let variableIndexElements = updatedData.filter(element => element.type !== "image" && element.type !== "signature" && element.type !== "QR" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
                    let variablesLocal = variableIndexElements.map(element => element.variables)
                    let mergedVariablesLocal = [].concat.apply([], variablesLocal);
                    let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
                    uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);

                    this.setState({
                        elements: updatedData,
                        variables: uniqueVariablesLocal,
                        cert_bg: res.data.cert_bg,
                        csvData: JSON.parse(res.data.csvData),
                        certView: true,
                        event_name: res.data.event_name,
                        issue_date: res.data.issue_date,
                        signees: res.data.signees,
                        cert_title: res.data.cert_title,
                        cert_attachment: res.data.cert_attachment,
                        org_id: res.data.org_id,
                        doc_array: res.data.doc_array,
                        doc_id: res.data.doc_array.length > 0 ? res.data.doc_array.find((entry) => entry.email === JSON.parse(res.data.csvData)[0]['[Email]']).docId : false,
                        withdrawn: res.data.withdrawn,
                    })

                    const img = new Image();
                    const imgPromise = new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                    img.src = "https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(res.data.org_id) + "/" + encodeURIComponent(res.data.cert_bg);

                    try {
                        await imgPromise;
                        let cWidth = 904;
                        let cHeight = 640;

                        if (img.naturalWidth > img.naturalHeight) {
                            cWidth = 904;
                            cHeight = 640;
                        } else if (img.naturalWidth < img.naturalHeight) {
                            cWidth = 640;
                            cHeight = 904;
                        } else {
                            console.log("Invalid Size");
                        }
                        this.setState({
                            certWidth: cWidth,
                            certHeight: cHeight,
                            withdrawn_type: cWidth > cHeight ? 'wit0.png' : 'wit0p.png',
                            scaleWidth: myElement.offsetHeight > myElement.offsetWidth ? ((width - 20) / cWidth).toFixed(2) : ((width - 20) / cHeight).toFixed(2),
                        })
                    } catch (error) {
                        console.log("Error loading image:", error);
                    }
                }
            }
        })
    }

    updateZoom = (e) => {
        this.setState({
            sliderValue: e.target.value,
            sliderClass: "slider-" + e.target.value
        })
    }

    downloadCertificate = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
        })
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
            .then(async (dataUrl) => {
                await download(dataUrl, 'certificate.png');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    printPreset: false,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    printPreset: false,
                })
            });
    };

    downloadCertificatePdf = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
        })
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
            .then(async (dataUrl) => {
                const imgData = dataUrl.split(',')[1]; // Remove data URL header
                // Calculate the aspect ratio
                const aspectRatio = elementWidth / elementHeight;
                // Determine orientation based on aspect ratio
                const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
                let pdfWidth, pdfHeight;
                if (orientation === 'landscape') {
                    pdfWidth = 297; // A4 paper width in mm
                    pdfHeight = (pdfWidth * elementHeight) / elementWidth;
                } else {
                    pdfHeight = 297; // A4 paper height in mm
                    pdfWidth = (pdfHeight * elementWidth) / elementHeight;
                }
                // Create a new PDF instance
                const pdf = new jsPDF(orientation, 'mm', 'a4');
                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // Save the PDF
                pdf.save('certificate.pdf');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    printPreset: false,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    printPreset: false,
                })
            });
    };

    printCertificate = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
        });

        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');

        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;

        // Scroll the element into view
        node.scrollIntoView();

        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';

        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        });

        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)' } })
            .then(async (dataUrl) => {
                // Calculate the aspect ratio
                const aspectRatio = elementWidth / elementHeight;
                // Determine orientation based on aspect ratio
                const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
                // Create an iframe for printing
                const printIframe = document.createElement('iframe');
                printIframe.style.position = 'absolute';
                printIframe.style.top = '-10000px';
                document.body.appendChild(printIframe);

                printIframe.onload = () => {
                    const printDocument = printIframe.contentWindow || printIframe.contentDocument;
                    if (printDocument.document) printDocument.document.write('');
                    printDocument.document.open();
                    printDocument.document.write(`
                        <html>
                        <head>
                            <title>Print Certificate</title>
                            <style>
                                @page { size: ${orientation};margin: 0; }  /* Set the page orientation to landscape */
                                body { margin: 0; }  /* Remove default body margin for better alignment */
                                @media print {
                                    body {
                                        margin: 0;  /* Remove default body margin */
                                    }
                                    img {
                                        display: block;  /* Ensure image displays properly */
                                        max-height: 100vh;  /* Limit image height to viewport */
                                        page-break-after: always;  /* Ensure content after image doesn't print */
                                    }
                                    body * {
                                        visibility: hidden;  /* Hide all content initially */
                                    }
                                    body img {
                                        visibility: visible;  /* Make only the image visible */
                                        width: 100%;  /* Adjust the image width to fit the page */
                                        height: auto;  /* Maintain aspect ratio */
                                        max-height: 100vh;  /* Fit within one page */
                                    }
                                }
                            </style>
                        </head>
                        <body>
                            <img src="${dataUrl}" style="width: 100%; height: auto;"/>
                        </body>
                        </html>
                    `);
                    printDocument.document.close();

                    // Trigger the print
                    printDocument.focus(); // Ensure the iframe has focus
                    printDocument.print();

                    // Remove the iframe after printing
                    setTimeout(() => {
                        document.body.removeChild(printIframe);
                    }, 1000);
                };

                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                });

                this.setState({
                    progress_loader: false,
                    printContent: false,
                });
            })
            .catch((error) => {
                console.error('Error occurred while trying to print the image', error);

                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                });

                this.setState({
                    progress_loader: false,
                    printContent: false,
                });
            });
    };


    handleCertDragStop = (e, dragElement) => {
        this.setState({
            resetX: dragElement.x,
            resetY: dragElement.y,
        })
    };


    recenterCertificate = () => {
        this.setState({
            resetX: 0,
            resetY: 0,
        });
    }

    nextCertificate = () => {
        if (this.state.csvDataIndex < this.state.csvData.length - 1) {
            this.setState({
                csvDataIndex: this.state.csvDataIndex + 1,
                doc_id: this.state.doc_array.length > 0 ? this.state.doc_array.find((entry) => entry.email === this.state.csvData[this.state.csvDataIndex + 1]['[Email]']).docId : false,
            });
        } else {
            this.setState({
                csvDataIndex: 0,
                doc_id: this.state.doc_array.length > 0 ? this.state.doc_array.find((entry) => entry.email === this.state.csvData[0]['[Email]']).docId : false,
            });
        }
    }

    prevCertificate = () => {
        if (this.state.csvDataIndex > 0) {
            this.setState({
                csvDataIndex: this.state.csvDataIndex - 1,
                doc_id: this.state.doc_array.length > 0 ? this.state.doc_array.find((entry) => entry.email === this.state.csvData[this.state.csvDataIndex - 1]['[Email]']).docId : false,
            });
        } else {
            this.setState({
                csvDataIndex: this.state.csvData.length - 1,
                doc_id: this.state.doc_array.length > 0 ? this.state.doc_array.find((entry) => entry.email === this.state.csvData[this.state.csvData.length - 1]['[Email]']).docId : false,
            });
        }
    }

    handleDownloadTypeToggle = () => {
        this.setState({
            download_type_pdf: !this.state.download_type_pdf,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.progress_loader && prevState.csvDataIndex !== this.state.csvDataIndex) {
            // The csvDataIndex has changed, indicating a state update
            if (this.state.download_type_pdf) {
                this.captureScreenshotPDF();
            } else {
                this.captureScreenshotImage();
            }
        }
    }

    captureScreenshotPDF = async () => {
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        if (this.state.csvDataIndex < this.state.csvData.length) {
            // Capture the screenshot
            await domtoimage
                .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
                .then(async (dataUrl) => {
                    const imgData = dataUrl.split(',')[1]; // Remove data URL header
                    // Calculate the aspect ratio
                    const aspectRatio = elementWidth / elementHeight;
                    // Determine orientation based on aspect ratio
                    const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
                    let pdfWidth, pdfHeight;
                    if (orientation === 'landscape') {
                        pdfWidth = 297; // A4 paper width in mm
                        pdfHeight = (pdfWidth * elementHeight) / elementWidth;
                    } else {
                        pdfHeight = 297; // A4 paper height in mm
                        pdfWidth = (pdfHeight * elementWidth) / elementHeight;
                    }
                    // Create a new PDF instance
                    const pdf = new jsPDF(orientation, 'mm', 'a4');
                    // Add the image to the PDF
                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                    // Save the PDF
                    pdf.save(this.state.csvData[this.state.csvDataIndex]['[Recepient Name]'] + '.pdf');
                })
                .catch((error) => {
                    console.error('Error occurred while trying to download the image', error);
                });

            // Restore original size
            node.style.width = originalWidth;
            node.style.height = originalHeight;
            element.style.transform = 'scale(1) translate(0%, 0%)';
            //qr.style.transform = 'scale(1) translate(0%, 0%)'
            const excludedElements = element.querySelectorAll('.exclude-scale');
            excludedElements.forEach((excludedElement) => {
                excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
            })

            if (this.state.csvDataIndex < this.state.csvData.length - 1) {
                // Update the csvDataIndex for the next iteration
                this.setState((prevState) => ({
                    csvDataIndex: prevState.csvDataIndex + 1,
                    doc_id:
                        prevState.csvDataIndex + 1 < this.state.csvData.length
                            ? this.state.doc_array[prevState.csvDataIndex + 1].docId
                            : false,
                    progress_message: "Downloading " + (parseInt(this.state.csvDataIndex) + 2) + " / " + this.state.csvData.length + " certificates",
                }));
            } else {
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })

                this.setState({
                    progress_loader: false,
                    progress_opaque: false,
                })
            }
        }
    };

    captureScreenshotImage = async () => {
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        if (this.state.csvDataIndex < this.state.csvData.length) {
            // Capture the screenshot
            await domtoimage
                .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
                .then(async (dataUrl) => {
                    await download(dataUrl, this.state.csvData[this.state.csvDataIndex]['[Recepient Name]'] + '.png');
                })
                .catch((error) => {
                    console.error('Error occurred while trying to download the image', error);
                });

            // Restore original size
            node.style.width = originalWidth;
            node.style.height = originalHeight;
            element.style.transform = 'scale(1) translate(0%, 0%)';
            //qr.style.transform = 'scale(1) translate(0%, 0%)'
            const excludedElements = element.querySelectorAll('.exclude-scale');
            excludedElements.forEach((excludedElement) => {
                excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
            })

            if (this.state.csvDataIndex < this.state.csvData.length - 1) {
                // Update the csvDataIndex for the next iteration
                this.setState((prevState) => ({
                    csvDataIndex: prevState.csvDataIndex + 1,
                    doc_id:
                        prevState.csvDataIndex + 1 < this.state.csvData.length
                            ? this.state.doc_array[prevState.csvDataIndex + 1].docId
                            : false,
                    progress_message: "Downloading " + (parseInt(this.state.csvDataIndex) + 2) + " / " + this.state.csvData.length + " certificates",
                }));
            } else {
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })

                this.setState({
                    progress_loader: false,
                    progress_opaque: false,
                    progress_message: false,
                })
            }
        }
    };

    downloadAll = async (pdf_download, start, stop) => {
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            progress_message: "Downloading " + (parseInt(this.state.csvDataIndex) + 1) + " / " + this.state.csvData.length + " certificates",
        })
        if (pdf_download) {
            this.captureScreenshotPDF();
        } else {
            this.captureScreenshotImage();
        }
    }

    togglePrintModal = () => {
        this.setState({
            printModal: !this.state.printModal,
        })
    }

    requestPrintService = () => {
        axios.post(this.props.node_server_axios + 'request_print_service', {
            params: {
                email: this.props.email,
                batch_id: this.props.batch_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Print Request Received, We will contact you soon.",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        printModal: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "A print request is already open for this batch.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        printModal: false,
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        })
    }

    downloadPreset = async (e) => {
        this.setState({
            printPreset: true,
        }, () => {
            if (this.state.download_type_pdf) {
                this.downloadCertificatePdf(e);
            } else {
                this.downloadCertificate(e);
            }
        })
    }

    printDetails = async (e) => {
        this.setState({
            printContent: true,
        }, () => {
            this.printCertificate(e);
        })
    }

    handleSearch = () => {
        const searchQuery = this.state.searchQuery.toLowerCase();
        const currentIndex = this.state.csvDataIndex;
        const index = this.state.csvData.slice(currentIndex + 1).findIndex((entry) =>
            entry['[Email]'].toLowerCase().includes(searchQuery)
        );
        const nextIndex = index !== -1 ? currentIndex + 1 + index : -1;
        if (nextIndex !== -1) {
            this.setState({
                csvDataIndex: nextIndex,
                doc_id: this.state.doc_array.length > 0 ? this.state.doc_array.find((entry) => entry.email === this.state.csvData[nextIndex]['[Email]']).docId : false,
            });
        } else {
            const startIndex = this.state.csvData.findIndex((entry) =>
                entry['[Email]'].toLowerCase().includes(searchQuery)
            );
            if (startIndex !== -1) {
                this.setState({
                    csvDataIndex: startIndex,
                    doc_id: this.state.doc_array.length > 0 ? this.state.doc_array.find((entry) => entry.email === this.state.csvData[startIndex]['[Email]']).docId : false,
                });
            } else {
                this.setState({
                    warning: {
                        message: "No certificates match this email",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                });
            }
        }
    }

    updateSearchQuery = (e) => {
        this.setState({ searchQuery: e.target.value, });
    }

    render() {
        const { csvDataIndex } = this.state;
        return (
            <React.Fragment>
                {this.state.printModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.togglePrintModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Pronetin Print Service</h1>
                            </div>
                            <p>We will print and deliver all the certificates in this batch to your address, Futher communications will be via email.</p>
                            <button type="button" onClick={this.requestPrintService}>Request Service <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                )}
                <div className="element max-element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Certificate<label>viewer</label></h1>
                            </div>
                        </div>
                        <div className='tiles marketplace pg-certficate_viewer'>
                            <div className='sub-nav'>
                                <p>Choose certificate</p>
                                <div className='container-right'>
                                    <button onClick={this.prevCertificate} className='download-btn center'><i className="fa-solid fa-arrow-left"></i></button>
                                    <button onClick={this.nextCertificate} className='download-btn center'><i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                                <p>Search</p>
                                <div className='container-right'>
                                    <input type="text" placeholder='Email' value={this.state.searchQuery} onChange={this.updateSearchQuery} />
                                    <button className='search-btn' onClick={this.handleSearch}><i className="fa-solid fa-magnifying-glass"></i></button>
                                </div>
                                <p>Choose file format</p>
                                <div className='container-right'>
                                    <div className='download-btn-wrapper'>
                                        <p><i className="fa-regular fa-image"></i> Image</p>
                                        <label className="switch">
                                            <input type="checkbox" checked={this.state.download_type_pdf} onChange={this.handleDownloadTypeToggle} />
                                            <span className="slider"></span>
                                        </label>
                                        <p><i className="fa-regular fa-file-pdf"></i> PDF</p>
                                    </div>
                                </div>
                                <p>Download</p>
                                <div className='container-right'>
                                    <button className='download-btn' onClick={this.state.download_type_pdf ? this.downloadCertificatePdf : this.downloadCertificate}><i className="fa-solid fa-chevron-down"></i> <p>Download</p></button>
                                    <button className='download-btn gray' onClick={() => { this.downloadAll(this.state.download_type_pdf, 0, true) }}><i className="fa-solid fa-angles-down"></i> <p>Download {this.state.csvData && (this.state.csvDataIndex + 1 + "-" + this.state.csvData.length)}</p></button>
                                </div>
                                <p>Event time printing</p>
                                <div className='container-right'>
                                    <button className='download-btn' onClick={this.printDetails}><i className="fa-regular fa-address-card"></i><p>Print Details</p></button>
                                    <button className='download-btn gray' onClick={this.downloadPreset}><i className="fa-regular fa-image"></i><p>Download Preset</p></button>
                                </div>
                                <p>Print Service</p>
                                <div className='container-right'>
                                    <button className='download-btn green' onClick={this.togglePrintModal}><i className="fa-solid fa-print"></i> <p>Request Print</p></button>
                                </div>
                            </div>
                            <div className='wrapper'>
                                <button className='recenter-certificate' onClick={this.recenterCertificate}><i className="fa-solid fa-arrows-to-circle"></i></button>
                                <div className='cropper-rel'>
                                    {this.state.certView && (
                                        <div className='image-container'>
                                            <div className={this.state.sliderClass} style={{ '--scale-width': this.state.scaleWidth, transformOrigin: "top left" }}>
                                                <Draggable position={{ x: this.state.resetX, y: this.state.resetY }} onStop={this.handleCertDragStop}>
                                                    <div className='cert-container' id="capture" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', width: this.state.certWidth, height: this.state.certHeight }}>
                                                        <div className='elements of-hidden'>
                                                            {!this.state.printContent && (<img src={this.state.withdrawn !== 'true' ? "https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_bg) + "?cacheblock=" + this.state.cacheTs : "https://pronetin-new.s3.amazonaws.com/templates/certificates/background/" + this.state.withdrawn_type + "?cacheblock=" + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template' className='exclude-scale'></img>)}
                                                            {this.state.elements && this.state.elements.length ?
                                                                this.state.elements.map((element, index) => {
                                                                    if (this.state.printContent) {
                                                                        if (element.content.includes('[') && element.content.includes(']')) {
                                                                            if (element.type === "table") {
                                                                                return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} csvDataIndex={csvDataIndex} />
                                                                            } else if (element.type !== "QR") {
                                                                                return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} csvDataIndex={csvDataIndex} />
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        } else {
                                                                            return null
                                                                        }
                                                                    } else if (this.state.printPreset && element.content.includes('[') && element.content.includes(']')) {
                                                                        return null;
                                                                    } else {
                                                                        if (element.type === "image") {
                                                                            return <ViewImage element_id={index} key={element.timeStamp + "-" + index} data={element} />
                                                                        } else if (element.type === "table") {
                                                                            return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} csvDataIndex={csvDataIndex} />
                                                                        } else if (element.type === "signature") {
                                                                            return <ViewSignee element_id={index} key={element.timeStamp + "-" + index} data={element} node_server_axios={this.props.node_server_axios} batch_id={this.props.batch_id} />
                                                                        } else if (element.type !== "QR") {
                                                                            return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} csvDataIndex={csvDataIndex} doc_id={this.state.doc_id} />
                                                                        } else {
                                                                            return null
                                                                        }
                                                                    }
                                                                })
                                                                : ""}
                                                            <ViewQR data={this.state.doc_id} size={this.state.QRSize} position={this.state.elements.find(element => element.type === "QR")} printPreset={this.state.printPreset} />
                                                        </div>
                                                    </div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='cropper-zoom'>
                                    <div className='container'>
                                        <p>Zoom</p>
                                        <input type="range" min="1" max="7" className="slider" defaultValue={this.state.sliderValue} onChange={this.updateZoom}></input>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} message={this.state.progress_message} />)
                }
            </React.Fragment>
        );
    }
}

export default CertificateViewerComponent;