import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
import Loading from '../components/loading';
import Element from '../components/download_batch';

const Viewer = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user');
    const [userId, setUserId] = useState('');
    const [storage, setStorage] = useState('');
    let { slug } = useParams();

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'session')
            .then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '' && res.data.user_type === 'organisation') {
                    setLoading(false);
                    setName(res.data.name);
                    setEmail(res.data.email);
                    setUserType(res.data.user_type);
                    setUserId(res.data.user_id);
                    setStorage(res.data.storage);
                } else {
                    window.location = '/signin';
                }
            })
            .catch(() => {
                window.location = '/signin';
            });
    }, [props.node_server_axios, slug]);

    return loading ? (
        <React.Fragment>
            <div className="main">
                <Header />
                <Loading />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="download-page-wrapper">
                <div className='main no-margin batch-download-wrapper'>
                    <Header login={false} node_server_axios={props.node_server_axios} />
                    <Element email={email} name={name} user_type={userType} userId={userId} storage={storage} batch_id={slug} node_server_axios={props.node_server_axios} />
                </div>
                <div className='editor-nosupport center'>
                    <i className="fa-solid fa-desktop"></i>
                    <p>Signin using PC / Larger Display</p>
                    <button onClick={() => { window.location = './' }}><i className="fa-solid fa-arrow-left"></i> Back</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Viewer;