import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
import Element from '../components/verifyqr';
import ProgressLoader from '../components/progress_loader';

const Viewer = (props) => {
    const [certData, setCertData] = useState('');
    const [progress_loader, setProgressLoader] = useState(true);

    let { slug } = useParams();

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'get_verify_qr_data', { params: { document_id: slug } })
            .then((res) => {
                if (res.data.message === 'success') {
                    setCertData(res.data.docData);
                    setProgressLoader(false);
                } else {
                    window.location = '../'
                }
            })
    }, [props.node_server_axios, slug]);

    return (
        <React.Fragment>
            <div className="main">
                <Header login={false} node_server_axios={props.node_server_axios} />
                <Element data={certData} node_server_axios={props.node_server_axios} />
            </div>
            {
                progress_loader && (<ProgressLoader />)
            }
        </React.Fragment>
    );
};

export default Viewer;