import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';

class App extends React.Component {
    onSuccess = (user) => {
        const decoded = jwtDecode(user.credential);
        console.log(decoded);

        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'signin_google', {
            params: {
                data: {
                    email: decoded.email,
                    name: decoded.name,
                    picture: decoded.picture,
                    email_verified: decoded.email_verified
                },
            },
        }).then(async (res) => {
            console.log(res.data.redirect, res.data.message)
            if (res.data.redirect !== '' && res.data.redirect !== undefined && res.data.message === 'verifiedLogin') {
                window.location = res.data.redirect;
            }
        });
    };

    onFailure = (error) => {
        console.error('Login failed:', error);
        // Handle login failure
    };

    render() {
        return (
            <GoogleLogin
                clientId="245021852067-kpv5m5gabflp8tavibb6cokq8jgb16n5.apps.googleusercontent.com"
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                isSignedIn
            />
        );
    }
}

export default App;