const emailDomains = [
    "123mail.org",
    "150mail.com",
    "150ml.com",
    "16mail.com",
    "2-mail.com",
    "4email.net",
    "50mail.com",
    "aim.com",
    "airpost.net",
    "alice.it",
    "aliceadsl.fr",
    "allmail.net",
    "aol.com",
    "arcor.de",
    "att.net",
    "attorneymail.ch",
    "barmail.ch",
    "bellsouth.net",
    "bestmail.us",
    "bigpond.com",
    "bigpond.net.au",
    "bk.ru",
    "bluewin.ch",
    "blueyonder.co.uk",
    "bol.com.br",
    "centurytel.net",
    "charter.net",
    "chello.nl",
    "club-internet.fr",
    "cluemail.com",
    "collaborative.li",
    "comcast.net",
    "cox.net",
    "diplomail.ch",
    "earthlink.net",
    "elitemail.org",
    "emailcorner.net",
    "emailengine.net",
    "emailengine.org",
    "emailgroups.net",
    "emailplus.org",
    "emailuser.net",
    "eml.cc",
    "excite.com",
    "f-m.fm",
    "facebook.com",
    "fast-email.com",
    "fast-mail.org",
    "fastem.com",
    "fastemail.us",
    "fastemailer.com",
    "fastest.cc",
    "fastimap.com",
    "fastmail.cn",
    "fastmail.co.uk",
    "fastmail.com",
    "fastmail.com.au",
    "fastmail.de",
    "fastmail.es",
    "fastmail.fm",
    "fastmail.fr",
    "fastmail.im",
    "fastmail.in",
    "fastmail.jp",
    "fastmail.mx",
    "fastmail.net",
    "fastmail.nl",
    "fastmail.org",
    "fastmail.se",
    "fastmail.to",
    "fastmail.tw",
    "fastmail.uk",
    "fastmail.us",
    "fastmailbox.net",
    "fastmessaging.com",
    "fea.st",
    "fmail.co.uk",
    "fmailbox.com",
    "fmgirl.com",
    "fmguy.com",
    "free.fr",
    "freedommail.ch",
    "freenet.de",
    "frontiernet.net",
    "ftml.net",
    "gandi.net",
    "gmail.com",
    "gmx.at",
    "gmx.ch",
    "gmx.com",
    "gmx.de",
    "gmx.net",
    "googlemail.com",
    "groupoffice.ch",
    "h-mail.us",
    "hailmail.net",
    "hetnet.nl",
    "hey.com",
    "home.nl",
    "hotmail.co.uk",
    "hotmail.com",
    "hotmail.de",
    "hotmail.es",
    "hotmail.fr",
    "hotmail.it",
    "hushmail.com",
    "icloud.com",
    "ig.com.br",
    "imap-mail.com",
    "imap.cc",
    "imapmail.org",
    "inbox.ru",
    "inoutbox.com",
    "internet-e-mail.com",
    "internet-mail.org",
    "internet.ru",
    "internetemails.net",
    "internetmailing.net",
    "jetemail.net",
    "journalistmail.ch",
    "juno.com",
    "justemail.net",
    "keemail.me",
    "kolabnow.ch",
    "kolabnow.com",
    "laposte.net",
    "legalprivilege.ch",
    "letterboxes.org",
    "libero.it",
    "libertymail.co",
    "libertymail.net",
    "list.ru",
    "live.ca",
    "live.co.uk",
    "live.com",
    "live.com.au",
    "live.fr",
    "live.it",
    "live.nl",
    "lycos.com",
    "mac.com",
    "mail-central.com",
    "mail-page.com",
    "mail.com",
    "mail.ru",
    "mailandftp.com",
    "mailatlaw.ch",
    "mailbolt.com",
    "mailc.net",
    "mailcan.com",
    "mailforce.net",
    "mailftp.com",
    "mailhaven.com",
    "mailhost.work",
    "mailhouse.biz",
    "mailingaddress.org",
    "mailite.com",
    "mailmight.com",
    "mailnew.com",
    "mailsent.net",
    "mailservice.ms",
    "mailup.net",
    "mailworks.org",
    "me.com",
    "medmail.ch",
    "messagebox.email",
    "ml1.net",
    "mm.st",
    "msn.com",
    "myfastmail.com",
    "mykolab.ch",
    "mykolab.com",
    "mymacmail.com",
    "myswissmail.ch",
    "neuf.fr",
    "nospammail.net",
    "ntlworld.com",
    "offshore.rocks",
    "opengroupware.ch",
    "optonline.net",
    "optusnet.com.au",
    "orange.fr",
    "outlook.com",
    "ownmail.net",
    "petml.com",
    "planet.nl",
    "posteo.af",
    "posteo.at",
    "posteo.be",
    "posteo.ca",
    "posteo.ch",
    "posteo.cl",
    "posteo.co",
    "posteo.co.uk",
    "posteo.com.br",
    "posteo.cr",
    "posteo.cz",
    "posteo.de",
    "posteo.dk",
    "posteo.ee",
    "posteo.es",
    "posteo.eu",
    "posteo.fi",
    "posteo.gl",
    "posteo.gr",
    "posteo.hn",
    "posteo.hr",
    "posteo.hu",
    "posteo.ie",
    "posteo.in",
    "posteo.is",
    "posteo.it",
    "posteo.jp",
    "posteo.la",
    "posteo.li",
    "posteo.lt",
    "posteo.lu",
    "posteo.me",
    "posteo.mx",
    "posteo.my",
    "posteo.net",
    "posteo.nl",
    "posteo.no",
    "posteo.nz",
    "posteo.org",
    "posteo.pe",
    "posteo.pl",
    "posteo.pm",
    "posteo.pt",
    "posteo.ro",
    "posteo.ru",
    "posteo.se",
    "posteo.sg",
    "posteo.si",
    "posteo.tn",
    "posteo.uk",
    "posteo.us",
    "postinbox.com",
    "postpro.net",
    "pressmail.ch",
    "proinbox.com",
    "promessage.com",
    "protonmail.com",
    "qq.com",
    "rambler.ru",
    "rbox.co",
    "rbox.me",
    "rbx.email",
    "rbx.life",
    "rbx.run",
    "realemail.net",
    "reallyfast.biz",
    "reallyfast.info",
    "rediffmail.com",
    "rnbx.uk",
    "rocketmail.com",
    "runbox.at",
    "runbox.biz",
    "runbox.bz",
    "runbox.ch",
    "runbox.co",
    "runbox.co.in",
    "runbox.com",
    "runbox.dk",
    "runbox.email",
    "runbox.eu",
    "runbox.is",
    "runbox.it",
    "runbox.ky",
    "runbox.li",
    "runbox.me",
    "runbox.nl",
    "runbox.no",
    "runbox.uk",
    "runbox.us",
    "rushpost.com",
    "sbcglobal.net",
    "sent.as",
    "sent.at",
    "sent.com",
    "sfr.fr",
    "shaw.ca",
    "sky.com",
    "skynet.be",
    "speedpost.net",
    "speedymail.org",
    "ssl-mail.com",
    "swift-mail.com",
    "swissgroupware.ch",
    "switzerlandmail.ch",
    "sympatico.ca",
    "t-online.de",
    "telenet.be",
    "terra.com.br",
    "the-fastest.net",
    "the-quickest.com",
    "theinternetemail.com",
    "tin.it",
    "tiscali.co.uk",
    "tiscali.it",
    "trusted-legal-mail.ch",
    "tuta.io",
    "tutamail.com",
    "tutanota.com",
    "tutanota.de",
    "uol.com.br",
    "verizon.net",
    "veryfast.biz",
    "veryspeedy.net",
    "virgilio.it",
    "voila.fr",
    "wanadoo.fr",
    "warpmail.net",
    "web.de",
    "windstream.net",
    "xobnur.uk",
    "xsmail.com",
    "yahoo.ca",
    "yahoo.co.id",
    "yahoo.co.in",
    "yahoo.co.jp",
    "yahoo.co.uk",
    "yahoo.com",
    "yahoo.com.ar",
    "yahoo.com.au",
    "yahoo.com.br",
    "yahoo.com.mx",
    "yahoo.com.sg",
    "yahoo.de",
    "yahoo.es",
    "yahoo.fr",
    "yahoo.in",
    "yahoo.it",
    "yandex.ru",
    "yepmail.net",
    "ymail.com",
    "your-mail.com",
    "zohomail.in",
    "zonnet.nl",
    "gmail.com"
];

export default emailDomains;