import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';
import Draggable from 'react-draggable';

import domtoimage from 'dom-to-image';
import download from 'downloadjs';

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import Warning from './warning';
import WarningAction from '../components/warning_action';

import jsPDF from 'jspdf';
import ProgressLoader from '../components/progress_loader';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            QRSize: 65,
            sliderValue: 3,
            sliderClass: "slider-3",
            pinchStartDistance: null,
            initialSliderValue: 1,
            elements: [],
            certView: false,
            view_type: '',
            resetX: 0,
            resetY: 0,
            scaleWidth: 1,
            scaleFactor: 1,
            moreBtn: 'more',
            url: new URL(window.location.href).hostname + `/awards/${this.props.documentId}`,
            title: '',
            description: '',
            progress_loader: false,
            progress_opaque: false,
            cacheTs: Date.now(),
            hideFixedControls: false,
        };
        this.handleShare = this.handleShare.bind(this);
        this.imageDivRef = React.createRef();
        this.certDivRef = React.createRef();
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        this.setState({
            progress_loader: true,
            progress_opaque: true,
        })
        axios.post(this.props.node_server_axios + 'get_appreciation', {
            params: {
                email: this.props.email,
                documentId: this.props.documentId,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'certificate found') {

                    var myElement = document.querySelector('.cropper-rel');
                    var width = myElement.offsetHeight > myElement.offsetWidth ? myElement.offsetWidth : myElement.offsetHeight;

                    const updatedData = JSON.parse(res.data.elements);
                    let tableVariables = []
                    if (updatedData.filter(element => element.type === "table").length > 0) {
                        tableVariables = updatedData.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
                    }
                    let variableIndexElements = updatedData.filter(element => element.type !== "image" && element.type !== "signature" && element.type !== "QR" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
                    let variablesLocal = variableIndexElements.map(element => element.variables)
                    let mergedVariablesLocal = [].concat.apply([], variablesLocal);
                    let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
                    uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);

                    this.setState({
                        elements: updatedData,
                        variables: uniqueVariablesLocal,
                        org_id: res.data.org_id,
                        cert_bg: res.data.cert_bg,
                        csvData: JSON.parse(res.data.csvData),
                        certView: true,
                        accepted: res.data.accepted,
                        event_name: res.data.event_name,
                        issue_date: res.data.issue_date,
                        pronetin_verified: res.data.pronetin_verified,
                        signees: res.data.signees,
                        org_name: res.data.org_name,
                        cert_title: res.data.cert_title,
                        cert_attachment: res.data.cert_attachment,
                        domain: res.data.domain,
                        contact: res.data.contact,
                        title: res.data.cert_title,
                        description: 'Document received for ' + res.data.event_name + ' on ' + res.data.issue_date,
                    }, () => {
                        this.props.documentLoaded(true);
                    })

                    const img = new Image();
                    const imgPromise = new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                    img.src = "https://pronetin-new.s3.amazonaws.com/batch/00000000-0000-0000-0000-000000000000/" + encodeURIComponent(res.data.cert_bg);

                    try {
                        await imgPromise;
                        let cWidth = 904;
                        let cHeight = 640;

                        if (img.naturalWidth > img.naturalHeight) {
                            cWidth = 904;
                            cHeight = 640;
                        } else if (img.naturalWidth < img.naturalHeight) {
                            cWidth = 640;
                            cHeight = 904;
                        } else {
                            console.log("Invalid Size");
                        }
                        this.setState({
                            certWidth: cWidth,
                            certHeight: cHeight,
                            scaleWidth: ((width - 20) / cHeight).toFixed(2),
                            scaleFactor: myElement.offsetHeight > myElement.offsetWidth ? ((width - 20) / cWidth).toFixed(2) : ((width - 20) / cHeight).toFixed(2),
                            progress_loader: false,
                            progress_opaque: false,
                        })
                    } catch (error) {
                        console.log("Error loading image:", error);
                    }
                }
            }
        })
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    componentDidUpdate = () => {
        if (this.state.initialX === undefined || this.state.initialY === undefined) {
            let imgDivWidth = 0;
            let imgDivHeight = 0;
            let certDivWidth = 0;
            let certDivHeight = 0;
            if (this.imageDivRef.current) {
                imgDivWidth = this.imageDivRef.current.clientWidth;
                imgDivHeight = this.imageDivRef.current.clientHeight;
            }
            if (this.certDivRef.current) {
                certDivWidth = parseInt(this.certDivRef.current.clientWidth * this.state.scaleFactor);
                certDivHeight = parseInt(this.certDivRef.current.clientHeight * this.state.scaleFactor);
            }
            let initialX = (imgDivWidth - certDivWidth) < 0 ? 0 : (imgDivWidth - certDivWidth)
            let initialY = (imgDivHeight - certDivHeight) < 0 ? 0 : (imgDivHeight - certDivHeight)
            if (this.imageDivRef.current && this.certDivRef.current && this.state.scaleFactor !== 1) {
                this.setState({
                    initialX: initialX / 2,
                    initialY: initialY / 2,
                    resetX: initialX / 2,
                    resetY: initialY / 2,
                })
            }
        }
    }

    updateZoom = (newSliderValue) => {
        this.setState({
            sliderValue: newSliderValue,
            sliderClass: "slider-" + newSliderValue
        })
    }

    updateZoomInput = (e) => {
        this.setState({
            sliderValue: e.target.value,
            sliderClass: "slider-" + e.target.value
        })
    }

    handlePinchStart = (e) => {
        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            this.setState({
                pinchStartDistance: distance,
                initialSliderValue: this.state.sliderValue,
            });
        }
    };

    handlePinchMove = (e) => {
        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            const zoomFactor = distance / this.state.pinchStartDistance;
            const newSliderValue = parseInt(this.state.initialSliderValue * zoomFactor);

            // Limit the zoom to a specific range (e.g., between 1 and 7)
            if (newSliderValue >= 1 && newSliderValue <= 7) {
                this.updateZoom(newSliderValue);
            }
        }
    };

    handlePinchEnd = () => {
        this.setState({
            pinchStartDistance: null,
        });
    };

    downloadCertificate = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
            .then(async (dataUrl) => {
                await download(dataUrl, 'certificate.png');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    downloadCertificatePdf = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');
        const element = document.querySelector('#capture .elements');
        //const qr = document.getElementById('qr-div');
        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;
        // Scroll the element into view
        node.scrollIntoView();
        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth * 2 + 'px';
        node.style.height = elementHeight * 2 + 'px';
        element.style.transform = 'scale(2) translate(25%, 25%)';
        //qr.style.transform = 'scale(2) translate(25%, 25%)'
        const excludedElements = element.querySelectorAll('.exclude-scale');
        excludedElements.forEach((excludedElement) => {
            excludedElement.style.transform = 'scale(0.5) translate(-50%, -50%)';
        })
        // Capture the screenshot
        domtoimage
            .toPng(node, { quality: 1, style: { transform: 'scale(2) translate(25%, 25%)', } })
            .then(async (dataUrl) => {
                const imgData = dataUrl.split(',')[1]; // Remove data URL header
                // Calculate the aspect ratio
                const aspectRatio = elementWidth / elementHeight;
                // Determine orientation based on aspect ratio
                const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
                let pdfWidth, pdfHeight;
                if (orientation === 'landscape') {
                    pdfWidth = 297; // A4 paper width in mm
                    pdfHeight = (pdfWidth * elementHeight) / elementWidth;
                } else {
                    pdfHeight = 297; // A4 paper height in mm
                    pdfWidth = (pdfHeight * elementWidth) / elementHeight;
                }
                // Create a new PDF instance
                const pdf = new jsPDF(orientation, 'mm', 'a4');
                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // Save the PDF
                pdf.save('certificate.pdf');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                element.style.transform = 'scale(1) translate(0%, 0%)';
                //qr.style.transform = 'scale(1) translate(0%, 0%)'
                const excludedElements = element.querySelectorAll('.exclude-scale');
                excludedElements.forEach((excludedElement) => {
                    excludedElement.style.transform = 'scale(1) translate(-0%, -0%)';
                })
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    deleteDocument = async (e) => {
        e.preventDefault();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Document",
                subtext: "Once the document is deleted it cannot be recovered. Do you want to Continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'appreciation_delete', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    window.location = '../'
                } else {
                    this.setState({
                        warning: {
                            message: "Failed to delete document.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        })
    }

    handleCertDragStop = (e, dragElement) => {
        this.setState({
            resetX: dragElement.x,
            resetY: dragElement.y,
        })
    };

    acceptCertificate = (e) => {
        e.preventDefault();
        axios.post(this.props.node_server_axios + 'document_accept', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'accepted') {
                    this.setState({
                        warning: {
                            message: "Certificate Accepted.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        accepted: 'true',
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        })
    }

    rejectCertificate = (e) => {
        e.preventDefault();
        axios.post(this.props.node_server_axios + 'document_reject', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
                type: "appreciation",
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    window.location = '../'
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        // var wrapperElem = document.getElementsByClassName('wrapper')[0];
        if (subNav.style.display !== 'flex') {
            subNav.style.display = 'flex';
            // wrapperElem.style.display = 'none';
            this.setState({
                moreBtn: 'back',
                hideFixedControls: true,
            })
        } else {
            subNav.style.display = 'none';
            // wrapperElem.style.display = 'flex';
            this.setState({
                moreBtn: 'more',
                hideFixedControls: false,
            })
        }
    }

    copyUrl = (e) => {
        e.preventDefault();
        const url = this.state.url;
        const textarea = document.createElement("textarea");
        textarea.value = url;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            warning: {
                message: "URL copied to clipboard.",
                type: "success",
                learnmore: false,
                autoclose: true,
            }
        })
    }

    recenterCertificate = () => {
        this.setState({
            resetX: this.state.initialX,
            resetY: this.state.initialY,
            sliderValue: 3,
            sliderClass: "slider-3",
        });
    }

    toggleShareModal = (e) => {
        e.preventDefault();
        this.setState({
            shareModal: !this.state.shareModal,
        });
    }

    handleShare(event) {
        event.preventDefault();
        const { url, title, description } = this.state;
        const socialMedia = event.currentTarget.getAttribute('data-network');
        const shareUrl = getShareUrl(socialMedia, url, title, description);
        window.open(shareUrl, '_blank', 'width=550,height=450');
    }

    render() {
        return (
            <React.Fragment>
                {this.state.shareModal && (
                    <div className='social-share-modal'>
                        <div className='wrapper'>
                            <button className='close-btn' onClick={this.toggleShareModal}><i className="fa-regular fa-circle-xmark"></i> Close</button>
                        </div>
                        <div className='container'>
                            <h1>Share your certificate</h1>
                            <button onClick={this.handleShare} data-network="linkedin"><i className="fa-brands fa-linkedin"></i> Share on linkedin</button>
                            <button onClick={this.handleShare} data-network="facebook"><i className="fa-brands fa-facebook-f"></i> Share on facebook</button>
                            <button onClick={this.handleShare} data-network="twitter"><i className="fa-brands fa-twitter"></i> Share on twitter</button>
                            <button onClick={this.copyUrl}><i className="fa-regular fa-copy"></i> Copy URL</button>
                        </div>
                    </div>
                )}
                <div className={this.props.minNav ? "element max-element" : "element min-element"}>
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Certificate<label>viewer</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace pg-certficate_viewer'>
                            {this.state.certView && (
                                <div className='sub-nav'>
                                    <h1><i className="fa-solid fa-gamepad"></i> Controls</h1>
                                    {this.state.accepted === 'true' ?
                                        <div className='nav-container'>
                                            <a className='nav nav_sub' href="foo" onClick={this.downloadCertificate}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                            <a className='nav nav_sub' href="foo" onClick={this.downloadCertificatePdf}>
                                                <i className="fa-regular fa-file-pdf"></i>
                                                Download PDF
                                            </a>
                                            <a className='nav nav_sub' href="foo" onClick={this.toggleShareModal}>
                                                <i className="fa-solid fa-share"></i>
                                                Share
                                            </a>
                                            <a className='nav nav_sub' href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.state.url)}&title=${encodeURIComponent(this.state.title)}&summary=${encodeURIComponent(this.state.description)}`}>
                                                <i className="fa-brands fa-linkedin-in"></i>
                                                Linkedin
                                            </a>
                                            <a className='nav warn nav_sub' href="foo" onClick={this.deleteDocument}>
                                                <i className="fa-solid fa-trash-can"></i>
                                                Delete
                                            </a>
                                        </div>
                                        :
                                        <div className='nav-container'>
                                            <a className='nav green nav_sub nav_accept' href="foo" onClick={this.acceptCertificate}>
                                                {/* continue from here */}
                                                <i className="fa-regular fa-circle-check"></i>
                                                Accept
                                            </a>
                                            <a className='nav warn nav_sub' href="foo" onClick={this.rejectCertificate}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                                Reject
                                            </a>
                                            {/* <a className='nav yellow' href="foo">
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            Report
                                        </a> */}
                                        </div>
                                    }
                                </div>
                            )}
                            <div className='wrapper'>
                                <button className='recenter-certificate' onClick={this.recenterCertificate}><i className="fa-solid fa-arrows-to-circle"></i></button>
                                <div className='cropper-rel'>
                                    {this.state.certView && (
                                        <div className='image-container'
                                            onTouchStart={this.handlePinchStart}
                                            onTouchMove={this.handlePinchMove}
                                            onTouchEnd={this.handlePinchEnd}
                                            ref={this.imageDivRef}
                                        >
                                            <div className={this.state.sliderClass + " origin"} style={{ '--scale-width': this.state.scaleFactor }}>
                                                <Draggable position={{ x: this.state.resetX, y: this.state.resetY }} onStop={this.handleCertDragStop}>
                                                    <div className='cert-container' id="capture" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', width: this.state.certWidth, height: this.state.certHeight }}>
                                                        <div className='elements' ref={this.certDivRef}>
                                                            <img src={"https://pronetin-new.s3.amazonaws.com/batch/00000000-0000-0000-0000-000000000000/" + encodeURIComponent(this.state.cert_bg) + "?cacheblock=" + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template' className='exclude-scale'></img>
                                                            {this.state.elements && this.state.elements.length ?
                                                                this.state.elements.map((element, index) => {
                                                                    if (element.type === "image") {
                                                                        return <ViewImage element_id={index} key={element.timeStamp + "-" + index} data={element} />
                                                                    } else if (element.type === "table") {
                                                                        return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else if (element.type === "signature") {
                                                                        return <ViewSignee element_id={index} key={element.timeStamp + "-" + index} data={element} node_server_axios={this.props.node_server_axios} />
                                                                    } else if (element.type !== "QR") {
                                                                        return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })
                                                                : ""}
                                                            <ViewQR data={this.props.documentId} size={this.state.QRSize} position={this.state.elements.find(element => element.type === "QR")} />
                                                        </div>
                                                    </div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="mob-actions mobile-only fixed-controls">
                                    {this.state.accepted === 'true' ?
                                        <div className='nav-container'>
                                            <a className='nav' href="foo" onClick={this.downloadCertificate}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                            <a className='nav' href="foo" onClick={this.toggleShareModal}>
                                                <i className="fa-solid fa-share"></i>
                                                Share
                                            </a>
                                            <a className='nav' href="foo">
                                                <i className="fa-brands fa-linkedin-in"></i>
                                                Linkedin
                                            </a>
                                            <a className='nav warn' href="foo" onClick={this.deleteDocument}>
                                                <i className="fa-solid fa-trash-can"></i>
                                                Delete
                                            </a>
                                        </div>
                                        :
                                        <div className='nav-container'>
                                            <a className='nav green' href="foo" onClick={this.acceptCertificate}>
                                                {/* continue from here */}
                                                <i className="fa-regular fa-circle-check"></i>
                                                Accept
                                            </a>
                                            <a className='nav warn' href="foo" onClick={this.rejectCertificate}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                                Reject
                                            </a>
                                            {/* <a className='nav yellow' href="foo">
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            Report
                                        </a> */}
                                        </div>
                                    }
                                </div>
                                <div className='cropper-zoom cert-viewer'>
                                    <div className='container onboarding-zoom'>
                                        <p>Zoom</p>
                                        <input type="range" min="1" max="7" className="slider" value={this.state.sliderValue} onChange={this.updateZoomInput}></input>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} />)
                }
            </React.Fragment>
        );
    }
}

function getShareUrl(network, url, title, description) {
    switch (network) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
        case 'twitter':
            return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
        case 'linkedin':
            return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`;
        default:
            return null;
    }
}

export default CertificateViewerComponent;