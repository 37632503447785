import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import WarningAction from '../components/warning_action';
import pronetinLogo from '../images/static/pronetin-logo-P.png';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            storage: this.props.storage,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    createOrder = async (plan) => {
        axios.post(this.props.node_server_axios + 'create-order-user', {
            params: {
                plan: plan,
                subscriptionDays: 365,
            },
        }).then(res => {
            const data = res.data;
            this.setState({ order: data, currentPlan: plan });
            this.showRazorpay(data);
        });
    };

    showRazorpay = (order) => {
        const options = {
            key: this.props.rzp_auth_key,
            amount: order.amount,
            currency: order.currency,
            name: 'Pronetin',
            description: 'Upgrade Pro Plan',
            image: pronetinLogo,
            order_id: order.id,
            handler: this.handlePaymentSuccess,
            prefill: {
                name: this.props.name,
                email: this.props.email,
            },
            theme: {
                color: "#2977C9",
            }
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    handlePaymentSuccess = async (payment) => {
        const response = await fetch(this.props.node_server_axios + 'verify-payment-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                razorpay_payment_id: payment.razorpay_payment_id,
                razorpay_order_id: payment.razorpay_order_id,
                razorpay_signature: payment.razorpay_signature,
                plan: this.state.currentPlan,
                email: this.props.email,
            })
        });
        const data = JSON.parse(await response.text());
        this.setState({ paymentId: payment.razorpay_payment_id, signature: payment.razorpay_signature });
        if (data.message === 'success') {
            axios.post(this.props.node_server_axios + 'force_update_session');
            this.setState({
                warning: {
                    message: "Plan Changed.",
                    type: "success",
                    learnmore: false,
                    autoclose: false,
                },
                storage: data.storage,
            })
        } else {
            this.setState({
                warning: {
                    message: "Sorry, An error has occoured, Contact our customer care.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                }
            })
        }
    };

    removePlan = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Downgrade",
                subtext: "Once you downgrade you cannot revert back this change. Paid amount for the upgraded plan will not be refunded.",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'remove-user-plan', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'updated') {
                axios.post(this.props.node_server_axios + 'force_update_session');
                this.setState({
                    warning: {
                        message: "Plan Changed.",
                        type: "success",
                        learnmore: false,
                        autoclose: false,
                    },
                    storage: 100,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured, Contact our customer care.",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    },
                    storage: 100,
                })
            }
        });
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        this.setState({ razorpayScript: script });
    }

    componentWillUnmount() {
        if (this.state.razorpayScript) {
            document.body.removeChild(this.state.razorpayScript);
        }
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }


    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Personal Plan<label>Become our premium user to enjoy unique benefits</label></h1>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace buysection'>
                            <div className={this.state.storage < 300 ? 'buy-container active' : 'buy-container'}>
                                <div className='inset'>
                                    <h2>Free</h2>
                                    <h3>₹ 0 <span>/ Year</span></h3>
                                    <hr></hr>
                                    <div className='features'>
                                        <p><i className="fa-solid fa-circle-check"></i> Storage up to 100MB</p>
                                        <p><i className="fa-regular fa-circle-xmark"></i> Embed your achievements anywhere</p>
                                        <p><i className="fa-regular fa-circle-xmark"></i> Ads disabling</p>
                                        <p><i className="fa-regular fa-circle-xmark"></i> View Count</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Generate 5 E-signatures</p>
                                        <p><i className="fa-regular fa-circle-xmark"></i> Premium Support</p>
                                    </div>
                                    <div className='fg-1'></div>
                                    {this.state.storage < 300 ?
                                        <div className='current-plan'>Active Plan</div>
                                        :
                                        <button onClick={this.removePlan}>Downgrade <i className="fa-regular fa-circle-down"></i></button>
                                    }
                                </div>
                            </div>
                            <div className={this.state.storage === 300 ? 'buy-container active' : 'buy-container'}>
                                <div className='inset'>
                                    <h2>Pro</h2>
                                    <h3>₹ 119 <span>/ Year</span></h3>
                                    <hr></hr>
                                    <div className='features'>
                                        <p><i className="fa-solid fa-circle-check"></i> Storage up to 300MB</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Embed your achievements anywhere</p>
                                        <p><i className="fa-regular fa-circle-xmark"></i> Ads disabling</p>
                                        <p><i className="fa-solid fa-circle-check"></i> View Count</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Generate 5 E-signatures</p>
                                        <p><i className="fa-regular fa-circle-xmark"></i> Premium Support</p>
                                    </div>
                                    <div className='fg-1'></div>
                                    {this.state.storage === 300 ?
                                        <div className='current-plan'>Active Plan</div>
                                        :
                                        this.state.storage > 300 ?
                                            <button onClick={() => this.createOrder('pro')}>Downgrade <i className="fa-regular fa-circle-down"></i></button>
                                            :
                                            <button onClick={() => this.createOrder('pro')}>Upgrade <i className="fa-regular fa-circle-up"></i></button>
                                    }
                                </div>
                            </div>
                            <div className={this.state.storage === 500 ? 'buy-container active' : 'buy-container'}>
                                <div className='inset'>
                                    <h2>Pro +</h2>
                                    <h3>₹ 199 <span>/ Year</span></h3>
                                    <hr></hr>
                                    <div className='features'>
                                        <p><i className="fa-solid fa-circle-check"></i> Storage up to 500MB</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Embed your achievements anywhere</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Ads disabling</p>
                                        <p><i className="fa-solid fa-circle-check"></i> View Count</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Generate 5 E-signatures</p>
                                        <p><i className="fa-solid fa-circle-check"></i> Premium Support</p>
                                    </div>
                                    <div className='fg-1'></div>
                                    {this.state.storage === 500 ?
                                        <div className='current-plan'>Active Plan</div>
                                        :
                                        <button onClick={() => this.createOrder('proplus')}>Upgrade <i className="fa-regular fa-circle-up"></i></button>
                                    }
                                </div>
                            </div>
                            {this.props.user_type === 'organisation' && (
                                <div className='switch-organisation-buy'>
                                    <p>Are you looking to buy credits or upgrade organisation plan ?</p>
                                    <a href='/premium'>Organisation Plans <i className="fa-solid fa-arrow-right-long"></i></a>
                                </div>
                            )}
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;