import React from 'react';
import axios from 'axios';

import Header from '../components/header';
import EditsavedSideNav from '../components/editsaved_sidenav';
import EditsavedElement from '../components/editsaved_element';
import Loading from '../components/loading';

import WarningAction from '../components/warning_action';
import ProgressLoader from '../components/progress_loader';

import '../components/css/main.css';

class Issue extends React.Component {
    constructor(props) {
        super(props);
        let initTimeStamp = 0;
        this.state = {
            loading: true,
            step_selection: '1',
            toAdd: {
                type: false,
            },
            certTemplate: "tem0",
            elementsIndex: [],
            variableIndex: [
                {
                    type: "variable",
                    variables: ['[Recepient Name]']
                }
            ],
            toPdf: {
                pdf: false,
                pdfFilename: false,
                pdfSize: false,
            },
            email: "",
            csvData: [],
            updateCsv: 0,
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            elements: [
                {
                    type: 'variable',
                    timeStamp: Date.now() + initTimeStamp++,
                    fontSize: 21,
                    fontFamily: 'Arimo, sans-serif',
                    fontColor: '#000',
                    fontWeight: false,
                    fontStyle: false,
                    textDecorationLine: false,
                    lineHeight: '1',
                    textAlign: 'center',
                    dataType: "textarea",
                    content: "[Recepient Name]",
                    required: true,
                    readOnly: true,
                    placeholder: 'Recepient Name',
                    elHeight: 38,
                    elWidth: 200,
                    x: 275,
                    y: 150,
                    zIndex: 1,
                },
                {
                    type: 'required',
                    timeStamp: Date.now() + initTimeStamp++,
                    fontSize: 24,
                    fontFamily: 'Arimo, sans-serif',
                    fontColor: '#157EFB',
                    fontWeight: false,
                    fontStyle: false,
                    textDecorationLine: false,
                    lineHeight: '1',
                    textAlign: 'center',
                    dataType: "textarea",
                    content: "Certificate of Participation",
                    required: true,
                    readOnly: false,
                    placeholder: 'Certificate Title',
                    elHeight: 41,
                    elWidth: 300,
                    x: 250,
                    y: 100,
                    zIndex: 1,
                },
            ],
            certWidth: 904,
            certHeight: 640,
            save_id: '',
            save_org_id: '',
            generative: false,
            colors: null,
            elementToDelete: false,
            savedTemplates: [],
            saveListModal: false,
            access_check_loading: true,
            progress_loader: false,
            progress_opaque: false,
            leave_page_warn: false,
            org_name: 'Pronetin',
            animateSignatureToDelete: false,
        }
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        this.setState({ progress_loader: true, progress_opaque: true });

        const slug = window.location.href.split('/').pop();
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                if (res.data === null) {
                    window.location = "/";
                } else {
                    if (res.data.org_id) {
                        axios.post(this.props.node_server_axios + 'get_organisation_data', {
                            params: {
                                org_id: res.data.org_id,
                            }
                        }).then((res) => {
                            if (res.data !== undefined && res.data.message === 'success') {
                                this.setState({
                                    org_name: res.data.org_name,
                                });
                            } else {
                                window.location = "/";
                            }
                        })
                    }
                    this.setState({ email: res.data.email, loading: false, user_id: res.data.user_id, header_user_data: res.data, });
                    if (slug !== undefined || slug !== null || slug !== 'issue') {
                        axios.post(this.props.node_server_axios + 'get_saved_templates_by_id', {
                            params: {
                                email: res.data.email,
                                save_id: slug,
                            },
                        }).then(async (res) => {
                            if (res.data !== undefined) {
                                if (res.data.message === 'saved templates') {
                                    let colorData = null;
                                    const img = new Image();

                                    const imgPromise = new Promise((resolve, reject) => {
                                        img.onload = resolve;
                                        img.onerror = reject;
                                    });

                                    let CTemplate = res.data.saved_templates[0].draft_bg !== null ? res.data.saved_templates[0].draft_bg : 'tem0'
                                    if (CTemplate.includes("_")) {
                                        img.src = 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + CTemplate + '.png';
                                    } else {
                                        img.src = 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + CTemplate + '.png';

                                        const response = await axios.post(this.props.node_server_axios + 'get_cert_elements', {
                                            params: {
                                                id: CTemplate,
                                            },
                                        });

                                        colorData = response.data.colors;
                                    }

                                    try {
                                        await imgPromise;
                                        let cWidth = 904;
                                        let cHeight = 640;

                                        if (img.naturalWidth > img.naturalHeight) {
                                            cWidth = 904;
                                            cHeight = 640;
                                        } else if (img.naturalWidth < img.naturalHeight) {
                                            cWidth = 640;
                                            cHeight = 904;
                                        } else {
                                            console.log("Invalid Size");
                                        }
                                        const updatedData = await Promise.all(JSON.parse(res.data.saved_templates[0].draft).map(async (element) => {
                                            if (element.type === 'image' && element.content !== 'shape') {
                                                const response = await fetch("https://pronetin-new.s3.amazonaws.com/saved/" + encodeURIComponent(slug) + "/" + encodeURIComponent(element.timeStamp + ".png"));
                                                const data = await response.arrayBuffer();
                                                return new Promise((resolve, reject) => {
                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(new Blob([data]));
                                                    reader.onloadend = () => {
                                                        let base64data = reader.result;
                                                        resolve({ ...element, content: base64data });
                                                    }
                                                });
                                            } else {
                                                return element;
                                            }
                                        }));
                                        this.setState({
                                            certTemplate: res.data.saved_templates[0].draft_bg !== null ? res.data.saved_templates[0].draft_bg : 'tem0',
                                            owner: res.data.owner,
                                            save_id: res.data.saved_templates[0].save_id,
                                            save_org_id: res.data.saved_templates[0].save_org_id,
                                            save_email: res.data.saved_templates[0].save_email,
                                            certWidth: cWidth,
                                            certHeight: cHeight,
                                            colors: colorData,
                                            access_check_loading: false,
                                            progress_loader: false,
                                            elementsIndex: updatedData.filter(element => element.type !== "image").map(element => ({ timeStamp: element.timeStamp, type: element.type, required: element.required, readOnly: element.readOnly, content: element.content })),
                                            elements: updatedData,
                                        })
                                    } catch (error) {
                                        console.log("Error loading image:", error);
                                    }
                                } else {
                                    this.setState({
                                        access_check_loading: false,
                                    })
                                }
                            }
                        })
                        axios.post(this.props.node_server_axios + 'get_saved_templates', {
                            params: {
                                email: res.data.email,
                            },
                        }).then(async (res) => {
                            if (res.data !== undefined) {
                                if (res.data.message === 'saved templates') {
                                    this.setState({
                                        savedTemplates: res.data.saved_templates,
                                    })
                                }
                            }
                        })
                    } else {
                        window.location = "/mydesign";
                    }
                }
            } else {
                window.location = "/signin?page=edit&id=" + slug;
            }
        }).catch(() => {
            window.location = "/signin";
        })

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleLeavePageWarn = (value) => {
        if (value === false) {
            this.setState({
                leave_page_warn: false,
            })
        }
    }

    handleBeforeUnload = (e) => {
        if (this.state.leave_page_warn) {
            e.preventDefault();
            e.returnValue = '';
            const confirmationMessage = 'Are you sure you want to leave this page?';
            return confirmationMessage;
        }
    };

    updateSavedTemplateCount = () => {
        axios.post(this.props.node_server_axios + 'get_saved_templates', {
            params: {
                email: this.state.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved templates') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                    })
                }
            }
        })
    }

    handleSendToParent = (values) => {
        this.setState({
            toAdd: values,
        })
    }

    handleCsvToParent = (values) => {
        this.setState({
            csvData: values,
            updateCsv: Math.floor(Math.random() * (999 - 100 + 1) + 100),
            leave_page_warn: true,
        })
    }

    handleCsvRowToParent = (values) => {
        this.setState(prevState => ({
            csvData: prevState.csvData.concat(values),
            leave_page_warn: true,
        }));
    }

    handleCsvRowFromParent = (id) => {
        const newArray = [...this.state.csvData];
        newArray.splice(id, 1);
        this.setState({
            csvData: newArray,
            leave_page_warn: newArray.length > 0 ? true : false,
        });
    }


    handleClearCsv = () => {
        this.setState({
            csvData: [],
            leave_page_warn: false,
        })
    }

    handlePdfToParent = (values) => {
        if (this.state.toPdf.pdf !== values.pdf) {
            this.setState({
                toPdf: values,
            })
        }
    }

    handleElementIndex = (values) => {
        this.setState({
            elementsIndex: values,
            elementToDelete: false,
        })
    }

    handleVariableIndex = (values) => {
        this.setState({
            variableIndex: values
        })
    }

    handleStageSelect = async (values) => {
        if (values === '6') {
            const containsSampleSignature = this.state.elementsIndex.some(element => element.content === 'help@pronetin.com|Help, Pronetin');
            if (containsSampleSignature) {
                //--------------------------Warning Action Trigger Start------------------------------
                let proceed_function = true;
                this.setState({
                    warning_action: {
                        message: "Placeholder Signature Found",
                        subtext: "Please remove/delete the sample placeholder signature element. You may add your desired signatures by adding the signing authorities (Optional)",
                        type: "success",
                        proceed_action: "true",
                        proceed_function: proceed_function,
                        back_action: false,
                        close: false,
                    }
                })
                if (proceed_function) {
                    await new Promise((resolve) => {
                        this.resumeFunction = resolve;
                    });
                    this.setState({
                        warning_action: {
                            message: "",
                            subtext: "",
                            type: "warning",
                            proceed_action: false,
                            proceed_function: false,
                            back_action: false,
                            close: true,
                        },
                    })
                }
                //--------------------------Warning Action Trigger End------------------------------
                this.setState({
                    step_selection: '5',
                    animateSignatureToDelete: true,
                })
                return null;
            }
            const containsEmptyValidity = this.state.elementsIndex.some(element => { return element.type === 'validity' && element.content === '' });
            if (containsEmptyValidity) {
                //--------------------------Warning Action Trigger Start------------------------------
                let proceed_function = true;
                this.setState({
                    warning_action: {
                        message: "Validity is Empty",
                        subtext: "Please select a valid date for validity in certificate editor or remove the element",
                        proceed_action: "true",
                        proceed_function: proceed_function,
                        back_action: false,
                        close: false,
                    }
                })
                if (proceed_function) {
                    await new Promise((resolve) => {
                        this.resumeFunction = resolve;
                    });
                    this.setState({
                        warning_action: {
                            message: "",
                            subtext: "",
                            type: "warning",
                            proceed_action: false,
                            proceed_function: false,
                            back_action: false,
                            close: true,
                        },
                    })
                }
                //--------------------------Warning Action Trigger End------------------------------
                this.setState({ step_selection: '2' })
                return null;
            }
        }
        if (values !== '6' && this.state.step_selection === '6') {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Return to Editor.",
                    subtext: "Recipient details entered can be lost. You may copy and save the content.",
                    type: "warning",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: true,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
        this.setState({ step_selection: values })
    }

    handleTemplate = async (values) => {

        function areArraysEqual(array1, array2) {
            if (array1.length !== array2.length) {
                return false;
            }
            const sortedArray1 = array1.sort();
            const sortedArray2 = array2.sort();
            for (let i = 0; i < sortedArray1.length; i++) {
                const obj1 = sortedArray1[i];
                const obj2 = sortedArray2[i];
                const string1 = JSON.stringify(obj1);
                const string2 = JSON.stringify(obj2);
                if (string1 !== string2) {
                    return false;
                }
            }
            return true;
        }


        let contentIndex = false;
        let titleExtracted = "CERTIFICATE\nOF APPRECIATION";
        if (values.includes("|")) {
            titleExtracted = values.split("|")[2] !== "" ? values.split("|")[2] : "CERTIFICATE\nOF APPRECIATION";
            contentIndex = values.split("|")[1];
            values = values.split("|")[0];
        }

        //logic for detecting if switched from landscape to portrait and vice versa
        let layout_chang_prompt = false;
        let layout_change_detector = this.state.certTemplate.charAt(this.state.certTemplate.length - 1).concat(values.charAt(values.length - 1))
        if ((layout_change_detector.split('p').length - 1) === 1) {
            layout_chang_prompt = true;
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Changing Templates",
                    subtext: "Your Current content and its position will be lost. Do you want to Continue?",
                    type: "warning",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: false,
                    cancel_btn_text: "Change Background",
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                        cancel_btn_text: false,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
        this.setState({ certTemplate: values })

        const img = new Image();

        const imgPromise = new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
        });

        if (values.includes("_")) {
            img.src = 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + values + '.png';
        } else {
            img.src = 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + values + '.png';
        }

        try {
            await imgPromise;
            let cWidth = 904;
            let cHeight = 640;

            if (img.naturalWidth > img.naturalHeight) {
                cWidth = 904;
                cHeight = 640;
            } else if (img.naturalWidth < img.naturalHeight) {
                cWidth = 640;
                cHeight = 904;
            } else {
                console.log("Invalid Size");
            }

            const response = await axios.post(this.props.node_server_axios + 'get_cert_elements', {
                params: {
                    id: values.includes("_") ? values.split("_")[0] : values,
                },
            });

            if (response.data.elements !== null) {
                //If you want to remove the popup box when switching through portrait templates, remove timestamp from elements in database
                if (!areArraysEqual(response.data.elements.map(obj => ({ ...JSON.parse(obj) })), this.state.elements.map(obj => { const { timeStamp, ...rest } = obj; return rest; }))) {
                    //An else logic for the below if can be used to retain positions of content while switching layout (UGLY) issue #130
                    if (!layout_chang_prompt) {
                        //--------------------------Warning Action Trigger Start------------------------------
                        let proceed_function = true;
                        this.setState({
                            warning_action: {
                                message: "Changing Templates",
                                subtext: "Your Current content and its position will be lost. Do you want to Continue?",
                                type: "warning",
                                proceed_action: "true",
                                proceed_function: proceed_function,
                                back_action: false,
                                close: true,
                                cancel_btn_text: "Change Background",
                            }
                        })
                        if (proceed_function) {
                            await new Promise((resolve) => {
                                this.resumeFunction = resolve;
                            });
                            this.setState({
                                warning_action: {
                                    message: "",
                                    subtext: "",
                                    type: "warning",
                                    proceed_action: false,
                                    proceed_function: false,
                                    back_action: false,
                                    close: true,
                                },
                            })
                        }
                        //--------------------------Warning Action Trigger End------------------------------
                    }
                }
                let initTimeStamp = 0;
                let parsedData = response.data.elements.map((obj) => {
                    const updatedObj = {
                        ...JSON.parse(obj),
                        timeStamp: Date.now() + initTimeStamp++
                    };
                    if (JSON.parse(obj).type === "required") {
                        updatedObj.content = titleExtracted.toUpperCase();
                    }
                    return updatedObj;
                });

                let colorData = response.data.colors;

                if (contentIndex !== false && values.includes("_")) {
                    axios.defaults.withCredentials = true;
                    await axios.post(this.props.node_server_axios + 'get_tag_data_ai', {
                        params: {
                            id: contentIndex,
                        },
                    }).then(async (res) => {
                        if (res.data !== undefined) {
                            if (res.data.message === 'tag data') {
                                let contentData = JSON.parse(`[${res.data.tagDataDefault}]`);
                                let randomIndex = Math.floor(Math.random() * contentData.length);
                                parsedData = parsedData.map(obj => {
                                    if (obj.type === "paragraph") {
                                        return {
                                            ...obj,
                                            content: contentData[randomIndex].body,
                                        };
                                    } else if (obj.type === "textField") {
                                        return {
                                            ...obj,
                                            content: contentData[randomIndex].header,
                                        };
                                    } else if (obj.type === "required") {
                                        return {
                                            ...obj,
                                            content: titleExtracted.toUpperCase(),
                                        };
                                    }
                                    return obj;
                                });
                                this.setState({
                                    elements: parsedData,
                                    colors: colorData,
                                    certWidth: cWidth,
                                    certHeight: cHeight,
                                    generative: true,
                                });
                            } else {
                                this.setState({
                                    elements: parsedData,
                                    colors: colorData,
                                    certWidth: cWidth,
                                    certHeight: cHeight,
                                    generative: false,
                                });
                            }
                        }
                    })
                } else {
                    this.setState({
                        elements: parsedData,
                        colors: colorData,
                        certWidth: cWidth,
                        certHeight: cHeight,
                        generative: false,
                    });
                }
            } else {
                this.setState({
                    certWidth: cWidth,
                    certHeight: cHeight,
                    generative: false,
                });
            }
        } catch (error) {
            console.log("Error loading image:", error);
        }
    }

    handleElementToDelete = (values) => {
        this.setState({
            elementToDelete: values,
        });
    }

    exitFromCurrentEditor = async (location) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Leaving this Editor",
                subtext: "Documents attached and recipient details will be lost. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        window.location.href = "/" + location;
    }

    closeSaveListModal = (e) => {
        if (e.target.className === 'editor-switch-save-modal') {
            this.setState({
                saveListModal: false,
            });
        }
    }

    openSaveListModal = () => {
        this.setState({
            saveListModal: true,
        });
    }

    notPartOfOrganisation = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "No Organisation Found",
                subtext: "Create / Join an organisation to access this feature. Do you wish to continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        window.location.href = "/create";
    }

    handleChildElements = (values) => {
        this.setState({
            elements: values,
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header />
                        <Loading />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="main issue-page">
                        <Header login={true} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                        <div className='editor-switch'>
                            {this.state.header_user_data && this.state.header_user_data.user_type === 'organisation' ?
                                <button onClick={() => this.exitFromCurrentEditor('issue')}>My Draft</button>
                                :
                                <button onClick={this.notPartOfOrganisation} className='inactive'>My Draft</button>
                            }
                            <button onClick={this.openSaveListModal} className='active'>Saved Designs</button>
                        </div>
                        {this.state.saveListModal && (
                            <div className='editor-switch-save-modal' onClick={this.closeSaveListModal}>
                                <div className='container'>
                                    <h2><i className="fa-regular fa-floppy-disk"></i> Choose Saved Template</h2>
                                    {this.state.savedTemplates !== null && this.state.savedTemplates.length > 0 ?
                                        this.state.savedTemplates.map((element, index) =>
                                            <div className='saved-tile' key={index} onClick={() => this.exitFromCurrentEditor('edit/' + element.save_id)}>
                                                <img src={element.draft_bg.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + element.draft_bg + '.png' : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + element.draft_bg + '.png'} alt='certificate template'></img>
                                                <button><i className="fa-regular fa-edit"></i> Edit</button>
                                            </div>
                                        )
                                        :
                                        <p>No saved Templates</p>
                                    }
                                </div>
                            </div>
                        )}
                        <div className='editor-wrapper'>
                            <EditsavedSideNav fetchChildState={this.handleSendToParent} fetchChildStageSelect={this.handleStageSelect} step_selection={this.state.step_selection} elementIndex={this.state.elementsIndex} variableIndex={this.state.variableIndex} fetchPdfState={this.handlePdfToParent} fetchTemplate={this.handleTemplate} email={this.state.email} fetchCsvState={this.handleCsvToParent} saveId={this.state.save_id} owner={this.state.owner} node_server_axios={this.props.node_server_axios} user_id={this.state.user_id} fetchElementToDelete={this.handleElementToDelete} access_check_loading={this.state.access_check_loading} org_name={this.state.org_name} animateSignatureToDelete={this.state.animateSignatureToDelete} />
                            {this.state.save_id !== '' && (
                                <EditsavedElement toAdd={this.state.toAdd} fetchChildStageSelect={this.handleStageSelect} step_selection={this.state.step_selection} fetchChildElements={this.handleChildElements} fetchChildElementIndex={this.handleElementIndex} fetchInlineVariableIndex={this.handleVariableIndex} pdf={this.state.toPdf} certTemplate={this.state.certTemplate} email={this.state.email} addRecepient={this.state.csvData} updateCsv={this.state.updateCsv} addRowCsv={this.handleCsvRowToParent} removeRowCsv={this.handleCsvRowFromParent} clearCsv={this.handleClearCsv} saveId={this.state.save_id} orgId={this.state.save_org_id} save_email={this.state.save_email} owner={this.state.owner} node_server_axios={this.props.node_server_axios} elements={this.state.elements} certWidth={this.state.certWidth} certHeight={this.state.certHeight} fetchChildState={this.handleSendToParent} generative={this.state.generative} colors={this.state.colors} elementToDelete={this.state.elementToDelete} savedTemplateCount={this.state.savedTemplates.length} updateSavedTemplateCount={this.updateSavedTemplateCount} leavePageWarn={this.handleLeavePageWarn} fetchPdfState={this.handlePdfToParent} />
                            )}
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                        <div className='editor-nosupport center'>
                            <i className="fa-solid fa-desktop"></i>
                            <p>Signin using PC / Larger Display</p>
                            <button onClick={() => { window.location = './' }}><i className="fa-solid fa-arrow-left"></i> Back</button>
                        </div>
                        <div className='editor-nosupport'>
                            <i className="fa-solid fa-mobile-screen-button"></i>
                            <h2>Editor not supported in this resolution ({window.innerWidth + "x" + window.innerHeight})</h2>
                            <p>Please login your account in a larger display to access editor</p>
                        </div>
                    </div>
                    {
                        this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} />)
                    }
                </React.Fragment>
            )
        }
    }
}
export default Issue;