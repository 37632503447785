import React from 'react';

class OnboardingTutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
            shouldRender: true,
            steps: [
                {
                    heading: 'Welcome to Pronetin Editor',
                    content: 'This is where you design your certificates.',
                    style: { top: this.getPositionPx("editor-wrapper", 0, "top") + 10, left: this.getPositionPx("editor-wrapper", 0, "middleX"), zIndex: "995" },
                    caret: { bottom: '-9px', left: '10px', transform: 'rotate(180deg)' },
                    controls: false,
                }
            ],
            doNotShowChecked: false,
        };
    }

    handleNextStep = () => {
        if (this.state.currentStep >= this.state.steps.length - 1) {
            this.handleCloseTutorial();
        } else {
            this.setState((prevState) => ({
                currentStep: prevState.currentStep + 1,
            }));

            switch (this.state.currentStep + 1) {
                case 1:
                    this.props.step_selection('1');
                    break;
                default:
                    return null;
            }
        }
    };

    componentDidMount = () => {
        setTimeout(() => {
            this.updateSteps();
        }, 1000);
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate = (prevProps) => {
        //prevProps.goToStep < this.props.goToStep removed to make possiblity of dynamic onboadrding based on selected step
        if (prevProps.goToStep !== this.props.goToStep) {
            this.updateSteps();
            this.setState({
                currentStep: this.props.goToStep,
            });
        }
    }

    updateSteps = () => {
        let newSteps = [
            this.props.functionalTutorialRedirect ?
                {
                    heading: 'Welcome to Pronetin Editor',
                    content: 'Here you can restart the functional tutorial when you require.',
                    style: { top: this.getPositionPx("editor-help-btn", 0, "top") - 200, left: this.getPositionPx("editor-help-btn", 0, "left") - 180, zIndex: "995" },
                    caret: { bottom: '-9px', left: '180px', transform: 'rotate(180deg)' },
                    controls: true,
                }
                :
                {
                    heading: 'Welcome to Pronetin Editor',
                    content: 'This is where you design your certificates.',
                    style: { top: this.getPositionPx("editor-wrapper", 0, "top") + 10, left: this.getPositionPx("editor-wrapper", 0, "middleX"), zIndex: "995" },
                    caret: { bottom: '-9px', left: '10px', transform: 'rotate(180deg)' },
                    controls: true,
                },
            {
                heading: 'Automatic Save',
                content: 'Edits you make are automatically saved. You can also manually save.',
                style: { top: this.getPositionPx("manual-save", 0, "top") - 160, left: this.getPositionPx("manual-save", 0, "left"), zIndex: "995" },
                caret: { bottom: '-9px', left: '10px', transform: 'rotate(180deg)' },
                controls: true,
            },
            {
                heading: 'Template Selection',
                content: 'Select a template to base your design. (Choose a template to see next steps)',
                style: { top: this.getPositionPx("step-expand", 0, "middleY"), left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
                controls: false,
            },
            {
                heading: 'Step 2',
                content: 'Add text, tables, variables & much more to your certificate from here.',
                style: { top: this.getPositionPx("icon", 1, "bottom") + 10, left: this.getPositionPx("icon", 1, "left") },
                caret: { top: '0px', left: '5px' },
                controls: false,
            },
            {
                heading: 'Add Attributes',
                content: 'Click on any attribute element to add that field to your certificate. You can also see "Used" attribute elements in your certificate here.',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 20, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Add Text',
                content: 'Click on Add Text element to add text fields to your certificate.',
                style: { top: this.getPositionPx("step-expand", 0, "bottom") - 200, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { bottom: '20px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Change Font style',
                content: 'Here you can change font color, boldness etc of the selected text element.',
                style: { top: this.getPositionPx("top-sec", 0, "bottom") + 10, left: this.getPositionPx("top-sec", 0, "left") },
                caret: { top: '0px', left: '10px' },
                controls: true,
            },
            {
                heading: 'Edit',
                content: 'Double-click on this text element to start editing. (Make some edit, to see next steps). Also, when not in edit mode you can drag the elements.',
                style: { top: this.getPositionPx("react-draggable", 3, "bottom"), left: this.getPositionPx("react-draggable", 3, "left") },
                caret: { top: '0px', left: '10px' },
                controls: false,
            },
            {
                heading: 'Variables',
                content: 'Variables are text elements which will have different content for each certificate recipient, (eg. names, email id, age, grades etc). Its Contents can be added easily in the last step with the recipient list.',
                style: { top: this.getPositionPx("react-draggable", 3, "top") - 230, left: this.getPositionPx("react-draggable", 3, "right") - 120 },
                caret: { bottom: '-9px', left: '10px', transform: 'rotate(180deg)' },
                controls: false,
            },
            {
                heading: 'Variable Name',
                content: 'Variable names are always put between square brackets. Change the sample variable name to your desired variable name, eg. Change [Variable 1.1] to [Marks]',
                style: { top: this.getPositionPx("react-draggable", 3, "bottom"), left: this.getPositionPx("react-draggable", 3, "left") },
                caret: { top: '0px', left: '10px' },
                controls: false,
            },
            {
                heading: 'Selected Variables',
                content: 'All the variables you have used in this certificate will appear here.',
                style: { top: this.getPositionPx("step-expand", 0, "middleY") + 10, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
                controls: 'limited',
            },
            {
                heading: 'Save Content',
                content: '(Double click to enter text editing) You can save the text contents which are often used in your certificates.',
                style: { top: this.getPositionPx("react-draggable", 3, "middleY"), left: this.getPositionPx("react-draggable", 3, "left") + 145 },
                caret: { top: '36px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'View Saved Content',
                content: 'Saved content provided by pronetin and saved by you can be found here.',
                style: { top: this.getPositionPx("top-sec", 0, "bottom") + 10, right: 160 },
                caret: { top: '0px', left: '100px' },
                controls: true,
            },
            {
                heading: 'Step 5',
                content: 'This is where you can populate all the given variables (We will get back to this).',
                style: { top: this.getPositionPx("icon", 4, "top") - 30, left: this.getPositionPx("icon", 4, "right") + 20 },
                caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Step 3',
                content: 'This is where you can upload images into your certificate.',
                style: { top: this.getPositionPx("icon", 2, "top") - 30, left: this.getPositionPx("icon", 2, "right") + 20 },
                caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
                controls: false,
            },
            // {
            //     heading: 'Step 4',
            //     content: 'This is where you can upload document, this document will be distributed as attachment along with the certificate to all receipients.',
            //     style: { top: this.getPositionPx("icon", 3, "top") - 30, left: this.getPositionPx("icon", 3, "right") + 20 },
            //     caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
            //     controls: true,
            // },
            {
                heading: 'Add Images',
                content: 'Choose and Upload Logos, Images or Background for your certificate design.',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 20, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Uploaded Images',
                content: 'Here you could find uploaded logos and images which are stored for future use.',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 160, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Step 4',
                content: 'This is where you can add signing authority for this certificate.',
                style: { top: this.getPositionPx("icon", 3, "top") - 30, left: this.getPositionPx("icon", 3, "right") + 20 },
                caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
                controls: false,
            },
            {
                heading: 'Add Signee',
                content: 'Add new signee by adding signing authority.',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 20, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Added Signing Authorities',
                content: 'You could see your added Signing Authorities here.',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 230, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Step 5',
                content: 'This is where you can upload recepients.',
                style: { top: this.getPositionPx("icon", 4, "top") - 30, left: this.getPositionPx("icon", 4, "right") + 20 },
                caret: { top: '60px', left: '-14px', transform: 'rotate(270deg)' },
                controls: false,
            },
            {
                heading: 'Download Format',
                content: 'To upload large list of recepients, start by downloading the format of spreadsheet',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 20, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Add recepients',
                content: 'Here you can add recepinets one by one.',
                style: { top: this.getPositionPx("top-sec", 0, "bottom") + 120, left: this.getPositionPx("top-sec", 0, "left") },
                caret: { top: '0px', left: '10px' },
                controls: true,
            },
            {
                heading: 'Upload Format',
                content: 'After setting up your recepient list in the given format, you can upload it here.',
                style: { top: this.getPositionPx("step-expand", 0, "top") + 140, left: this.getPositionPx("step-expand", 0, "right") },
                caret: { top: '50px', left: '-14px', transform: 'rotate(270deg)' },
                controls: true,
            },
            {
                heading: 'Choose Event',
                content: 'Next choose an event under which this certificate is to be distributed. (click to see the next steps)',
                style: { top: this.getPositionPx("next", 0, "top") - 140, left: this.getPositionPx("next", 0, "left"), zIndex: "995" },
                caret: { bottom: '-9px', left: '10px', transform: 'rotate(180deg)' },
                controls: false,
            },
            {
                heading: 'Event Name',
                content: 'Add the designed certificate under an event.',
                style: { top: this.getPositionPx("input-wrapper", 0, "bottom"), left: this.getPositionPx("input-wrapper", 0, "left") },
                caret: { top: '0px', left: '5px' },
                controls: true,
            },
            {
                heading: 'Groups',
                content: 'If you have group or subevents for the main event, you can club this certificate under that specific group or subevent.',
                style: { top: this.getPositionPx("switch", 0, "bottom") + 10, left: this.getPositionPx("switch", 0, "left") },
                caret: { top: '0px', left: '5px' },
                controls: true,
            },
            {
                heading: 'Feedbacks',
                content: 'To collect feedback for your event from the certificate recipients, you can turn it on here.',
                style: { top: this.getPositionPx("finishing-block", 2, "bottom") + 10, left: this.getPositionPx("finishing-block", 2, "left") },
                caret: { top: '0px', left: '5px' },
                controls: true,
            },
            {
                heading: 'Create Batch',
                content: 'After designing and creating the certificate, click here to create batch of this certificate for sending.',
                style: { top: this.getPositionPx("complete-finishing", 0, "top") - 180, left: this.getPositionPx("complete-finishing", 0, "right") - 300, zIndex: "995" },
                caret: { bottom: '-9px', right: '30px', transform: 'rotate(180deg)' },
                controls: true,
            },
            {
                heading: 'To Know More',
                content: 'Click here to get further ideas on how to use this platform.',
                style: { top: this.getPositionPx("editor-help-btn", 0, "top") - 200, left: this.getPositionPx("editor-help-btn", 0, "left") - 180, zIndex: "995" },
                caret: { bottom: '-9px', left: '180px', transform: 'rotate(180deg)' },
                controls: true,
            }
        ]
        this.setState({
            currentStep: 0,
            steps: newSteps,
        });
    }

    handlePreviousStep = () => {
        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
        }));
    };

    handleCloseTutorial = () => {
        this.setState({ shouldRender: false });
        this.props.toggleOnboarding(false);
        if (this.state.doNotShowChecked) {
            localStorage.setItem('onboarding_editor', true);
        }
    }

    getPositionPx(elem, index, pos) {
        const element = document.getElementsByClassName(elem)[index];
        if (element) {
            const rect = element.getBoundingClientRect();

            const top = rect.top + window.scrollY;
            const left = rect.left + window.scrollX;
            const bottom = rect.bottom + window.scrollY;
            const right = rect.right + window.scrollX;
            const middleY = top + (bottom - top) / 2;
            const middleX = left + (right - left) / 2;

            switch (pos) {
                case "top":
                    return top;
                case "left":
                    return left;
                case "bottom":
                    return bottom;
                case "right":
                    return right;
                case "middleY":
                    return middleY;
                case "middleX":
                    return middleX;
                default:
                    return "0px";
            }
        }
    }

    handleWindowResize = () => {
        this.updateSteps();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }


    doNotShowAgain = (e) => {
        this.setState({
            doNotShowChecked: e.target.checked,
        })
    }

    render() {
        const { currentStep } = this.state;
        const { heading } = this.state.steps[currentStep];
        const { content } = this.state.steps[currentStep];
        const { style } = this.state.steps[currentStep];
        const { caret } = this.state.steps[currentStep];
        const { controls } = this.state.steps[currentStep];

        return (
            <React.Fragment>
                {this.state.shouldRender && (
                    <div className='onboarding-tutorial violet' style={style}>
                        <div className='pos-rel'>
                            <div className='caret' style={caret}></div>
                            <div className='close-btn' onClick={this.handleCloseTutorial}><i className="fa-regular fa-circle-xmark"></i></div>
                            <h2>{heading}</h2>
                            <p>{content}</p>
                            {(currentStep === 0 || currentStep === this.state.steps.length - 1) && (
                                <div className='do-not-show'>
                                    <input type='checkbox' onChange={this.doNotShowAgain} checked={this.state.doNotShowChecked} />
                                    <p>Do not show this again</p>
                                </div>
                            )}
                            {controls && (
                                <div className='buttons'>
                                    {currentStep !== 0 && controls !== 'limited' && (<button onClick={this.handlePreviousStep} disabled={currentStep === 0}><i className="fa-solid fa-angle-left"></i></button>)}
                                    <p className='steps'>{currentStep + 1 + " / " + this.state.steps.length}</p>
                                    <button onClick={this.handleNextStep} disabled={currentStep >= this.state.steps.length}><i className="fa-solid fa-angle-right"></i></button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default OnboardingTutorial;