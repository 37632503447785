import React from 'react';
import axios from 'axios';

import Warning from '../warning';
import WarningAction from '../warning_action';

import ProgressLoader from '../progress_loader';

class DataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            api_keys: [],
            newKeyModal: false,
            key_name: '',
            invalidName: false,
            key_description: '',
            key_domains: [''],
            invalidDomains: [true],
            key_resources: [],
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_api_keys', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    api_keys: res.data.api_keys,
                })
            }
        });
    }

    toggleNewKey = () => {
        this.setState({
            newKeyModal: !this.state.newKeyModal,
        })
    }

    validateDomain = (domain) => {
        const domainPattern = /^(localhost|[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,})$/;
        return domainPattern.test(domain);
    };

    validateAllDomains = () => {
        const { key_domains } = this.state;
        const invalidDomains = key_domains.map((domain) => !this.validateDomain(domain));
        this.setState({ invalidDomains });
    };

    updateKeyDomain = (index, event) => {
        const newDomains = [...this.state.key_domains];
        newDomains[index] = event.target.value.toLowerCase();
        this.setState({ key_domains: newDomains }, () => {
            this.validateAllDomains();
        });
    };

    addDomainField = () => {
        const { key_domains } = this.state;
        if (key_domains.length < 5) {
            this.setState((prevState) => ({
                key_domains: [...prevState.key_domains, ''],
                invalidDomains: [...prevState.invalidDomains, false],
            }));
        } else {
            this.setState({
                warning: {
                    message: "You can only add up to 5 domains.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    };

    removeDomainField = (index) => {
        const { key_domains } = this.state;
        if (key_domains.length > 1) {
            const newDomains = [...key_domains];
            newDomains.splice(index, 1);
            this.setState({ key_domains: newDomains }, () => {
                this.validateAllDomains(); // Re-validate after removal
            });
        } else {
            this.setState({
                warning: {
                    message: "You must have at least one domain.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    };

    updateKeyDescription = (e) => {
        this.setState({
            key_description: e.target.value,
        })
    }

    updateKeyName = (e) => {
        const domainPattern = /^[a-zA-Z-]+$/;
        if (domainPattern.test(e.target.value)) {
            this.setState({
                key_name: e.target.value,
                invalidName: false
            })
        } else {
            this.setState({
                key_name: e.target.value,
                invalidName: true
            })
        }
    }

    updateKeyResource = (e) => {
        console.log(e.target.value)
        if (e.target.value === 'All') {
            this.setState({
                key_resources: [],
            })
        } else {
            this.setState({
                key_resources: [e.target.value],
            })
        }
    }

    createKey = () => {
        this.setState({
            progress_loader: true,
        })
        let now = new Date(Date.now());
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let todaysDate = `${year}-${month}-${day}`;

        if (this.state.key_name !== '' && this.state.invalidDomains.every(element => element === false) && !this.state.invalidName && this.state.key_domains.length > 0) {
            axios.post(this.props.node_server_axios + 'generate_api_key', {
                params: {
                    key_name: this.state.key_name,
                    key_description: this.state.key_description,
                    key_domains: this.state.key_domains,
                    key_resources: this.state.key_resources,
                    key_date: todaysDate,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    window.location.href = '/account?selection=api';
                } else if (res.data.message === 'limit reached') {
                    this.setState({
                        warning: {
                            message: "Upgrade your plan to create more api keys.",
                            type: "warning",
                            learnmore: 'buy',
                            autoclose: false,
                        },
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An Error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Empty / Invalid inputs detected. Please fill the form to generate API Key",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    maskString = (str) => {
        if (str.length <= 8) {
            return str;
        }

        const firstFour = str.slice(0, 4);
        const lastFour = str.slice(-4);

        const middleStars = '*'.repeat(8);

        return `${firstFour}${middleStars}${lastFour}`;
    }

    deleteApiKey = async (id) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete API Key",
                subtext: "Once deleted this API key will be disabled forever. Do you want to Continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'delete_api_key', {
            params: {
                key: id
            }
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState(prevState => ({
                    warning: {
                        message: "API Key Deleted!",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                    api_keys: prevState.api_keys.filter(item => item.key !== id),
                }));
            } else {
                this.setState({
                    warning: {
                        message: "Oops, something went wrong!",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    copyKey = (textToCopy) => {
        if (window.isSecureContext) {
            // If the page is served over HTTPS
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    this.setState({
                        warning: {
                            message: "API Key copied to clipboard!",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                    });
                })
                .catch(err => {
                    console.error('Failed to copy:', err);
                });
        } else {
            // If the page is not served over HTTPS
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.style.padding = 0;
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'transparent';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    this.setState({
                        warning: {
                            message: "API Key copied to clipboard!",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                    });
                } else {
                    console.error('Fallback: Copying text command was unsuccessful');
                }
            } catch (err) {
                console.error('Fallback: Unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    render() {
        return (
            <React.Fragment>
                <h1><span>API</span> Keys</h1>
                <p>Manage your integration & API Keys here.</p>
                {!this.state.newKeyModal ?
                    <div className='api-table'>
                        <h2>Active keys</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Created On</th>
                                    <th>Key</th>
                                    <th>Revoke</th>
                                </tr>
                                {this.state.api_keys && this.state.api_keys.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{element.name}</td>
                                            <td><p>{element.description}</p></td>
                                            <td>{element.created_on}</td>
                                            <td onClick={() => this.copyKey(element.key)}><p><i className="fa-regular fa-copy"></i> {this.maskString(element.key)}</p></td>
                                            <td><button onClick={() => this.deleteApiKey(element.key)}>Revoke <i className="fa-regular fa-trash-can"></i></button></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <button onClick={this.toggleNewKey}>Create New Key</button>
                    </div>
                    :
                    <div className='api-modal'>
                        <button className='back-btn' onClick={this.toggleNewKey}><i className="fa-solid fa-arrow-left-long"></i> Back</button>
                        <h2>Create New Key</h2>
                        <a href='/resources/api' target="_blank" className='info-box'>
                            <p><i className="fa-solid fa-circle-info"></i> Learn how to use API Keys</p>
                            <b>See Documentation <i className="fa-solid fa-arrow-right-long"></i></b>
                        </a>
                        <div className='input-wrapper'>
                            <label>Key Name</label>
                            <input type='text' placeholder='Key-Name' value={this.state.key_name} onChange={this.updateKeyName} />
                            {this.state.invalidName ?
                                <p className='warn'>Key name can only contain alphabets and -</p>
                                :
                                <p className='desc'>Will be used to identify your key</p>
                            }
                        </div>
                        <div className='input-wrapper'>
                            <label>Description</label>
                            <textarea placeholder='descripr what this key will be used for (optional)' onChange={this.updateKeyDescription}></textarea>
                            <p className='desc'>Will be used to identify your key</p>
                        </div>
                        <div className='input-wrapper'>
                            <label>White-listed Domains</label>
                            {this.state.key_domains.map((domain, index) => (
                                <div key={index} className={`container ${this.state.invalidDomains[index] ? 'invalid' : ''}`}>
                                    <input type='text' placeholder='domain.com' value={domain} onChange={(event) => this.updateKeyDomain(index, event)} />
                                    <i title="invalid domain" className="fa-solid fa-triangle-exclamation"></i>
                                    <button onClick={() => this.removeDomainField(index)}><i className="fa-regular fa-trash-can"></i></button>
                                </div>
                            ))}
                            <p className='desc'>{this.state.key_domains.length}/5 domains</p>
                            <button onClick={this.addDomainField}>+ Add</button>
                        </div>
                        <div className='input-wrapper'>
                            <label>Resources</label>
                            <select onChange={this.updateKeyResource}>
                                <option>All</option>
                                <option>Pronetin badge API</option>
                            </select>
                            <p className='desc'>It is better to give access to only desired resources</p>
                        </div>
                        <button onClick={this.createKey}>Create Key</button>
                    </div>
                }
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default DataComponent;