import React from 'react';
import Header from '../components/header';
import Element from '../components/help';
import axios from 'axios';

class HelpComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
            logged_in: false,
            header_user_data: "",
        }
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    loading: false,
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    user_id: res.data.user_id,
                    storage: res.data.storage === null ? 10 : res.data.storage,
                    header_user_data: res.data,
                    logged_in: true,
                });
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="main no-margin">
                    <Header login={this.state.logged_in} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                    <Element node_server_axios={this.props.node_server_axios} logged_in={this.state.logged_in} />
                </div>
            </React.Fragment>
        );
    }
}

export default HelpComponent;