import React, { Component } from 'react';
import axios from 'axios';

import Header from '../components/header';
import Loading from '../components/loading';

import '../components/css/main.css';
import '../components/css/premium.css';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';

import pronetinLogo from '../images/static/Pronetin Logo.png';
import coinIcon from '../images/static/Component 397 – 4.png';

class PaymentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
            org_id: "",
            order: null,
            paymentId: null,
            signature: null,
            currentPlan: '',
            creditToBuy: 100,
            per_credit: 4,
            amountToPay: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            previousPageName: null,
            promo_code: '',
            yearlyPayment: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    createOrder = async (plan) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Changing Plan",
                subtext: "Plan will be changed along with its features after the purchase, This cannot be reverted. Do you want to Continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        if (this.state.running_plan !== plan) {
            // let credits = 0;
            // switch (plan) {
            //     case 'pro':
            //         credits = 530;
            //         break;
            //     case 'business':
            //         credits = 1530;
            //         break;
            //     case 'enterprise':
            //         credits = 2530;
            //         break;
            //     case 'pro-y':
            //         credits = 6360;
            //         break;
            //     case 'business-y':
            //         credits = 18360;
            //         break;
            //     case 'enterprise-y':
            //         credits = 30360;
            //         break;
            //     default:
            //         credits = 0;
            //         break;
            // }
            axios.post(this.props.node_server_axios + 'create-order', {
                params: {
                    plan: plan,
                },
            }).then(res => {
                const data = res.data;
                // this.setState({ order: data, currentPlan: plan, creditToBuy: credits });
                this.setState({ order: data, currentPlan: plan });
                this.showRazorpay(data);
            });
        } else {
            this.setState({
                warning: {
                    message: "This plan is already running for your organisation, You can purchase more credits if necessary.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                }
            })
        }
    };

    showRazorpay = (order) => {
        const options = {
            key: this.props.rzp_auth_key,
            amount: order.amount,
            currency: order.currency,
            name: 'Pronetin',
            description: this.state.currentPlan + ' Plan',
            image: pronetinLogo,
            order_id: order.id,
            handler: this.handlePaymentSuccess,
            prefill: {
                name: this.state.name,
                email: this.state.email,
            },
            theme: {
                color: "#2977C9",
            }
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    handlePaymentSuccess = async (payment) => {
        const response = await fetch(this.props.node_server_axios + 'verify-payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                razorpay_payment_id: payment.razorpay_payment_id,
                razorpay_order_id: payment.razorpay_order_id,
                razorpay_signature: payment.razorpay_signature,
                plan: this.state.currentPlan,
                creditToBuy: this.state.creditToBuy,
                org_id: this.state.org_id,
            })
        });
        const data = JSON.parse(await response.text());
        this.setState({ paymentId: payment.razorpay_payment_id, signature: payment.razorpay_signature });
        if (data.message === 'success') {
            if (this.state.currentPlan === 'credit') {
                //---------------------Logging Data (Organisation) Start---------------------
                await axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.state.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '+' + this.state.creditToBuy,
                        content: "Purchased credits",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
            } else {
                //---------------------Logging Data (Organisation) Start---------------------
                await axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.state.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '+' + this.state.creditToBuy,
                        content: "Organisation billing plan changed to " + this.state.currentPlan,
                    },
                })
                //----------------------Logging Data (Organisation) End---------------------- 
            }
            window.location = '/premium';
        } else {
            this.setState({
                warning: {
                    message: "Sorry, An error has occoured, Contact our customer care.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                }
            })
        }
    };

    componentDidMount = async () => {
        const previousPageUrl = document.referrer;
        const previousPageName = this.getPageName(previousPageUrl);

        axios.defaults.withCredentials = true;
        await axios.post(this.props.node_server_axios + 'force_update_session').catch(() => {
            window.location = "/signin";
        });

        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    org_id: res.data.org_id,
                    header_user_data: res.data,
                });
                axios.post(this.props.node_server_axios + 'get_organisation_data', {
                    params: {
                        org_id: res.data.org_id,
                    }
                }).then((res) => {
                    if (res.data !== undefined && res.data.message === 'success') {
                        this.setState({
                            loading: false,
                            credits: parseInt(res.data.credits),
                            per_credit: parseInt(res.data.per_credit),
                            amountToPay: this.state.creditToBuy * parseInt(res.data.per_credit),
                            running_plan: res.data.running_plan,
                            previousPageName: previousPageName,
                        });
                    } else {
                        window.location = "/signin";
                    }
                })
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })

        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        this.setState({ razorpayScript: script });
    }

    componentWillUnmount() {
        if (this.state.razorpayScript) {
            document.body.removeChild(this.state.razorpayScript);
        }
    }

    getPageName(url) {
        const segments = url.split('/');
        const lastSegment = segments[segments.length - 1];
        return lastSegment;
    }


    toggleMode = () => {
        const testElements = document.querySelectorAll(".toggle-mode .t");
        testElements.forEach(element => {
            element.classList.remove("active");
        });
        this.setState({
            yearlyPayment: !this.state.yearlyPayment,
        });
    }

    increaseCredit = () => {
        this.setState({
            creditToBuy: this.state.creditToBuy + 100,
            amountToPay: (this.state.creditToBuy + 100) * this.state.per_credit,
        });
    }

    decreaseCredit = () => {
        if (this.state.creditToBuy > 100) {
            this.setState({
                creditToBuy: this.state.creditToBuy - 100,
                amountToPay: (this.state.creditToBuy - 100) * this.state.per_credit,
            });
        }
    }

    buyCredits = (amountToPay) => {
        axios.post(this.props.node_server_axios + 'get_organisation_data', {
            params: {
                org_id: this.state.org_id,
            }
        }).then((res) => {
            if (res.data !== undefined && res.data.message === 'success') {
                if (res.data.per_credit === this.state.per_credit) {
                    if (parseInt(amountToPay / this.state.per_credit) === this.state.creditToBuy) {
                        axios.post(this.props.node_server_axios + 'create-order-credits', {
                            params: {
                                amount: amountToPay,
                            },
                        }).then(res => {
                            const data = res.data;
                            this.setState({ order: data, currentPlan: 'credit' });
                            this.showRazorpay(data);
                        });
                    } else {
                        this.setState({
                            warning: {
                                message: "Invalid Purchase Plan",
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            }
                        })
                    }
                } else {
                    this.setState({
                        warning: {
                            message: "Invalid Purchase Plan",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                window.location = "/signin";
            }
        })
    }

    changePromoCode = (e) => {
        this.setState({
            promo_code: e.target.value,
        })
    }

    applyPromo = () => {
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    org_id: res.data.org_id,
                });
                axios.post(this.props.node_server_axios + 'apply_promo', {
                    params: {
                        email: res.data.email,
                        user_type: res.data.user_type,
                        org_id: res.data.org_id,
                        promo_code: this.state.promo_code,
                    }
                }).then((res) => {
                    if (res.data !== undefined && res.data.message === 'success') {
                        //---------------------Logging Data (Organisation) Start---------------------
                        axios.post(this.props.node_server_axios + 'update_organisation_log', {
                            params: {
                                email: this.state.email,
                                dateTS: this.logFormattedDate(new Date()),
                                creditsConsumed: '+' + this.state.promo_code.match(/\d+$/)[0],
                                content: "Promo Code: " + this.state.promo_code + " was applied",
                            },
                        })
                        //----------------------Logging Data (Organisation) End----------------------
                        this.setState({
                            warning: {
                                message: res.data.value,
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            credits: res.data.new_credits,
                        })
                    } else if (res.data !== undefined) {
                        this.setState({
                            warning: {
                                message: res.data.value,
                                type: "warning",
                                learnmore: false,
                                autoclose: true,
                            }
                        })
                    }
                })
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header />
                        <Loading />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <div className="main">
                    <Header login={true} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                    <div className='premium-header'>
                        {this.state.previousPageName !== null && this.state.previousPageName !== 'premium' ?
                            <a className='nav' href={'/' + this.state.previousPageName}>
                                <i className="fa-solid fa-arrow-left"></i>
                                Back
                            </a>
                            :
                            <a className='nav' href='/organisation'>
                                <i className="fa-solid fa-arrow-left"></i>
                                Back
                            </a>
                        }
                        <div className='sub-wrapper'>
                            <button className='lisence-key-btn' onClick={() => window.location.href = './account?selection=payments'}>Enter License Key <i className="fa-solid fa-key"></i></button>
                            <div className='plan-wrapper'>
                                <p>{this.state.running_plan === null || this.state.running_plan === '' ? 'Free' : this.state.running_plan}</p>
                                <hr></hr>
                                <p><span>{this.state.credits}</span> <img src={coinIcon} className="coin-icon" alt="credit icon"></img> Credits</p>
                            </div>
                        </div>
                        <div className='toggle-mode' onClick={this.toggleMode}>
                            <div className={this.state.yearlyPayment ? 't active' : 't'}>Pay Yearly <b>(upto 18% off)</b></div>
                            <div className={!this.state.yearlyPayment ? 't active' : 't'}>Pay Monthly</div>
                        </div>
                    </div>
                    <div className='premium-container'>
                        <div className={this.state.running_plan === 'pro' || this.state.running_plan === 'pro-y' ? 'pricing active' : 'pricing'}>
                            {(this.state.running_plan === 'pro' || this.state.running_plan === 'pro-y') && (<div className='popular'>Current Plan</div>)}
                            <h2>Premium</h2>
                            <h3>₹ {this.state.yearlyPayment ? 10200 : 999} <span>/ {this.state.yearlyPayment ? 'Year' : 'Month'}</span></h3>
                            <hr></hr>
                            <div className='features'>
                                <p><i className="fa-regular fa-circle-check"></i> All templates free</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                <p><i className="fa-regular fa-circle-check"></i> Full fledge Certificate Editor</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free {this.state.yearlyPayment ? '6360' : '530'} credits/{this.state.yearlyPayment ? 'year' : 'month'}</p>
                                <p><i className="fa-regular fa-circle-check"></i> Issue up to {this.state.yearlyPayment ? '6350' : '520'} certificates</p>
                                <p><i className="fa-regular fa-circle-check"></i> Collaborate design with up to 2 organisation members per design.</p>
                                <p><i className="fa-regular fa-circle-check"></i> Customizable pronetin templates + additional designs on request with payment</p>
                                <p><i className="fa-regular fa-circle-check"></i> Premium support</p>
                                <p><i className="fa-regular fa-circle-check"></i> Scheduled Onboarding & Training*</p>
                            </div>
                            <button className='buy-button' onClick={() => { this.state.yearlyPayment ? this.createOrder('pro-y') : this.createOrder('pro') }}>Buy <i className="fa-regular fa-credit-card"></i></button>
                        </div>
                        <div className={this.state.running_plan === 'business' || this.state.running_plan === 'business-y' ? 'pricing active' : 'pricing'}>
                            <div className='popular'>{this.state.running_plan === 'business' || this.state.running_plan === 'business-y' ? 'Current Plan' : 'Most Popular'}</div>
                            <h2>Business</h2>
                            <h3>₹ {this.state.yearlyPayment ? 29544 : 2897}<span>/ {this.state.yearlyPayment ? 'Year' : 'Month'}</span></h3>
                            <hr></hr>
                            <div className='features'>
                                <p><i className="fa-regular fa-circle-check"></i> All templates free</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                <p><i className="fa-regular fa-circle-check"></i> Full fledge Certificate Editor</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                <p><i className="fa-regular fa-circle-check"></i> free {this.state.yearlyPayment ? '18360' : '1530'} credits/{this.state.yearlyPayment ? 'year' : 'month'}</p>
                                <p><i className="fa-regular fa-circle-check"></i> Issue up to {this.state.yearlyPayment ? '18350' : '1520'} certificates</p>
                                <p><i className="fa-regular fa-circle-check"></i> Collaborate design with up to 5 organisation members</p>
                                <p><i className="fa-regular fa-circle-check"></i> Customizable pronetin templates + Free 10 custom templates on request + Extra designs with payment</p>
                                <p><i className="fa-regular fa-circle-check"></i> Premium support</p>
                                <p><i className="fa-regular fa-circle-check"></i> Scheduled Onboarding & Training</p>
                            </div>
                            <button className='buy-button' onClick={() => { this.state.yearlyPayment ? this.createOrder('business-y') : this.createOrder('business') }}>Buy <i className="fa-regular fa-credit-card"></i></button>
                        </div>
                        <div className={this.state.running_plan === 'enterprise' || this.state.running_plan === 'enterprise-y' ? 'pricing active' : 'pricing'}>
                            {(this.state.running_plan === 'enterprise' || this.state.running_plan === 'enterprise-y') && (<div className='popular'>Current Plan</div>)}
                            <h2>Enterprise</h2>
                            <h3>₹ {this.state.yearlyPayment ? 49200 : 4999}<span>/ {this.state.yearlyPayment ? 'Year' : 'Month'}</span></h3>
                            <hr></hr>
                            <div className='features'>
                                <p><i className="fa-regular fa-circle-check"></i> All templates free</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                <p><i className="fa-regular fa-circle-check"></i> Full fledge Certificate Editor</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                <p><i className="fa-regular fa-circle-check"></i> free {this.state.yearlyPayment ? '30360' : '2530'} credits/{this.state.yearlyPayment ? 'year' : 'month'}</p>
                                <p><i className="fa-regular fa-circle-check"></i> Issue up to {this.state.yearlyPayment ? '30350' : '2520'} certificates</p>
                                <p><i className="fa-regular fa-circle-check"></i> Collaborate design with 10+ organisation members and 10+ non-organization members.</p>
                                <p><i className="fa-regular fa-circle-check"></i> Customizable pronetin templates + Free 10 custom templates on request + Extra designs with payment.</p>
                                <p><i className="fa-regular fa-circle-check"></i> Get pronetin designers for design assistance.</p>
                                <p><i className="fa-regular fa-circle-check"></i> Premium support</p>
                                <p><i className="fa-regular fa-circle-check"></i> Any time Onboarding & Training</p>
                            </div>
                            <button className='buy-button' onClick={() => { this.state.yearlyPayment ? this.createOrder('enterprise-y') : this.createOrder('enterprise') }}>Buy <i className="fa-regular fa-credit-card"></i></button>
                        </div>
                        <div className='pricing'>
                            <h2>Free</h2>
                            <h3>₹ 0 <span>/ Month</span></h3>
                            <hr></hr>
                            <div className='features'>
                                <p><i className="fa-regular fa-circle-check"></i> Limited free templates</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free Email template</p>
                                <p><i className="fa-regular fa-circle-check"></i> Certificate editor with limited features</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free document storage up to 100+ documents*</p>
                                <p><i className="fa-regular fa-circle-check"></i> Free 130 Pronetin credits</p>
                                <p><i className="fa-regular fa-circle-check"></i> Issue up to 120 certificates for free</p>
                                <p><i className="fa-regular fa-circle-check"></i> Can only use customizable pronetin templates.</p>
                                <p><i className="fa-regular fa-circle-check"></i> Customer support via Email</p>
                            </div>
                        </div>
                        <div className='pricing'>
                            <h2>Buy Credits</h2>
                            <p className='buy-credit-content'>Cost per credit varies based on the selected plan <b>Current Plan : {this.state.running_plan}</b> <b>Cost per Credit : ₹{this.state.per_credit}</b></p>
                            <div className='qty-wrapper'>
                                <div className='pack'>
                                    <div className='qty' onClick={this.decreaseCredit}>-</div>
                                    <div className='wrapper'>
                                        <h4>{this.state.creditToBuy}</h4>
                                        <p>Credits</p>
                                    </div>
                                    <div className='qty' onClick={this.increaseCredit}>+</div>
                                </div>
                                <div className='buy'>
                                    <h3>₹ {this.state.amountToPay}</h3>
                                    <button onClick={() => this.buyCredits(this.state.amountToPay)}>Buy <i className="fa-regular fa-credit-card"></i></button>
                                </div>
                                <div className='buy'>
                                    <input type='text' placeholder='Promo Code' name='promoCode' onChange={this.changePromoCode}></input>
                                    <button onClick={this.applyPromo}>Redeem</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                    }
                    {
                        this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                    }
                </div>
            );
        }
    }
}

export default PaymentButton;