import React from 'react';
import './css/signin.css';
import SigninIcon from './resources/lf-1.png';
// import GoogleButton from './googleSigin';
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confpassword: '',
            isError: {
                password: '',
                confpassword: '',
            },
            response: '',
            warningActive: '',
            resetCompleted: false,
            showPassword: false,
            showConfPassword: false,
        };
    }

    checkValidation = (e) => {
        var regPassword = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).+$/;
        const { name, value } = e.target;
        let isError = { ...this.state.isError };
        let pass = { ...this.state };

        //Resets warning on any field input change
        this.setState({
            response: '',
            warningActive: false
        });

        switch (name) {
            case "password":
                isError.password = value.length < 8 ? "Atleast 8 characaters required" : "";
                if (isError.password === "") {
                    isError.password = !regPassword.test(value) ? "Password should contain \n 1. Alphabet \n 2. Number \n 3. Special Character" : "";
                }
                document.getElementById('confpassword').value = '';
                break;
            case "confpassword":
                isError.confpassword = pass.password === value ? "" : "Passwords do not match";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault();

        axios.defaults.withCredentials = true;

        axios.post(this.props.node_server_axios + 'reset_password', {
            params: {
                data: {
                    email: this.props.email.toLowerCase().replace(/\s+/g, ''),
                    otp: this.props.otp,
                    password: event.target.password.value,
                    confpassword: event.target.confpassword.value,
                },
                error: this.state.isError
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    response: res.data.message,
                    warningActive: true,
                    resetCompleted: true,
                })
            } else if (res.data.message !== '') {
                this.setState({
                    response: res.data.message,
                    warningActive: true,
                    resetCompleted: false,
                })
            }
        });
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    }

    toggleConfPasswordVisibility = () => {
        this.setState(prevState => ({
            showConfPassword: !prevState.showConfPassword,
        }));
    }

    render() {
        const { isError } = this.state;
        return (
            <React.Fragment>
                <header className="wrapper">
                    <form method='POST' onSubmit={this.handleSubmit}>
                        {this.state.resetCompleted ?
                            <div className="container">
                                <img src={SigninIcon} className="userimage" alt="user signin"></img>
                                <h1>Completed</h1>
                                <p>Your password has been reset.<br></br>Signin to continue.</p>
                                <p><a className='secondary' href='/signin'>Sign in <i className="fa-solid fa-arrow-right-to-bracket"></i></a></p>
                            </div>
                            :
                            <div className="container">
                                <h1>New Password</h1>
                                <div className='relative block w-100 flex-cr'>
                                    {isError.password.length > 0 && (
                                        <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.password}</span></div>
                                    )}
                                    <div className='password-visibility' onClick={this.togglePasswordVisibility}>{this.state.showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                    <input type={this.state.showPassword ? 'text' : 'password'} name='password' autoComplete='new-password' placeholder="********" className={this.state.response !== '' && this.state.password === '' ? 'error' : ''} onChange={this.checkValidation} onKeyDown={(e) => e.key === " " && e.preventDefault()} />
                                    <label>Password</label>
                                </div>
                                {this.state.password.length > 0 && (
                                    <div className='password-warns'>
                                        <p>{isError.password === 'Atleast 8 characaters required' ? <i className="fa-solid fa-circle-xmark"></i> : <i className="fa-solid fa-circle-check"></i>} Atleast 8 Character</p>
                                        <p>{/[a-zA-Z]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Alphabet</p>
                                        <p>{/[0-9]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Number</p>
                                        <p>{/[\W_]/.test(this.state.password) ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-xmark"></i>} Atleast 1 Special Character</p>
                                    </div>
                                )}
                                <div className='relative block w-100 flex-cr'>
                                    {isError.confpassword.length > 0 && (
                                        <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.confpassword}</span></div>
                                    )}
                                    <div className='password-visibility' onClick={this.toggleConfPasswordVisibility}>{this.state.showConfPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                    <input type={this.state.showConfPassword ? 'text' : 'password'} name='confpassword' autoComplete='new-password' placeholder="********" id='confpassword' className={this.state.response !== '' && this.state.confpassword === '' ? 'error' : ''} onChange={this.checkValidation} onKeyDown={(e) => e.key === " " && e.preventDefault()} />
                                    <label>Confirm Password</label>
                                </div>
                                <p className={this.state.warningActive ? 'warning block' : 'warning none'}><i className='fa-solid fa-circle-exclamation'></i> {this.state.response}</p>
                                <button type='submit'>Reset Password</button>
                                <p><a className='secondary' href='/signin'>Sign in <i className="fa-solid fa-arrow-right-to-bracket"></i></a></p>
                            </div>
                        }
                    </form>
                </header>
            </React.Fragment>
        );
    }
};

export default App;