import React from 'react';
import './css/element.css';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href).hostname + `/certificate/${this.props.data.document_id}`,
            title: this.props.data.title,
            description: 'Document received for ' + this.props.data.event + ' on ' + this.props.data.date,
            moreShareOptions: false
        };
        this.containerRef = React.createRef();
        this.textElementRef = React.createRef();
    }

    openTile = () => {
        window.location = `/appreciation/${this.props.data.document_id}`
    }

    toggleMoreShareOptions = () => {
        this.setState({
            moreShareOptions: !this.state.moreShareOptions,
        })
    }

    componentDidMount() {
        const container = this.containerRef.current;
        const textElement = this.textElementRef.current;

        if (textElement.scrollWidth > container.clientWidth) {
            textElement.classList.add('scrolling');
        } else {
            textElement.classList.remove('scrolling');
        }
    }

    render() {
        const document = this.props.data;

        return (
            <div className='tileV2 pronetin' onClick={this.openTile}>
                <div className='name'>
                    <h1 ref={this.containerRef}><span ref={this.textElementRef}>{document.event}</span></h1>
                    <p>{document.title}</p>
                </div>
                <label>Received date : {document.date}</label>
                <div className='organisation'>{document.issuer_name}</div>
                <div className='bottom'>
                    <div className={document.view_type === "public" ? 'view-option public' : 'view-option private'} title={document.view_type}>
                        {document.view_type === "public" ? <i className="fa-solid fa-lock-open"></i> : <i className="fa-solid fa-lock"></i>}
                    </div>
                    <div className='trial'><i className="fa-solid fa-gift"></i> We value your contribution</div>
                </div>
                <button className='view-cert-v2-btn'>View certificate</button>
            </div>
        );
    }
}

export default TileComponent;