import React from 'react';
import axios from 'axios';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

import coinIcon from '../../images/static/Component 397 – 4.png';
import logo_small from '../../images/static/Pronetin Logo.png';

import Warning from '../../components/warning';

class CheckoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            perCredit: 4,
            billingTenure: "monthly",
            credits: 0,
            price: 0,
            sendUpto: 0,
            planName: "Free",
            additionalCredit: 0,
            additionalCreditLimit: 100,
            discountPercent: 0,
            discountLimit: 120,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            emailVerification: false,
            email: "",
            otp: Array(6).fill(''),
            timer: 180, //seconds
            showResendButton: false,
            OTPModal: false,
            response: "",
            incorrectOTP: false,
            customerName: "",
        };
        this.inputs = [];
        this.interval = null;
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const plan = params.get('plan');
        const acredits = params.get('acredits');
        if (plan) {
            const paymentGatewayLimit = 50000;
            let credits = 0;
            let price = 0;
            let billingTenure = "monthly";
            let sendUpto = 0;
            let planName = "Free";
            let perCredit = 4;
            switch (plan) {
                case 'pro':
                    credits = 530;
                    price = 999;
                    billingTenure = "monthly";
                    sendUpto = credits - 10;
                    planName = "Premium";
                    perCredit = 4;
                    break;
                case 'business':
                    credits = 1530;
                    price = 2897;
                    billingTenure = "monthly";
                    sendUpto = credits - 10;
                    planName = "Business";
                    perCredit = 3;
                    break;
                case 'enterprise':
                    credits = 2530;
                    price = 4999;
                    billingTenure = "monthly";
                    sendUpto = credits - 10;
                    planName = "Enterprise";
                    perCredit = 2;
                    break;
                case 'pro-y':
                    credits = 6360;
                    price = 850;
                    billingTenure = "yearly";
                    sendUpto = credits - 10;
                    planName = "Premium";
                    perCredit = 4;
                    break;
                case 'business-y':
                    credits = 18360;
                    price = 2462;
                    billingTenure = "yearly";
                    sendUpto = credits - 10;
                    planName = "Business";
                    perCredit = 3;
                    break;
                case 'enterprise-y':
                    credits = 30360;
                    price = 4100;
                    billingTenure = "yearly";
                    sendUpto = credits - 10;
                    planName = "Enterprise";
                    perCredit = 2;
                    break;
                default:
                    credits = 0;
                    price = 0;
                    billingTenure = "monthly";
                    sendUpto = credits - 10;
                    planName = "Free";
                    perCredit = 4;
                    break;
            }
            let additionalCreditLimit = billingTenure === "yearly" ? Math.floor((paymentGatewayLimit - (price * 12)) / (perCredit * 100)) * 100 : Math.floor((paymentGatewayLimit - price) / (perCredit * 100)) * 100;
            this.setState({
                perCredit,
                billingTenure,
                credits,
                price,
                sendUpto,
                planName,
                additionalCreditLimit,
                additionalCredit: (acredits && parseInt(acredits) < additionalCreditLimit) ? parseInt(acredits) : acredits !== null ? additionalCreditLimit : 0,
                plan,
            });
        } else {
            window.location.href = "/pricing";
        }

        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        this.setState({ razorpayScript: script });
    }

    componentWillUnmount() {
        if (this.state.razorpayScript) {
            document.body.removeChild(this.state.razorpayScript);
        }
    }

    updateAdditionalCredit = (e) => {
        this.setState({ additionalCredit: parseInt(e.target.value) });
    }

    applyPromo = () => {
        let promoCode = document.getElementById("promoCode").value
        if (promoCode === "NEWACC10") {
            this.setState({ discountPercent: 10, discountLimit: 120, promoCode });
        } else {
            this.setState({
                warning: {
                    message: "Invalid Promocode",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    updateEmail = (e) => {
        this.setState({ email: e.target.value, response: "" });
    }

    updateCustomerName = (e) => {
        this.setState({ customerName: e.target.value });
    }

    handleOTPChange = (element, index) => {
        const { value } = element.target;
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...this.state.otp];
            newOtp[index] = value;
            this.setState({ otp: newOtp, incorrectOTP: false }, () => {
                if (value !== '' && index < 5) {
                    this.inputs[index + 1].focus();
                }
            });
        }
    };

    handleOTPPaste = (e) => {
        const pastedData = e.clipboardData.getData('text').slice(0, 6);
        if (/^\d{6}$/.test(pastedData)) {
            this.setState({ otp: pastedData.split('') }, () => {
                this.inputs[5].focus();
            });
        }
        e.preventDefault();
    };

    handleOTPKeyDown = (e, index) => {
        if (e.key === 'Backspace' && this.state.otp[index] === '' && index > 0) {
            this.inputs[index - 1].focus();
        }
    };

    startTimer = () => {
        this.interval = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.timer === 0) {
                    clearInterval(this.interval);
                    return { showResendButton: true };
                }
                return { timer: prevState.timer - 1 };
            });
        }, 1000);
    };

    formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    verifyEmail = () => {
        const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (this.state.email !== '' && isValidEmail(this.state.email)) {
            this.startTimer();
            this.setState({ OTPModal: true });
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'checkout-email-verification', {
                params: {
                    data: {
                        email: this.state.email,
                        action: "send",
                    },
                },
            }).then(res => {
                if (res.data.message) {
                    this.setState({ response: res.data.message })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Invalid Email ID",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    handleEmailResend = () => {
        const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (this.state.email !== '' && isValidEmail(this.state.email)) {
            this.startTimer();
            this.setState({ OTPModal: true, timer: 180, showResendButton: false });
            axios.post(this.props.node_server_axios + 'checkout-email-verification', {
                params: {
                    data: {
                        email: this.state.email,
                        action: "resend",
                    },
                },
            }).then(res => {
                if (res.data.message) {
                    this.setState({ response: res.data.message })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Invalid Email ID",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    verifyEmailOTP = () => {
        axios.post(this.props.node_server_axios + 'checkout-email-otp-verify', {
            params: {
                email: this.state.email,
                otp: this.state.otp.join(''),
            },
        }).then(res => {
            if (res.data.message === "verified") {
                this.setState({
                    emailVerification: true,
                    OTPModal: false,
                    response: "",
                    warning: {
                        message: "Email verified, License key will be send to this email.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            } else {
                this.setState({
                    incorrectOTP: true,
                })
            }
        });
    }

    closeOTPModal = () => {
        clearInterval(this.interval);
        this.setState({ OTPModal: false, timer: 180 });
    }

    createOrder = (totalAmount) => {
        if (parseInt(totalAmount) > 50000) {
            this.setState({
                warning: {
                    message: "For purchases above ₹50,000 plase contact us at help@pronetin.com.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        } else if (this.state.customerName.length < 1) {
            this.setState({
                warning: {
                    message: "Enter your Name for billing purposes.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        } else if (this.state.emailVerification) {
            axios.post(this.props.node_server_axios + 'checkout-order', {
                params: {
                    plan: this.state.plan,
                    credits: this.state.additionalCredit,
                    promocode: this.state.promoCode,
                },
            }).then(res => {
                const data = res.data;
                this.showRazorpay(data);
            });
        } else {
            this.setState({
                warning: {
                    message: "Please Verify your Email ID",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    showRazorpay = (order) => {
        const options = {
            key: this.props.rzp_auth_key,
            amount: order.amount,
            currency: order.currency,
            name: 'Pronetin',
            description: this.state.planName + ' Plan',
            image: logo_small,
            order_id: order.id,
            handler: this.handlePaymentSuccess,
            prefill: {
                name: this.state.customerName,
                email: this.state.email,
            },
            theme: {
                color: "#2977C9",
            }
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    handlePaymentSuccess = async (payment) => {
        const response = await fetch(this.props.node_server_axios + 'verify-payment-checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                razorpay_payment_id: payment.razorpay_payment_id,
                razorpay_order_id: payment.razorpay_order_id,
                razorpay_signature: payment.razorpay_signature,
                plan: this.state.plan,
                additionalCredit: this.state.additionalCredit,
                email: this.state.email,
                customerName: this.state.customerName,
                promoCode: this.state.promoCode,
                planName: this.state.planName,
                price: this.state.price,
                billingTenure: this.state.billingTenure,
            })
        });
        const data = JSON.parse(await response.text());
        if (data.message === 'success') {
            window.location = '/thankyou?invoice=' + data.invoiceNumber;
        } else {
            this.setState({
                warning: {
                    message: "Sorry, An error has occoured, Contact our customer care.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                }
            })
        }
    };

    render() {
        let netTotal = this.state.billingTenure === "yearly" ? (this.state.price * 12) + (this.state.additionalCredit * this.state.perCredit) : this.state.price + (this.state.additionalCredit * this.state.perCredit);
        let discountMax = (netTotal * this.state.discountPercent) / 100;
        let discountPrice = Math.floor(discountMax > this.state.discountLimit ? this.state.discountLimit : discountMax);
        let totalAmount = netTotal - discountPrice;
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    {this.state.OTPModal && (
                        <div className='modal-otp'>
                            <button className='cancel-btn' onClick={this.closeOTPModal}>Cancel <i className="fa-regular fa-circle-xmark"></i></button>
                            <div className='container'>
                                <h2>Verify Email</h2>
                                <p>Enter the otp send to {this.state.email} via email</p>
                                <div className='otp-field'>
                                    <div onPaste={this.handleOTPPaste}>
                                        {this.state.otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => this.handleOTPChange(e, index)}
                                                onKeyDown={(e) => this.handleOTPKeyDown(e, index)}
                                                ref={(input) => (this.inputs[index] = input)}
                                                className={this.state.incorrectOTP ? 'otp-input otp-input-error' : 'otp-input'}
                                            />
                                        ))}
                                    </div>
                                    {this.state.incorrectOTP && (<p className='warning-small'><i className="fa-solid fa-triangle-exclamation"></i> Incorrect OTP</p>)}
                                </div>
                                {this.state.showResendButton ? (
                                    <button onClick={this.handleEmailResend} className="resend-btn">
                                        Resend Email
                                    </button>
                                ) : (
                                    <p className='small'>
                                        Resend email in {this.formatTime(this.state.timer)}
                                    </p>
                                )}
                                <button onClick={this.verifyEmailOTP}>Verify <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    )}
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>Checkout</h2>
                            <p>continue purchasing your license key</p>
                        </div>
                        <div className='checkout-box'>
                            <div className='items'>
                                <div className='sub'>
                                    <label>License key for purchased plan will be send to this Email ID <span>*</span></label>
                                    <div className='input-email'>
                                        <input type="text" placeholder='Email' value={this.state.email} required onChange={this.updateEmail}></input>
                                        <div className='action'>
                                            {this.state.emailVerification ?
                                                <i className="fa-solid fa-circle-check"></i>
                                                :
                                                <button onClick={this.verifyEmail}>verify</button>
                                            }
                                        </div>
                                    </div>
                                    {this.state.response.length > 1 && (<p className='warning-small'>{this.state.response}</p>)}
                                    <input type="text" placeholder='Billing Name' value={this.state.customerName} required onChange={this.updateCustomerName}></input>
                                </div>
                                <div className='sub'>
                                    <h2>Pronetin plans</h2>
                                    <div className="wrapper">
                                        <img src={logo_small} alt='plan-icon' />
                                        <div className='content'>
                                            <h3>{this.state.planName} Plan</h3>
                                            <label>License Key - billed {this.state.billingTenure}</label>
                                            <p>{this.state.credits} Free credits</p>
                                            <label>(Send upto {this.state.sendUpto} certificates*)</label>
                                            <button>Check if this plan is right for me</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='sub'>
                                    <h2>Add-ons</h2>
                                    <div className="wrapper">
                                        <img src={coinIcon} alt='plan-icon' />
                                        <div className='content'>
                                            <h3>Additional Credits</h3>
                                            <label>₹{this.state.perCredit} per credit</label>
                                            <input type="number" id="multiples-input" name="multiples-input" min="0" max={this.state.additionalCreditLimit} value={this.state.additionalCredit} required onChange={this.updateAdditionalCredit}></input>
                                            <p className='small'>Capped at {this.state.additionalCreditLimit} credits<br></br>(total purchase amount cannot exceed ₹50,000)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='checkout-pricing'>
                                <h2><i className="fa-solid fa-tag"></i> Promocode</h2>
                                <div className='promo'>
                                    <input type='text' placeholder='NEWACC10' maxLength='8' id="promoCode" />
                                    <button onClick={this.applyPromo}><i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                                <h2><i className="fa-solid fa-cart-shopping"></i> Order Summary</h2>
                                <p>Items ({this.state.additionalCredit > 0 ? "2" : "1"})</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.planName} Plan</td>
                                            <td>₹ {this.state.billingTenure === "yearly" ? this.state.price * 12 : this.state.price}</td>
                                        </tr>
                                        {this.state.additionalCredit > 0 && (
                                            <tr>
                                                <td>Additional Credit ({this.state.additionalCredit})</td>
                                                <td>₹ {this.state.additionalCredit * this.state.perCredit}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>Net Total</td>
                                            <th>₹ {netTotal}</th>
                                        </tr>
                                        <tr>
                                            <td className='label' colSpan={2}><i className="fa-solid fa-circle-info"></i> The above rates are inclusive of taxes and fees</td>
                                        </tr>
                                        {discountPrice > 0 && (
                                            <tr>
                                                <td className='discount'>Discount <span><i className="fa-solid fa-tag"></i> {this.state.promoCode}</span></td>
                                                <td className='discount'>₹ {discountPrice}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>Total</th>
                                            <th>₹ {totalAmount}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='fg-1'></div>
                                <button onClick={() => this.createOrder(totalAmount)}>Proceed to checkout</button>
                            </div>
                            <div className='astrix'>
                                *A minimum of 10 credits is required for creating and distributing a batch of certificates
                            </div>
                        </div>
                    </section>
                    {
                        this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                    }
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default CheckoutPage;