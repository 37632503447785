import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/batch_data_v2';
import Loading from '../components/loading';

import '../components/css/main.css';

const Batch = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user');
    const [orgId, setOrgId] = useState('');
    const [storage, setStorage] = useState('');
    const [orgName, setOrgName] = useState('');
    const [header_user_data, setHeaderUserData] = useState(null);
    let { slug } = useParams();
    const [batch_id, setBatchId] = useState(null);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [subOrganisationModal, setSubOrganisationModal] = useState(undefined);

    useEffect(() => {
        async function fetchData() {
            axios.defaults.withCredentials = true;
            await axios.post(props.node_server_axios + 'session').then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                    if (res.data.org_id.length < 2) {
                        window.location = '/';
                    } else {
                        axios.post(props.node_server_axios + 'get_organisation_data', { params: { org_id: res.data.org_id } }).then((resOrg) => {
                            if (resOrg.data !== undefined && resOrg.data.org_name !== undefined && resOrg.data.org_name !== '') {
                                setOrgName(resOrg.data.org_name);
                                setName(res.data.name);
                                setEmail(res.data.email);
                                setUserType(res.data.user_type);
                                setOrgId(res.data.org_id);
                                setStorage(res.data.storage);
                                setHeaderUserData(res.data);
                            } else {
                                window.location = '/signin';
                            }
                            // Check if slug is undefined
                            if (!slug) {
                                axios.post(props.node_server_axios + 'get_first_batch').then((response) => {
                                    if (response.data && response.data.message === 'success') {
                                        setBatchId(response.data.batchId)
                                        setLoading(false);
                                    } else {
                                        window.location = '/';
                                    }
                                }).catch((error) => {
                                    console.error('Error fetching first batch:', error);
                                    window.location = '/';
                                });
                            } else {
                                setBatchId(slug)
                                setLoading(false);
                            }
                        }).catch(() => {
                            window.location = '/signin';
                        });
                    }
                } else {
                    window.location = '/signin';
                }
            }).catch(() => {
                window.location = '/signin';
            });
        }
        fetchData();
    }, [props.node_server_axios, slug]);

    const handleDocumentLoaded = (e) => {
        setDocumentLoaded(e)
    }

    const handleSubOrganisationModal = () => {
        setSubOrganisationModal(!subOrganisationModal)
    }

    if (loading) {
        return (
            <React.Fragment>
                <div className="main">
                    <Header />
                    <Loading />
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="main">
                    <Header login={true} user_data={header_user_data} email={email} node_server_axios={props.node_server_axios} documentLoaded={documentLoaded} subOrganisationModal={subOrganisationModal} />
                    <div className='body-wrapper'>
                        <SideNav name={name} selected="myorganisation" email={email} user_data={header_user_data} user_type={userType} node_server_axios={props.node_server_axios} storage={storage} />
                        <Element email={email} user_type={userType} org_id={orgId} batchId={batch_id} org_name={orgName} node_server_axios={props.node_server_axios} documentLoaded={handleDocumentLoaded} fetchSubOrganisationModal={handleSubOrganisationModal} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default Batch;