import React, { Component } from 'react';

class DraggableGuidelines extends Component {
    constructor(props) {
        super(props);

        this.state = {
            xGuidelines: [],
            yGuidelines: [],
            isDragging: false,
            draggingGuideId: null,
            containerRect: null,
            guidesVisible: true,
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.addGuideline = this.addGuideline.bind(this);
        this.removeGuideline = this.removeGuideline.bind(this);
        this.toggleGuidesVisibility = this.toggleGuidesVisibility.bind(this);

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const containerRect = this.containerRef.current.getBoundingClientRect();
        this.setState({ containerRect });
    }

    handleMouseDown(id, e) {
        e.preventDefault();
        this.setState({
            isDragging: true,
            draggingGuideId: id,
        });

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseUp() {
        this.setState({
            isDragging: false,
            draggingGuideId: null,
        });

        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove(e) {
        if (this.state.isDragging) {
            const { xGuidelines, yGuidelines, draggingGuideId, containerRect } = this.state;

            const mouseX = e.clientX - containerRect.left;
            const mouseY = e.clientY - containerRect.top;

            const updatedXGuidelines = xGuidelines.map((guide) =>
                guide.id === draggingGuideId ? { ...guide, position: mouseX } : guide
            );
            const updatedYGuidelines = yGuidelines.map((guide) =>
                guide.id === draggingGuideId ? { ...guide, position: mouseY } : guide
            );

            this.setState({
                xGuidelines: updatedXGuidelines,
                yGuidelines: updatedYGuidelines,
            });

            // Remove guideline if dragged outside of bounds
            if (
                mouseX < 0 ||
                mouseY < 0 ||
                mouseX > containerRect.width ||
                mouseY > containerRect.height
            ) {
                this.removeGuideline(draggingGuideId);
            }
        }
    }

    removeGuideline(id) {
        const { xGuidelines, yGuidelines } = this.state;

        const updatedXGuidelines = xGuidelines.filter((guide) => guide.id !== id);
        const updatedYGuidelines = yGuidelines.filter((guide) => guide.id !== id);

        this.setState({
            xGuidelines: updatedXGuidelines,
            yGuidelines: updatedYGuidelines,
            draggingGuideId: null, // Clear draggingGuideId to prevent issues
        });
    }

    addGuideline(axis) {
        const { xGuidelines, yGuidelines } = this.state;
        const newGuideline = {
            id: Date.now(),
            position: axis === 'x' ? (xGuidelines.length * 2) + 9 : (yGuidelines.length * 2) + 9,
        };

        this.setState({
            xGuidelines: axis === 'x' ? [...xGuidelines, newGuideline] : xGuidelines,
            yGuidelines: axis === 'y' ? [...yGuidelines, newGuideline] : yGuidelines,
            guidesVisible: true,
        });
    }

    toggleGuidesVisibility() {
        this.setState({
            guidesVisible: !this.state.guidesVisible,
        });
    }

    render() {
        const { xGuidelines, yGuidelines, containerRect, guidesVisible } = this.state;

        return (
            <div
                ref={this.containerRef}
                style={{
                    position: 'absolute',
                    width: '100%', // Set to your desired width
                    height: '100%', // Set to your desired height
                    zIndex: '2',
                    pointerEvents: 'none',
                }}
            >
                {/* Display draggable guidelines */}
                {guidesVisible && containerRect &&
                    xGuidelines.map((guide) => (
                        <div
                            key={guide.id}
                            style={{
                                position: 'absolute',
                                left: guide.position,
                                height: '100%',
                                width: '1px',
                                backgroundColor: 'transparent',
                                padding: '0px 3px',
                                cursor: 'col-resize',
                                zIndex: '998',
                                pointerEvents: 'all',
                            }}
                            onMouseDown={(e) => this.handleMouseDown(guide.id, e)}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    width: '1px',
                                    backgroundColor: '#21937B',
                                }}
                            />
                        </div>
                    ))}
                {guidesVisible && containerRect &&
                    yGuidelines.map((guide) => (
                        <div
                            key={guide.id}
                            style={{
                                position: 'absolute',
                                top: guide.position,
                                width: '100%',
                                height: '1px',
                                backgroundColor: 'transparent',
                                padding: '3px 0px',
                                cursor: 'row-resize',
                                zIndex: '998',
                                pointerEvents: 'all',
                            }}
                            onMouseDown={(e) => this.handleMouseDown(guide.id, e)}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: '#21937B',
                                }}
                            />
                        </div>
                    ))}

                <button onMouseDown={() => this.addGuideline('x')} className='editor-guidelines x'>Add Guide Line +</button>
                <button onMouseDown={() => this.addGuideline('y')} className='editor-guidelines y'>Add Guide Line +</button>

                {(xGuidelines.length > 0 || yGuidelines.length > 0) && (
                    <button onClick={this.toggleGuidesVisibility} className='editor-toggle-guidelines' title={guidesVisible ? 'Hide Guidelines' : 'Show Guidelines'}>
                        {guidesVisible ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye"></i>}
                    </button>
                )}
            </div>
        );
    }
}

export default DraggableGuidelines;
