import React from 'react';
import axios from 'axios';

import Warning from '../warning';
import WarningAction from '../warning_action';

class DataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            user_plan: 'Free',
            licenseKey: '',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    org_id: res.data.org_id,
                    header_user_data: res.data,
                });
                if (res.data.user_type === 'organisation') {
                    axios.post(this.props.node_server_axios + 'get_organisation_data', {
                        params: {
                            org_id: res.data.org_id,
                        }
                    }).then((res) => {
                        if (res.data !== undefined && res.data.message === 'success') {
                            this.setState({
                                loading: false,
                                credits: parseInt(res.data.credits),
                                per_credit: parseInt(res.data.per_credit),
                                amountToPay: this.state.creditToBuy * parseInt(res.data.per_credit),
                                running_plan: res.data.running_plan,
                            });
                        } else {
                            window.location = "/signin";
                        }
                    })
                }
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })

        axios.post(this.props.node_server_axios + 'get-subscription-data', {
            params: {
                email: this.props.email,
                latest: true,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    subscription_data: res.data.data,
                })
            }
        });

        axios.post(this.props.node_server_axios + 'get-invoice-list', {
            params: {
                email: this.props.email,
                latest: true,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    invoice_data: res.data.data,
                })
            }
        });

        switch (this.props.storage) {
            case 100:
                this.setState({
                    user_plan: 'Free'
                });
                break;
            case 300:
                this.setState({
                    user_plan: 'Pro'
                });
                break;
            case 500:
                this.setState({
                    user_plan: 'Pro Plus'
                });
                break;
            default:
                this.setState({
                    user_plan: 'Free'
                });
                break;

        }
    }

    changeLicenseKey = (event) => {
        const { value } = event.target;
        const cleanedValue = value.replace(/[^A-Z0-9]/gi, '');
        const formattedValue = cleanedValue.replace(/(.{5})/g, '$1-').slice(0, 23);
        this.setState({ licenseKey: formattedValue });
    }

    activateLicense = () => {
        const isValidKeyFormat = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/.test(this.state.licenseKey);
        if (isValidKeyFormat) {
            axios.post(this.props.node_server_axios + 'activate-license-key', {
                params: {
                    email: this.props.email,
                    key: this.state.licenseKey,
                    user_type: this.state.user_type,
                    org_id: this.state.org_id,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Congratulation, you have activated a License Key.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                    })
                    axios.defaults.withCredentials = true;
                    axios.post(this.props.node_server_axios + 'force_update_session').catch(() => {
                        window.location = "/signin";
                    });
                } else {
                    this.setState({
                        warning: {
                            message: res.data.message,
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Invalid License Key Format",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        }
    }

    listTransactions = () => {
        this.setState({
            warning: {
                message: "You do not have any more transactions",
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
        })
    }

    listAllInvoices = () => {
        this.setState({
            warning: {
                message: "You do not have any more invoices",
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1><span>Payments </span>& Subscription</h1>
                <p>Manage your active subscriptions, See payment history.</p>
                <div className='account-container'>
                    <div className='container'>
                        <h2>Personal</h2>
                        <p className='small-font'>Manage your personal subscription.</p>
                        <div className='column-container'>
                            <p>Plan</p>
                            <p className='small-font'>{this.state.user_plan} Subscription</p>
                            <button className='secondary-btn' onClick={() => window.location.href = './buy'}><i className="fa-regular fa-circle-up"></i> Upgrade</button>
                        </div>

                        <div className='column-container'>
                            <p>Features</p>
                            <p className='small-font'><i className="fa-regular fa-circle-check"></i> Document upload storage of {this.props.storage}Mb</p>
                        </div>
                    </div>
                    <div className='container'>
                        <h2>Purchase History</h2>
                        <p className='small-font'>See all your payments in Pronetin.</p>
                        {this.state.subscription_data ?
                            this.state.subscription_data.map((element, index) => (
                                <div className='column-container transactions' key={index}>
                                    <p>₹ {element.amount}.00</p>
                                    <div className='from'>Active from : <span>{element.started_on}</span></div>
                                    <p className='small-font'>Plan : {element.plan}</p>
                                    <div className={index === 0 ? 'to first' : 'to'}>Expiry : <span>{element.expiry_on}</span></div>
                                </div>
                            ))
                            :
                            <p className='small-font'>No subscriptions yet</p>
                        }
                        <button className='secondary-btn' onClick={this.listTransactions}>View all purchases</button>
                    </div>
                    <div className='container'>
                        <h2>Organisation Invoices</h2>
                        <p className='small-font'>See all your invoices generated by Pronetin.</p>
                        {this.state.invoice_data ?
                            this.state.invoice_data.map((element, index) => (
                                <div className='column-container transactions' key={index}>
                                    <p># {element.id}</p>
                                    <div className='from'>Date : <span>{element.date}</span></div>
                                    <p className='small-font'>Amount : {element.total}</p>
                                    <a className='invoice-btn' href={'/invoice?id=' + element.id} target="_blank" rel="noreferrer">View Invoice <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            ))
                            :
                            <p className='small-font'>No subscriptions yet</p>
                        }
                        <button className='secondary-btn' onClick={this.listAllInvoices}>View all invoices</button>
                    </div>
                    <div className='container full'>
                        <h2>Add License Key</h2>
                        {this.props.user_type === 'organisation' ?
                            <React.Fragment>
                                <p className='small-font'>Enter your license key to redeem plan and credits.</p>
                                <div className='column-container'>
                                    <p>Enter Key</p>
                                    <div className='row-container'>
                                        <input type='text' placeholder='XXXXX-XXXXX-XXXXX-XXXXX' value={this.state.licenseKey} onChange={this.changeLicenseKey} />
                                        <button onClick={this.activateLicense} className="btn-fit">Activate</button>
                                    </div>
                                </div>
                                <button className='secondary-btn' onClick={() => window.location.href = './premium'}>See Organisation Plan <i className="fa-solid fa-arrow-right-long"></i></button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <p className='small-font'>Create an oganisation to be able to enter License Key.</p>
                                <button className='secondary-btn' onClick={() => window.location.href = '/create'}>Create Organisation <i className="fa-solid fa-arrow-right-long"></i></button>
                            </React.Fragment>
                        }
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment>
        );
    }
}

export default DataComponent;