import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';
import Draggable from 'react-draggable';

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import Warning from './warning';
import ProgressLoader from './progress_loader';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            sliderValue: 3,
            sliderClass: "slider-3",
            pinchStartDistance: null,
            initialSliderValue: 1,
            elements: [],
            certView: false,
            view_type: '',
            QRSize: 65,
            resetX: 0,
            resetY: 0,
            cert_attachment: null,
            scaleWidth: 1,
            scaleFactor: 1,
            moreBtn: 'more',
            cacheTs: Date.now(),
            progress_loader: false,
            hideFixedControls: false,
            percentage_progress: 0,
            timeLeft: { days: 3, hours: 0, minutes: 0 },
            signinPrompt: false,
            screenCaptured: false,
        };
        this.imageDivRef = React.createRef();
        this.certDivRef = React.createRef();
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    formatDateToInteger = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const dateObject = new Date(year, month - 1, day);
        dateObject.setDate(dateObject.getDate() + 3); // Add 3 days to the date
        const updatedDay = String(dateObject.getDate()).padStart(2, '0');
        const updatedMonth = String(dateObject.getMonth() + 1).padStart(2, '0');
        const updatedYear = dateObject.getFullYear();
        const formattedDate = `${updatedYear}${updatedMonth}${updatedDay}`;
        return parseInt(formattedDate, 10);
    };

    getCurrentDateInFormat = () => parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));

    getTimeDifference(dateInteger) {
        const dateString = String(dateInteger);
        // Convert the date string to a Date object
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Month is 0-indexed
        const day = dateString.substring(6, 8);
        const givenDate = new Date(year, month, day);

        // Get the current date
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = givenDate - currentDate;

        // Convert milliseconds to days, hours, and minutes
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        if (days >= 0) {
            this.setState({
                timeLeft: { days, hours, minutes }
            })
        } else {
            this.setState({
                timeLeft: { days: 0, hours: 0, minutes: 0 }
            })
        }
    }

    componentDidMount() {
        this.setState({
            progress_loader: true,
            progress_opaque: true,
            percentage_progress: 10,
        })
        axios.post(this.props.node_server_axios + 'get_certificate', {
            params: {
                documentId: this.props.documentId,
            },
        }).then(async (res) => {
            this.setState({
                percentage_progress: 30,
            });
            if (res.data !== undefined) {
                if (res.data.message === 'certificate found') {
                    let expiryDate = this.formatDateToInteger(res.data.issue_date)
                    if (expiryDate > this.getCurrentDateInFormat() && res.data.accepted === 'false') {
                        var myElement = document.querySelector('.cropper-rel');
                        var width = myElement.offsetHeight > myElement.offsetWidth ? myElement.offsetWidth : myElement.offsetHeight;

                        const updatedData = JSON.parse(res.data.elements);
                        let tableVariables = []
                        if (updatedData.filter(element => element.type === "table").length > 0) {
                            tableVariables = updatedData.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
                        }
                        let variableIndexElements = updatedData.filter(element => element.type !== "image" && element.type !== "signature" && element.type !== "QR" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
                        let variablesLocal = variableIndexElements.map(element => element.variables)
                        let mergedVariablesLocal = [].concat.apply([], variablesLocal);
                        let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
                        uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);

                        this.setState({
                            elements: res.data.issue_date ? updatedData : null,
                            variables: uniqueVariablesLocal,
                            org_id: res.data.org_id,
                            cert_bg: res.data.cert_bg,
                            csvData: JSON.parse(res.data.csvData),
                            certView: true,
                            view_type: res.data.view_type,
                            event_name: res.data.event_name,
                            issue_date: res.data.issue_date,
                            pronetin_verified: res.data.pronetin_verified,
                            signees: res.data.signees,
                            org_name: res.data.org_name,
                            cert_title: res.data.cert_title,
                            cert_attachment: res.data.cert_attachment,
                            domain: res.data.domain,
                            contact: res.data.contact,
                            receiver_email: res.data.receiver_email,
                            batch_id: res.data.batch_id,
                            withdrawn: res.data.withdrawn,
                            percentage_progress: 60,
                            expiryDate: expiryDate,
                        })

                        setTimeout(this.openSigninPrompt, 15000);

                        const img = new Image();
                        const imgPromise = new Promise((resolve, reject) => {
                            img.onload = resolve;
                            img.onerror = reject;
                        });
                        img.src = "https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(res.data.org_id) + "/" + encodeURIComponent(res.data.cert_bg);

                        try {
                            await imgPromise;
                            let cWidth = 904;
                            let cHeight = 640;

                            if (img.naturalWidth > img.naturalHeight) {
                                cWidth = 904;
                                cHeight = 640;
                            } else if (img.naturalWidth < img.naturalHeight) {
                                cWidth = 640;
                                cHeight = 904;
                            } else {
                                console.log("Invalid Size");
                            }
                            this.setState({
                                certWidth: cWidth,
                                certHeight: cHeight,
                                withdrawn_type: cWidth > cHeight ? 'wit0.png' : 'wit0p.png',
                                scaleWidth: ((width - 20) / cHeight).toFixed(2),
                                scaleFactor: myElement.offsetHeight > myElement.offsetWidth ? ((width - 20) / cWidth).toFixed(2) : ((width - 20) / cHeight).toFixed(2),
                                percentage_progress: res.data.view_type !== 'public' ? false : 90,
                                progress_loader: res.data.view_type !== 'public' || this.state.percentage_progress === false ? false : true,
                                progress_opaque: res.data.view_type !== 'public' || this.state.percentage_progress === false ? false : true,
                            })
                        } catch (error) {
                            console.log("Error loading image:", error);
                        }
                    } else {
                        this.setState({
                            percentage_progress: false,
                            progress_loader: false,
                            progress_opaque: false,
                            certView: false,
                            expiryDate: expiryDate,
                        })
                    }
                }
            }
        })

        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyDown = (event) => {
        event.preventDefault();
        if ((event.metaKey && event.shiftKey) || (event.key === 'PrintScreen' || event.key === 'Snapshot')) {
            this.setState({ screenCaptured: true });
        }
    }

    handleKeyUp = (event) => {
        if (!event.shiftKey || event.key === 'PrintScreen' || event.key === 'Snapshot') {
            setTimeout(() => this.setState({ screenCaptured: false }), 1000);
        }
    }

    completedLoad = () => {
        this.setState({
            progress_loader: false,
            progress_opaque: false,
            percentage_progress: false,
        })
    }

    componentDidUpdate = () => {
        if (this.state.initialX === undefined || this.state.initialY === undefined) {
            let imgDivWidth = 0;
            let imgDivHeight = 0;
            let certDivWidth = 0;
            let certDivHeight = 0;
            if (this.imageDivRef.current) {
                imgDivWidth = this.imageDivRef.current.clientWidth;
                imgDivHeight = this.imageDivRef.current.clientHeight;
            }
            if (this.certDivRef.current) {
                certDivWidth = parseInt(this.certDivRef.current.clientWidth * this.state.scaleFactor);
                certDivHeight = parseInt(this.certDivRef.current.clientHeight * this.state.scaleFactor);
            }
            let initialX = (imgDivWidth - certDivWidth) < 0 ? 0 : (imgDivWidth - certDivWidth)
            let initialY = (imgDivHeight - certDivHeight) < 0 ? 0 : (imgDivHeight - certDivHeight)
            if (this.imageDivRef.current && this.certDivRef.current && this.state.scaleFactor !== 1) {
                this.setState({
                    initialX: initialX / 2,
                    initialY: initialY / 2,
                    resetX: initialX / 2,
                    resetY: initialY / 2,
                })
            }
        }
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        // var wrapperElem = document.getElementsByClassName('wrapper')[0];
        if (subNav.style.display !== 'flex') {
            subNav.style.display = 'flex';
            // wrapperElem.style.display = 'none';
            this.setState({
                moreBtn: 'back',
                hideFixedControls: true,
            })
        } else {
            subNav.style.display = 'none';
            // wrapperElem.style.display = 'flex';
            this.setState({
                moreBtn: 'more',
                hideFixedControls: false,
            })
        }
    }

    toggleSigninPrompt = (e) => {
        e.preventDefault();
        this.getTimeDifference(this.state.expiryDate);
        this.setState({
            signinPrompt: !this.state.signinPrompt,
        })
    }

    openSigninPrompt = () => {
        this.getTimeDifference(this.state.expiryDate);
        this.setState({
            signinPrompt: true,
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.screenCaptured && <div className="blackout"><p>PREVIEW</p></div>}
                <div className="element full-element public">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Preview<label>received certificate</label></h1>
                                {this.state.certView && (<button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>)}
                            </div>
                        </div>
                        <div className='tiles marketplace pg-certficate_viewer'>
                            {this.state.certView && (
                                <div className='sub-nav'>
                                    <h1><i className="fa-solid fa-circle-info"></i> About</h1>
                                    <div className='nav-container'>
                                        {this.state.pronetin_verified === 'verified' ?
                                            <p className='verified tooltip-2'>PRONETIN Verified <i className="fa-regular fa-circle-question"></i> <span className="tooltiptext">Pronetin verifies the organisation distributing the certificates by various means which includes Website check, Incorporation document check and Location check. Any organisation who fails to prove the same or who have not applied for verification check will remain as 'Not Verified' otherwise as 'Pronetin Verified.</span></p>
                                            :
                                            <p className='not-verified tooltip-2'>Not verified by PRONETIN <i className="fa-regular fa-circle-question"></i> <span className="tooltiptext">Pronetin verifies the organisation distributing the certificates by various means which includes Website check, Letter of concent check, Location check etc. Any organisation who fails to prove the same or who have not applied for verification check will remain as 'Not Verified' otherwise as 'Pronetin Verified'.</span></p>
                                        }
                                        <div className='info-container'>
                                            <h3>Document Information</h3>
                                            <p>Title : {this.state.cert_title}</p>
                                            <p>Event : {this.state.event_name}</p>
                                            <p>Issue Date : {this.state.issue_date}</p>
                                        </div>
                                        {this.state.cert_attachment && this.state.cert_attachment !== 'null' && (
                                            <a className='nav' href="foo" onClick={this.toggleSigninPrompt}>
                                                <i className="fa-solid fa-paperclip"></i>
                                                View Attachment
                                            </a>
                                        )}
                                        <a className='nav' href="foo" onClick={this.toggleSigninPrompt}>
                                            <i className="fa-solid fa-download"></i>
                                            Download
                                        </a>
                                        <a className='nav green' href="foo" onClick={this.toggleSigninPrompt}>
                                            <i className="fa-regular fa-circle-check"></i>
                                            Accept
                                        </a>
                                        <a className='nav warn' href="foo" onClick={this.toggleSigninPrompt}>
                                            <i className="fa-regular fa-circle-xmark"></i>
                                            Reject
                                        </a>
                                        <div className='info-container'>
                                            <h3>Issuer Information</h3>
                                            <p>Issuer : {this.state.org_name}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='wrapper'>
                                <div className='cropper-rel'>
                                    {this.state.certView ?
                                        <div className='image-container'
                                            ref={this.imageDivRef}
                                        >
                                            <div className={this.state.sliderClass} style={{ '--scale-width': this.state.scaleFactor }}>
                                                <Draggable position={{ x: this.state.resetX, y: this.state.resetY }} disabled>
                                                    <div className='cert-container' id="capture" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', width: this.state.certWidth, height: this.state.certHeight }}>
                                                        <div className='elements of-hidden' ref={this.certDivRef}>
                                                            <img src={this.state.withdrawn !== 'true' ? "https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_bg) + "?cacheblock=" + this.state.cacheTs : "https://pronetin-new.s3.amazonaws.com/templates/certificates/background/" + this.state.withdrawn_type + "?cacheblock=" + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template' onLoad={this.completedLoad}></img>
                                                            {this.state.elements && this.state.elements.length ?
                                                                this.state.elements.map((element, index) => {
                                                                    if (element.type === "image") {
                                                                        return <ViewImage element_id={index} key={element.timeStamp + "-" + index} data={element} />
                                                                    } else if (element.type === "table") {
                                                                        return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else if (element.type === "signature") {
                                                                        return <ViewSignee element_id={index} key={element.timeStamp + "-" + index} data={element} node_server_axios={this.props.node_server_axios} batch_id={this.state.batch_id} />
                                                                    } else if (element.type !== "QR") {
                                                                        return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })
                                                                : ""}
                                                            <ViewQR data={this.props.documentId} size={this.state.QRSize} position={this.state.elements.find(element => element.type === "QR")} />
                                                        </div>
                                                    </div>
                                                </Draggable>
                                            </div>
                                        </div>
                                        :
                                        <div className='private-doc-message'>
                                            <h1>This Link has Expired</h1>
                                            <p>Please signup / signin to pronetin to view this document</p>
                                            <i className="fa-solid fa-hourglass-end"></i>
                                            <a href='/signin' className='signin-btn'>Signin <i className="fa-solid fa-arrow-right-to-bracket"></i></a>
                                        </div>
                                    }
                                </div>
                                {!this.state.hideFixedControls && (
                                    <div className="mob-actions mobile-only fixed-controls">
                                        <div className='nav-container'>
                                            {this.state.cert_attachment && this.state.cert_attachment !== 'null' && (
                                                <a className='nav' href="foo" onClick={this.toggleSigninPrompt}>
                                                    <i className="fa-solid fa-paperclip"></i>
                                                    Attachment
                                                </a>
                                            )}
                                            <a className='nav' href="foo" onClick={this.toggleSigninPrompt}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                            <a className='nav green' href="foo" onClick={this.toggleSigninPrompt}>
                                                <i className="fa-regular fa-circle-check"></i>
                                                Accept
                                            </a>
                                            <a className='nav warn' href="foo" onClick={this.toggleSigninPrompt}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                                Reject
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                    <div className={this.state.signinPrompt ? 'signup-prompt active' : 'signup-prompt'}>
                        <div className='fade-out'></div>
                        <div className='content'>
                            <div className='timer'>
                                <p>You have only</p>
                                <div className='time'>{this.state.timeLeft.days} <span>Days</span>, {this.state.timeLeft.hours} <span>Hours and</span> {this.state.timeLeft.minutes} <span>Minutes</span></div>
                                <p>Remaining</p>
                            </div>
                            <button onClick={() => window.location.href = '/signin'}>Signin & Accept Certificate <i className="fa-solid fa-arrow-right-to-bracket"></i></button>
                            <div className='usecases'>
                                <div className='split'>
                                    <p><i className="fa-solid fa-eye"></i> View all your received certificates in your profile</p>
                                    <p><i className="fa-solid fa-circle-user"></i> Request name change on certificate</p>
                                    <p><i className="fa-solid fa-circle-check"></i> Accept / Reject Certificates</p>
                                    <p><i className="fa-solid fa-circle-down"></i> Download Certificates at high quality</p>
                                </div>
                                <div className='split'>
                                    <p><i className="fa-solid fa-share-nodes"></i> Share and track views of your certificate</p>
                                    <p><i className="fa-solid fa-qrcode"></i> Enable QR verification</p>
                                    <p><i className="fa-solid fa-file-shield"></i> View attached document (if available)</p>
                                    <p><i className="fa-solid fa-file-pdf"></i> Download as PDF</p>
                                </div>
                            </div>
                            <button className='non-cta' onClick={this.toggleSigninPrompt}>Close <i className="fa-regular fa-circle-xmark"></i></button>
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} message={this.state.percentage_progress > 0 ? this.state.percentage_progress + "% Loaded" : false} />)
                }
            </React.Fragment>
        );
    }
}

export default CertificateViewerComponent;