import React from 'react';
import '../../components/css/docs.css';
import Header from '../../components/landing_header';
import Footer from '../../components/landing_footer';
import CodeBlock from '../../components/code_block';
import BadgeImage from '../../images/help/images/pronetinbadge.png'

class LandingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false
        };
    }

    scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="new-landing">
                    <div className='landing-v1'>
                        <Header />
                        <section>
                            <div className='api-section'>
                                <div className='api-nav'>
                                    <a className='back-btn' href='/resources/api'><i className="fa-solid fa-arrow-left-long"></i> Back</a>
                                    <div className='container'>
                                        <h3>Introduction</h3>
                                        <ul>
                                            <li onClick={() => this.scrollToElement('1-1')}>What is Pronetin badge</li>
                                            <li onClick={() => this.scrollToElement('1-2')}>Customisation</li>
                                            <li onClick={() => this.scrollToElement('1-3')}>Prerequisite</li>
                                        </ul>
                                        <h3>Embedd in website</h3>
                                        <ul>
                                            <li onClick={() => this.scrollToElement('2-1')}>Available Data</li>
                                            <li onClick={() => this.scrollToElement('2-2')}>HTML Code</li>
                                            <li onClick={() => this.scrollToElement('2-3')}>JS Code</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='content'>
                                    <h3>Embedd Pronetin Badge</h3>

                                    <h2>Introduction</h2>
                                    <div className='info' id='1-1'>
                                        <h4>What is Pronetin badge</h4>
                                        Pronetin badge is an <strong>HTML element</strong> that you can embedd in your website that will help you <strong>showcase</strong> your achievement as a user / organiser. This element is <strong>automatically updated</strong> with latest data from pronetin servers.
                                        <img src={BadgeImage} className='main-img' alt='api reference' />
                                    </div>

                                    <div className='desc' id='1-2'>
                                        <h4>Customisation</h4>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Verb</th>
                                                    <th>Description</th>
                                                </tr>
                                                <tr>
                                                    <td>bg_color</td>
                                                    <td>Background color of your badge can be change in hex code (Default: <strong>#F1F1F1</strong>)</td>
                                                </tr>
                                                <tr>
                                                    <td>font_color</td>
                                                    <td>Font color of your badge can be change in hex code (Default: <strong>#000000</strong>)</td>
                                                </tr>
                                                <tr>
                                                    <td>border_color</td>
                                                    <td>Border color of your badge can be change in hex code (Default: <strong>#00000</strong>)</td>
                                                </tr>
                                                <tr>
                                                    <td>border_radius</td>
                                                    <td>Border radius of your badge can be change in px (pixels) (Default: <strong>16px</strong>)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='desc' id='1-3'>
                                        <h4>Prerequisite</h4>
                                        <ul>
                                            <li>You need to have an account in pronetin. If you do not have <a href='/signup' target='_blank'>create an account</a>.</li>
                                            <li>You need to have an <a href='/account?selection=api' target='_blank'>API Key</a>. If you do not have it <a href='/docs/api/basics'>follow these steps</a> to create one</li>
                                            <li><strong>Access</strong> to the website code where you want to embedd Pronetin badge</li>
                                        </ul>
                                    </div>

                                    <h2>Embedd in website</h2>

                                    <div className='desc' id='2-1'>
                                        <h4>Available Data</h4>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Verb</th>
                                                    <th>Description</th>
                                                </tr>
                                                <tr>
                                                    <td>Appreciations</td>
                                                    <td>Appreciations received from other Organisations or Pronetin for exceptional performance</td>
                                                </tr>
                                                <tr>
                                                    <td>Certificates</td>
                                                    <td>Certificates received in your account</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>Registered email so that people can reach you</td>
                                                </tr>
                                                <tr>
                                                    <td>Designs Created</td>
                                                    <td>Certificates designed using pronetin</td>
                                                </tr>
                                                <tr>
                                                    <td>Certificates Issued</td>
                                                    <td>Certificates issued as an event organiser</td>
                                                </tr>
                                                <tr>
                                                    <td>Certificates Signed</td>
                                                    <td>Number of certificates where you were the signing authority</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='desc' id='2-2'>
                                        <h4>HTML Code</h4>
                                        Paste the below code into your website, where you want your badge to appear.
                                        <CodeBlock code={`<div id="pronetin-user-badge" class="pronetin-user-badge light">
    <p>Loading badge...</p>
</div>`} inline={false} content={'This is inline code'} language={'HTML'} />
                                    </div>

                                    <div className='desc' id='2-3'>
                                        <h4>JS Code</h4>
                                        Paste the below code into your website script file or <strong>below the html code</strong>.
                                        <CodeBlock code={`<script src="https://d39odw0f6is413.cloudfront.net/api_v1.5.js"></script>
<script>
    const apiUrl = 'http://pronetin.in/public/get-user-badge';
    const email = "<YOUR EMAIL ID>";
    const key = "<API KEY>";
    fetchPronetinUserBadgeData(apiUrl, email, key);
</script>`} inline={false} content={'This is inline code'} language={'Javascript'} />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Verb</th>
                                                    <th>Description</th>
                                                </tr>
                                                <tr>
                                                    <td>YOUR EMAIL ID</td>
                                                    <td>Email id linked with the pronetin account under which the API Key was generated should be given here</td>
                                                </tr>
                                                <tr>
                                                    <td>API KEY</td>
                                                    <td>API Key that was generated in Prerequisite should be used here</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* <a href='/docs/api/embedd-badge' className='next-btn'>Embedd Pronetin Badge <i className="fa-solid fa-arrow-right-long"></i></a> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

export default LandingComponent;