import React from 'react';
import './css/editor.css';

import helpVid1 from '../images/help/videos/dummy.mp4'

class HelpEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPiPSupported: false,
            step: this.props.step !== undefined ? this.props.step : 1,
            tab: this.props.tab !== undefined ? this.props.tab : 1,
            bullets: {
                '1': 2,
                '2': 4,
                '3': 2,
                '5': 1,
                '6': 4
            }, //Change as number of bullets for each stage increases
        };
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        // Check if PiP is supported by the browser
        if (this.videoRef.current && typeof this.videoRef.current.requestPictureInPicture === 'function') {
            this.setState({ isPiPSupported: true });
        }
    }

    handleRequestPiP = () => {
        if (this.videoRef.current) {
            this.videoRef.current.requestPictureInPicture();
        }
    };

    changeStep = (step) => {
        this.setState({
            step: step,
            tab: 1,
        })
    }

    changeTab = (tab) => {
        this.setState({
            tab: tab,
        })
    }

    renderContent = (step, tab) => {
        let video = helpVid1;
        let h = 'Heading';
        let p = 'content';
        switch (step + "_" + tab) {
            case '1_1':
                video = helpVid1;
                h = 'How to change Templates';
                p = 'To change templates for your design, You may click on the various design templates given on the choose template section on the left navigation module. You can also change landscape to portrait for portrait designs.';
                break;
            case '1_2':
                video = helpVid1;
                h = 'How to design from scratch';
                p = 'From the templates choose a blank canvas. Then add Text elements and other design elements as required.';
                break;
            case '2_1':
                video = helpVid1;
                h = 'How to add texts';
                p = 'Choose any of the attributes like Paragraph, Description etc or can add a new text field by clicking Add Text.';
                break;
            case '2_2':
                video = helpVid1;
                h = 'Add Table';
                p = 'One may add a table to the certificate by clicking the Use button next to the Add Table Section. The table size can be customised by choosing the number of rows and columns.';
                break;
            case '2_3':
                video = helpVid1;
                h = 'What are variables?';
                p = 'Variables are text elements which will have a variable value for all the recipients of the certificate. Variables are represented by a variable name inside square brackets eg: [Recepient]';
                break;
            case '2_4':
                video = helpVid1;
                h = 'Recipient Name';
                p = 'Recipient Name field cannot be edited. Adding recipient names is done at the last step (Step 6) of certificate designing.';
                break;
            case '3_1':
                video = helpVid1;
                h = 'Add Logos';
                p = 'Your Organisation Logo is Auto fetched from Organisation account and is visible on the left Add Image/Logo panel by default. You can double click on it to bring that logo into the canvas.';
                break;
            case '3_2':
                video = helpVid1;
                h = 'Add Images';
                p = 'Other images or design elements';
                break;
            case '4_1':
                video = helpVid1;
                h = 'How to attach Documents with certificates?';
                p = 'You can attach any documents in pdf format, like notes, program info etc with the certificates you send by clicking on Upload document and choosing relevant file, having a file size less than 5MB.';
                break;
            case '5_1':
                video = helpVid1;
                h = 'How to add a signature to a certificate?';
                p = 'For adding a signature in the certificate, you can input the email id and designation of the signee in the signature section. The request to sign will be sent to the signee at the time of batch creation and will be added to the certificate once the signee approves the request.';
                break;
            case '6_1':
                video = helpVid1;
                h = 'How to add recipient names?';
                p = 'The recipient names and email ids to which the certificates to be sent can be entered in 2 ways. 1) enter the details manually one by one by clicking the Add Row Button. 2) If the list is very big you can download the spreadsheet format by clicking Download Format and paste the information in the given format and upload back.';
                break;
            case '6_2':
                video = helpVid1;
                h = 'How to see a preview of the certificate designed?';
                p = 'You can see the preview of the certificate designed without the signature, but with the name of the 1st participant in the recipient name field by clicking the preview button on the top right corner.';
                break;
            case '6_3':
                video = helpVid1;
                h = 'How to choose an event and why?';
                p = 'After designing the certificate and adding the recipient list you can go forward to choose an event or create a new event, under which the certificate batch you are going to send will be present.';
                break;
            case '6_4':
                video = helpVid1;
                h = 'What is feedback collection?';
                p = 'While sending the certificates to the desired recipients you can collect feedback about the event / program, if you enable the feedbacks toggle button. Feedbacks thus received can be seen inside that particular event in the Issue Certificate section at home page.';
                break;
            default:
                video = helpVid1;
                h = 'How to change template';
                p = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam placerat ornare. Sed orci orci, placerat malesuada urna ut, malesuada viverra justo.';
        }
        return (
            <React.Fragment>
                <div className='video' style={{ "display": "none" }}>
                    <video src={video} muted autoPlay loop ref={this.videoRef} onClick={this.state.isPiPSupported ? this.handleRequestPiP : null}></video>
                    {this.state.isPiPSupported && (
                        <button onClick={this.handleRequestPiP}><i className="fa-solid fa-square-arrow-up-right"></i></button>
                    )}
                </div>
                <div className='content'>
                    <h3>{h}</h3>
                    <p>{p}</p>
                    <a href='/help' target="_blank">More <i className="fa-solid fa-arrow-right-long"></i></a>
                </div>
            </React.Fragment>
        )
    }

    startOnboarding = () => {
        localStorage.removeItem('onboarding_editor');
        window.location.reload()
    }

    renderBullets = (n) => {
        const divs = Array.from({ length: n }, (_, index) => (
            <button className={this.state.tab === (index + 1) ? 'active' : ''} onClick={() => this.changeTab(index + 1)}></button>
        ));
        return divs;
    }

    render() {
        return (
            <div className='editor-help-modal'>
                <div className='close-btn' onClick={() => this.props.toggleHelpModal()}>x</div>
                <div className='onboarding-toggle-editor'>
                    <button onClick={this.startOnboarding}><i className="fa-regular fa-message"></i> Onboarding</button>
                    <button onClick={() => window.location.href = '/tutorial'}><i className="fa-solid fa-play"></i> Tutorial</button>
                </div>
                <div className='stage-select'>
                    <hr></hr>
                    <button className={this.state.step === 1 ? 'active' : ''} onClick={() => this.changeStep(1)}><label>1</label><p>Template</p></button>
                    <button className={this.state.step === 2 ? 'active' : ''} onClick={() => this.changeStep(2)}><label>2</label><p>Text</p></button>
                    <button className={this.state.step === 3 ? 'active' : ''} onClick={() => this.changeStep(3)}><label>3</label><p>Image</p></button>
                    {/* <button className={this.state.step === 4 ? 'active' : ''} onClick={() => this.changeStep(4)}><label>4</label><p>Document</p></button> */}
                    <button className={this.state.step === 5 ? 'active' : ''} onClick={() => this.changeStep(5)}><label>4</label><p>Signature</p></button>
                    <button className={this.state.step === 6 ? 'active' : ''} onClick={() => this.changeStep(6)}><label>5</label><p>Recepients</p></button>
                </div>

                {this.renderContent(this.state.step, this.state.tab)}

                <div className='tabs'>
                    {this.renderBullets(this.state.bullets[this.state.step])}
                </div>
            </div>
        );
    }
};

export default HelpEditor;