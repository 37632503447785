import React from 'react';
import axios from 'axios';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/batches_distributed_v2';
import ElementMobile from '../components/batches_distributed';
import Loading from '../components/loading';

import '../components/css/main.css';

class Organisation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
            mobileViewRender: false,
        }
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                if (res.data.org_id.length < 2) {
                    window.location = "./";
                } else {
                    this.setState({
                        loading: false,
                        name: res.data.name,
                        email: res.data.email,
                        user_type: res.data.user_type,
                        user_id: res.data.user_id,
                        storage: res.data.storage,
                        org_id: res.data.org_id,
                        header_user_data: res.data,
                    });
                }
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })

        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        if (window.innerWidth < 820) {
            this.setState({ mobileViewRender: true });
        } else {
            this.setState({ mobileViewRender: false });
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header />
                        <Loading />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header login={true} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} documentLoaded={true} />
                        <div className='body-wrapper'>
                            <SideNav name={this.state.name} email={this.state.email} user_data={this.state.header_user_data} selected={"distributed"} user_type={this.state.user_type} node_server_axios={this.props.node_server_axios} storage={this.state.storage} />

                            {this.state.mobileViewRender ?
                                <ElementMobile email={this.state.email} user_type={this.state.user_type} org_id={this.state.org_id} node_server_axios={this.props.node_server_axios} />
                                :
                                <Element email={this.state.email} user_type={this.state.user_type} org_id={this.state.org_id} node_server_axios={this.props.node_server_axios} />
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}
export default Organisation;