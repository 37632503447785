import React from 'react';
import staticImage from '../images/static/Group 252.png';
import { useParams } from 'react-router-dom';

import Header from '../components/header';
import Element from '../components/reset_password';

import '../components/css/main.css';

const Marketplace = (props) => {
    let { slug } = useParams();
    let { otp } = useParams();

    return (
        <React.Fragment>
            <div className="signin">
                <Header />
                <img src={staticImage} alt="static" className='landing-img' />
                {(slug !== undefined && otp !== undefined) ?
                    <Element email={slug.replace(/-/g, ' ')} otp={otp.replace(/-/g, ' ')} node_server_axios={props.node_server_axios} />
                    :
                    window.location = '/signin'
                }
            </div>
        </React.Fragment>
    );
};

export default Marketplace;