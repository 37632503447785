import React from 'react';

class OnboardingTutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
            shouldRender: true,
            steps: [
                {
                    heading: 'To Know More',
                    content: 'Click here to get further ideas on how to use this platform.',
                    style: { top: this.getPositionPx("onboarding-help", 0, "bottom") + 10, left: this.getPositionPx("onboarding-help", 0, "left") },
                    caret: { top: '0px', left: '10px' }
                }
            ],
        };
    }

    handleNextStep = () => {
        if (this.state.currentStep >= this.state.steps.length - 1) {
            this.handleCloseTutorial();
        } else {
            this.setState((prevState) => ({
                currentStep: prevState.currentStep + 1,
            }));
        }
    };

    componentDidMount = () => {
        this.updateSteps();

        window.addEventListener('resize', this.handleWindowResize);
    }

    updateSteps = () => {
        let newSteps = [];
        switch (this.props.mode) {
            case 'organisation':
                if (this.getPositionPx("event-wrapper", 0, "bottom")) {
                    newSteps = [
                        {
                            heading: 'Welcome Organisers',
                            content: 'You can start creating, designing and managing certificates in this section.',
                            style: { top: this.getPositionPx("organisation-module-js", 0, "top"), left: this.getPositionPx("organisation-module-js", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                        {
                            heading: 'Send Certificates',
                            content: 'Certificate batches created after designing can be send from here.',
                            style: { top: this.getPositionPx("nav", 0, "bottom") + 10, left: this.getPositionPx("nav", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Distributed Certificates',
                            content: 'Certificate batches after distribution and their analytics can be found here.',
                            style: { top: this.getPositionPx("nav", 1, "bottom") + 10, left: this.getPositionPx("nav", 1, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Action Center',
                            content: 'All requests from recepients will be displayed here.',
                            style: { top: this.getPositionPx("nav", 2, "bottom") + 10, left: this.getPositionPx("nav", 2, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'My Designs',
                            content: 'You will find your saved designs and draft design here.',
                            style: { top: this.getPositionPx("nav", 3, "bottom") + 10, left: this.getPositionPx("nav", 3, "left") },
                            caret: { top: '0px', left: '5px' }

                        },
                        {
                            heading: 'Email Templates',
                            content: 'You will find the email content you can attach with the certificates you distribute here.',
                            style: { top: this.getPositionPx("nav", 4, "bottom") + 10, left: this.getPositionPx("nav", 4, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Marketplace',
                            content: 'More designs and templates can be chosen from here.',
                            style: { top: this.getPositionPx("nav", 5, "bottom") + 10, left: this.getPositionPx("nav", 5, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Create Certificates',
                            content: 'You may start designing certificates by clicking here.',
                            style: { top: this.getPositionPx("btn-issue", 0, "bottom") + 10, left: this.getPositionPx("btn-issue", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Events',
                            content: 'Events under which there are pending certificate batches to be distributed can be found here.',
                            style: { top: this.getPositionPx("nav-v2", 0, "top") + 100, left: this.getPositionPx("nav-v2", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Groups',
                            content: 'You can fing groups/subevents under which there are pending certificate batches to be sent.',
                            style: { top: this.getPositionPx("template-subevent-new", 0, "bottom") + 10, left: this.getPositionPx("template-subevent-new", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                    ]
                } else {
                    newSteps = [
                        {
                            heading: 'Welcome Organisers',
                            content: 'You can start creating, designing and managing certificates in this section.',
                            style: { top: this.getPositionPx("organisation-module-js", 0, "top"), left: this.getPositionPx("organisation-module-js", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                        {
                            heading: 'Send Certificates',
                            content: 'Certificate batches created after designing can be send from here.',
                            style: { top: this.getPositionPx("nav", 0, "bottom") + 10, left: this.getPositionPx("nav", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Distributed Certificates',
                            content: 'Certificate batches after distribution and their analytics can be found here.',
                            style: { top: this.getPositionPx("nav", 1, "bottom") + 10, left: this.getPositionPx("nav", 1, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Action Center',
                            content: 'All requests from recepients will be displayed here.',
                            style: { top: this.getPositionPx("nav", 2, "bottom") + 10, left: this.getPositionPx("nav", 2, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'My Designs',
                            content: 'You will find your saved designs and draft design here.',
                            style: { top: this.getPositionPx("nav", 3, "bottom") + 10, left: this.getPositionPx("nav", 3, "left") },
                            caret: { top: '0px', left: '5px' }

                        },
                        {
                            heading: 'Email Templates',
                            content: 'You will find the email content you can attach with the certificates you distribute here.',
                            style: { top: this.getPositionPx("nav", 4, "bottom") + 10, left: this.getPositionPx("nav", 4, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Marketplace',
                            content: 'More designs and templates can be chosen from here.',
                            style: { top: this.getPositionPx("nav", 5, "bottom") + 10, left: this.getPositionPx("nav", 5, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Create Certificates',
                            content: 'You may start designing certificates by clicking here.',
                            style: { top: this.getPositionPx("btn-issue", 0, "bottom") + 10, left: this.getPositionPx("btn-issue", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                    ]
                }
                break;
            case 'signee':
                newSteps = [
                    {
                        heading: 'Sign & Approve',
                        content: 'If you are a signing authority you could find the requests to sign the certificates here.',
                        style: { top: this.getPositionPx("nav", 0, "top") - 40, left: this.getPositionPx("nav", 0, "right") },
                        caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                    },
                    {
                        heading: 'Approval History',
                        content: 'You could find your approval history here.',
                        style: { top: this.getPositionPx("nav", 1, "top") - 40, left: this.getPositionPx("nav", 1, "right") },
                        caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                    },
                    {
                        heading: 'Approval Requests',
                        content: 'This is how you receive request to sign certificates.',
                        style: { top: this.getPositionPx("approval-block-js", 0, "bottom") + 10, left: this.getPositionPx("approval-block-js", 0, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Upload Signature',
                        content: 'You may upload your signature here, which will appear on the certificates you sign.',
                        style: { top: this.getPositionPx("approvals-signature", 0, "bottom") + 10, left: this.getPositionPx("approvals-signature", 0, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                ]
                break;
            case 'subevents':
                newSteps = [
                    {
                        heading: 'Batches',
                        content: 'These are batches pending to be distributed.',
                        style: { top: this.getPositionPx("batch-tile-new", 0, "bottom") + 10, left: this.getPositionPx("batch-tile-new", 0, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Feedbacks',
                        content: 'You could see feedbacks by recepients if you have enabled it while creating certificate batch.',
                        style: { top: this.getPositionPx("btn-sub-nav", 0, "top") - 180, left: this.getPositionPx("btn-sub-nav", 0, "left") },
                        caret: { bottom: '-10px', left: '10px', transform: 'rotate(180deg)' }
                    },
                    {
                        heading: 'Add Organisers',
                        content: 'This is where you can add organisers of this event, organisers will get free achievement certificate from pronetin.(NB: You should add the organiseres before the batches are distributed)',
                        style: { top: this.getPositionPx("btn-sub-nav", 1, "top") - 243, left: this.getPositionPx("btn-sub-nav", 1, "left") },
                        caret: { bottom: '-10px', left: '10px', transform: 'rotate(180deg)' }
                    },
                    {
                        heading: 'Infographics',
                        content: 'You can view the status of this event here.',
                        style: { top: this.getPositionPx("btn-sub-nav", 2, "top") - 160, left: this.getPositionPx("btn-sub-nav", 2, "left") },
                        caret: { bottom: '-10px', left: '10px', transform: 'rotate(180deg)' }
                    },
                ]
                break;
            case 'actioncenter':
                newSteps = [
                    {
                        heading: 'Action Center',
                        content: 'Requests from recepients to edit their names in the certificate they received apper here, you may approve or reject them.',
                        style: { top: this.getPositionPx("action-js", 0, "top") - 40, left: this.getPositionPx("action-js", 0, "right") },
                        caret: { top: '50px', left: '-15px', transform: 'rotate(270deg)' }
                    },
                    {
                        heading: 'History',
                        content: 'Here you could find the history of actions taken and download them.',
                        style: { top: this.getPositionPx("action-js", 1, "top") - 40, left: this.getPositionPx("action-js", 1, "right") },
                        caret: { top: '50px', left: '-15px', transform: 'rotate(270deg)' }
                    },
                ]
                break;
            case 'mydesign':
                newSteps = [
                    {
                        heading: 'Draft',
                        content: 'You may edit or save your existing draft design here.',
                        style: { top: this.getPositionPx("template-tile", 0, "top") - 40, left: this.getPositionPx("template-tile", 0, "right") + 10 },
                        caret: { top: '50px', left: '-15px', transform: 'rotate(270deg)' }
                    },
                    {
                        heading: 'Saved Design',
                        content: 'You can find your saved design here, which can be edited or shared with other users for collaborative designing.',
                        style: { top: this.getPositionPx("template-tile ", 1, "bottom") + 10, left: this.getPositionPx("template-tile ", 1, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Upgrade',
                        content: 'For more saved design slots & other privilages you could upgrade your organisation plan.',
                        style: { top: this.getPositionPx("plan-container", 0, "top") - 40, left: this.getPositionPx("plan-container", 0, "right") + 10 },
                        caret: { top: '50px', left: '-15px', transform: 'rotate(270deg)' }
                    },
                ]
                break;
            case 'send_certificate':
                if (this.getPositionPx("issued-failed-btn", 0, "bottom")) {
                    newSteps = [
                        {
                            heading: 'Recepients',
                            content: 'Here you can see the list of recipients you have added.',
                            style: { top: this.getPositionPx("batch-btn-js ", 0, "top") + 50, left: this.getPositionPx("batch-btn-js ", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Recepients',
                            content: 'Here you can sort out the distributed list and their status such as sucessfully issued, failed, bounced etc.',
                            style: { top: this.getPositionPx("batch-btn-js ", 1, "top") + 50, left: this.getPositionPx("batch-btn-js ", 1, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Status',
                            content: 'Here you can see the status of certificate such as accepted, rejected etc.',
                            style: { top: this.getPositionPx("status-icons ", 0, "top") + 40, left: this.getPositionPx("status-icons ", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Resend Email',
                            content: 'Here you can notify recepients by resending the email when necessary.',
                            style: { top: this.getPositionPx("actions ", 0, "top") + 50, left: this.getPositionPx("actions ", 0, "left") },
                            caret: { top: '0px', right: '20px' }
                        },
                        {
                            heading: 'Download',
                            content: 'Here you can download and keep copies of distributed certificates (this can also be used to give physical copies for live events, if the batch is schedule & sent).',
                            style: { top: this.getPositionPx("check-accept-status", 0, "top") - 40, left: this.getPositionPx("check-accept-status", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                    ]
                } else if (this.getPositionPx("more-info", 0, "top")) {
                    newSteps = [
                        {
                            heading: 'Recepients',
                            content: 'Here you can see the list of recipients you have added.',
                            style: { top: this.getPositionPx("wrapper", 0, "top") + 90, left: this.getPositionPx("wrapper", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Preview',
                            content: 'Here you can see preview of the designed certificate.',
                            style: { top: this.getPositionPx("batch-data-email-preview", 0, "top") - 40, left: this.getPositionPx("batch-data-email-preview", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                        {
                            heading: 'View Signees',
                            content: 'Here you can find the list of signing authorities & their signing status.',
                            style: { top: this.getPositionPx("more-info", 0, "top") - 50, left: this.getPositionPx("more-info", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                        {
                            heading: 'Send Certificates',
                            content: 'Once signing authority signs (optional) this button is activated and you can proceed to distribute certificates to the added recepients.',
                            style: { top: this.getPositionPx("issue-box", 0, "top") - 50, left: this.getPositionPx("issue-box", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                    ]
                } else {
                    newSteps = [
                        {
                            heading: 'Recepients',
                            content: 'Here you can see the list of recipients you have added.',
                            style: { top: this.getPositionPx("wrapper", 0, "top") + 90, left: this.getPositionPx("wrapper", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Preview',
                            content: 'Here you can see preview of the designed certificate.',
                            style: { top: this.getPositionPx("batch-data-email-preview", 0, "top") - 40, left: this.getPositionPx("batch-data-email-preview", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                        {
                            heading: 'Send Certificates',
                            content: 'Once signing authority signs (optional) this button is activated and you can proceed to distribute certificates to the added recepients.',
                            style: { top: this.getPositionPx("issue-box", 0, "top") - 50, left: this.getPositionPx("issue-box", 0, "right") + 10 },
                            caret: { top: '60px', left: '-15px', transform: 'rotate(270deg)' }
                        },
                    ]
                }
                break;
            case 'appreciation':
                if (this.getPositionPx("nav_accept", 0, "bottom")) {
                    newSteps = [
                        {
                            heading: 'Accept / Reject',
                            content: 'Accept the certificate if its yours. Once rejected the certificate will be deleted forever',
                            style: { top: this.getPositionPx("nav_sub", 0, "bottom") + 10, left: this.getPositionPx("nav_sub", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Your Certificate',
                            content: 'This is how you will see your certificate. You can also drag your certificate around',
                            style: { top: this.getPositionPx("image-container", 0, "top") + 10, left: this.getPositionPx("image-container", 0, "left") },
                            caret: { bottom: '-10px', right: '30px', transform: 'rotate(180deg)' }
                        },
                        {
                            heading: 'Zoom',
                            content: 'You can zoom into your certificate here.',
                            style: { top: this.getPositionPx("onboarding-zoom", 0, "top") - 160, left: this.getPositionPx("onboarding-zoom", 0, "left") },
                            caret: { bottom: '-10px', left: '10px', transform: 'rotate(180deg)' }
                        },
                        {
                            heading: 'Re-center',
                            content: 'You can re-center your certificate here.',
                            style: { top: this.getPositionPx("recenter-certificate", 0, "bottom") + 10, left: this.getPositionPx("recenter-certificate", 0, "left") - 250 },
                            caret: { top: '0px', right: '30px' }
                        }
                    ]
                } else {
                    newSteps = [
                        {
                            heading: 'Your Certificate',
                            content: 'This is how you will see your certificate. You can also drag your certificate around',
                            style: { top: this.getPositionPx("image-container", 0, "top") + 10, left: this.getPositionPx("image-container", 0, "left") },
                            caret: { bottom: '-10px', right: '30px', transform: 'rotate(180deg)' }
                        },
                        {
                            heading: 'Zoom',
                            content: 'You can zoom into your certificate here.',
                            style: { top: this.getPositionPx("onboarding-zoom", 0, "top") - 160, left: this.getPositionPx("onboarding-zoom", 0, "left") },
                            caret: { bottom: '-10px', left: '10px', transform: 'rotate(180deg)' }
                        },
                        {
                            heading: 'Re-center',
                            content: 'You can re-center your certificate here.',
                            style: { top: this.getPositionPx("recenter-certificate", 0, "bottom") + 10, left: this.getPositionPx("recenter-certificate", 0, "left") - 250 },
                            caret: { top: '0px', right: '30px' }
                        },
                        {
                            heading: 'Download',
                            content: 'You can download the certificate from here here.',
                            style: { top: this.getPositionPx("nav_sub", 0, "bottom") + 10, left: this.getPositionPx("nav_sub", 0, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Share',
                            content: 'You can share / copy your certificate URL from here.',
                            style: { top: this.getPositionPx("nav_sub", 2, "bottom") + 10, left: this.getPositionPx("nav_sub", 2, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                        {
                            heading: 'Delete',
                            content: 'You can delete this certificate here.',
                            style: { top: this.getPositionPx("nav_sub", 4, "bottom") + 10, left: this.getPositionPx("nav_sub", 4, "left") },
                            caret: { top: '0px', left: '5px' }
                        },
                    ]
                }
                break;
            case 'viewer':
                newSteps = [
                    {
                        heading: 'Your Certificate',
                        content: 'This is how you will see your certificate. You can also drag your certificate around',
                        style: { top: this.getPositionPx("wrapper", 0, "top") + 10, left: this.getPositionPx("wrapper", 0, "left") },
                        caret: { bottom: '-10px', right: '30px', transform: 'rotate(180deg)' }
                    },
                    {
                        heading: 'Zoom',
                        content: 'You can zoom into your certificate here.',
                        style: { top: this.getPositionPx("onboarding-zoom", 0, "top") - 160, left: this.getPositionPx("onboarding-zoom", 0, "left") },
                        caret: { bottom: '-10px', left: '10px', transform: 'rotate(180deg)' }
                    },
                    {
                        heading: 'Re-center',
                        content: 'You can re-center your certificate here.',
                        style: { top: this.getPositionPx("recenter-certificate", 0, "bottom") + 10, left: this.getPositionPx("recenter-certificate", 0, "left") - 250 },
                        caret: { top: '0px', right: '30px' }
                    }
                ]
                break;
            default:
                newSteps = [
                    {
                        heading: 'Welcome to Pronetin',
                        content: 'This is how you will receive your certificates.',
                        style: { top: this.getPositionPx("tiles", 0, "top") + 180, left: this.getPositionPx("tiles", 0, "left"), zIndex: "995" },
                        caret: { top: '0px', left: '10px' }
                    },
                    {
                        heading: 'My Certificates',
                        content: 'All the certificates you receive can be found here',
                        style: { top: this.getPositionPx("nav", 0, "bottom") + 10, left: this.getPositionPx("nav", 0, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'My Documents',
                        content: 'Certificates you received outside this platform can be uploaded and stored here.',
                        style: { top: this.getPositionPx("nav", 1, "bottom") + 10, left: this.getPositionPx("nav", 1, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'My Settings',
                        content: 'Manage your pronetin profile from here.',
                        style: { top: this.getPositionPx("nav", 2, "bottom") + 10, left: this.getPositionPx("nav", 2, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Sign Certificates',
                        content: 'If you are a signing authority, Upload your signature, approve and sign certificates here.',
                        style: { top: this.getPositionPx("signee-module-js", 0, "bottom") + 10, left: this.getPositionPx("signee-module-js", 0, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Create Certificates',
                        content: 'Create and distribute certificates from here.',
                        style: { top: this.getPositionPx("btn-issue", 0, "bottom") + 10, left: this.getPositionPx("btn-issue", 0, "left") },
                        caret: { top: '0px', left: '5px' }
                    },
                    {
                        heading: 'Explore',
                        content: 'More of Pronetin features here.',
                        style: { top: this.getPositionPx("profile-icon-container", 0, "bottom") + 10, right: this.getPositionPx("profile-icon-container", 0, "bottom") - 20 },
                        caret: { top: '0px', right: '30px' }
                    },
                    {
                        heading: 'Notifications',
                        content: 'See all your notifications here.',
                        style: { top: this.getPositionPx("notification-icon-container", 0, "bottom") + 10, right: this.getPositionPx("notification-icon-container", 0, "bottom") - 20 },
                        caret: { top: '0px', right: '90px' }
                    },

                    {
                        heading: 'Know More',
                        content: 'Click here to get further ideas on how to use this platform.',
                        style: { top: this.getPositionPx("onboarding-help", 0, "bottom") + 10, left: this.getPositionPx("onboarding-help", 0, "left") },
                        caret: { top: '0px', left: '10px' }
                    }
                ]
        }
        this.setState({
            currentStep: 0,
            steps: newSteps,
        });
    }

    handlePreviousStep = () => {
        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
        }));
    };

    handleCloseTutorial = () => {
        localStorage.setItem('onboarding_' + this.props.page, true);
        this.setState({ shouldRender: false });
        this.props.toggleHelpModal('explore-pronetin');
        this.props.closeOnboardingTutorial(true)
    }

    getPositionPx(elem, index, pos) {
        const element = document.getElementsByClassName(elem)[index];
        if (element) {
            const rect = element.getBoundingClientRect();

            const top = rect.top + window.scrollY;
            const left = rect.left + window.scrollX;
            const bottom = rect.bottom + window.scrollY;
            const right = rect.right + window.scrollX;

            switch (pos) {
                case "top":
                    return top;
                case "left":
                    return left;
                case "bottom":
                    return bottom;
                case "right":
                    return right;
                default:
                    return "0px";
            }
        }
    }

    handleWindowResize = () => {
        this.updateSteps();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        const { currentStep } = this.state;
        const { heading } = this.state.steps[currentStep];
        const { content } = this.state.steps[currentStep];
        const { style } = this.state.steps[currentStep];
        const { caret } = this.state.steps[currentStep];

        return (
            <React.Fragment>
                {this.state.shouldRender && (
                    <div className='onboarding-tutorial' style={style}>
                        <div className='pos-rel'>
                            <div className='caret' style={caret}></div>
                            <div className='close-btn' onClick={this.handleCloseTutorial}><i className="fa-regular fa-circle-xmark"></i></div>
                            <h2>{heading}</h2>
                            <p>{content}</p>
                            <div className='buttons'>
                                <button onClick={this.handlePreviousStep} disabled={currentStep === 0}><i className="fa-solid fa-angle-left"></i></button>
                                <p className='steps'>{currentStep + 1 + " / " + this.state.steps.length}</p>
                                <button onClick={this.handleNextStep} disabled={currentStep >= this.state.steps.length}><i className="fa-solid fa-angle-right"></i></button>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default OnboardingTutorial;