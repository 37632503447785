import React from 'react';
import Header from '../components/header';

function App() {
    return (
        <div className='main'>
            <Header />
            <div className="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>At Pronetin, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, and safeguard the information you provide when using our certificate distribution platform. We encourage you to read this policy carefully to understand our practices regarding your personal data.</p>
                <h2>Information We Collect</h2>
                <h3>1.1 Personal Information:</h3>
                <p>When you sign up for Pronetin, we collect personal information such as your name, email address, and contact details.
                    We may collect additional information, such as your organisation's name, address, and industry, to tailor our services to your specific needs.</p>
                <h3>1.2 Certificate Recipient Information:</h3>
                <p>When you upload a recipient list for certificate distribution, we collect the necessary recipient information, including names and email addresses, solely for the purpose of delivering certificates.</p>
                <h3>1.3 Usage Data:</h3>
                <p>We collect information about your interactions with our platform, including your IP address, browser type, device information, and operating system. This data is used for analytics purposes and to enhance your user experience.</p>
                <h2>How We Use Your Information</h2>
                <h3>2.1 Certificate Distribution:</h3>
                <p>We use the recipient information you provide to distribute certificates to the intended recipients' email addresses as per your instructions.</p>
                <h3>2.2 Platform Improvement:</h3>
                <p>We analyse usage data to improve our platform's functionality, user interface, and overall performance. This data helps us enhance your experience and develop new features and services.</p>
                <h3>2.3 Communication:</h3>
                <p>We may use your contact information to communicate with you about platform updates, new features, promotional offers, and relevant news. You can opt out of these communications at any time by following the instructions provided in the communication or by contacting us.</p>
                <h3>2.4 Aggregated and Anonymized Data:</h3>
                <p>We may aggregate and anonymize data for statistical or research purposes. This data does not personally identify you and may be shared with third parties for industry analysis, benchmarking, or other similar purposes.</p>
                <h2>Data Security</h2>
                <h3>3.1 Security Measures:</h3>
                <p>We implement industry-standard security measures to protect your personal information from unauthorised access, alteration, disclosure, or destruction.
                    Our platform uses encryption, secure socket layer (SSL) technology, and other measures to ensure the secure transmission of data.</p>
                <h3>3.2 Data Retention:</h3>
                <p>We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
                <h2>Data Sharing</h2>
                <h3>4.1 Service Providers:</h3>
                <p>We may engage trusted third-party service providers to assist us in delivering our services and maintaining the platform. These providers are obligated to protect your information and are prohibited from using it for any other purpose.</p>
                <h3>4.2 Legal Compliance:</h3>
                <p>We may disclose your personal information if required to do so by law or in response to a valid legal request, such as a court order or government investigation.</p>
                <h2>Your Rights and Choices</h2>
                <h3>5.1 Access, Update, and Delete:</h3>
                <p>You have the right to access, update, and delete your personal information. You can manage your account settings or contact us to exercise these rights.</p>
                <h3>5.2 Opt-Out:</h3>
                <p>You can opt out of receiving promotional communications from us by following the instructions provided in the communication or contacting us directly.</p>
                <h2>Changes to this Privacy Policy</h2>
                <p>We reserve the right to modify this Privacy Policy at any time. Any updates will be posted on our website, and we encourage you to review this policy periodically.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at help@pronetin.com.</p>
                <p>This Privacy Policy is effective as of 10th May 2023. By using Pronetin, you acknowledge that you have read and understood this Privacy Policy and agree to our collection, use, and disclosure of your personal information as described herein.</p>
            </div>
        </div>
    );
}

export default App;
