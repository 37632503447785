import React from 'react';
import './css/home.css';
import axios from 'axios';
import ShiedIcon from '../images/icons/98065-security-tick.png';
import DesignElement from '../images/static/design.png'
import logo from '../images/static/Pronetin Logo.png';

import Warning from '../components/warning';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            // thumbnailsAndTitles: [],
            detailedFAQ: false,
            callTime: "Today",
            supportType: "Sales",
            supportName: "",
            supportPhone: "",
            support_token: false,
            faqType: "Organisations",
            faqData: [],
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            searchText: '',
        };
        this.debounceTimeout = null;
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        this.fetchThumbnails();
        this.containerRef.current.addEventListener('scroll', this.handleScroll);
        const hashValue = window.location.hash
        const id = hashValue.substring(1).split('_')[0];
        const type = hashValue.substring(1).split('_')[1];
        console.log(type);
        axios.post(this.props.node_server_axios + 'get_help_data', {
            params: {
                faqType: type ? type : this.state.faqType,
            }
        }).then((res) => {
            if (res.data !== undefined && res.data.message === 'success') {
                if (id === '') {
                    this.setState({
                        faqData: res.data.faqData,
                        faqDataTemp: res.data.faqData,
                    });
                } else {
                    this.setState({
                        faqData: res.data.faqData,
                        faqDataTemp: res.data.faqData,
                        detailedFAQ: true,
                        detailedFAQDescription: res.data.faqData.find(obj => obj.id === id).description,
                        detailedFAQHeading: res.data.faqData.find(obj => obj.id === id).heading,
                    });
                }
            }
        })
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener('scroll', this.handleScroll);

        // Clear the debounce timeout on component unmount
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
    }

    handleSearchChange = (event) => {
        const searchText = event.target.value;
        this.setState({ searchText });
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        this.debounceTimeout = setTimeout(() => {
            this.performSearch(searchText);
        }, 300);
    };

    performSearch = (searchText) => {
        const filteredItems = this.state.faqDataTemp.filter((item) =>
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.heading.toLowerCase().includes(searchText.toLowerCase())
        );

        this.setState({ faqData: filteredItems });
    };

    handleScroll = () => {
        const container = this.containerRef.current;
        const element = document.getElementById("footer")
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 30) {
            element.classList.add("active");
        } else if (element.classList.contains("active")) {
            element.classList.remove("active");
        }
    };

    fetchThumbnails() {
        const apiKey = 'AIzaSyAKDy3QHaBr6N2V4xrghTSQZGru4hLlYUw';
        const videoIds = ['EgdM5RB2AKc'];
        const urls = videoIds.map(videoId =>
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`
        );

        Promise.all(urls.map(url => fetch(url).then(response => response.json())))
            .then(dataArray => {
                const thumbnailsAndTitles = dataArray.map((data, index) => {
                    const snippet = data.items[0].snippet;
                    return {
                        thumbnailUrl: snippet.thumbnails.high.url,
                        title: snippet.title,
                        url: "https://www.youtube.com/watch?v=" + videoIds[index],
                    };
                });
                this.setState({ thumbnailsAndTitles }); // Update the state with the thumbnail URLs and video titles
            })
            .catch(error => {
                console.error('Error fetching YouTube thumbnails:', error);
            });
    }

    openDetailedContent = (heading, description) => {
        this.setState({
            detailedFAQ: true,
            detailedFAQDescription: description,
            detailedFAQHeading: heading,
        });
    }

    closeDetailedContent = () => {
        this.setState({
            detailedFAQ: false,
        });
    }

    handleSupportTypeChange = (event) => {
        this.setState({
            supportType: event.target.value
        });
    };

    handleSupportName = (event) => {
        this.setState({
            supportName: event.target.value
        });
    };

    handleSupportPhone = (event) => {
        this.setState({
            supportPhone: event.target.value
        });
    };

    handleCallTimeChange = (event) => {
        this.setState({
            callTime: event.target.value
        });
    };

    changeFaqType = (event) => {
        this.setState({
            faqType: event.target.value,
            detailedFAQ: false,
        });
        axios.post(this.props.node_server_axios + 'get_help_data', {
            params: {
                faqType: event.target.value,
            }
        }).then((res) => {
            if (res.data !== undefined && res.data.message === 'success') {
                this.setState({
                    faqData: res.data.faqData,
                });
            } else {
                this.setState({
                    faqData: [],
                });
            }
        })
    }

    submitCallback = () => {
        if (this.props.logged_in) {
            axios.post(this.props.node_server_axios + 'submit_callback', {
                params: {
                    support_type: this.state.supportType,
                    support_time: this.state.callTime,
                    support_name: this.state.supportName,
                    support_phone: this.state.supportPhone,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        support_token: res.data.token
                    });
                } else {
                    this.setState({
                        support_token: "You already have an open callback"
                    });
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Sorry, You can only Schedule Callback if Signed In. But you can mail us for a quick response",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
            const email = 'help@pronetin.com';
            const subject = encodeURIComponent('Request for Callback');
            const body = encodeURIComponent('Hi Pronetin Team, \n\nI hope this email finds you well.\nI am writing to request a callback at your earliest convenience.\n\nI require ' + this.state.supportType + ' Support\nPlease contact me ' + this.state.callTime + '\nYou can reach me at ' + this.state.supportPhone + '.\n\nThank you for your attention to this matter. I look forward to speaking with you soon.\n\nBest regards,\n' + this.state.supportName);

            window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
        }
    }

    render() {
        // const { thumbnailsAndTitles } = this.state;

        return (
            <div className='help-section' ref={this.containerRef}>
                <div className='wrap-sec'>
                    <div className='faq-sec'>
                        <div className='header'>
                            <div className='left-sec'>
                                <h1>Help Center</h1>
                                <select onChange={this.changeFaqType} defaultValue={this.state.faqType}>
                                    <option value={'General'}>General</option>
                                    <option value={'Organisations'}>Organisation</option>
                                    <option value={'Recepients'}>Recepient</option>
                                </select>
                                <div className='faq-type'>
                                    <button className={this.state.faqType === 'General' ? 'active' : null} value={'General'} onClick={this.changeFaqType}>General</button>
                                    <button className={this.state.faqType === 'Organisations' ? 'active' : null} value={'Organisations'} onClick={this.changeFaqType}>Organisations</button>
                                    <button className={this.state.faqType === 'Recepients' ? 'active' : null} value={'Recepients'} onClick={this.changeFaqType}>Recepients</button>
                                </div>
                            </div>
                            <div className='search'>
                                <input type="text" placeholder='Search' value={this.state.searchText} onChange={this.handleSearchChange} />
                                <button style={{ pointerEvents: "none" }}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                        <h2>{this.state.detailedFAQ && (<button className='back-btn' onClick={this.closeDetailedContent}><i className="fa-solid fa-arrow-left-long"></i> Back</button>)} Frequently Asked Questions</h2>
                        {this.state.detailedFAQ ?
                            <div className='faq-content'>
                                <div className='faq-heading'>
                                    <p>For {this.state.faqType}</p>
                                    <ul>
                                        {this.state.faqData.map((data, index) => (
                                            <li key={index} onClick={() => this.openDetailedContent(data.heading, data.description)}>{data.heading}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='faq-description'>
                                    <h1>{this.state.detailedFAQHeading}</h1>
                                    <p>{this.state.detailedFAQDescription}</p>
                                </div>
                            </div>
                            :
                            <div className='faq-section'>
                                {this.state.faqData.map((data, index) => (
                                    <div className='faq-tile' onClick={() => this.openDetailedContent(data.heading, data.description)} key={index}>
                                        <p>{data.heading}</p><i className="fa-solid fa-plus"></i>
                                    </div>
                                ))}
                            </div>

                        }
                        {!this.state.detailedFAQ && (
                            <div className='hr-wrapper'>
                                <i className="fa-solid fa-chevron-down"></i>
                                <hr></hr>
                            </div>
                        )}
                        {/* <div className='video-help-section'>
                            {thumbnailsAndTitles.map((data, index) => (
                                <div className='vid-tile' key={index} onClick={() => { window.open(data.url, "_blank"); }}>
                                    <img src={data.thumbnailUrl} alt="YouTube Thumbnail" />
                                    <p><i className="fa-regular fa-circle-play"></i> <span>{data.title}</span></p>
                                </div>
                            ))}
                        </div> */}
                    </div>
                    {this.state.support_token ?
                        <div className='callback-sec'>
                            <h2><i className="fa-solid fa-phone-volume"></i> Schedule A Callback</h2>
                            <p>We have received your callback request, out team will contact you soon via your registered email</p>
                            <h3>{this.state.support_token}</h3>
                            <p>Generated token</p>
                        </div>
                        :
                        <div className='callback-sec'>
                            <h2><i className="fa-solid fa-phone-volume"></i> Schedule A Callback</h2>
                            <p>Tell us a bit more about your need, so that we can personalize the follow up</p>
                            <p className='label'><i className="fa-solid fa-headset"></i> Support type</p>
                            <div className='support-type'>
                                <div className='radio-btn'>
                                    <input type="radio" id="Sales" name="support-type" value="Sales" checked={this.state.supportType === 'Sales'} onChange={this.handleSupportTypeChange} />
                                    <label htmlFor="Sales"><i className="fa-regular fa-circle-check"></i> Sales</label>
                                </div>
                                <div className='radio-btn'>
                                    <input type="radio" id="Technical" name="support-type" value="Technical" checked={this.state.supportType === 'Technical'} onChange={this.handleSupportTypeChange} />
                                    <label htmlFor="Technical"><i className="fa-regular fa-circle-check"></i> Technical</label>
                                </div>
                                <div className='radio-btn'>
                                    <input type="radio" id="Training" name="support-type" value="Training" checked={this.state.supportType === 'Training'} onChange={this.handleSupportTypeChange} />
                                    <label htmlFor="Training"><i className="fa-regular fa-circle-check"></i> Training</label>
                                </div>
                            </div>
                            <p className='label'><i className="fa-regular fa-user"></i> Name</p>
                            <input type='text' placeholder='John Doe' required onChange={this.handleSupportName} />
                            <p className='label'><i className="fa-solid fa-phone"></i> Phone</p>
                            <input type='number' placeholder='Contact Number' required onChange={this.handleSupportPhone} />
                            <p className='label'><i className="fa-regular fa-clock"></i> Best time to call</p>
                            <div className='support-type'>
                                <div className='radio-btn'>
                                    <input type="radio" id="ASAP" name="call-time" value="ASAP" checked={this.state.callTime === 'ASAP'} onChange={this.handleCallTimeChange} />
                                    <label htmlFor="ASAP"><i className="fa-regular fa-circle-check"></i> ASAP</label>
                                </div>
                                <div className='radio-btn'>
                                    <input type="radio" id="Today" name="call-time" value="Today" checked={this.state.callTime === 'Today'} onChange={this.handleCallTimeChange} />
                                    <label htmlFor="Today"><i className="fa-regular fa-circle-check"></i> Today</label>
                                </div>
                                <div className='radio-btn'>
                                    <input type="radio" id="Tomorrow" name="call-time" value="Tomorrow" checked={this.state.callTime === 'Tomorrow'} onChange={this.handleCallTimeChange} />
                                    <label htmlFor="Tomorrow"><i className="fa-regular fa-circle-check"></i> Tomorrow</label>
                                </div>
                            </div>
                            <button onClick={this.submitCallback}>Request Callback <i className="fa-solid fa-arrow-right-long"></i></button>
                        </div>
                    }
                </div>
                <div className='signin-section'>
                    <img src={DesignElement} alt="Design Element" />
                    <p>{this.props.logged_in ? "Go to home page" : "Signin"} to use step by step guide <br></br> for Pronetin</p>
                    {this.props.logged_in ? <a href='/'>Home</a> : <a href='/signin'>Signin</a>}
                </div>
                <div className='section moto'>
                    <h1>Fast</h1>
                    <img src={ShiedIcon} alt="shield icon" />
                    <h1>Secure</h1>
                </div>

                <div className='rel-footer-space'></div>
                <div className='footer' id="footer">
                    <div className='footer-img-container'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#e4e9ee" fillOpacity="1" d="M 0 0 L 570 0 Q 480 0 420 90 Q 360 180 270 180 L 0 180 L 0 0"></path>
                        </svg>
                        <img src={logo} alt="logo" />
                        <div className='content'>
                            <h2>Pronetin</h2>
                            <p>Authorisation and Distribution of Digital Certificates</p>
                        </div>
                    </div>
                    <div className='footer-wrapper'>
                        <a href={this.props.logged_in ? '/' : '/home#landing'}>Home</a>
                        {!this.props.logged_in && (<a href='/home#features'>Features</a>)}
                        {!this.props.logged_in && (<a href='/home#pricing'>Pricing</a>)}
                        {!this.props.logged_in && (<a href='/home#contact'>Contact</a>)}
                        {!this.props.logged_in && (<a href='/signin'>Sign In</a>)}
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
            </div>
        );
    }
};

export default LandingHome;