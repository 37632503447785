import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';
import MainImage from '../../images/home/Solutions/Events/events.png';
import InfoImage from '../../images/home/Solutions/Events/events @ pronetin.png';
import Image1 from '../../images/home/Solutions/Events/events why.png';
import Image2 from '../../images/home/Solutions/Events/events benefits.png';
import Image3 from '../../images/home/Solutions/Events/events get started.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='solution intro'>
                            <div className='content'>
                                <h1>Events</h1>
                                <h2>Efficient Event Management with Pronetin</h2>
                                <p>Organising and managing events can be a complex task, but Pronetin offers a streamlined solution to simplify the process. From creation to follow-up, Pronetin’s event management tools are designed to make your events successful and hassle-free.</p>
                                <div className='btn-container'>
                                    <button className='alt' onClick={() => window.location = '/help'}>Request a demo</button>
                                    <button onClick={() => window.location = '/signin'}>Explore</button>
                                </div>
                            </div>
                            <div className='rep'>
                                <img src={MainImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution info'>
                            <div className='content'>
                                <h2>What is Pronetin's Event Management Solution?</h2>
                                <p>Pronetin provides an all-in-one platform for creating, promoting, managing, and tracking events. Whether you're hosting a professional conference, a training session, or an educational seminar, Pronetin equips you with the tools you need to manage every aspect of your event seamlessly.</p>
                            </div>
                            <div className='rep'>
                                <img src={InfoImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>Why Choose Pronetin's Event Management Solution?</h2>
                                <ul>
                                    <li><strong>Seamless Event Creation</strong>: Easily create and customise events with intuitive tools, managing registrations, sending invitations, and tracking attendance in a few simple steps.</li>
                                    <li><strong>Integrated Certificate Distribution</strong>: Design, generate, and send certificates to participants instantly and securely through our platform.</li>
                                    <li><strong>Customizable Registration Forms</strong>: Gather necessary participant information with professional, customizable registration forms, and manage data efficiently.</li>
                                    <li><strong>Real-Time Analytics and Reporting</strong>: Gain valuable insights into your event’s performance with real-time analytics and detailed reports.</li>
                                    <li><strong>Effective Promotion</strong>: Maximise visibility with targeted promotional tools, ensuring your event reaches the right audience.</li>
                                    <li><strong>Streamlined Communication</strong>: Maintain clear communication with automated reminders, updates, and follow-up messages.</li>
                                    <li><strong>Enhanced Participant Experience</strong>: Provide a smooth and engaging experience for your participants, from registration to feedback collection.</li>
                                    <li><strong>Secure and Reliable</strong>: Ensure the safety of your event data with robust security features built into the platform.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image1} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution alt'>
                            <div className='content'>
                                <h2>Benefits of Pronetin's Event Management Solution</h2>
                                <ul>
                                    <li><strong>Efficiency</strong>: Save time and reduce the manual hassle of managing events.</li>
                                    <li><strong>Professionalism</strong>: Create a polished and professional event experience for your participants.</li>
                                    <li><strong>Engagement</strong>: Boost participant engagement and satisfaction through seamless communication and follow-up.</li>
                                    <li><strong>Insights</strong>: Use real-time analytics to understand your audience and refine future events.</li>
                                    <li><strong>Security</strong>: Protect sensitive event data with advanced security measures.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image2} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>How to Get Started</h2>
                                <p>
                                    Getting started with Pronetin’s event management solution is simple. Sign up on our platform, create your event, and take advantage of our powerful tools to manage every aspect of your event efficiently.<br></br>
                                    <strong>Join Pronetin today and transform your event management experience. Simplify, streamline, and succeed with Pronetin, your partner in effective event management.</strong>
                                </p>
                            </div>
                            <div className='rep'>
                                <img src={Image3} alt='pronetin solutions' />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;