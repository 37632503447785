import React from 'react';
import '../../components/css/docs.css';
import Header from '../../components/landing_header';
import Footer from '../../components/landing_footer';
import ApiKeyImage from '../../images/help/images/apikeys.png'

class LandingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false
        };
    }

    scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="new-landing">
                    <div className='landing-v1'>
                        <Header />
                        <section>
                            <div className='api-section'>
                                <div className='api-nav'>
                                    <a className='back-btn' href='/resources/api'><i className="fa-solid fa-arrow-left-long"></i> Back</a>
                                    <div className='container'>
                                        <h3>Introduction</h3>
                                        <ul>
                                            <li onClick={() => this.scrollToElement('1-1')}>What is API</li>
                                            <li onClick={() => this.scrollToElement('1-2')}>Usecases</li>
                                            <li onClick={() => this.scrollToElement('1-3')}>Why API Key</li>
                                            <li onClick={() => this.scrollToElement('1-4')}>Revoke Key</li>
                                            <li onClick={() => this.scrollToElement('1-5')}>Copy Key</li>
                                        </ul>
                                        <h3>Generating API Key</h3>
                                        <ul>
                                            <li onClick={() => this.scrollToElement('2-1')}>Account</li>
                                            <li onClick={() => this.scrollToElement('2-2')}>Inputs Required</li>
                                            <li onClick={() => this.scrollToElement('2-3')}>View Your Keys</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='content'>
                                    <h3>Basics</h3>

                                    <h2>Introduction</h2>

                                    <div className='info' id='1-1'>
                                        <h4>What is API</h4>
                                        At Pronetin, API (Application Programming Interface) forms the backbone of our digital infrastructure, facilitating seamless communication between different software applications. Essentially, it allows diverse systems to interact and share data with each other in a structured manner. For Pronetin, APIs serve as the gateway that enables developers, businesses, and partners to harness our services and integrate them into their own applications effortlessly.
                                    </div>

                                    <div className='desc' id='1-2'>
                                        <h4>Usecases</h4>
                                        <ul>
                                            <li><strong>Integration:</strong> Easily integrate Pronetin’s features into third-party apps, enhancing functionality without reinventing the wheel.</li>
                                            <li><strong>Automation:</strong> Streamline processes by automating tasks such as data retrieval, updates, and notifications through Pronetin’s API.</li>
                                            <li><strong>Customization:</strong> Tailor Pronetin’s services to meet specific business needs and user preferences, ensuring a personalized experience.</li>
                                            <li><strong>Scalability:</strong> Scale applications efficiently by leveraging Pronetin’s robust API infrastructure, adapting to growing demands seamlessly.</li>
                                        </ul>
                                    </div>

                                    <div className='desc' id='1-3'>
                                        <h4>Why API Key</h4>
                                        To ensure secure and authorized access to Pronetin’s API, we use API keys. These keys act as unique identifiers for developers and applications, verifying their identity and permissions. By using API keys, Pronetin maintains strict control over who can access our API, safeguarding data integrity and preventing unauthorized usage.
                                    </div>

                                    <div className='desc' id='1-4'>
                                        <h4>Revoke Key</h4>
                                        Should the need arise, Pronetin provides the capability to revoke API keys instantly. This ensures immediate termination of access in case of security concerns or when access privileges are no longer needed.
                                    </div>

                                    <div className='desc' id='1-5'>
                                        <h4>Copy Key</h4>
                                        Easily copy your API key from Pronetin’s developer portal for seamless integration into your applications. This simplifies the setup process, allowing developers to get started quickly and efficiently.
                                    </div>

                                    <h2>Generating API Key</h2>

                                    <div className='info' id='2-1'>
                                        <h4>Account</h4>
                                        If you do not have an account in Pronetin, <a href='/signup' target='_blank'>create an account</a>.<br></br>
                                        After signing into pronetin navigate to the <a href='/account' target='_blank'>account</a> settings.<br></br>
                                        In the navigation menu choose <strong>API Keys</strong>.<br></br>
                                        Click on <strong>Create New Key</strong> to generate a new key.<br></br>
                                        <strong>Complete the Form</strong> and click on <strong>Create Key</strong>.
                                    </div>

                                    <div className='desc' id='2-2'>
                                        <h4>Inputs Required</h4>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Verb</th>
                                                    <th>Description</th>
                                                </tr>
                                                <tr>
                                                    <td>Key-Name</td>
                                                    <td>A name by which you can identify your API key</td>
                                                </tr>
                                                <tr>
                                                    <td>Description</td>
                                                    <td>(optional) here you can describe what this API key is used for</td>
                                                </tr>
                                                <tr>
                                                    <td>White-listed Domains</td>
                                                    <td>While using this particular API Key, only traffic from these white-listed domains will be allowed. For development environment <strong>'localhost'</strong> can be added as domain</td>
                                                </tr>
                                                <tr>
                                                    <td>Resources</td>
                                                    <td>Here you can select the Pronetin resource that can be accessed by this API key. By default it is set to <strong>All</strong> resources <strong>(not advised for production environment)</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='desc' id='2-3'>
                                        <h4>View Your Keys</h4>
                                        After generating your API Key you can access it in your <a href='/account' target='_blank'>account</a> settings, in <strong>API Keys</strong> section<br></br>
                                        You can copy your API Key by clicking the key. <strong>API keys are kept partially visible, but the complete key will be copied to your clipboard.</strong>
                                        <img src={ApiKeyImage} className='main-img' alt='api reference' />
                                    </div>

                                    <a href='/docs/api/embedd-badge' className='next-btn'>Embedd Pronetin Badge <i className="fa-solid fa-arrow-right-long"></i></a>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

export default LandingComponent;