import React from 'react';
import Header from '../components/header';
import checkGif from '../images/static/check.webm';
import cancelGif from '../images/static/cancel.webm';

import '../components/css/main.css';

const SigninComponent = (props) => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectStatus = searchParams.get("o");

    return (
        <React.Fragment>
            <div className="signin">
                <Header signin={true} />
                <div className='action-completed-message'>
                    {redirectStatus === 'success' ?
                        <div className='container'>
                            <video autoPlay muted>
                                <source src={checkGif} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            Action successfull
                        </div>
                        :
                        <div className='container'>
                            <video autoPlay muted>
                                <source src={cancelGif} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            Error Occoured
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );

};

export default SigninComponent;