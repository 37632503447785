import React, { Component } from 'react';

import TutorialVideo from '../images/videos/Functional Tutorial.mp4'

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pauseTimes: [145, 250, 460, 560, 795, 900, 1000, 1250, 1375, 1530, 1915, 2100, 2265, 2380, 2490, 2670, 2765, 2875, 2980, 3165, 3255, 3545, 3635, 3755, 3940, 4115, 4245, 4350, 4545, 4680, 4795, 4960, 5060, 5215, 5310, 5420, 5515, 5695, 6100, 6210, 6460, 6685, 6770, 6870, 6945, 7090, 7215, 7315, 7450, 7590, 7685, 7775, 7865, 7960], // Example pause times in seconds
            clickableParts: [
                { centerX: 15, centerY: 42, width: 7, height: 9 }, // Example clickable part
                { centerX: 80, centerY: 92, width: 11, height: 6 },
                { centerX: 50, centerY: 57, width: 7, height: 9 },
                { centerX: 43, centerY: 56, width: 9, height: 6 },
                { centerX: 9, centerY: 89, width: 9, height: 8 },
                { centerX: 37, centerY: 27, width: 10, height: 5 },
                { centerX: 53, centerY: 57, width: 10, height: 4 },
                { centerX: 33, centerY: 9, width: 4, height: 6 },
                { centerX: 37, centerY: 9, width: 4, height: 6 },
                { centerX: 80, centerY: 9, width: 10, height: 6 },
                { centerX: 80, centerY: 92, width: 10, height: 5 },
                { centerX: 9, centerY: 15, width: 10, height: 9 },
                { centerX: 10, centerY: 27, width: 10, height: 4 },
                { centerX: 34, centerY: 28, width: 5, height: 9 },
                { centerX: 9, centerY: 15, width: 10, height: 9 },
                { centerX: 34, centerY: 28, width: 7, height: 9 },
                { centerX: 37, centerY: 32, width: 10, height: 5 },
                { centerX: 73, centerY: 9, width: 3, height: 6 },
                { centerX: 80, centerY: 92, width: 10, height: 5 },
                { centerX: 19, centerY: 36, width: 3, height: 6 },
                { centerX: 10, centerY: 13, width: 10, height: 6 },
                { centerX: 53, centerY: 60, width: 7, height: 6 },
                { centerX: 34, centerY: 28, width: 7, height: 14 },
                { centerX: 80, centerY: 92, width: 10, height: 5 },
                { centerX: 23, centerY: 21, width: 7, height: 4 },
                { centerX: 80, centerY: 19, width: 4, height: 4 },
                { centerX: 24, centerY: 16, width: 5, height: 4 },
                { centerX: 87, centerY: 20, width: 2, height: 4 },
                { centerX: 10, centerY: 14, width: 10, height: 5 },
                { centerX: 9, centerY: 26, width: 10, height: 8 },
                { centerX: 10, centerY: 39, width: 10, height: 6 },
                { centerX: 80, centerY: 92, width: 10, height: 5 },
                { centerX: 25, centerY: 13, width: 10, height: 6 },
                { centerX: 24, centerY: 21, width: 4, height: 5 },
                { centerX: 25, centerY: 26, width: 10, height: 6 },
                { centerX: 60, centerY: 13, width: 4, height: 4 },
                { centerX: 84, centerY: 92, width: 9, height: 5 },
                { centerX: 80, centerY: 92, width: 10, height: 5 },
                { centerX: 38, centerY: 33, width: 10, height: 10 },
                { centerX: 38, centerY: 33, width: 8, height: 13 },
                { centerX: 22, centerY: 54, width: 9, height: 5 },
                { centerX: 23, centerY: 42, width: 11, height: 5 },
                { centerX: 37, centerY: 30, width: 10, height: 19 },
                { centerX: 73, centerY: 19, width: 2, height: 3 },
                { centerX: 53, centerY: 30, width: 10, height: 19 },
                { centerX: 35, centerY: 6, width: 11, height: 4 },
                { centerX: 45, centerY: 40, width: 8, height: 5 },
                { centerX: 82, centerY: 82, width: 10, height: 5 },
                { centerX: 38, centerY: 33, width: 9, height: 13 },
                { centerX: 23, centerY: 42, width: 10, height: 5 },
                { centerX: 36, centerY: 48, width: 2, height: 3 },
                { centerX: 51, centerY: 48, width: 2, height: 3 },
                { centerX: 34, centerY: 54, width: 2, height: 3 },
                { centerX: 58, centerY: 67, width: 11, height: 6 }
            ],
            currentIndex: -1, // Start with -1 to indicate video not playing
            playButton: true,
            completedButton: false,
            clickBox: false,
        };
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        this.timer = setInterval(this.checkPauseTimes, 210); // Check every second for pause times
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    checkPauseTimes = () => {
        const { pauseTimes, currentIndex } = this.state;
        const video = this.videoRef.current;

        const currentTime = video.currentTime * 24;
        const nextIndex = currentIndex + 1;

        if (nextIndex < pauseTimes.length && currentTime >= pauseTimes[nextIndex]) {
            this.setState({ currentIndex: nextIndex, clickBox: true });
            video.pause();
        }
    };

    handleClickToPlay = (partIndex) => {
        const { currentIndex } = this.state;
        if (currentIndex === partIndex) {
            const video = this.videoRef.current;
            video.play();
            this.setState({ clickBox: false });
        }
    };

    startTutorial = () => {
        const video = this.videoRef.current;
        video.play();
        this.setState({ playButton: false });
    }

    showCompletedButton = () => {
        this.setState({
            playButton: false,
            completedButton: true,
        });
        localStorage.setItem('functionalTutorial_editor', true);
    }

    skipVideoTutorial = () => {
        localStorage.setItem('functionalTutorial_editor', true);
        window.location = "/issue/new";
    }

    handleContextMenu = (event) => {
        event.preventDefault();
        return false;
    };

    previousStep = () => {
        const { currentIndex, pauseTimes } = this.state;
        const video = this.videoRef.current;

        if (currentIndex > 0) {
            const previousIndex = currentIndex - 1;
            this.setState({ currentIndex: previousIndex, clickBox: true }, () => {
                video.currentTime = pauseTimes[previousIndex] / 24;
                video.pause();
            });
        }
    }

    render() {
        const { clickableParts, currentIndex, clickBox } = this.state;

        return (
            <div className='functional-tutorial'>
                <div className='tutorial-wrap' onContextMenu={this.handleContextMenu}>
                    <video ref={this.videoRef} controls={false} onEnded={this.showCompletedButton}>
                        <source src={TutorialVideo} type="video/mp4" />
                    </video>
                    {this.state.playButton && (
                        <div className='start-button' onClick={this.startTutorial}>
                            Start Tutorial <i className="fa-solid fa-play"></i>
                        </div>
                    )}
                    {this.state.completedButton && (
                        <div className='start-button' onClick={() => window.location.href = './issue/new'}>
                            Begin Designing Your Certificate <i className="fa-solid fa-arrow-right-long"></i>
                        </div>
                    )}
                    <div className='skip-functional-tutorial'>
                        <button onClick={this.skipVideoTutorial}>Skip <i className="fa-solid fa-forward"></i></button>
                    </div>
                    {this.state.currentIndex > 0 && (
                        <button onClick={this.previousStep} className="previous-functional-tutorial"><i className="fa-solid fa-backward-step"></i> last step</button>
                    )}
                    {clickableParts.map((part, index) => {
                        if (index === currentIndex && clickBox === true) {
                            return (
                                <div
                                    key={index}
                                    onMouseDown={() => this.handleClickToPlay(index)}
                                    style={{
                                        position: 'absolute',
                                        left: part.centerX + "%",
                                        top: part.centerY + "%",
                                        width: part.width + "%",
                                        height: part.height + "%",
                                        // backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        cursor: 'pointer',
                                    }}
                                />
                            );
                        }
                        return null; // Hide other clickable parts
                    })}
                </div>
            </div>
        );
    }
}

export default VideoPlayer;