import './css/sidenav.css';
import axios from 'axios';

import React from 'react';
import coinIcon from '../images/static/Component 397 – 4.png';
import Warning from '../components/warning';

class SidenavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: [],
            approvals: 0,
            credits: 0,
            minNav: localStorage.getItem("minNav") === null ? false : JSON.parse(localStorage.getItem("minNav")),
            storage: this.props.storage,
            currentlyOpen: 'personal',
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            sideNav_newCertificate: false,
            org_verified: "loading",
        }
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    organisationRender = (value) => {
        if (value === 'organisation') {
            return (
                this.state.currentlyOpen === 'organisation' ?
                    <div className='module organisation-module-js'>
                        <div className='profile'>
                            <div className='user-sec'>
                                <div className='icon tooltip-premium'>
                                    <span className="tooltiptext">Verified Org</span>
                                    <i className="fa-solid fa-users"></i>
                                    {this.state.org_verified !== "loading" && this.state.org_verified === "verified" && (<div className='verifiedbadge'><i className="fa-solid fa-shield outer"></i><i className="fa-solid fa-check inner"></i></div>)}
                                </div>
                                {!this.state.minNav ?
                                    <div className='user-name'>
                                        <label>Organisation</label>
                                        <h1><span>{this.props.org_name ? this.props.org_name : this.state.org_name}</span></h1>
                                        <div className='org-upgrade'>
                                            <div className='plan' onClick={() => window.location = '/premium'}><span>{this.state.running_plan && this.state.running_plan.includes('-') ? this.state.running_plan.split('-')[0] : this.state.running_plan}</span> <button>Upgrade&nbsp;Plan</button></div>
                                            <div className='plan credits' onClick={() => window.location = '/premium'}>{this.props.credits ? this.props.credits : this.state.credits} <img src={coinIcon} className="coin-icon" alt="credit icon"></img> <button>Buy&nbsp;Credits</button></div>
                                        </div>
                                    </div>
                                    :
                                    <div className='plan' onClick={() => window.location = '/premium'}><span>{this.state.running_plan && this.state.running_plan.includes('-') ? this.state.running_plan.split('-')[0] : this.state.running_plan}</span> <button>Upgrade&nbsp;Plan</button></div>
                                }
                            </div>
                        </div>
                        {/* {!this.state.minNav ?
                            <div className='org-sec'>
                                <div className='org-name'>{this.props.org_name ? this.props.org_name : this.state.org_name}</div>
                                <h1><span>{this.props.credits ? this.props.credits : this.state.credits}</span><img src={coinIcon} className="coin-icon" alt="credit icon"></img></h1>
                                <p>Credits</p>
                                <a className='org-plan' href='/premium'>Upgrade <i className="fa-solid fa-bolt"></i></a>
                                <a className='add-credits' href='/premium'><p>Add&nbsp;Credits</p><i className="fa-solid fa-plus"></i></a>
                            </div>
                            :
                            <div className='org-sec-min'>
                                <h1>{this.props.credits ? this.props.credits : this.state.credits}</h1>
                                <p>Credits</p>
                            </div>
                        } */}
                        {!this.state.minNav && this.state.org_verified !== "loading" && this.state.org_verified !== "verified" && (
                            <a href="/account?selection=verification" className='verification-warning' style={{ "margin": "0px" }}>
                                <p><i className="fa-solid fa-triangle-exclamation"></i> Your organisation is not verified.<br></br> <span>The certificates you distribute lacks credibility.</span> <u>Get verified now <i className="fa-solid fa-arrow-right"></i></u></p>
                            </a>
                        )}
                        <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-paintbrush"></i> {!this.state.minNav && ('Create Certificate')}</button>
                        <a className={this.props.selected === 'myorganisation' ? 'nav selected' : 'nav'} href="/organisation">
                            <i className="fa-solid fa-paper-plane"></i>
                            {!this.state.minNav && (
                                <div className='description'>
                                    Send Certificate
                                    <label>Certificates Pending to be distributed</label>
                                </div>
                            )}
                        </a>
                        <a className={this.props.selected === 'distributed' ? 'nav selected' : 'nav'} href="/distributed">
                            <i className="fa-solid fa-trophy"></i>
                            {!this.state.minNav && (
                                <div className='description'>
                                    All Events
                                    <label>All Created Certificates</label>
                                </div>
                            )}
                        </a>
                        <a className={this.props.selected === 'actioncenter' ? 'nav selected' : 'nav'} href="/actioncenter">
                            <i className="fa-solid fa-list-check"></i>
                            {!this.state.minNav && (
                                <div className='description'>
                                    Action Center
                                    <label>All requests from recipients</label>
                                </div>
                            )}
                            {sessionStorage.getItem('sideNav_actionCenter') === '1' && !this.state.minNav && (
                                <div className='bell-icon'><i className="fa-solid fa-bell"></i></div>
                            )}
                        </a>
                        <a className={this.props.selected === 'mydesign' ? 'nav selected' : 'nav'} href="/mydesign">
                            <i className="fa-solid fa-brush"></i>
                            {!this.state.minNav && (
                                <div className='description'>
                                    My Designs
                                    <label>All your saved and draft designs</label>
                                </div>
                            )}
                        </a>
                        <a className={this.props.selected === 'emailtemplate' ? 'nav selected' : 'nav'} href="/email">
                            <i className="fa-solid fa-envelope-open-text"></i>
                            {!this.state.minNav && (
                                <div className='description'>
                                    Email Templates
                                    <label>Templates for Email Notification</label>
                                </div>
                            )}
                        </a>
                        <a className={this.props.selected === 'marketplace' ? 'nav selected' : 'nav'} href="/marketplace">
                            <i className="fa-solid fa-shop"></i>
                            {!this.state.minNav && (
                                <div className='description'>
                                    Marketplace
                                    <label>New designs and design assistance</label>
                                </div>
                            )}
                        </a>
                        {/* <a className={this.props.selected === 'settings' ? 'nav selected' : 'nav'} href="/settings">
                        <i className="fa-solid fa-gear"></i>
                        {!this.state.minNav && ('Organisation Settings')}
                    </a> */}
                    </div>
                    :
                    <div className='module organisation-module-js' onClick={() => this.togglesubNav('organisation')}>
                        <div className='profile'>
                            <div className='user-sec'>
                                <div className='icon tooltip-premium'>
                                    <span className="tooltiptext">Verified Org</span>
                                    <i className="fa-solid fa-users"></i>
                                    {this.state.org_verified !== "loading" && this.state.org_verified === "verified" && (<div className='verifiedbadge'><i className="fa-solid fa-shield outer"></i><i className="fa-solid fa-check inner"></i></div>)}
                                </div>
                                {!this.state.minNav ?
                                    <div className='user-name'>
                                        <label>Organisation</label>
                                        <h1><span>{this.props.org_name ? this.props.org_name : this.state.org_name}</span></h1>
                                        <div className='org-upgrade'>
                                            <div className='plan' onClick={() => window.location = '/premium'}><span>{this.state.running_plan && this.state.running_plan.includes('-') ? this.state.running_plan.split('-')[0] : this.state.running_plan}</span> <button>Upgrade&nbsp;Plan</button></div>
                                            <div className='plan credits' onClick={() => window.location = '/premium'}>{this.props.credits ? this.props.credits : this.state.credits} <img src={coinIcon} className="coin-icon" alt="credit icon"></img> <button>Buy&nbsp;Credits</button></div>
                                        </div>
                                    </div>
                                    :
                                    <div className='plan' onClick={() => window.location = '/premium'}><span>{this.state.running_plan && this.state.running_plan.includes('-') ? this.state.running_plan.split('-')[0] : this.state.running_plan}</span> <button>Upgrade&nbsp;Plan</button></div>
                                }
                            </div>
                        </div>
                        <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-paintbrush"></i> {!this.state.minNav && ('Create Certificate')}</button>
                        {this.props.selected === 'myorganisation' && (
                            <a className='nav selected' href="/organisation">
                                <i className="fa-solid fa-paper-plane"></i>
                                {!this.state.minNav && (
                                    <div className='description'>
                                        Send Certificate
                                        <label>Certificates Pending to be distributed</label>
                                    </div>
                                )}
                            </a>
                        )}
                        {this.props.selected === 'distributed' && (
                            <a className='nav selected' href="/distributed">
                                <i className="fa-solid fa-trophy"></i>
                                {!this.state.minNav && (
                                    <div className='description'>
                                        All Events
                                        <label>All Created Certificates</label>
                                    </div>
                                )}
                            </a>
                        )}
                        {this.props.selected === 'actioncenter' && (
                            <a className='nav selected' href="/actioncenter">
                                <i className="fa-solid fa-list-check"></i>
                                {!this.state.minNav && (
                                    <div className='description'>
                                        Action Center
                                        <label>All requests from recipients</label>
                                    </div>
                                )}
                                {sessionStorage.getItem('sideNav_actionCenter') === '1' && !this.state.minNav && (
                                    <div className='bell-icon'><i className="fa-solid fa-bell"></i></div>
                                )}
                            </a>
                        )}
                        {this.props.selected === 'mydesign' && (
                            <a className='nav selected' href="/mydesign">
                                <i className="fa-solid fa-brush"></i>
                                {!this.state.minNav && (
                                    <div className='description'>
                                        My Designs
                                        <label>All your saved and draft designs</label>
                                    </div>
                                )}
                            </a>
                        )}
                        {this.props.selected === 'emailtemplate' && (
                            <a className='nav selected' href="/email">
                                <i className="fa-solid fa-envelope-open-text"></i>
                                {!this.state.minNav && (
                                    <div className='description'>
                                        Email Templates
                                        <label>Templates for Email Notification</label>
                                    </div>
                                )}
                            </a>
                        )}
                        {this.props.selected === 'marketplace' && (
                            <a className='nav selected' href="/marketplace">
                                <i className="fa-solid fa-shop"></i>
                                {!this.state.minNav && (
                                    <div className='description'>
                                        Marketplace
                                        <label>New designs and design assistance</label>
                                    </div>
                                )}
                            </a>
                        )}
                        <button className='toggle-sub-nav-btn'><i className="fa-solid fa-chevron-down"></i></button>
                    </div>
            );
        }
    }

    componentDidMount() {
        if (this.props.user_type === 'organisation') {
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'session').then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                    this.setState({
                        name: res.data.name,
                        email: res.data.email,
                        user_type: res.data.user_type,
                        org_id: res.data.org_id,
                        storage: res.data.storage,
                    });

                    axios.post(this.props.node_server_axios + 'get_organisation_data', {
                        params: {
                            org_id: res.data.org_id,
                        }
                    }).then((res) => {
                        if (res.data !== undefined && res.data.message === 'success') {
                            localStorage.setItem('displyCredits', parseInt(res.data.credits));
                            this.setState({
                                loading: false,
                                credits: parseInt(res.data.credits),
                                per_credit: parseInt(res.data.per_credit),
                                running_plan: res.data.running_plan,
                                org_name: res.data.org_name,
                                org_verified: res.data.verified,
                            }, () => {
                                if (typeof this.props.documentLoaded === 'function') {
                                    this.props.documentLoaded(true);
                                } else {
                                    console.log('sidenav loaded');
                                }
                            });
                        } else {
                            window.location = "/signin";
                        }
                    })
                } else {
                    window.location = "/signin";
                }
            }).catch(() => {
                window.location = "/signin";
            })
        }

        //--------------Fetch certificates in an interval to check if new certificates received----------------
        if (this.props.email) {
            // console.log(localStorage.getItem('sideNav_newCertificate_prev'), localStorage.getItem('sideNav_newCertificate'));
            if (this.props.selected === 'allDocuments') {
                // console.log("Visited");
                this.setState({
                    sideNav_newCertificate: false,
                });
                localStorage.setItem('sideNav_newCertificate_prev', localStorage.getItem('sideNav_newCertificate'))
            } else if ((this.props.selected === 'myorganisation' || this.props.selected === 'approvals') && (localStorage.getItem('sideNav_newCertificate_prev') === localStorage.getItem('sideNav_newCertificate') || localStorage.getItem('sideNav_newCertificate') === null)) {
                // console.log("fetched certificates")
                axios.defaults.withCredentials = true;
                axios.post(this.props.node_server_axios + 'document_list', {
                    params: {
                        view_type: 'all',
                        email: this.props.email,
                    },
                }).then(res => {
                    if (res.data.length !== parseInt(localStorage.getItem('sideNav_newCertificate_prev'))) {
                        this.setState({
                            sideNav_newCertificate: true,
                        });
                        localStorage.setItem('sideNav_newCertificate', res.data.length)
                    }
                });
            } else if (localStorage.getItem('sideNav_newCertificate_prev') !== localStorage.getItem('sideNav_newCertificate')) {
                this.setState({
                    sideNav_newCertificate: true,
                });
            }
        }
        //-----------------------------------------------END-------------------------------------------------

        switch (this.props.selected) {
            case 'allDocuments':
                this.setState({
                    currentlyOpen: 'personal',
                });
                break;
            case 'uploadedDocuments':
                this.setState({
                    currentlyOpen: 'personal',
                });
                break;
            case 'mySignature':
                this.setState({
                    currentlyOpen: 'signee',
                });
                break;
            case 'approvals':
                this.setState({
                    currentlyOpen: 'signee',
                });
                break
            case 'approvalHistory':
                this.setState({
                    currentlyOpen: 'signee',
                });
                break;
            case 'pronetinESign':
                this.setState({
                    currentlyOpen: 'signee',
                });
                break;
            case 'myorganisation':
                this.setState({
                    currentlyOpen: 'organisation',
                });
                break;
            case 'distributed':
                this.setState({
                    currentlyOpen: 'organisation',
                });
                break;
            case 'emailtemplate':
                this.setState({
                    currentlyOpen: 'organisation',
                });
                break;
            case 'mydesign':
                this.setState({
                    currentlyOpen: 'organisation',
                });
                break;
            case 'marketplace':
                this.setState({
                    currentlyOpen: 'organisation',
                });
                break;
            case 'actioncenter':
                this.setState({
                    currentlyOpen: 'organisation',
                });
                break;
            default:
                this.setState({
                    currentlyOpen: 'personal',
                });
        }

        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);

        if (window.innerWidth < 1250 && window.innerWidth > 820) {
            this.setState({
                minNav: true,
            });
        }
    }

    componentDidUpdate() {
        if (this.props.sidenav_type && this.props.sidenav_type !== this.state.currentlyOpen) {
            this.setState({
                currentlyOpen: this.props.sidenav_type,
            });
            this.props.sidenavType(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        if (window.innerWidth <= 820) {
            this.setState({
                minNav: false,
            });
        }
        if (window.innerWidth > 820) {
            var mobileNavModal = document.getElementById('mobile-nav-modal');
            mobileNavModal.style.display = 'flex';
        }
    };

    toggleMenu = (e) => {
        e.preventDefault();
        var subNav = document.getElementsByClassName('cert-search')[0];
        if (subNav !== undefined) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
        }
        var mobileNavModal = document.getElementById('mobile-nav-modal');
        if (mobileNavModal.style.display !== 'flex') {
            mobileNavModal.style.display = 'flex';
        } else {
            mobileNavModal.style.display = 'none';
        }
    }

    toggleMinNav = () => {
        localStorage.setItem("minNav", !this.state.minNav);
        this.setState({
            minNav: !this.state.minNav,
        }, () => {
            if (this.props.minifiedNav && typeof this.props.minifiedNav === 'function') {
                this.props.minifiedNav(this.state.minNav)
            }
        });
    }

    toggleSearch = (e) => {
        e.preventDefault();
        var mobileNavModal = document.getElementById('mobile-nav-modal');
        mobileNavModal.style.display = 'none';
        var subNav = document.getElementsByClassName('cert-search')[0];
        if (subNav !== undefined) {
            if (subNav.classList.contains('show')) {
                subNav.classList.remove('show');
                subNav.classList.add('hide');
            } else {
                subNav.classList.add('show');
                subNav.classList.remove('hide');
            }
        } else {
            window.location = '/?search=true';
        }
    }

    togglesubNav = (data) => {
        this.setState({
            currentlyOpen: data,
        });
    }

    checkEligibility = () => {
        if (this.props.user_type !== "organisation") {
            // this.setState({
            //     warning: {
            //         message: "Sorry, You need an organisation account to issue certificates.",
            //         type: "warning",
            //         learnmore: "create",
            //         autoclose: false,
            //     }
            // })
            window.location = "/create";
        } else {
            if (!localStorage.getItem('functionalTutorial_editor')) {
                window.location = "/tutorial";
            } else {
                window.location = "/issue";
            }
        }
    }

    render() {
        return (
            <div className={!this.state.minNav ? "sidenav" : "sidenav min-sidenav"}>
                <div className='mobile'>
                    <a href='../' className={this.props.selected === 'allDocuments' ? 'nav-ico active' : 'nav-ico'}><i className="fa-solid fa-house"></i><p>Home</p></a>
                    <a href='foo' className='nav-ico' onClick={this.toggleSearch}><i className="fa-solid fa-magnifying-glass"></i><p>Search</p></a>
                    {this.state.user_type === 'organisation' ?
                        <a href='/organisation' className={this.props.selected === 'myorganisation' ? 'nav-ico active' : 'nav-ico'}><i className="fa-solid fa-stamp"></i><p>Issue</p></a>
                        :
                        <a href='/create' className={this.props.selected === 'createorganisation' ? 'nav-ico active' : 'nav-ico'}><i className="fa-solid fa-stamp"></i><p>Issue</p></a>
                    }
                    <a href='foo' className='nav-ico' onClick={this.toggleMenu}><i className="fa-solid fa-bars"></i><p>Menu</p></a>
                </div>
                <div className="container scrollable" id='mobile-nav-modal'>
                    {/* <form method='POST' className='search'>
                        <input type='text' name='search' placeholder='Search' />
                        <button type='submit'><i className="fa-solid fa-magnifying-glass"></i></button>
                    </form> */}

                    <div className='container'>
                        {
                            this.organisationRender(this.props.user_type)
                        }
                        {this.state.currentlyOpen === 'personal' ?
                            <div className='module personal-module-js'>
                                <div className='profile'>
                                    <div className='user-sec'>
                                        <div className='icon tooltip-premium'>
                                            <span className="tooltiptext">Upgrade</span>
                                            <i className="fa-solid fa-user"></i>
                                            <div className={this.state.storage > 100 ? 'userbadge premium' : 'userbadge'} onClick={() => window.location = '/buy'}><i className="fa-solid fa-crown"></i></div>
                                        </div>
                                        {!this.state.minNav ?
                                            <div className='user-name'>
                                                <label>Personal</label>
                                                <h1><span>{this.props.name}</span></h1>
                                                <div className='plan' onClick={() => window.location = '/buy'}>{this.state.storage > 100 ? 'Pro' : 'Free'} <button>Upgrade&nbsp;Plan</button></div>
                                            </div>
                                            :
                                            <div className='plan' onClick={() => window.location = '/buy'}>{this.state.storage > 100 ? 'Pro' : 'Free'} <button>Upgrade&nbsp;Plan</button></div>
                                        }
                                    </div>
                                </div>
                                <a className={this.props.selected === 'allDocuments' ? 'nav selected' : 'nav'} href="/">
                                    <i className="fa-solid fa-award"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Received Certificates
                                            <label>All certificates you have received</label>
                                        </div>
                                    )}
                                    {this.state.sideNav_newCertificate && !this.state.minNav && (
                                        <div className='bell-icon'><i className="fa-solid fa-bell"></i></div>
                                    )}
                                </a>
                                <a className={this.props.selected === 'uploadedDocuments' ? 'nav selected' : 'nav'} href="/mydocuments">
                                    <i className="fa-regular fa-file-lines"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Uploaded Documents
                                            <label>Store other certificates here</label>
                                        </div>
                                    )}
                                </a>
                                <a className={this.props.selected === 'editProfile' ? 'nav selected' : 'nav'} href="/account">
                                    <i className="fa-solid fa-pen-to-square"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Manage Profile
                                        </div>
                                    )}
                                </a>
                            </div>
                            :
                            <div className='module personal-module-js' onClick={() => this.togglesubNav('personal')}>
                                <div className='profile'>
                                    <div className='user-sec'>
                                        <div className='icon tooltip-premium'>
                                            <span className="tooltiptext">Upgrade</span>
                                            <i className="fa-solid fa-user"></i>
                                            <div className={this.state.storage > 100 ? 'userbadge premium' : 'userbadge'} onClick={() => window.location = '/buy'}><i className="fa-solid fa-crown"></i></div>
                                        </div>
                                        {!this.state.minNav ?
                                            <div className='user-name'>
                                                <label>Personal</label>
                                                <h1><span>{this.props.name}</span></h1>
                                                <div className='plan' onClick={() => window.location = '/buy'}>{this.state.storage > 100 ? 'Pro' : 'Free'} <button>Upgrade&nbsp;Plan</button></div>
                                                {this.state.sideNav_newCertificate && !this.state.minNav && (
                                                    <div className='bell-icon'><i className="fa-solid fa-bell"></i></div>
                                                )}
                                            </div>
                                            :
                                            <div className='plan' onClick={() => window.location = '/buy'}>{this.state.storage > 100 ? 'Pro' : 'Free'} <button>Upgrade&nbsp;Plan</button></div>
                                        }
                                    </div>
                                </div>
                                {this.props.selected === 'allDocuments' && (
                                    <a className='nav selected' href="/">
                                        <i className="fa-solid fa-award"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Received Certificates
                                                <label>All certificates you have received</label>
                                            </div>
                                        )}
                                    </a>
                                )}
                                {this.props.selected === 'uploadedDocuments' && (
                                    <a className='nav selected' href="/mydocuments">
                                        <i className="fa-regular fa-file-lines"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Uploaded Documents
                                                <label>Store other certificates here</label>
                                            </div>
                                        )}
                                    </a>
                                )}
                                {this.props.selected === 'editProfile' && (
                                    <a className='nav selected' href="/account">
                                        <i className="fa-solid fa-pen-to-square"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Manage Profile
                                            </div>
                                        )}
                                    </a>
                                )}
                                <button className='toggle-sub-nav-btn'><i className="fa-solid fa-chevron-down"></i></button>
                            </div>
                        }
                        {this.state.currentlyOpen === 'signee' ?
                            <div className='module signee-module-js'>
                                <div className='profile'>
                                    <div className='user-sec'>
                                        <div className='icon tooltip-premium'>
                                            <i className="fa-solid fa-user-tie"></i>
                                        </div>
                                        {!this.state.minNav && (
                                            <div className='user-name'>
                                                <label>{this.props.user_data && JSON.parse(this.props.user_data.user_info) && JSON.parse(this.props.user_data.user_info).organisation_name ? JSON.parse(this.props.user_data.user_info).organisation_name : 'Your Organisation'}</label>
                                                <h1><span>Sign Certificates</span></h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* <a className={this.props.selected === 'mySignature' ? 'nav selected' : 'nav'} href="/mysignature">
                                    <i className="fa-solid fa-signature"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Upload Signature
                                            <label>All certificates you have received</label>
                                        </div>
                                    )}
                                </a> */}
                                <a className={this.props.selected === 'approvals' ? 'nav selected' : 'nav'} href="/approvals">
                                    <i className="fa-solid fa-file-circle-check"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Sign & Approve
                                            <label>All Requests to Sign Certificates</label>
                                        </div>
                                    )}
                                    {/* <div className='fg-1'></div>
                                    {this.state.approvals > 0 && (
                                        <i className="notification-bell fa-solid fa-bell"></i>
                                    )} */}
                                </a>
                                <a className={this.props.selected === 'approvalHistory' ? 'nav selected' : 'nav'} href="/approvalhistory">
                                    <i className="fa-solid fa-clock-rotate-left"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Approval History
                                            <label>Previously signed certificates</label>
                                        </div>
                                    )}
                                </a>
                                {/* <a className={this.props.selected === 'editProfile' ? 'nav selected' : 'nav'} href="/profile">
                                    <i className="fa-solid fa-pen-to-square"></i>
                                    {!this.state.minNav && ('Edit Profile')}
                                </a> */}
                                <a className={this.props.selected === 'pronetinESign' ? 'nav selected' : 'nav'} href="/e-sign">
                                    <i className="fa-solid fa-signature"></i>
                                    {!this.state.minNav && (
                                        <div className='description'>
                                            Pronetin e-Sign
                                            <label>Generate secured signatures</label>
                                        </div>
                                    )}
                                </a>
                            </div>
                            :
                            <div className='module signee-module-js' onClick={() => this.togglesubNav('signee')}>
                                <div className='profile'>
                                    <div className='user-sec'>
                                        <div className='icon tooltip-premium'>
                                            <i className="fa-solid fa-user-tie"></i>
                                        </div>
                                        {!this.state.minNav && (
                                            <div className='user-name'>
                                                <label>{this.props.user_data && JSON.parse(this.props.user_data.user_info) && JSON.parse(this.props.user_data.user_info).organisation_name ? JSON.parse(this.props.user_data.user_info).organisation_name : 'Your Organisation'}</label>
                                                <h1><span>Sign Certificates</span></h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.props.selected === 'mySignature' && (
                                    <a className='nav selected' href="/mysignature">
                                        <i className="fa-solid fa-signature"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Upload Signature
                                                <label>Upload your signature here</label>
                                            </div>
                                        )}
                                    </a>
                                )}
                                {this.props.selected === 'approvals' && (
                                    <a className='nav selected' href="/approvals">
                                        <i className="fa-solid fa-file-circle-check"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Sign & Approve
                                                <label>All Requests to Sign Certificates</label>
                                            </div>
                                        )}
                                    </a>
                                )}
                                {this.props.selected === 'approvalHistory' && (
                                    <a className='nav selected' href="/approvalhistory">
                                        <i className="fa-solid fa-clock-rotate-left"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Approval History
                                                <label>Previously signed certificates</label>
                                            </div>
                                        )}
                                    </a>
                                )}
                                {this.props.selected === 'pronetinESign' && (
                                    <a className='nav selected' href="/e-sign">
                                        <i className="fa-solid fa-signature"></i>
                                        {!this.state.minNav && (
                                            <div className='description'>
                                                Pronetin e-Sign
                                                <label>Generate secured signatures</label>
                                            </div>
                                        )}
                                    </a>
                                )}
                                <button className='toggle-sub-nav-btn'><i className="fa-solid fa-chevron-down"></i></button>
                            </div>
                        }
                        {this.props.user_type !== 'organisation' && (
                            <button className='btn-issue organisation-module-js' onClick={this.checkEligibility}><i className="fa-solid fa-paintbrush"></i> {!this.state.minNav && ('Create Certificate')}</button>
                        )}
                        {/* <div className='fg-1'></div> */}
                        <div className='min-sidenav-btn' onClick={this.toggleMinNav}>
                            {this.state.minNav ? <i className="fa-solid fa-angle-right"></i> : <i className="fa-solid fa-angle-left"></i>}
                        </div>
                        {/* {!this.state.minNav && (
                            <div className='copyright'>
                                <b>PRONETIN &copy; {new Date().getFullYear()}</b>
                                <p>Aerobits Developers Pvt. Ltd.</p>
                            </div>
                        )} */}

                        {
                            this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default SidenavComponent;