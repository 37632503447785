import React from 'react';
import ForUsers from '../components/landing/landing_features_users';
import ForOrganisation from '../components/landing/landing_features_organisation';
import ForHr from '../components/landing/landing_features_hr';
import ForSignee from '../components/landing/landing_features_signee';
import Pricing from '../components/landing/landing_pricing';
import Api from '../components/landing/landing_api';
import Quantum from '../components/landing/landing_quantum';
import Advertisements from '../components/landing/landing_advertisements';
import Events from '../components/landing/landing_events';
import ESigns from '../components/landing/landing_e-signs';
import Checkout from '../components/landing/landing_checkout';

class LandingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            randomType: 'features',
        };
    }

    toggleMenuNavigation = (state) => {
        this.setState({
            menu_navigation: state,
        });
    }

    renderPage = () => {
        switch (this.props.section) {
            case 'user':
                return (<ForUsers node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'organisation':
                return (<ForOrganisation node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'hr':
                return (<ForHr node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'signee':
                return (<ForSignee node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'pricing':
                return (<Pricing node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'api':
                return (<Api node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'post-quantum-certificates':
                return (<Quantum node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'advertisements':
                return (<Advertisements node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'events':
                return (<Events node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'e-signs':
                return (<ESigns node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            case 'checkout':
                return (<Checkout node_server_axios={this.props.node_server_axios} rzp_auth_key={this.props.rzp_auth_key} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
            default:
                return (<ForUsers node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="new-landing">
                    {
                        this.renderPage()
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default LandingComponent;