import React from 'react';
import './css/element.css';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href).hostname + `/certificate/${this.props.data.document_id}`,
            title: this.props.data.title,
            description: 'Document received for ' + this.props.data.event + ' on ' + this.props.data.date,
            moreShareOptions: false
        };
        this.containerRef = React.createRef();
        this.textElementRef = React.createRef();
    }

    openTile = () => {
        window.location = `/viewer/${this.props.data.document_id}`
    }

    toggleMoreShareOptions = () => {
        this.setState({
            moreShareOptions: !this.state.moreShareOptions,
        })
    }

    componentDidMount() {
        const container = this.containerRef.current;
        const textElement = this.textElementRef.current;

        if (textElement.scrollWidth > container.clientWidth) {
            textElement.classList.add('scrolling');
        } else {
            textElement.classList.remove('scrolling');
        }
    }

    formatNumberWithKAndM = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }

    render() {
        const document = this.props.data;
        //For trial countdown
        // const trial_period = 365;
        // const parts = document.date.split('/');
        // const year = parseInt(parts[2], 10);
        // const month = parseInt(parts[1], 10) - 1;
        // const day = parseInt(parts[0], 10);

        // let issue_date = new Date(year, month, day);
        // let current_date = new Date();
        // let trial = "Certificate has expired"
        // let days_left = trial_period - ((current_date.getTime() - issue_date.getTime()) / (1000 * 3600 * 24));
        // if (days_left > 0 && days_left < 365) {
        //     trial = "Expires in " + Math.floor(days_left) + " days";
        // }

        //Repurposed
        const parts = document.date.split('/');
        const year = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[0], 10);

        let issue_date = new Date(year, month, day);
        let current_date = new Date();
        let trial = "Received Just Now"
        let days_old = ((current_date.getTime() - issue_date.getTime()) / (1000 * 3600 * 24));

        if (days_old > 1) {
            if (days_old < 7) {
                trial = "Received " + Math.floor(days_old) + " day" + (Math.floor(days_old) > 1 ? "s" : "") + " ago";
            } else if (days_old < 30) {
                const weeks_old = Math.floor(days_old / 7);
                trial = "Received " + weeks_old + " week" + (weeks_old > 1 ? "s" : "") + " ago";
            } else if (days_old < 365) {
                const months_old = Math.floor(days_old / 30);
                trial = "Received " + months_old + " month" + (months_old > 1 ? "s" : "") + " ago";
            } else {
                const years_old = Math.floor(days_old / 365);
                trial = "Received " + years_old + " year" + (years_old > 1 ? "s" : "") + " ago";
            }
        }

        if (this.props.data.document_id !== null) {
            return (
                <div className='tileV2' onClick={this.openTile}>
                    <div className='name'>
                        <h1 ref={this.containerRef}><span ref={this.textElementRef}>{document.event}</span></h1>
                        <p>{document.title}</p>
                    </div>
                    <label>Received date : {document.date}</label>
                    <div className='organisation'>{document.issuer_name}</div>
                    <div className='bottom'>
                        <div className={document.view_type === "public" ? 'view-option public' : 'view-option private'} title={document.view_type}>
                            {document.view_type === "public" ? <i className="fa-solid fa-lock-open"></i> : <i className="fa-solid fa-lock"></i>}
                            {/* <div className='view'>{document.view_type}</div> */}
                        </div>
                        <div className='trial'><i className="fa-solid fa-stopwatch"></i> {trial}</div>
                        <div className='views'><i className="fa-solid fa-eye"></i> {this.formatNumberWithKAndM(document.view_count)}</div>
                    </div>
                    <button className='view-cert-v2-btn'>View certificate</button>
                </div>
            );
        } else {
            return (
                <div className='tileV2 grayed'>
                    <div className='name'>
                        <div className='view-option comingsoon'>
                            <div className='view'>Unlocking Soon</div>
                            <i className="fa-solid fa-lock"></i>
                        </div>
                        <h1 ref={this.containerRef}><span ref={this.textElementRef}>{document.event}</span></h1>
                    </div>
                    {/* <div className='organisation'>{document.issuer_name}</div> */}
                    <div className='bottom'>
                        <div className='trial'>{document.issuer_name} has scheduled this certificate to be unlocked automatically on {document.date}</div>
                    </div>
                </div>
            );
        }
    }
}

export default TileComponent;