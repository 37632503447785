import React from 'react';
import Header from '../components/header';

function App() {
    return (
        <div className='main'>
            <Header />
            <div className="privacy-policy">
                <h1>Terms of Service</h1>
                <p>Effective Date: 15th Oct 2023</p>
                <p>Thank you for choosing Pronetin. These Terms of Service ("Terms'') outline the rules and guidelines governing your use of our certificate distribution platform. Please read these Terms carefully and understand your rights and obligations when using our services.</p>
                <h2>1. Acceptance of Terms</h2>

                <p>By using Pronetin, you agree to comply with and be bound by these Terms. If you do not agree to these Terms,you may refrain yourself from using this platform.</p>

                <h2>2. Account Registration</h2>

                <p>2.1 Account Information: To use Pronetin, you may be required to create an account. You are responsible for providing accurate and up-to-date personal information during the registration process.</p>

                <p>2.2 Account Security: You are responsible for maintaining the confidentiality of your account credentials. You agree not to share your account information or use another user's account.</p>

                <h2>3. Use of the Platform</h2>

                <p>3.1 Purpose: Pronetin provides a platform for certificate distribution and receiving certificates. You agree to use our services for lawful purposes only.</p>

                <p>3.2 Compliance with Laws: You agree to comply with all applicable laws and regulations while using our platform.</p>

                <p>3.3 Prohibited Activities: You are prohibited from engaging in activities that may disrupt or interfere with the proper functioning of Pronetin or harm other users.</p>

                <h2>4. Data Privacy</h2>

                <p>4.1 Data Collection: Our data practices are outlined in our Privacy Policy. By using Pronetin, you consent to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy.</p>

                <p>4.2 Data Security: We take reasonable measures to protect your data. However, you are responsible for safeguarding any data you upload to the platform.</p>

                <h2>5. Intellectual Property</h2>

                <p>5.1 Ownership: Pronetin retains all rights to its intellectual property, including but not limited to the platform, its features, and content.</p>

                <p>5.2 User Content: By uploading content to Pronetin, you grant us a non-exclusive, royalty-free, worldwide licence to use, display, and distribute your content on our platform.</p>

                <h2>6. Termination</h2>

                <p>6.1 Termination by User: You may terminate your account at any time by following the provided account closure procedures.</p>

                <p>6.2 Termination by Pronetin: We reserve the right to suspend or terminate your account if you violate these Terms or engage in any activities that may harm Pronetin or its users.</p>

                <h2>7. Changes to the Terms</h2>

                <p>Pronetin reserves the right to modify these Terms at any time. We will notify users of any updates through the platform or via email or both. Your continued use of Pronetin after such changes constitutes your acceptance of the modified Terms.</p>

                <h2>8. Contact Information</h2>

                <p>If you have questions or concerns about these Terms or our platform, please contact us at help@pronetin.com.</p>

                <p>Thank you for using Pronetin!</p>
            </div>
        </div>
    );
}

export default App;
