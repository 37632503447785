import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import PLogo from '../images/static/pronetin-logo-P.png';

import WarningAction from './warning_action';
import ProgressLoader from './progress_loader';
import { QRCodeCanvas } from "qrcode.react";

import domtoimage from 'dom-to-image';
import download from 'downloadjs';

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            moreBtn: 'view created e-signs',
            progress_loader: false,
            toggleAdvanced: false,
            unrestricted: true,
            useimage: false,
            validity: false,
            esignModal: false,
            description: '',
            purpose: false,
            orgName: this.props.user_data.org_name ? this.props.user_data.org_name : '',
            designation: false,
            validityDifference: '',
            profileImage: false,
            QRData: false,
            QREmail: false,
            QROrgName: false,
            QRDesignation: false,
            accessTo: '',
            eSignatures: [],
            physicalSignature: true,
            invertSignature: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'create new',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'view created e-signs',
            });
        }
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    componentDidMount = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_signature', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                current_signature: res.data.signature,
            })
        });

        fetch("https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + Date.now())
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        profileImage: true,
                    })
                } else {
                    this.setState({
                        profileImage: false,
                    })
                }
            })

        axios.post(this.props.node_server_axios + 'get_esignatures').then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    eSignatures: res.data.eSignatures,
                    progress_loader: false,
                })
            }
        });
    }

    toggleAdvanced = () => {
        this.setState({
            toggleAdvanced: !this.state.toggleAdvanced,
        })
    }

    toggleSignatureUseBy = () => {
        this.setState({
            unrestricted: !this.state.unrestricted,
        })
    }

    toggleUseImage = () => {
        if (this.state.profileImage) {
            this.setState({
                useimage: !this.state.useimage,
            })
        } else {
            this.setState({
                warning: {
                    message: "You do not have a profile picture, you can set one in edit profile.",
                    type: "warning",
                    learnmore: 'account',
                    autoclose: false,
                }
            })
        }
    }

    calculateDifference = (inputDate) => {
        if (inputDate) {
            const currentDate = new Date();
            const inputDateObject = new Date(inputDate);
            const diffInTime = inputDateObject - currentDate;

            if (isNaN(diffInTime)) {
                this.setState({ difference: 'Invalid date' });
                return;
            }

            const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
            const diffInMonths = Math.floor(diffInDays / 30);
            const diffInYears = Math.floor(diffInMonths / 12);

            let displayDifference;
            if (diffInYears > 0) {
                const remainingMonths = diffInMonths % 12;
                const remainingDays = diffInDays % 30;
                displayDifference = `${diffInYears} years, ${remainingMonths} months and ${remainingDays} days`;
            } else if (diffInMonths > 0) {
                const remainingDays = diffInDays % 30;
                displayDifference = `${diffInMonths} months and ${remainingDays} days`;
            } else {
                displayDifference = `${diffInDays} days`;
            }

            return (displayDifference)
        } else {
            return ('0 days');
        }
    };

    updateValidity = (e) => {
        this.setState({
            validity: e.target.value,
            validityDifference: this.calculateDifference(e.target.value),
        })
    }

    toggleNewESignModal = (e) => {
        if (e.target.classList.contains('esign-modal')) {
            this.setState({
                esignModal: !this.state.esignModal,
            })
        } else {
            this.setState({
                esignModal: true,
            })
        }
    }

    toggleQRModal = (e) => {
        if (e.target.classList.contains('esign-modal')) {
            this.setState({
                QRData: false,
            })
        }
    }

    updateDescription = (e) => {
        this.setState({
            description: e.target.value,
        })
    }

    updatePurpose = (e) => {
        this.setState({
            purpose: e.target.value,
        })
    }

    updateOrgName = (e) => {
        this.setState({
            orgName: e.target.value,
        })
    }

    updateAccessList = (e) => {
        this.setState({
            accessTo: e.target.value,
        })
    }

    updateDesignation = (e) => {
        this.setState({
            designation: e.target.value,
        })
    }

    generateESignature = () => {
        this.setState({
            progress_loader: true,
        })
        let now = new Date(Date.now());
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let todaysDate = `${year}-${month}-${day}`;

        if (this.state.purpose && this.state.orgName && this.state.designation) {
            axios.post(this.props.node_server_axios + 'generate_esignature', {
                params: {
                    purpose: this.state.purpose,
                    org_name: this.state.orgName,
                    designation: this.state.designation,
                    use_by: this.state.unrestricted,
                    access_to: this.state.unrestricted ? '' : this.state.accessTo,
                    valid_upto: this.state.validity.toString(),
                    profile_icon: this.state.useimage,
                    description: this.state.description,
                    created_on: todaysDate,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    const newItem = {
                        created_on: todaysDate,
                        designation: this.state.designation,
                        email: this.props.email,
                        purpose: this.state.purpose,
                        org_name: this.state.orgName,
                        valid_upto: this.state.validity.toString(),
                        qr_id: res.data.qr_id,
                        use_by: this.state.unrestricted,
                    };
                    this.setState(prevState => ({
                        toggleAdvanced: false,
                        unrestricted: true,
                        useimage: false,
                        validity: false,
                        esignModal: false,
                        description: '',
                        purpose: false,
                        validityDifference: '',
                        profileImage: false,
                        progress_loader: false,
                        QRData: res.data.qr_id,
                        QREmail: this.props.email,
                        QRDesignation: this.state.designation,
                        QROrgName: this.state.orgName,
                        eSignatures: [...prevState.eSignatures, newItem],
                    }));
                } else if (res.data.message === 'limit reached') {
                    this.setState({
                        warning: {
                            message: "Upgrade your plan to create more e-Signatures.",
                            type: "warning",
                            learnmore: 'buy',
                            autoclose: false,
                        },
                        progress_loader: false,
                        QRData: false,
                        QREmail: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An Error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                        QRData: false,
                        QREmail: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Empty inputs detected. Please fill the form to generate Pronetin e-Sign",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
                QRData: false,
                QREmail: false,
            })
        }
    }

    togglePhysicalSignature = () => {
        this.setState({
            physicalSignature: !this.state.physicalSignature,
        })
    }

    toggleInvertSignature = () => {
        this.setState({
            invertSignature: !this.state.invertSignature,
        })
    }

    openDownloadQRModal = (email, id, designation, orgName) => {
        this.setState({
            QRData: id,
            QREmail: email,
            QRDesignation: designation,
            QROrgName: orgName
        })
    }

    downloadSignature = (id) => {
        this.setState({
            progress_loader: true,
        })
        const node = document.getElementById(id);

        domtoimage.toPng(node)
            .then((dataUrl) => {
                download(dataUrl, 'signature.png');
                this.setState({
                    warning: {
                        message: "Signature Downloaded.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                    QRData: false,
                    QREmail: false,
                })
            })
            .catch((error) => {
                this.setState({
                    warning: {
                        message: "Oops, something went wrong!",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            });
    }

    deleteESign = async (id, e) => {
        e.stopPropagation();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete e-Sign",
                subtext: "Once deleted this e-Sign will be lost forever. Do you want to Continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'delete_esignature', {
            params: {
                id: id
            }
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState(prevState => ({
                    warning: {
                        message: "Pronetin e-Sign Deleted!",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                    eSignatures: prevState.eSignatures.filter(item => item.qr_id !== id),
                }));
            } else {
                this.setState({
                    warning: {
                        message: "Oops, something went wrong!",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.esignModal && (
                    <div className='esign-modal' onClick={this.toggleNewESignModal}>
                        <div className='container'>
                            <div className='form'>
                                <h3>Create New</h3>
                                <div className='input'>
                                    <label>Purpose</label>
                                    <input type='text' placeholder='Granting Permission' onChange={this.updatePurpose} required />
                                </div>
                                <div className='input'>
                                    <label>Your Organisation Name</label>
                                    <input type='text' placeholder='Aerobits Developers Pvt Ltd.' defaultValue={this.state.orgName} onChange={this.updateOrgName} required />
                                </div>
                                <div className='input'>
                                    <label>Your Designation</label>
                                    <input type='text' placeholder='CEO' onChange={this.updateDesignation} required />
                                </div>
                                {this.state.toggleAdvanced ?
                                    <React.Fragment>
                                        <div className='input'>
                                            <label>Signature can be used by</label>
                                            <div className='input-wrapper'>
                                                <label className="switch">
                                                    <input type="checkbox" checked={this.state.unrestricted} onChange={this.toggleSignatureUseBy} />
                                                    <span className="slider"></span>
                                                </label>
                                                {this.state.unrestricted ?
                                                    <label>Anyone</label>
                                                    :
                                                    <label>the people metioned below</label>
                                                }
                                            </div>
                                            {!this.state.unrestricted && (<textarea placeholder='Name 1, Name 2, Name 3...' onChange={this.updateAccessList} value={this.state.accessTo}></textarea>)}
                                        </div>
                                        <div className='input'>
                                            <label>Valid upto</label>
                                            <input type='date' placeholder='Date' onChange={this.updateValidity} min={new Date().toISOString().split('T')[0]} />
                                        </div>
                                        <div className='input'>
                                            <label>Show your profile image</label>
                                            <div className='input-wrapper'>
                                                <label className="switch">
                                                    <input type="checkbox" checked={this.state.useimage} onChange={this.toggleUseImage} />
                                                    <span className="slider"></span>
                                                </label>
                                                {this.state.useimage ?
                                                    <label>Yes (your profile image will be public)</label>
                                                    :
                                                    <label>No</label>
                                                }
                                            </div>
                                        </div>
                                        <div className='input'>
                                            <label>Description</label>
                                            <textarea placeholder='description about what this signature is used for' onChange={this.updateDescription}></textarea>
                                        </div>
                                        <button className='advanced-btn' onClick={this.toggleAdvanced}>Close Advanced settings <i className="fa-solid fa-caret-up"></i></button>
                                    </React.Fragment>
                                    :
                                    <button className='advanced-btn' onClick={this.toggleAdvanced}>Advanced settings <i className="fa-solid fa-caret-down"></i></button>
                                }
                            </div>
                            <div className='preview'>
                                <h2>Preview <span>This is what will appear when your QR is scanned</span></h2>
                                <div className='wrapper'>
                                    <div className='user-icons'>
                                        {this.state.useimage && this.state.profileImage ?
                                            <img src={"https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + this.state.cacheTs} alt="profile icon"></img>
                                            :
                                            <i className="fa-solid fa-user"></i>
                                        }
                                        <i className="fa-solid fa-circle-check"></i>
                                    </div>
                                    <div className='basic'>
                                        <h2>{this.props.user_data.name}</h2>
                                        <label>{this.state.designation.length > 0 ? this.state.designation : 'Designation'}</label>
                                        <label>{this.state.orgName.length > 0 ? this.state.orgName : 'Organisation Name'}</label>
                                        <p>Purpose : {this.state.purpose.length > 0 ? <span>{this.state.purpose}</span> : <span>For Anything usefull</span>}</p>
                                        {this.state.unrestricted ?
                                            <div className='use'>this signature can be used by<span>Anyone can use</span>{this.state.validity && (<b>Expires in {this.state.validityDifference}</b>)}</div>
                                            :
                                            <div className='use'>this signature can be used by<span className='red'>Authorised Users <i className="fa-solid fa-arrow-right-long"></i></span>{this.state.validity && (<b>Expires in {this.state.validityDifference}</b>)}</div>
                                        }
                                    </div>
                                    {this.state.description.length > 0 && (
                                        <div className='basic advanced'>
                                            <div className='use'>description</div>
                                            {this.state.description}
                                        </div>
                                    )}
                                </div>
                                <div className='fg-1'></div>
                                <button className='submit' onClick={this.generateESignature}>Generate e-Sign <i className="fa-solid fa-arrow-right-long"></i></button>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.QRData && (
                    <div className='esign-modal' onClick={this.toggleQRModal}>
                        <div className='qr-container'>
                            <div className='input-wrapper'>
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.physicalSignature} onChange={this.togglePhysicalSignature} />
                                    <span className="slider"></span>
                                </label>
                                <label>Include physical signture</label>
                            </div>
                            <div className='input-wrapper'>
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.invertSignature} onChange={this.toggleInvertSignature} />
                                    <span className="slider"></span>
                                </label>
                                <label>Flip signature and QR position</label>
                            </div>
                            <div className='sub'>
                                <div className={!this.state.invertSignature ? 'download' : 'download invert'} id='vertical'>
                                    <div className={!this.state.invertSignature ? 'render vertical' : 'render vertical invert'}>
                                        {!this.state.physicalSignature ?
                                            <div className='details'>
                                                <label>Signed by</label>
                                                <h2>{this.props.user_data.name}</h2>
                                                <p>{this.state.QRDesignation}</p>
                                                <p>{this.state.QROrgName}</p>
                                            </div>
                                            :
                                            <div className='rectangle'>
                                                <span></span>
                                                <img src={this.state.current_signature} className="signature" alt="user signature" />
                                            </div>
                                        }
                                        <QRCodeCanvas value={"http://pronetin.in/e/" + this.state.QREmail + '/' + this.state.QRData} size={100} level={'M'} imageSettings={{ excavate: true }} />
                                    </div>
                                    <div className='qr-footer'>
                                        <img src={PLogo} alt='Pronetin Logo' />
                                        <p className='badge'><label>e-Sign by</label>Pronetin</p>
                                    </div>
                                </div>
                                <button onClick={() => this.downloadSignature('vertical')}>Download <i className="fa-regular fa-circle-down"></i></button>
                            </div>
                            <div className='sub'>
                                <div className={!this.state.invertSignature ? 'download horizontal' : 'download horizontal invert'} id='horizontal'>
                                    <div className={!this.state.invertSignature ? 'render horizontal' : 'render horizontal invert'}>
                                        {!this.state.physicalSignature ?
                                            <div className='details'>
                                                <label>Signed by</label>
                                                <h2>{this.props.user_data.name}</h2>
                                                <p>{this.state.QRDesignation}</p>
                                                <p>{this.state.QROrgName}</p>
                                            </div>
                                            :
                                            <div className='rectangle'>
                                                <span></span>
                                                <img src={this.state.current_signature} className="signature" alt="user signature" />
                                            </div>
                                        }
                                        <QRCodeCanvas value={"http://pronetin.in/e/" + this.state.QREmail + '/' + this.state.QRData} size={100} level={'M'} imageSettings={{ excavate: true }} />
                                    </div>
                                    <div className='qr-footer'>
                                        <img src={PLogo} alt='Pronetin Logo' />
                                        <p className='badge'><label>e-Sign by</label>Pronetin</p>
                                    </div>
                                </div>
                                <button onClick={() => this.downloadSignature('horizontal')}>Download <i className="fa-regular fa-circle-down"></i></button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Pronetin e-Sign<label>Signature digitalized with authentication</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav show'>
                                <h1>Current Signature</h1>
                                {this.state.current_signature ?
                                    <React.Fragment>
                                        <div className='approvals-signature'>
                                            <img src={this.state.current_signature} className="user-signature" alt="user signature" />
                                            <a href='./mysignature'>Change Signature</a>
                                        </div>
                                        <button onClick={this.toggleNewESignModal} className="new-esign-btn"><i className="fa-solid fa-file-circle-plus"></i> Create New e-Sign</button>
                                        <p><b>{this.state.eSignatures && this.state.eSignatures.length > 0 ? this.state.eSignatures.length : 0}/5</b> Used. <a href='/buy'>Upgrade</a> for generating more e-signs</p>
                                    </React.Fragment>
                                    :
                                    <div className='approvals-signature'>
                                        <a href='./mysignature'>Upload Signature</a>
                                    </div>
                                }
                            </div>

                            <div className='eSignatures'>
                                {this.state.eSignatures && this.state.eSignatures.map((element, index) => {
                                    return (
                                        <div className='wrapper' onClick={() => this.openDownloadQRModal(element.email, element.qr_id, element.designation, element.org_name)} key={index}>
                                            <div className='bg-download'>Download <i className="fa-regular fa-circle-down"></i></div>
                                            <div className='qr'>
                                                <QRCodeCanvas value={"http://pronetin.in/e/" + element.email + '/' + element.qr_id} size={100} level={'M'} imageSettings={{ excavate: true }} />
                                            </div>
                                            <div className='content'>
                                                <h2>{element.purpose}</h2>
                                                <p>Created on {element.created_on}</p>
                                                {element.use_by ? <i className="fa-solid fa-lock" title='Resricted Use'></i> : <i className="fa-solid fa-lock-open" title='Anyone can use'></i>}
                                                <p>Expiry <b>{element.valid_upto === 'false' ? 'Lifetime' : element.valid_upto}</b></p>
                                                <button onClick={(e) => this.deleteESign(element.qr_id, e)} title='delete'><i className="fa-regular fa-trash-can"></i></button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;