import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';
import MainImage from '../../images/home/Solutions/Post Quantum/Post Quantum certificate.png';
import InfoImage from '../../images/home/Solutions/Post Quantum/pqc @ pronetin.png';
import Image1 from '../../images/home/Solutions/Post Quantum/pqc why.png';
import Image2 from '../../images/home/Solutions/Post Quantum/pqc benefits.png';
import Image3 from '../../images/home/Solutions/Post Quantum/pqc getstarted.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='solution intro'>
                            <div className='content'>
                                <h1>Post Quantum Certificate</h1>
                                <h2>Enhance Security with Post Quantum Certificates from Pronetin</h2>
                                <p>In today's digital age, ensuring the authenticity and security of certificates is paramount. Pronetin proudly introduces Post Quantum Certificates, a cutting-edge solution designed to withstand the evolving threats of the digital landscape.</p>
                                <div className='btn-container'>
                                    <button className='alt' onClick={() => window.location = '/help'}>Request a demo</button>
                                    <button onClick={() => window.location = '/signin'}>Explore</button>
                                </div>
                            </div>
                            <div className='rep'>
                                <img src={MainImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution info'>
                            <div className='content'>
                                <h2>What Are Post Quantum Certificates?</h2>
                                <p>Post Quantum Certificates leverage advanced cryptographic techniques to offer unparalleled security. As quantum computing progresses, traditional encryption methods face potential vulnerabilities. Our Post Quantum Certificates are specifically engineered to resist these future threats, providing long-term security for your certificates.</p>
                            </div>
                            <div className='rep'>
                                <img src={InfoImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>Why Choose Pronetin's Post Quantum Certificates?</h2>
                                <ul>
                                    <li><strong>Future-Proof Security</strong>: Our certificates are built to withstand quantum computing threats, ensuring your data remains secure even as technology advances.</li>
                                    <li><strong>Enhanced Authenticity</strong>: Each certificate is embedded with unique cryptographic signatures, making them tamper-proof and easily verifiable.</li>
                                    <li><strong>Seamless Integration</strong>: Pronetin's platform allows for easy creation, distribution, and management of Post Quantum Certificates, ensuring a smooth user experience.</li>
                                    <li><strong>Trust and Credibility</strong>: Enhance your organisation’s reputation by adopting the latest in security technology, demonstrating your commitment to protecting sensitive information.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image1} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution alt'>
                            <div className='content'>
                                <h2>Benefits of Post Quantum Certificates</h2>
                                <ul>
                                    <li><strong>Unmatched Protection</strong>: Safeguard your certificates against the emerging threats posed by quantum computing.</li>
                                    <li><strong>Boosted Trust</strong>: Assure recipients and stakeholders of the highest level of security and authenticity.</li>
                                    <li><strong>Simplified Management</strong>: Manage all your certificates efficiently through Pronetin’s intuitive platform.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image2} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>How to Get Started</h2>
                                <p>
                                    Adopting Post Quantum Certificates with Pronetin is straightforward. Simply sign up on our platform and enjoy the peace of mind that comes with state-of-the-art security.<br></br>
                                    <strong>Join Pronetin today</strong> and future-proof your certificates with our Post Quantum technology. Experience the next level of security and trust with Pronetin, your partner in secure certificate management.
                                </p>
                            </div>
                            <div className='rep'>
                                <img src={Image3} alt='pronetin solutions' />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;