import React from 'react';
import Signin from '../components/signin';
import Header from '../components/header';

import '../components/css/main.css';

const SigninComponent = (props) => {
    return (
        <React.Fragment>
            <div className="signin signin-v2">
                <Header signup={true} />
                <Signin node_server_axios={props.node_server_axios} />
            </div>
        </React.Fragment>
    );

};

export default SigninComponent;