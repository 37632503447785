import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/batch_list_v2';
import ElementMobile from '../components/batch_list_new';
import Loading from '../components/loading';

import '../components/css/main.css';

const SubEvents = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user');
    const [orgId, setOrgId] = useState('');
    const [storage, setStorage] = useState('');
    const [header_user_data, setHeaderUserData] = useState(null);
    let { slug } = useParams();
    let { subevent } = useParams();
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [mobileViewRender, setMobileViewRender] = useState(false);

    const handleWindowResize = () => {
        if (window.innerWidth < 820) {
            setMobileViewRender(true);
        } else {
            setMobileViewRender(false);
        }
    };

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                if (res.data.org_id.length < 2) {
                    window.location = '/';
                } else {
                    setLoading(false);
                    setName(res.data.name);
                    setEmail(res.data.email);
                    setUserType(res.data.user_type);
                    setOrgId(res.data.org_id);
                    setStorage(res.data.storage);
                    setHeaderUserData(res.data);
                }
            } else {
                window.location = '/signin';
            }
        }).catch(() => {
            window.location = '/signin';
        });

        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [props.node_server_axios]);

    const handleDocumentLoaded = (e) => {
        setDocumentLoaded(e)
    }

    if (loading) {
        return (
            <React.Fragment>
                <div className="main">
                    <Header />
                    <Loading />
                </div>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div className="main">
                <Header login={true} user_data={header_user_data} email={email} node_server_axios={props.node_server_axios} documentLoaded={documentLoaded} />
                <div className='body-wrapper'>
                    <SideNav name={name} selected="myorganisation" user_type={userType} node_server_axios={props.node_server_axios} storage={storage} />
                    {mobileViewRender ?
                        <ElementMobile email={email} user_type={userType} org_id={orgId} eventName={slug.replace(/-/g, ' ')} subeventName={subevent.replace(/-/g, ' ')} node_server_axios={props.node_server_axios} documentLoaded={handleDocumentLoaded} />
                        :
                        <Element email={email} user_type={userType} org_id={orgId} eventName={slug.replace(/-/g, ' ')} subeventName={subevent.replace(/-/g, ' ')} node_server_axios={props.node_server_axios} documentLoaded={handleDocumentLoaded} />
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default SubEvents;