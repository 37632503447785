import React from 'react';
import { useDropzone } from 'react-dropzone';

const PdfUpload = ({ onPdfUpload }) => {
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            return;
        }
        if (acceptedFiles.length > 0) {
            const pdfFiles = acceptedFiles.filter(file => file.type === 'application/pdf');
            if (pdfFiles.length > 0) {
                onPdfUpload(pdfFiles);
            }
        }
    };

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        },
        maxFiles: 1
    });

    const isFileRejected = fileRejections.length > 0;

    return (
        <div
            {...getRootProps()}
            style={{
                border: '2px dashed #157EFB',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                backgroundColor: isDragActive ? '#e9e9e9' : '#157EFB19',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the PDF file here ...</p>
            ) : isFileRejected ? (
                <p>Only one file allowed</p>
            ) : (
                <p><i className="fa-regular fa-folder"></i> Drag 'n' drop PDF file to upload here, or click to select file</p>
            )}
        </div>
    );
};

export default PdfUpload;
