import React from 'react';
import '../css/element.css';
import axios from 'axios';
import JohnDoe from '../../images/signature-image-john-doe.png'

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            font: false,
            image: this.props.data,
            signee_signature: '',
            signee_name: this.props.signee_name ? this.props.signee_name : '',
        };
    }

    componentDidMount = () => {
        axios.defaults.withCredentials = true;
        if (this.props.node_server_axios !== undefined && this.state.image.signee_email) {
            axios.post(this.props.node_server_axios + 'get_signee_signature', {
                params: {
                    signee_email: this.state.image.signee_email,
                    batch_id: this.props.batch_id,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        signee_signature: res.data.signee_signature,
                        signee_name: res.data.signee_name,
                    })
                }
            });
        }
    }

    render() {
        return (
            <div className="box wrap viewer-wrap" style={{ top: this.state.image.y + "px", left: this.state.image.x + "px", mixBlendMode: "exclusion", filter: "invert(100%)" }}>
                <div className={this.state.image.shape !== undefined ? 'imagearea-no-hover ' + this.state.image.shape : 'imagearea-no-hover'}
                    style={{
                        resize: "none",
                        transformOrigin: 'center', transform: `rotate(${this.state.image.angle}deg)`,
                        overflow: "visible",
                    }}
                >
                    {!this.props.hide_signature && (
                        <div className='sign-container'>
                            <div className='img-placeholder'>
                                <img src={this.state.signee_signature === "" ? JohnDoe : this.state.signee_signature} alt="sign in certificate"></img>
                            </div>
                            <h1>{this.state.signee_name === "" ? (this.props.signee_id ? "Signee " + this.props.signee_id : "John Doe") : this.state.signee_name}</h1>
                            <h2>{this.state.image.signee_designation}</h2>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ElementComponent;