import React from 'react';
import axios from 'axios';

import Warning from '../warning';
import WarningAction from '../warning_action';

import ProgressLoader from '../../components/progress_loader';
import newDoc from '../../images/docs/Pronetin Authorisation Letter.docx';

class DataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            file: null,
            verification_file: null,
            progress_loader: false,
            verified: false,
            noAccess: true,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    handleVerificationFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            this.setState({ verification_file: file });
        } else {
            this.setState({
                warning: {
                    message: "Invalid file format. Only PDF supported",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        }
    };

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    handleVerificationSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            progress_loader: true,
        })
        var imageTimestamp = Date.now();
        const formData = new FormData();
        if (this.state.verification_file) {
            (formData.append("file", this.state.verification_file, imageTimestamp + '.pdf'));
            axios.post(this.props.node_server_axios + 'upload_verification_file', formData, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            }).then(res => {
                if (res.data.message !== 'success') {
                    this.setState({
                        warning: {
                            message: res.data.message,
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else {
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '0',
                            content: "Verification documents for organisation submitted",
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------
                    this.setState({
                        warning: {
                            message: 'Verification document uploaded',
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        verification_file: null,
                        verified: 'pending',
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Please choose a pdf file to upload",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    };

    componentDidMount = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'organisation_info', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.length > 0) {
                this.setState({
                    verified: res.data[0].verified,
                    noAccess: false,
                })
            } else {
                if (this.props.user_type === 'organisation') {
                    this.setState({
                        noAccess: true,
                    })
                }
            }
        });

    }

    downloadFormat = () => {
        // Determine the platform (Windows or Mac)
        const isWindows = navigator.platform.toUpperCase().includes('WIN');

        // Change the file extension based on the platform
        const fileExtension = isWindows ? 'docx' : 'pages';
        const fileName = `Pronetin Authorisation Letter.${fileExtension}`;

        // Create an anchor element
        const link = document.createElement('a');
        link.href = newDoc;
        link.download = fileName;
        // Append the anchor to the body
        document.body.appendChild(link);
        // Trigger the click event on the anchor
        link.click();
        // Clean up by removing the anchor
        document.body.removeChild(link);
    }

    render() {
        return (
            <React.Fragment>
                <h1><span>Verification </span><i className="fa-solid fa-circle-check"></i></h1>
                <p>Manage your organisation and personal account verification.</p>
                <div className='account-container'>
                    <div className='container'>
                        <h2>Organisation Verification</h2>
                        {this.state.noAccess ?
                            <p className='small-font'>Only admin has access to organisation verification</p>
                            :
                            this.state.verified !== 'verified' ?
                                <div className='verification-container'>
                                    {this.state.verified !== 'pending' ? <p className='warn'>This organisation is not yet verified</p> : <p className='warn'>Your account will be verified in 1-3 business days after analysis of the document submitted</p>}
                                    <p>Verification will provide</p>
                                    <ul>
                                        <li>Pronetin Verified status to all issued certificate</li>
                                        <li>Provides validity to certificate</li>
                                        <li>Organisation Name will not have duplicates</li>
                                    </ul>
                                    {this.state.verified !== 'pending' && (<p className='info'>To verify your organisation please upload the incorporation certificate / Authorisation Letter from head of the institution (.pdf)</p>)}
                                    <button id='format_download' onClick={this.downloadFormat}><i className="fa-solid fa-download"></i> Download Authorisation Letter Format</button>
                                    {this.state.verified !== 'pending' && (
                                        <div className='row-container'>
                                            <input type='file' name='incorporation_certificate' accept="application/pdf" onChange={this.handleVerificationFileChange} />
                                            <button type='submit' onClick={this.handleVerificationSubmit}>Upload Document</button>
                                        </div>
                                    )}
                                    <p className='info'>in case of any help required reach us at email: <a href='mailto:help@pronetin.in'> help@pronetin.in</a></p>
                                    <p className='info'>If there is any difference in the document submitted or for any other clarifications, we will be communicating with you via {this.props.email}</p>
                                </div>
                                :
                                <div className='verified-doc-badge'>
                                    <i className="fa-solid fa-building-circle-check"></i> Pronetin Verified Organisation
                                </div>
                        }
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default DataComponent;