import './css/loading.css';
import React from 'react';

class ProgressLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tips: [
                'All your designs can be seen in saved designs',
                'Go to Issue Certificate to send certificates',
                'All Collected feedbacks are given within batches',
                'Request to change name from recepients is handled at Action Center',
                'You can learn more about the platform by visiting Help Center'
            ],
            randomElement: null
        };
    }

    componentDidMount = () => {
        const randomIndex = Math.floor(Math.random() * this.state.tips.length);
        const randomElement = this.state.tips[randomIndex];
        this.setState({ randomElement });
    }

    render() {
        const { randomElement } = this.state;
        return (
            <div className="progress-loader" style={this.props.opaque ? { backdropFilter: 'blur(25px)' } : { backdropFilter: 'none' }}>
                <div className='container'>
                    <p className='loading-text'>Loading...</p>
                    <div className="loader-p">
                        <div className="loaderBar"></div>
                    </div>
                    {this.props.message ?
                        <div className='tips'>
                            {this.props.message}
                        </div>
                        :
                        <div className='tips'>
                            <i className="fa-regular fa-lightbulb"></i> {randomElement && (randomElement)}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ProgressLoader;