import React from 'react';
import axios from 'axios';

import Warning from '../warning';
import WarningAction from '../warning_action';

class DataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            total_used_file_size: 0,
            document_count: 0,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'uploaded_document_list', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                document_count: res.data.length > 0 ? res.data.length : 0,
                total_used_file_size: res.data.length > 0 ? res.data.reduce((sum, item) => sum + item.file_size, 0) : 0,
            })
        });
        axios.post(this.props.node_server_axios + 'get-user-embedd-data', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    embedd_data: res.data.data,
                })
            }
        });
        axios.post(this.props.node_server_axios + 'get_saved_templates', {
            params: {
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved templates') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                    })
                }
            }
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_signature', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                current_signature: res.data.signature,
            })
        });
    }

    deleteSignature = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Signature",
                subtext: "Once deleted this signature cannot be recovered. Continue to delete?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'upload_signature', {
            params: {
                email: this.props.email,
                signature: '',
            },
        }).then(res => {
            window.location.replace("/mysignature?o=deleted");
        });
    }

    deleteSavedTemplate = async (save_id) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Permanently Delete",
                subtext: "Once deleted saved template cannot be recovered, Shared users added to this will also be removed.",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'delete_saved_templates', {
            params: {
                email: this.props.email,
                save_id: save_id,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                        warning: {
                            message: "Saved design removed.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            }
        })
    }

    render() {
        let percent_storage_used = Math.floor((this.state.total_used_file_size / (this.props.storage * 1024)) * 100);
        percent_storage_used = percent_storage_used > 0 ? percent_storage_used : 0;
        let css_level = Math.floor(100 - percent_storage_used);
        let saved_percentage = 0;
        let css_saved_percentage = 0;
        if (this.state.savedTemplates) {
            saved_percentage = Math.floor((this.state.savedTemplates.length / 6) * 100);
            css_saved_percentage = 100 - saved_percentage;
        }

        return (
            <React.Fragment>
                <h1><span>Data </span>& Other info</h1>
                <p>Manage your data, signature and sharing options.</p>
                <div className='account-container'>
                    <div className='container'>
                        <h2>Storage Info</h2>
                        <p className='small-font'>See how you have used the storage available in the platform.</p>
                        <div className='column-container'>
                            <p>Uploaded Documents</p>
                            <div className='storage-bar'>
                                <div className='neg-bar' style={{ "--storage-var": css_level + "%" }}></div>
                            </div>
                            <p className='small-font'>Used {Math.floor(this.state.total_used_file_size / 1024)}Mb of {this.props.storage}Mb Storage</p>
                        </div>
                        <div className='column-container'>
                            <p>Received Certificates</p>
                            {
                                this.state.embedd_data ?
                                    <div>
                                        <p className='small-font'> Appreciations Received : {this.state.embedd_data.appreciations_received}</p>
                                        <p className='small-font'> Certificates Received : {this.state.embedd_data.certificates_received}</p>
                                    </div>
                                    :
                                    <p className='small-font'>No data found</p>
                            }
                        </div>
                        <div className='column-container'>
                            <p>Saved Designs</p>
                            {
                                this.props.user_type === 'organisation' ?
                                    <React.Fragment>
                                        <div className='storage-bar'>
                                            <div className='neg-bar' style={{ "--storage-var": css_saved_percentage + "%" }}></div>
                                        </div>
                                        <p className='small-font'>Used {saved_percentage}% of available storage slots</p>
                                    </React.Fragment>
                                    :
                                    <p className='small-font'>Available only for organisers</p>
                            }
                        </div>
                        <div className='fg-1'></div>
                    </div>
                    <div className='container'>
                        <h2>Other Info</h2>
                        <p className='small-font'>Your activity in Pronetin.</p>
                        <div className='column-container'>
                            <p>Personal</p>
                            {
                                this.state.embedd_data ?
                                    <div>
                                        <p className='small-font'> Designed Certificates : {this.state.embedd_data.certificates_designed}</p>
                                        <p className='small-font'> Signed Batches : {this.state.embedd_data.certificates_signed}</p>
                                        <p className='small-font'> Issued Batches : {this.state.embedd_data.certificates_issued}</p>
                                    </div>
                                    :
                                    <p className='small-font'>No data found</p>
                            }
                        </div>
                        <div className='column-container'>
                            <p>Uploads</p>
                            <p className='small-font'> Documents : {this.state.document_count}</p>
                        </div>

                        <div className='column-container'>
                            <p>Account Type</p>
                            <p className='small-font'> {this.props.user_type} account</p>
                        </div>
                        <div className='fg-1'></div>
                    </div>
                    <div className='container'>
                        <h2>Signature Data</h2>
                        <p className='small-font'>Your signature is used to sign certificates with your concent.</p>
                        <div className='column-container'>
                            <p>Sign</p>
                            {this.state.current_signature ?
                                <img src={this.state.current_signature} className="user-signature" alt="user signature" />
                                :
                                <p className='small-font'>No signature found</p>
                            }
                        </div>
                        <div className='column-container'>
                            <p>Privacy Policy</p>
                            <p className='small-font'>The privacy policy for uploading a signature states that the signature will only be used for the intended purpose of signing the certificates sent via Pronetin when approved by the owner of the signature. It also ensures that the signature will not be used or shared without the user's explicit consent.</p>
                            {this.state.current_signature ?
                                <button className='secondary-btn' onClick={this.deleteSignature}><i className="fa-regular fa-trash-can"></i>Delete Signature</button>
                                :
                                <button className='secondary-btn' onClick={() => window.location.href = './mysignature'}><i className="fa-solid fa-arrow-up-from-bracket"></i> Upload Signature</button>
                            }
                        </div>
                        <div className='fg-1'></div>
                    </div>
                    <div className='container full'>
                        <h2>Sharing</h2>
                        {this.props.user_type === 'organisation' && (
                            <button className="secondary-btn" onClick={() => window.location.href = './mydesign'}>Go To My designs <i className="fa-solid fa-chevron-right"></i></button>
                        )}
                        <p className='small-font'>See who has access to your designs.</p>
                        <div className='grid-wrap'>
                            {this.state.savedTemplates && (
                                this.state.savedTemplates.map((item, index) => (
                                    <div className='column-container' key={index}>
                                        <p>Design {index + 1}</p>
                                        <div key={index} className='sharing-container'>
                                            <div className='saved-design'>
                                                <button className="secondary-btn red" onClick={() => this.deleteSavedTemplate(item.save_id)}><i className="fa-solid fa-trash"></i> Delete Design</button>
                                                <img src={item.draft_bg.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + item.draft_bg + '.png' : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + item.draft_bg + '.png'} alt='certificate template'></img>
                                            </div>
                                            <div className='shared-to'>
                                                <p>Shared to</p>
                                                {item.shared_to ?
                                                    item.shared_to.map((user, i) => (
                                                        <p className='small-font blue' key={i}><i className="fa-solid fa-share"></i> {user}</p>
                                                    ))
                                                    :
                                                    <p className='small-font'>This design is not yet shared</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment>
        );
    }
}

export default DataComponent;