import React from 'react';
import Element from '../components/landing_new';

class LandingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            user_type: "user",
            menu_navigation: false,
        }
    }

    toggleMenuNavigation = (state) => {
        this.setState({
            menu_navigation: state,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="new-landing">
                    <Element node_server_axios={this.props.node_server_axios} menu_navigation={this.state.menu_navigation} toggleMenuNavigation={this.toggleMenuNavigation} />
                </div>
            </React.Fragment>
        );
    }
}

export default LandingComponent;