import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';
import Draggable from 'react-draggable';

import domtoimage from 'dom-to-image';
import download from 'downloadjs';

import ViewElement from './viewer/editor';
import ViewImage from './viewer/image';
import ViewTable from './viewer/table';
import ViewSignee from './viewer/signee';
import ViewQR from './viewer/qr';

import Warning from '../components/warning';
import ProgressLoader from '../components/progress_loader';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            sliderValue: 3,
            sliderClass: "slider-3",
            pinchStartDistance: null,
            initialSliderValue: 1,
            elements: [],
            certView: false,
            view_type: '',
            QRSize: 65,
            resetX: 0,
            resetY: 0,
            cert_attachment: null,
            scaleWidth: 1,
            scaleFactor: 1,
            moreBtn: 'more',
            cacheTs: Date.now(),
            progress_loader: false,
            hideFixedControls: false,
            percentage_progress: 0,
        };
        this.imageDivRef = React.createRef();
        this.certDivRef = React.createRef();
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        this.setState({
            progress_loader: true,
            progress_opaque: true,
            percentage_progress: 10,
        })
        axios.post(this.props.node_server_axios + 'get_appreciation', {
            params: {
                documentId: this.props.documentId,
            },
        }).then(async (res) => {
            this.setState({
                percentage_progress: 30,
            });
            if (res.data !== undefined) {
                if (res.data.message === 'certificate found') {

                    var myElement = document.querySelector('.cropper-rel');
                    var width = myElement.offsetHeight > myElement.offsetWidth ? myElement.offsetWidth : myElement.offsetHeight;

                    const updatedData = JSON.parse(res.data.elements);
                    let tableVariables = []
                    if (updatedData.filter(element => element.type === "table").length > 0) {
                        tableVariables = updatedData.filter(element => element.type === "table")[0].content.join("|").match(/\[.*?\]/g)
                    }
                    let variableIndexElements = updatedData.filter(element => element.type !== "image" && element.type !== "signature" && element.type !== "QR" && element.content.includes("[")).map(element => ({ type: element.type, variables: element.content.match(/\[.*?\]/g) !== null ? element.content.match(/\[.*?\]/g).concat(tableVariables) : element.content.match(/\[.*?\]/g) }))
                    let variablesLocal = variableIndexElements.map(element => element.variables)
                    let mergedVariablesLocal = [].concat.apply([], variablesLocal);
                    let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
                    uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);
                    this.setState({
                        elements: updatedData,
                        variables: uniqueVariablesLocal,
                        org_id: res.data.org_id,
                        cert_bg: res.data.cert_bg,
                        csvData: JSON.parse(res.data.csvData),
                        certView: true,
                        accepted: res.data.accepted,
                        event_name: res.data.event_name,
                        issue_date: res.data.issue_date,
                        pronetin_verified: res.data.pronetin_verified,
                        signees: res.data.signees,
                        org_name: res.data.org_name,
                        cert_title: res.data.cert_title,
                        cert_attachment: res.data.cert_attachment,
                        domain: res.data.domain,
                        contact: res.data.contact,
                        percentage_progress: 60,
                    })

                    const img = new Image();
                    const imgPromise = new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                    img.src = "https://pronetin-new.s3.amazonaws.com/batch/00000000-0000-0000-0000-000000000000/" + encodeURIComponent(res.data.cert_bg);

                    try {
                        await imgPromise;
                        let cWidth = 904;
                        let cHeight = 640;

                        if (img.naturalWidth > img.naturalHeight) {
                            cWidth = 904;
                            cHeight = 640;
                        } else if (img.naturalWidth < img.naturalHeight) {
                            cWidth = 640;
                            cHeight = 904;
                        } else {
                            console.log("Invalid Size");
                        }
                        this.setState({
                            certWidth: cWidth,
                            certHeight: cHeight,
                            scaleWidth: ((width - 20) / cHeight).toFixed(2),
                            scaleFactor: myElement.offsetHeight > myElement.offsetWidth ? ((width - 20) / cWidth).toFixed(2) : ((width - 20) / cHeight).toFixed(2),
                            percentage_progress: res.data.view_type !== 'public' ? false : 90,
                            progress_loader: res.data.view_type !== 'public' || this.state.percentage_progress === false ? false : true,
                            progress_opaque: res.data.view_type !== 'public' || this.state.percentage_progress === false ? false : true,
                        })
                    } catch (error) {
                        console.log("Error loading image:", error);
                    }
                }
            }
        })
    }

    completedLoad = () => {
        this.setState({
            progress_loader: false,
            progress_opaque: false,
            percentage_progress: false,
        })
    }

    componentDidUpdate = () => {
        if (this.state.initialX === undefined || this.state.initialY === undefined) {
            let imgDivWidth = 0;
            let imgDivHeight = 0;
            let certDivWidth = 0;
            let certDivHeight = 0;
            if (this.imageDivRef.current) {
                imgDivWidth = this.imageDivRef.current.clientWidth;
                imgDivHeight = this.imageDivRef.current.clientHeight;
            }
            if (this.certDivRef.current) {
                certDivWidth = parseInt(this.certDivRef.current.clientWidth * this.state.scaleFactor);
                certDivHeight = parseInt(this.certDivRef.current.clientHeight * this.state.scaleFactor);
            }
            let initialX = (imgDivWidth - certDivWidth) < 0 ? 0 : (imgDivWidth - certDivWidth)
            let initialY = (imgDivHeight - certDivHeight) < 0 ? 0 : (imgDivHeight - certDivHeight)
            if (this.imageDivRef.current && this.certDivRef.current && this.state.scaleFactor !== 1) {
                this.setState({
                    initialX: initialX / 2,
                    initialY: initialY / 2,
                    resetX: initialX / 2,
                    resetY: initialY / 2,
                })
            }
        }
    }

    downloadCertificate = async (event) => {
        event.preventDefault();
        this.setState({
            resetX: 0,
            resetY: 0,
            progress_loader: true,
            progress_opaque: true,
            cacheTs: Date.now(),
        })
        const node = document.getElementById('capture');

        // Calculate total width and height of the element
        const elementWidth = node.offsetWidth;
        const elementHeight = node.offsetHeight;

        // Scroll the element into view
        node.scrollIntoView();

        // Adjust the size of the captured element
        const originalWidth = node.style.width;
        const originalHeight = node.style.height;
        node.style.width = elementWidth + 'px';
        node.style.height = elementHeight + 'px';

        // Capture the screenshot
        domtoimage
            .toPng(node)
            .then(async (dataUrl) => {
                await download(dataUrl, 'certificate.png');
                // Restore original size
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            })
            .catch((error) => {
                console.error('Error occurred while trying to download the image', error);
                // Restore original size in case of error
                node.style.width = originalWidth;
                node.style.height = originalHeight;
                this.setState({
                    progress_loader: false,
                    resetX: this.state.initialX,
                    resetY: this.state.initialY,
                })
            });
    };

    updateZoom = (newSliderValue) => {
        this.setState({
            sliderValue: newSliderValue,
            sliderClass: "slider-" + newSliderValue
        })
    }

    updateZoomInput = (e) => {
        this.setState({
            sliderValue: e.target.value,
            sliderClass: "slider-" + e.target.value
        })
    }

    handlePinchStart = (e) => {
        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            this.setState({
                pinchStartDistance: distance,
                initialSliderValue: this.state.sliderValue,
            });
        }
    };

    handlePinchMove = (e) => {
        if (e.touches.length === 2) {
            const [touch1, touch2] = e.touches;
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            const zoomFactor = distance / this.state.pinchStartDistance;
            const newSliderValue = parseInt(this.state.initialSliderValue * zoomFactor);

            // Limit the zoom to a specific range (e.g., between 1 and 7)
            if (newSliderValue >= 1 && newSliderValue <= 7) {
                this.updateZoom(newSliderValue);
            }
        }
    };

    handlePinchEnd = () => {
        this.setState({
            pinchStartDistance: null,
        });
    };

    handleCertDragStop = (e, dragElement) => {
        this.setState({
            resetX: dragElement.x,
            resetY: dragElement.y,
        })
    };

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        // var wrapperElem = document.getElementsByClassName('wrapper')[0];
        if (subNav.style.display !== 'flex') {
            subNav.style.display = 'flex';
            // wrapperElem.style.display = 'none';
            this.setState({
                moreBtn: 'back',
                hideFixedControls: true,
            })
        } else {
            subNav.style.display = 'none';
            // wrapperElem.style.display = 'flex';
            this.setState({
                moreBtn: 'more',
                hideFixedControls: false,
            })
        }
    }

    recenterCertificate = () => {
        this.setState({
            resetX: this.state.initialX,
            resetY: this.state.initialY,
            sliderValue: 3,
            sliderClass: "slider-3",
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="element full-element public">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Issued Document<label>public</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                        </div>
                        <div className='tiles marketplace pg-certficate_viewer'>
                            <div className='sub-nav'>
                                <h1><i className="fa-solid fa-circle-info"></i> About</h1>
                                {this.state.accepted === 'true' ?
                                    <div className='nav-container'>
                                        <div className='info-container'>
                                            <h3>Document Information</h3>
                                            <p>Title : {this.state.cert_title}</p>
                                            <p>Event : {this.state.event_name}</p>
                                            {this.state.event_name !== 'Onboarding' && (<p>Issue Date : {this.state.issue_date}</p>)}
                                        </div>
                                        <div className='info-container'>
                                            <h3>Signed By</h3>
                                            <p>CEO, Pronetin</p>
                                        </div>
                                        <a className='nav' href="foo" onClick={this.downloadCertificate}>
                                            <i className="fa-solid fa-download"></i>
                                            Download
                                        </a>
                                        <div className='info-container'>
                                            <h3>Issuer Information</h3>
                                            <p>Issuer : {this.state.org_name}</p>
                                        </div>
                                        {/* <a className='nav' href={"https://" + this.state.domain} target="_blank" rel="noreferrer">
                                            <i className="fa-solid fa-link"></i>
                                            Website
                                        </a>
                                        <a className='nav' href={"mailto:" + this.state.contact} target="_blank" rel="noreferrer">
                                            <i className="fa-regular fa-envelope"></i>
                                            Contact
                                        </a> */}
                                        <a className='nav' href={"https://pronetin.com"} target="_blank" rel="noreferrer">
                                            <i className="fa-solid fa-link"></i>
                                            Website
                                        </a>
                                        <a className='nav' href={"mailto:help@pronetin.com"} target="_blank" rel="noreferrer">
                                            <i className="fa-regular fa-envelope"></i>
                                            Contact
                                        </a>
                                    </div>
                                    :
                                    <div className='nav-container'>
                                        <p>Certificate not yet accepted</p>
                                    </div>
                                }
                            </div>
                            <div className='wrapper'>
                                <button className='recenter-certificate' onClick={this.recenterCertificate}><i className="fa-solid fa-arrows-to-circle"></i></button>
                                <div className='cropper-rel'>
                                    {this.state.certView && (
                                        <div className='image-container'
                                        onTouchStart={this.handlePinchStart}
                                        onTouchMove={this.handlePinchMove}
                                        onTouchEnd={this.handlePinchEnd}
                                        ref={this.imageDivRef}
                                        >
                                            <div className={this.state.sliderClass} style={{ '--scale-width': this.state.scaleFactor }}>
                                                <Draggable position={{ x: this.state.resetX, y: this.state.resetY }} onStop={this.handleCertDragStop}>
                                                    <div className='cert-container' id="capture" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', width: this.state.certWidth, height: this.state.certHeight }}>
                                                        <img src={"https://pronetin-new.s3.amazonaws.com/batch/00000000-0000-0000-0000-000000000000/" + encodeURIComponent(this.state.cert_bg) + "?cacheblock=" + this.state.cacheTs} crossOrigin="anonymous" alt='certificate template' onLoad={this.completedLoad}></img>
                                                        <div className='elements of-hidden' ref={this.certDivRef}>
                                                            {this.state.elements && this.state.elements.length ?
                                                                this.state.elements.map((element, index) => {
                                                                    if (element.type === "image") {
                                                                        return <ViewImage element_id={index} key={element.timeStamp + "-" + index} data={element} />
                                                                    } else if (element.type === "table") {
                                                                        return <ViewTable element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else if (element.type === "signature") {
                                                                        return <ViewSignee element_id={index} key={element.timeStamp + "-" + index} data={element} node_server_axios={this.props.node_server_axios} />
                                                                    } else if (element.type !== "QR") {
                                                                        return <ViewElement element_id={index} key={element.timeStamp + "-" + index} data={element} variables={this.state.variables} csvData={this.state.csvData} />
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })
                                                                : ""}
                                                            <ViewQR data={this.props.documentId} size={this.state.QRSize} position={this.state.elements.find(element => element.type === "QR")} />
                                                        </div>
                                                    </div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!this.state.hideFixedControls && (
                                <div className="mob-actions mobile-only fixed-controls">
                                    {this.state.accepted === 'true' ?
                                        <div className='nav-container'>
                                            <a className='nav' href="foo" onClick={this.downloadCertificate}>
                                                <i className="fa-solid fa-download"></i>
                                                Download
                                            </a>
                                            {/* <a className='nav' href={"https://" + this.state.domain} target="_blank" rel="noreferrer">
                                                <i className="fa-solid fa-link"></i>
                                                Website
                                            </a>
                                            <a className='nav' href={"mailto:" + this.state.contact} target="_blank" rel="noreferrer">
                                                <i className="fa-regular fa-envelope"></i>
                                                Contact
                                            </a> */}
                                            <a className='nav' href={"https://pronetin.com"} target="_blank" rel="noreferrer">
                                                <i className="fa-solid fa-link"></i>
                                                Website
                                            </a>
                                            <a className='nav' href={"mailto:help@pronetin.com"} target="_blank" rel="noreferrer">
                                                <i className="fa-regular fa-envelope"></i>
                                                Contact
                                            </a>
                                        </div>
                                        :
                                        <div className='nav-container'>
                                            <p>Certificate not yet accepted</p>
                                        </div>
                                    }
                                </div>
                                 )}
                                <div className='cropper-zoom cert-viewer'>
                                    <div className='container'>
                                        <p>Zoom</p>
                                        <input type="range" min="1" max="7" className="slider" value={this.state.sliderValue} onChange={this.updateZoomInput}></input>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} message={this.state.percentage_progress > 0 ? this.state.percentage_progress + "% Loaded" : false} />)
                }
            </React.Fragment>
        );
    }
}

export default CertificateViewerComponent;