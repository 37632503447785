import React from 'react';
import axios from 'axios';

class DraftQuickNavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            certTemplate: null,
            savedTemplates: [],
        };
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_draft', {
            params: {
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'draft') {
                    this.setState({
                        certTemplate: res.data.draft_bg !== null ? res.data.draft_bg : null,
                    })
                }
            }
        })
        axios.post(this.props.node_server_axios + 'get_saved_templates', {
            params: {
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'saved templates') {
                    this.setState({
                        savedTemplates: res.data.saved_templates,
                    })
                }
            }
        })
    }

    editMode = (save_id) => {
        let url = window.location.href;
        window.location.href = url.substring(0, url.lastIndexOf('/') + 1) + "edit/" + save_id
    }

    toggleDraftNav = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    render() {
        const { isOpen } = this.state;
        return (
            <React.Fragment>
                <div className={`filter ${isOpen ? 'open' : ''}`} onClick={this.toggleDraftNav}>
                    <i className="fa-solid fa-pen"></i> Draft
                </div>
                <div className={`filter-modal ${isOpen ? 'open' : ''}`}>
                    <button className='hide-btn' onClick={this.toggleDraftNav}>Close <i className="fa-solid fa-arrow-right-long"></i></button>
                    <div className='container'>
                        {this.state.certTemplate !== null ?
                            <div className='draft-tile'>
                                <img src={this.state.certTemplate.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + this.state.certTemplate + '.png' : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + this.state.certTemplate + '.png'} alt='certificate template'></img>
                                <div className='shimmer-bar b1'></div>
                                <div className='shimmer-bar b2'></div>
                                <div className='shimmer-bar b3'></div>
                                <button className='continue-btn' onClick={() => window.location = './issue'}>Continue Editing</button>
                            </div>
                            :
                            <p>No draft available</p>
                        }
                        <h2><i className="fa-regular fa-floppy-disk"></i> Saved Templates</h2>
                        {this.state.savedTemplates !== null && this.state.savedTemplates.length > 0 && (
                            this.state.savedTemplates.map((element, index) =>
                                <div className='saved-tile' key={index}>
                                    <img src={element.draft_bg.includes("_") ? 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/ai/background/' + element.draft_bg + '.png' : 'https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/' + element.draft_bg + '.png'} alt='certificate template'></img>
                                    <button onClick={() => this.editMode(element.save_id)}><i className="fa-regular fa-edit"></i> Edit</button>
                                </div>
                            ))}
                    </div>
                    <p>{this.state.savedTemplates !== null ? this.state.savedTemplates.length : 0}/3 Saved slots Used</p>
                </div>
            </React.Fragment>
        );
    }
}

export default DraftQuickNavComponent;