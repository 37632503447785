import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
            yearlyPayment: true,
        };
    }

    toggleAnually = () => {
        this.setState({
            yearlyPayment: !this.state.yearlyPayment,
        });
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>We have plans for you</h2>
                            <p>never miss an advantage of our platform</p>
                        </div>
                        <div className='pricing'>
                            <div className='toggle' onClick={this.toggleAnually}>
                                <p className={!this.state.yearlyPayment ? 'active' : ''}>Monthly</p>
                                <i className="fa-solid fa-arrow-right-arrow-left"></i>
                                <p className={this.state.yearlyPayment ? 'active' : ''}>Annually <span className='offer'>15% off</span></p>
                            </div>
                            <div className='container'>
                                <div className='wrapper'>
                                    <h2>Free <span><b>₹</b>0</span></h2>
                                    <div className='pointers'>
                                        <p><b>-</b> Free Email templates</p>
                                        <p><b>-</b> Free 130 Pronetin credits</p>
                                        <p><b>-</b> Issue up to 120 certificates for free</p>
                                        <p><b>-</b> Customer support via Email</p>
                                    </div>
                                    <a href='#foo' data='compare' onClick={this.handleAnchorClick}>All features</a>
                                    <div className='subscribe'>
                                        <button onClick={() => window.location = '/signin'}>Start free</button>
                                    </div>
                                </div>
                                <div className='wrapper premium'>
                                    <h2>Premium <span><b>₹</b>{this.state.yearlyPayment ? 850 : 999}<i>/mo.</i><label>billed {this.state.yearlyPayment ? "yearly" : "monthly"}</label></span></h2>
                                    <div className='pointers'>
                                        <p><b>-</b> Free 530 credits/month</p>
                                        <p><b>-</b> Issue up to 520 certificates</p>
                                        <p><b>-</b> Collaborate design with up to 4 organisation members per design</p>
                                        <p><b>-</b> Premium support</p>
                                    </div>
                                    <a href='#foo' data='compare' onClick={this.handleAnchorClick}>All features</a>
                                    <div className='subscribe'>
                                        <button onClick={() => window.location = this.state.yearlyPayment ? '/checkout?plan=pro-y' : 'checkout?plan=pro'}>Subscribe</button>
                                    </div>
                                </div>
                                <div className='wrapper business'>
                                    <h2>Business <span><b>₹</b>{this.state.yearlyPayment ? 2462 : 2897}<i>/mo.</i><label>billed {this.state.yearlyPayment ? "yearly" : "monthly"}</label></span></h2>
                                    <div className='pointers'>
                                        <p><b>-</b> Free 1530 credits/month</p>
                                        <p><b>-</b> Issue up to 1520 certificates</p>
                                        <p><b>-</b> Collaborate design with up to 8 organisation members</p>
                                        <p><b>-</b> Scheduled Onboarding & Training</p>
                                    </div>
                                    <a href='#foo' data='compare' onClick={this.handleAnchorClick}>All features</a>
                                    <div className='subscribe'>
                                        <button onClick={() => window.location = this.state.yearlyPayment ? '/checkout?plan=business-y' : 'checkout?plan=business'}>Subscribe</button>
                                    </div>
                                </div>
                                <div className='wrapper'>
                                    <h2>Enterprise <span><b>₹</b>{this.state.yearlyPayment ? 4250 : 5000}<i>/mo.</i><label>billed {this.state.yearlyPayment ? "yearly" : "monthly"}</label></span></h2>
                                    <div className='pointers'>
                                        <p><b>-</b> Free 2530 credits/month</p>
                                        <p><b>-</b> Issue up to 2520 certificates</p>
                                        <p><b>-</b> Collaborate design with 10+ organisation members</p>
                                        <p><b>-</b> Get pronetin designers for design assistance</p>
                                    </div>
                                    <a href='#foo' data='compare' onClick={this.handleAnchorClick}>All features</a>
                                    <div className='subscribe'>
                                        <button onClick={() => window.location = this.state.yearlyPayment ? '/checkout?plan=enterprise-y' : 'checkout?plan=enterprise'}>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='heading' id='compare'>
                            <h2>All Features</h2>
                            <p>per plan</p>
                        </div>
                        <div className='comparison'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th className='head'>Features</th>
                                        <th className='head'>Free <p><b>₹</b>0<span>/month</span></p></th>
                                        <th className='head'><label>most popular</label> Premium <p><b>₹</b>{this.state.yearlyPayment ? 850 : 999}<span>/month</span></p></th>
                                        <th className='head'><label className='golden'>best value</label> Business <p><b>₹</b>{this.state.yearlyPayment ? 2462 : 2897}<span>/month</span></p></th>
                                        <th className='head'>Enterprise <p><b>₹</b>{this.state.yearlyPayment ? 4250 : 5000}<span>/month</span></p></th>
                                    </tr>
                                    <tr><td></td><td></td><td></td><td></td><td></td></tr>
                                    <tr>
                                        <td>Premium Certificate Templates</td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Joining Bonus Credits</td>
                                        <td><p><span className='yes'>130</span></p></td>
                                        <td><p><span className='yes'>130</span></p></td>
                                        <td><p><span className='yes'>130</span></p></td>
                                        <td><p><span className='yes'>130</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Monthly Pronetin Credits (credits/month)</td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='yes'>530</span></p></td>
                                        <td><p><span className='yes'>1530</span></p></td>
                                        <td><p><span className='yes'>2530</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Certificate Count (Upto)</td>
                                        <td><p><span className='yes'>120</span></p></td>
                                        <td><p><span className='yes'>530</span></p></td>
                                        <td><p><span className='yes'>1530</span></p></td>
                                        <td><p><span className='yes'>2530</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Save Design Slots</td>
                                        <td><p><span className='yes'>3</span></p></td>
                                        <td><p><span className='yes'>6</span></p></td>
                                        <td><p><span className='yes'>6</span></p></td>
                                        <td><p><span className='yes'>6</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Design Collaboration (Users)</td>
                                        <td><p><span className='yes'>2</span></p></td>
                                        <td><p><span className='yes'>4</span></p></td>
                                        <td><p><span className='yes'>8</span></p></td>
                                        <td><p><span className='yes'>10</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Design Assistance</td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Video Call Support</td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Onboarding & Training</td>
                                        <td><p><span className='no'>no</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                        <td><p><span className='yes'>yes</span></p></td>
                                    </tr>
                                    <tr>
                                        <td>Per Credit Price</td>
                                        <td><p><span className='yes'>4</span></p></td>
                                        <td><p><span className='yes'>4</span></p></td>
                                        <td><p><span className='yes'>3</span></p></td>
                                        <td><p><span className='yes'>2</span></p></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><button className='free' onClick={() => window.location = '/signin'}>Start free</button></td>
                                        <td><button onClick={() => window.location = this.state.yearlyPayment ? '/checkout?plan=pro-y' : 'checkout?plan=pro'}>Subscribe</button></td>
                                        <td><button onClick={() => window.location = this.state.yearlyPayment ? '/checkout?plan=business-y' : 'checkout?plan=business'}>Subscribe</button></td>
                                        <td><button onClick={() => window.location = this.state.yearlyPayment ? '/checkout?plan=enterprise-y' : 'checkout?plan=enterprise'}>Subscribe</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;