import Cropper from 'react-easy-crop'
import React, { useState, useCallback } from "react";
import axios from 'axios';

import ProgressLoader from '../../components/progress_loader';

const Demo = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [data, setData] = useState(null)
    const [progress_loader, setProgressLoader] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setData(croppedAreaPixels)
    }, [])

    const onUploadCrop = async () => {
        setProgressLoader(true);
        const upload_image = await resizeImage(props.signature_image, data.width, data.height, data.x, data.y)

        const sizeInBytes = getBase64Size(upload_image);
        const sizeInKB = sizeInBytes / 1024;

        if (sizeInKB < 500) {
            axios.defaults.withCredentials = true;
            axios.post(props.node_server_axios + 'upload_signature', {
                params: {
                    email: props.email,
                    signature: upload_image
                },
            }).then(res => {
                window.location.href = "/mysignature?o=uploaded";
            });
        } else {
            window.location.replace("/mysignature?o=toolarge");
        }
    }

    function getBase64Size(base64String) {
        const stringLength = base64String.length;
        const paddingLength = (base64String.match(/=+$/) || [''])[0].length;
        const byteLength = (stringLength - paddingLength) * 0.75 - paddingLength;
        return byteLength;
    }

    function resizeImage(url, width, height, x, y) {
        return new Promise((resolve, reject) => {
            var canvas = document.createElement("canvas");
            var context = canvas.getContext('2d');
            var imageObj = new Image();

            // set canvas dimensions

            canvas.width = width;
            canvas.height = height;

            imageObj.onload = function () {
                context.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
                resolve(canvas.toDataURL());
            };

            imageObj.src = url;
        })
    }

    return (
        <div className='container'>
            <Cropper
                image={props.signature_image}
                crop={crop}
                zoom={props.zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
            />
            <button onClick={onUploadCrop}>Upload Signature</button>
            {
                progress_loader && (<ProgressLoader />)
            }
        </div>
    )
}

export default Demo