import React from 'react';
// import logo from '../images/static/Group 11.png';
import PLogo from '../images/home/logo.png';

class LandingFooter extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            randomType: 'features',
        };
    }

    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className='footer-new'>
                        <div className='company'>
                            <div className='logo pc-only'>
                                <img src={PLogo} alt='logo' />
                            </div>
                            <div className='wrap'>
                                <div className='for-mobile'>
                                    <div className='logo'>
                                        <img src={PLogo} alt='logo' />
                                    </div>
                                    <div className='company-details'>
                                        <h2>PRONETIN</h2>
                                        <p>Certificate authorisation and distribution of digital certificates</p>
                                    </div>
                                </div>
                                <div className='btns'>
                                    <button className='signin-btn' onClick={() => window.location = '/signin'}>Get Started <i className="fa-solid fa-angles-right"></i></button>
                                    <button className='signin-btn' onClick={() => window.location = '/signup'}>Sign Up</button>
                                </div>
                                <a href='mailto: help@pronetin.com' className='normal'>email : help@pronetin.com</a>
                            </div>
                        </div>
                        <div className='wrap'>
                            <h3>Quick Links</h3>
                            <a href='/features/user'>Features</a>
                            <a href='/pricing'>Pricing</a>
                            <a href='/home#testimonials'>Testimonials</a>
                        </div>
                        <div className='wrap'>
                            <h3>Others</h3>
                            <a href='/tos'>Terms Of Service</a>
                            <a href='/privacypolicy'>Privacy Policy</a>
                            <a href='/help'>Contact Us</a>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
};

export default LandingFooter;