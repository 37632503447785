import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

import audit from '../../images/home/Features/Signee/audit trail.png';
import oneclick from '../../images/home/Features/Signee/one click signing.png';
import authorization from '../../images/home/Features/Signee/checking profiles to sign.png';
import controlled from '../../images/home/Features/Signee/controlled signing.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        const { activeAnchor } = this.state;

        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>For Signee</h2>
                            <p>build your identity with pronetin</p>
                        </div>
                        <div className='features-page'>
                            <div className='bg-pattern'></div>
                            <div className='navigation'>
                                <a href='#foo' className={activeAnchor === 'n1' ? 'active' : ''} data='n1' onClick={this.handleAnchorClick}>Controlled Signature Use</a>
                                <a href='#foo' className={activeAnchor === 'n3' ? 'active' : ''} data='n3' onClick={this.handleAnchorClick}>Informed Authorization</a>
                                {/* <a href='#foo' className={activeAnchor === 'n5' ? 'active' : ''} data='n5' onClick={this.handleAnchorClick}></a> */}
                                <a href='#foo' className={activeAnchor === 'n2' ? 'active' : ''} data='n2' onClick={this.handleAnchorClick}>One-Click Signing</a>
                                <a href='#foo' className={activeAnchor === 'n4' ? 'active' : ''} data='n4' onClick={this.handleAnchorClick}>Audit Trail</a>
                                {/* <a href='#foo' className={activeAnchor === 'n6' ? 'active' : ''} data='n6' onClick={this.handleAnchorClick}></a> */}
                            </div>
                            <div className='container'>
                                <div className='col'>
                                    <div className='wrapper' id='n1'>
                                        <div className='image'><img src={controlled} alt="controlled" /></div>
                                        <div className='content'>
                                            <h3>Controlled Signature Use</h3>
                                            <p>Maintain full control over the use of your signature. Unlike traditional methods where signatures are shared and can be misused, Pronetin ensures that signing authorities do not need to share their signatures with anyone.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n3'>
                                        <div className='image'><img src={authorization} alt="authorization" /></div>
                                        <div className='content'>
                                            <h3>Informed Authorization</h3>
                                            <p>Review all the details of certificates before authorising them, ensuring informed consent.</p>
                                        </div>
                                    </div>
                                    {/* <div className='wrapper' id='n5'>
                                        <div className='image'></div>
                                        <div className='content'>
                                            <h3></h3>
                                            <p></p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='col'>
                                    <div className='wrapper' id='n2'>
                                        <div className='image'><img src={oneclick} alt="oneclick" /></div>
                                        <div className='content'>
                                            <h3>One-Click Signing</h3>
                                            <p>Easily sign multiple certificates or multiple events at once with just a single click, saving time and effort.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n4'>
                                        <div className='image'><img src={audit} alt="audit" /></div>
                                        <div className='content'>
                                            <h3>Audit Trail</h3>
                                            <p>Maintain a complete audit trail of all signed certificates for transparency and accountability.</p>
                                        </div>
                                    </div>
                                    {/* <div className='wrapper' id='n6'>
                                        <div className='image'></div>
                                        <div className='content'>
                                            <h3></h3>
                                            <p></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;