import React from 'react';
import logo from '../images/static/Group 11.png';
import PLogo from '../images/home/logo.png';

class LandingHeader extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            menu_navigation: false,
            navElem: false,
        };
    }

    toggleMenuNavigation = () => {
        this.setState({
            menu_navigation: !this.state.menu_navigation,
            navElem: false,
        })
    }

    navElem = (elem) => {
        this.setState({
            navElem: elem,
        })
    }

    isActive = (href) => {
        return window.location.pathname === href ? 'active' : '';
    }

    render() {
        return (
            <React.Fragment>
                <header className="header main-header">
                    <a className='logo' href='/'><img className='logo' src={logo} alt='Pronetin' /></a>
                    <div className='container'>
                        <b className='nav-elem'><label>Features</label>
                            <div className='drop-down'>
                                <div className='left'>
                                    <h3>About Pronetin</h3>
                                    <p>Learn what pronetin offers to connect organisation and users</p>
                                </div>
                                <div className='right'>
                                    <h2>Features</h2>
                                    <a href='/features/user'>For Users</a>
                                    <a href='/features/organisation'>For Organisation</a>
                                    <a href='/features/hr'>For Hiring / HR</a>
                                    <a href='/features/signee'>For Signee</a>
                                </div>
                            </div>
                        </b>
                        <a href='/pricing'><label>Pricing</label></a>
                        <b className='nav-elem'><label>Solutions</label>
                            <div className='drop-down'>
                                <div className='left'>
                                    <h3>Credential Management</h3>
                                    <p>Across industries, from education and healthcare to corporate and beyond</p>
                                </div>
                                <div className='right'>
                                    <h2>Solutions</h2>
                                    <a href='/solutions/post-quantum-certificates'>Post Quantum certificate</a>
                                    <a href='/solutions/advertisements'>Advertisements</a>
                                    <a href='/solutions/events'>Events</a>
                                    <a href='/solutions/e-signs'>Pronetin e-Signatures</a>
                                </div>
                            </div>
                        </b>
                        <b className='nav-elem'><label>Resources</label>
                            <div className='drop-down'>
                                <div className='left'>
                                    <h3>Learning Hub</h3>
                                    <p>Complete Guidance through the possibilities of the platform</p>
                                </div>
                                <div className='right'>
                                    <h2>Resources</h2>
                                    <a href='/help' target='_blank'>Help Center</a>
                                    <a href='/help' target='_blank'>Schedule a demo</a>
                                    <a href='/resources/api'>API documentation</a>
                                    <a href='https://www.youtube.com/@PronetinYT' rel='noreferrer' target='_blank'>Video tutorials</a>
                                </div>
                            </div>
                        </b>
                        <button className='signin-btn' onClick={() => window.location = '/signin'}>SignIn</button>
                        <button className='menu-nav-btn' onClick={this.toggleMenuNavigation}>{!this.state.menu_navigation ? <i className="fa-solid fa-bars"></i> : <i className="fa-solid fa-x"></i>}</button>
                    </div>
                </header>
                <div className={this.state.menu_navigation ? 'menu-nav-container active' : 'menu-nav-container'}>
                    <div className='menu-nav-new'>
                        <div className='logo-container'>
                            <a className='logo' href='/'><img className='logo' src={PLogo} alt='Pronetin' /></a>
                            <button className='menu-close-btn' onClick={this.toggleMenuNavigation}><i className="fa-solid fa-x"></i></button>
                        </div>
                        <div className='container'>
                            <b className='nav-elem' onClick={() => this.navElem('features')}><label>Features {this.state.navElem === 'features' ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-right"></i>}</label>
                                {this.state.navElem === 'features' && (
                                    <div className='drop-down'>
                                        <div className='left'>
                                            <h3>About Pronetin</h3>
                                            <p>Learn what pronetin offers to connect organisation and users</p>
                                        </div>
                                        <div className='right'>
                                            <a href='/features/user' className={this.isActive('/features/user')}>For Users</a>
                                            <a href='/features/organisation' className={this.isActive('/features/organisation')}>For Organisation</a>
                                            <a href='/features/hr' className={this.isActive('/features/hr')}>For Hiring / HR</a>
                                            <a href='/features/signee' className={this.isActive('/features/signee')}>For Signee</a>
                                        </div>
                                    </div>
                                )}
                            </b>
                            <a href='/pricing'><label>Pricing</label></a>
                            <b className='nav-elem' onClick={() => this.navElem('solutions')}><label>Solutions {this.state.navElem === 'solutions' ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-right"></i>}</label>
                                {this.state.navElem === 'solutions' && (
                                    <div className='drop-down'>
                                        <div className='left'>
                                            <h3>Credential Management</h3>
                                            <p>Across industries, from education and healthcare to corporate and beyond</p>
                                        </div>
                                        <div className='right'>
                                            <a href='/solutions/post-quantum-certificates' className={this.isActive('/solutions/post-quantum-certificates')}>Post Quantum certificate</a>
                                            <a href='/solutions/advertisements' className={this.isActive('/solutions/advertisements')}>Advertisements</a>
                                            <a href='/solutions/events' className={this.isActive('/solutions/events')}>Events</a>
                                            <a href='/solutions/e-signs' className={this.isActive('/solutions/e-signs')}>Pronetin e-Signatures</a>
                                        </div>
                                    </div>
                                )}
                            </b>
                            <b className='nav-elem' onClick={() => this.navElem('resources')}><label>Resources {this.state.navElem === 'resources' ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-right"></i>}</label>
                                {this.state.navElem === 'resources' && (
                                    <div className='drop-down'>
                                        <div className='left'>
                                            <h3>Learning Hub</h3>
                                            <p>Complete Guidance through the possibilities of the platform</p>
                                        </div>
                                        <div className='right'>
                                            <a href='/help' target='_blank'>Help Center</a>
                                            <a href='/help' target='_blank'>Schedule a demo</a>
                                            <a href='/resources/api' className={this.isActive('/resources/api')}>API documentation</a>
                                            <a href='https://www.youtube.com/@PronetinYT' rel='noreferrer' target='_blank'>Video tutorials</a>
                                        </div>
                                    </div>
                                )}
                            </b>
                        </div>
                        <button className='signin-btn' onClick={() => window.location = '/signup'}>SignUp</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

export default LandingHeader;