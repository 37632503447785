import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

import bulk from '../../images/home/Features/Organization/bulk distribution.png';
import customize from '../../images/home/Features/Organization/customizable design.png';
import analytics from '../../images/home/Features/Organization/analytics and reporting.png';
import followup from '../../images/home/Features/Organization/automated followups.png';
import requests from '../../images/home/Features/Organization/manage admin.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        const { activeAnchor } = this.state;

        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>For Organisation</h2>
                            <p>build your identity with pronetin</p>
                        </div>
                        <div className='features-page'>
                            <div className='bg-pattern'></div>
                            <div className='navigation'>
                                <a href='#foo' className={activeAnchor === 'n1' ? 'active' : ''} data='n1' onClick={this.handleAnchorClick}>Bulk Certificate Distribution</a>
                                <a href='#foo' className={activeAnchor === 'n3' ? 'active' : ''} data='n3' onClick={this.handleAnchorClick}>Customizable Certificate Designs</a>
                                <a href='#foo' className={activeAnchor === 'n5' ? 'active' : ''} data='n5' onClick={this.handleAnchorClick}>Analytics and Reporting</a>
                                <a href='#foo' className={activeAnchor === 'n2' ? 'active' : ''} data='n2' onClick={this.handleAnchorClick}>Automated Follow-Ups</a>
                                <a href='#foo' className={activeAnchor === 'n4' ? 'active' : ''} data='n4' onClick={this.handleAnchorClick}>Manage Admins and Requests</a>
                                {/* <a href='#foo' className={activeAnchor === 'n6' ? 'active' : ''} data='n6' onClick={this.handleAnchorClick}></a> */}
                            </div>
                            <div className='container'>
                                <div className='col'>
                                    <div className='wrapper' id='n1'>
                                        <div className='image'><img src={bulk} alt="bulk" /></div>
                                        <div className='content'>
                                            <h3>Bulk Certificate Distribution</h3>
                                            <p>Easily design, create, and distribute certificates to thousands of participants in just a few clicks.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n3'>
                                        <div className='image'><img src={customize} alt="customize" /></div>
                                        <div className='content'>
                                            <h3>Customizable Certificate Designs</h3>
                                            <p>Use our certificate design editor to create customised certificates that align with your organisation's branding.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n5'>
                                        <div className='image'><img src={analytics} alt="analytics" /></div>
                                        <div className='content'>
                                            <h3>Analytics and Reporting</h3>
                                            <p>Track feedback of your events, certificate distribution, and engagement metrics to measure the success of your events.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='wrapper' id='n2'>
                                        <div className='image'><img src={followup} alt="followup" /></div>
                                        <div className='content'>
                                            <h3>Automated Follow-Ups</h3>
                                            <p>Send automated emails and notifications to participants for certificate distribution and follow up reminders.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n4'>
                                        <div className='image'><img src={requests} alt="requests" /></div>
                                        <div className='content'>
                                            <h3>Manage Admins and Requests</h3>
                                            <p>Add other organisers and collaborate in designing certificates and also address participants requests for name edits etc, in one place.</p>
                                        </div>
                                    </div>
                                    {/* <div className='wrapper' id='n6'>
                                        <div className='image'></div>
                                        <div className='content'>
                                            <h3></h3>
                                            <p></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;