import React from 'react';
import Draggable from 'react-draggable';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleDragStop.bind(this);
        this.setTableStage.bind(this);
        this.outsideClickRef = React.createRef(null)
        this.clickTimeout = null
        this.state = {
            image: false,
            table: true,
            font: this.props.data,
            notDraggable: false,
            controls: false,
            id: this.props.element_id,
            toDelete: false,
            content: this.props.data.content,
            x: this.props.data.x,
            y: this.props.data.y,
            angle: this.props.data.angle ? this.props.data.angle : 0,
            autoResize: false,
            zIndex: this.props.element_id,
            unnamedVariable: 1,
            keyStamp: Date.now(),
            columns: this.props.data.columns,
            rows: this.props.data.rows,
            enableGrid: this.props.enableGrid,
            textarea: {
                height: this.props.data.elHeight,
                width: this.props.data.elWidth,
            },
            gridLevel: parseInt(this.props.grid_level) * 10,
            alignRange: 1,
            isMouseDown: false,
            rotateControls: false,
            mouseX: 0,
            mouseY: 0,
            prevMouseX: 0,
            prevMouseY: 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.font.content === state.content) {
            return {
                font: props.data,
                id: state.id,
                content: props.data.content,
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        } else {
            return {
                font: props.data,
                id: state.id,
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        }
    }

    hideControls = () => {
        this.setState({
            controls: false,
            notDraggable: false,
            zIndex: this.props.element_id,
        })
    }

    handleSendToParent = (e) => {
        function convertAllHtmlEntities(htmlString) {
            const parser = new DOMParser();
            const decodedString = parser.parseFromString(htmlString.replace(/<br\s*\/?>/g, '\n'), 'text/html').documentElement.textContent;
            return decodedString;
        }

        if (e !== 'update' && e.currentTarget.id === "delete" && this.state.toDelete !== 'required') {
            this.setState({
                toDelete: this.state.font.timeStamp,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        } else {
            const table = document.getElementById("table-" + this.state.font.timeStamp);
            let tableData = [];
            for (let i = 1; i < table.rows.length; i++) {
                let rowData = [];
                for (let j = 0; j < table.rows[i].cells.length - 1; j++) {
                    rowData.push(convertAllHtmlEntities(table.rows[i].cells[j].innerHTML));
                }
                tableData.push(rowData);
            }
            this.setState(({
                content: tableData
            }), () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    addRow = () => {
        let columns = document.getElementById("table-" + this.state.font.timeStamp).rows[0].cells.length;
        this.setState({
            content: [
                ...this.state.content,
                Array(columns).fill(""),
            ],
            rows: parseInt(this.state.rows) + 1,
        }, () => {
            this.props.fetchChildState(this.state)
        })
    }

    addColumn = () => {
        let arr = this.state.content;
        let updatedArr = arr.map((innerArr) => {
            return innerArr.concat("");
        });
        this.setState({
            content: updatedArr,
            columns: parseInt(this.state.columns) + 1,
        }, () => {
            this.props.fetchChildState(this.state)
        })
    }

    deleteRow = (index) => {
        if (parseInt(this.state.rows) > 1) {
            let injected_array = this.state.content
            injected_array.splice(index, 1)
            this.setState({
                content: injected_array,
                keyStamp: Date.now(),
                rows: parseInt(this.state.rows) - 1,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    deleteColumn = (index) => {
        if (parseInt(this.state.columns) > 1) {
            let arr = this.state.content;
            let updatedArr = arr.map((innerArr) => {
                return innerArr.filter((_, i) => parseInt(i) !== parseInt(index));
            });
            this.setState({
                content: updatedArr,
                keyStamp: Date.now(),
                columns: parseInt(this.state.columns) - 1,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    formatPasteValue = (e) => {
        var content = e.clipboardData.getData('text/plain');
        content = content.replace(/<\/?[^>]+(>|$)/g, "");
        document.execCommand('insertHTML', false, content);
        e.preventDefault();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener('paste', this.formatPasteValue);
        document.addEventListener("mouseup", this.stopRotate);
        document.addEventListener('mousemove', this.handleRotate);

        this.setState(({
            textarea: {
                height: document.getElementById("table-" + this.state.font.timeStamp).offsetHeight,
                width: document.getElementById("table-" + this.state.font.timeStamp).offsetWidth,
            },
        }), () => {
            this.generateParentBounding();
        })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener('paste', this.formatPasteValue);
        document.removeEventListener("mouseup", this.stopRotate);
        document.removeEventListener('mousemove', this.handleRotate);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.last_selected_element === this.state.id) {
            this.findGrid(0, { x: this.state.font.x, y: this.state.font.y })
        }
        if (prevState.font.angle !== this.state.font.angle) {
            this.setState({
                angle: this.state.font.angle,
            });
        }

        this.generateParentBounding(prevState);
    }

    findGrid = (event, dragElement) => {

        if (Math.abs(dragElement.deltaX) > 2 || Math.abs(dragElement.deltaY) > 2) {
            this.snapElement(dragElement);
        }

        let centerGridX = false;
        let centerGridY = false;
        if (Math.ceil(parseInt(dragElement.x + (parseInt(this.state.textarea.width / 2))) / 2) === this.props.certWidth / 4) {
            centerGridX = true
        }
        if (Math.ceil(parseInt(dragElement.y + (parseInt(this.state.textarea.height / 2))) / 2) === this.props.certHeight / 4) {
            centerGridY = true
        }
        if (this.state.controls) {
            this.props.fetchCenterGrid(false, false)
        } else {
            this.props.fetchCenterGrid(centerGridX, centerGridY)
        }

        this.alignLines(dragElement)
    }

    snapElement = (dragElement) => {
        if (this.state.enableGrid && !this.state.gridSnapped) {
            const gridLevel = this.state.gridLevel;
            const roundedX = Math.round(dragElement.x / gridLevel) * gridLevel;
            const roundedY = Math.round(dragElement.y / gridLevel) * gridLevel;
            this.setState({
                x: roundedX,
                y: roundedY,
                gridSnapped: true,
            }, () => {
                this.props.fetchChildDrag(this.state)
            })
        }
    }

    mouseDownCapture = () => {
        this.setState({
            isMouseDown: true,
        })
    }

    mouseUpCapture = () => {
        this.setState({
            isMouseDown: false,
        })
    }

    checkObject_Y_Top = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.y - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_Y_Bottom = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs((obj.y + obj.elHeight) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_Y_Middle = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.y + Math.round(obj.elHeight / 2) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }

    checkObject_X_Top = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.x - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_X_Bottom = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs((obj.x + obj.elWidth) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_X_Middle = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.x + Math.round(obj.elWidth / 2) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }

    alignLines = (dragElement) => {
        if (document.getElementById('rel-guide-y-top-' + this.state.font.timeStamp)) {
            const checkObject_Y_Top = this.checkObject_Y_Top(dragElement.y, this.state.font.timeStamp);
            const checkObject_Y_Bottom = this.checkObject_Y_Bottom(dragElement.y + this.state.textarea.height, this.state.font.timeStamp);
            const checkObject_Y_Middle = this.checkObject_Y_Middle(dragElement.y + Math.round(this.state.textarea.height / 2), this.state.font.timeStamp);

            const checkObject_X_Top = this.checkObject_X_Top(dragElement.x, this.state.font.timeStamp);
            const checkObject_X_Bottom = this.checkObject_X_Bottom(dragElement.x + this.state.textarea.width, this.state.font.timeStamp);
            const checkObject_X_Middle = this.checkObject_X_Middle(dragElement.x + Math.round(this.state.textarea.width / 2), this.state.font.timeStamp);

            if (this.state.isMouseDown) {
                if (checkObject_Y_Top >= 0) {
                    document.getElementById('rel-guide-y-top-' + this.state.font.timeStamp).style.display = 'block';
                } else if (checkObject_Y_Top < 0) {
                    document.getElementById('rel-guide-y-top-' + this.state.font.timeStamp).style.display = 'none';
                }
                if (checkObject_Y_Bottom >= 0) {
                    document.getElementById('rel-guide-y-bottom-' + this.state.font.timeStamp).style.display = 'block';
                } else if (checkObject_Y_Bottom < 0) {
                    document.getElementById('rel-guide-y-bottom-' + this.state.font.timeStamp).style.display = 'none';
                }
                if (checkObject_Y_Middle >= 0) {
                    document.getElementById('rel-guide-y-middle-' + this.state.font.timeStamp).style.display = 'block';
                } else if (checkObject_Y_Middle < 0) {
                    document.getElementById('rel-guide-y-middle-' + this.state.font.timeStamp).style.display = 'none';
                }

                if (checkObject_X_Top >= 0) {
                    document.getElementById('rel-guide-x-top-' + this.state.font.timeStamp).style.display = 'block';
                } else if (checkObject_X_Top < 0) {
                    document.getElementById('rel-guide-x-top-' + this.state.font.timeStamp).style.display = 'none';
                }
                if (checkObject_X_Bottom >= 0) {
                    document.getElementById('rel-guide-x-bottom-' + this.state.font.timeStamp).style.display = 'block';
                } else if (checkObject_X_Bottom < 0) {
                    document.getElementById('rel-guide-x-bottom-' + this.state.font.timeStamp).style.display = 'none';
                }
                if (checkObject_X_Middle >= 0) {
                    document.getElementById('rel-guide-x-middle-' + this.state.font.timeStamp).style.display = 'block';
                } else if (checkObject_X_Middle < 0) {
                    document.getElementById('rel-guide-x-middle-' + this.state.font.timeStamp).style.display = 'none';
                }
            } else {
                document.getElementById('rel-guide-y-top-' + this.state.font.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-y-bottom-' + this.state.font.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-y-middle-' + this.state.font.timeStamp).style.display = 'none';

                document.getElementById('rel-guide-x-top-' + this.state.font.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-x-bottom-' + this.state.font.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-x-middle-' + this.state.font.timeStamp).style.display = 'none';
            }
        }
    }

    handleClickOutside = (e) => {
        if (this.state.controls && !this.outsideClickRef.current.contains(e.target)) {
            this.hideControls();
            this.handleSendToParent('update');
        }

        this.setState(({
            textarea: {
                height: document.getElementById("table-" + this.state.font.timeStamp).offsetHeight,
                width: document.getElementById("table-" + this.state.font.timeStamp).offsetWidth,
            },
        }), () => {
            this.props.fetchChildSize(this.state)
        })
    }

    handleDragStop = (event, dragElement) => {
        this.setState({
            x: dragElement.x,
            y: dragElement.y,
        }, () => {
            this.props.fetchChildDrag(this.state)
        })
    };

    handleHistory = (event, dragElement) => {
        this.props.fetchHistory(this.state.id)
    };

    handleClicks = (e) => {
        if (this.clickTimeout !== null && !this.state.font.required) {
            this.setState({
                controls: true,
                notDraggable: true,
                zIndex: 999,
            });
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else if (!this.state.notDraggable) {
            this.clickTimeout = setTimeout(() => {
                e.preventDefault()
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 500)
        }
    }

    setTableStage = (e) => {
        this.props.fetchTableStage(this.state)
        this.handleHistory();
        setTimeout(() => {
            this.props.fetchCenterGrid(false, false)
        }, 1000);
    }

    startRotate = () => {
        this.setState({
            rotateControls: true,
            controls: false,
        });
    };

    handleRotate = (event) => {
        if (this.state.rotateControls) {
            const { clientX, clientY } = event;
            const { mouseX, mouseY, prevMouseX } = this.state;

            if (clientX !== mouseX || clientY !== mouseY) {
                if (clientX > prevMouseX) {
                    this.setState(prevState => ({
                        angle: parseInt((prevState.angle < 0 ? 360 : prevState.angle) - 1),
                    }));
                } else if (clientX < prevMouseX) {
                    this.setState(prevState => ({
                        angle: parseInt((prevState.angle > 360 ? 0 : prevState.angle) + 1),
                    }));
                }

                this.setState({
                    mouseX: clientX,
                    mouseY: clientY,
                    prevMouseX: mouseX,
                    prevMouseY: mouseY,
                });
            }
        }
    };

    stopRotate = () => {
        if (this.state.rotateControls) {
            this.setState({
                rotateControls: false,
                controls: true,
            }, () => {
                this.props.fetchChildDrag(this.state)
                this.handleHistory();
            })
        }
    }

    updateAngle = (e) => {
        if (e.target.value && e.target.value >= 0 && e.target.value <= 360) {
            this.setState({
                angle: parseInt(e.target.value),
            }, () => {
                this.props.fetchChildDrag(this.state)
                this.handleHistory();
            })
        } else {
            this.setState({
                angle: 0,
            }, () => {
                this.props.fetchChildDrag(this.state)
                this.handleHistory();
            })
        }
    }

    generateParentBounding = (prevState) => {
        if (prevState === undefined || prevState.angle !== this.state.angle || prevState.textarea !== this.state.textarea) {
            const angle = parseInt(this.state.angle);
            const radians = angle * (Math.PI / 180);

            const initialWidth = this.state.textarea.width;
            const initialHeight = this.state.textarea.height;

            const rotatedWidth = Math.abs(initialWidth * Math.cos(radians)) + Math.abs(initialHeight * Math.sin(radians));
            const rotatedHeight = Math.abs(initialWidth * Math.sin(radians)) + Math.abs(initialHeight * Math.cos(radians));

            this.setState({
                parentBound: {
                    top: 0 + (rotatedHeight / 2) - (initialHeight / 2),
                    bottom: document.getElementsByClassName('elements')[0].clientHeight - (initialHeight / 2) - (rotatedHeight / 2),
                    left: 0 + (rotatedWidth / 2) - (initialWidth / 2),
                    right: document.getElementsByClassName('elements')[0].clientWidth - (initialWidth / 2) - (rotatedWidth / 2),
                }
            });
        }
    }

    unlockElement = () => {
        this.props.fetchUnlockElement(false)
    }

    render() {
        return (
            <Draggable position={{ x: this.state.font.x, y: this.state.font.y }} bounds={this.state.parentBound} disabled={this.state.font.lock ? true : this.state.notDraggable} cancel={this.state.notDraggable ? 'table-wrapper,select,button' : 'select,button'} onStop={this.handleDragStop} grid={this.state.enableGrid ? [this.state.gridLevel, this.state.gridLevel] : null} onStart={this.handleHistory} onDrag={this.findGrid} scale={this.state.enableGrid ? 1 : this.props.certificate_zoom}>
                <div className={this.state.notDraggable ? 'box pinned' : 'box'} ref={this.outsideClickRef} style={{ zIndex: this.state.zIndex, pointerEvents: this.props.move_canvas ? "all" : "none" }} id={"box" + this.state.font.timeStamp} onMouseDownCapture={this.mouseDownCapture} onMouseUpCapture={this.mouseUpCapture}>
                    <div className='rotate-wrap' style={{ transformOrigin: 'center', transform: `rotate(${this.state.angle}deg)` }}>
                        {this.state.controls && (
                            !this.state.font.lock ?
                                <div className='variable-controls'>
                                    {
                                        this.state.font.required ? "" : <button className='delete' id="delete" onClick={this.handleSendToParent}><i className="fa-solid fa-trash pe-none"></i></button>
                                    }
                                </div>
                                :
                                <div className='variable-controls lock-controls'>
                                    <button className='delete' onClick={this.unlockElement}><i className="fa-solid fa-lock"></i></button>
                                </div>
                        )}
                        {
                            <div className="wrap">

                                {this.state.angle === 0 && (
                                    <React.Fragment>
                                        <hr className='rel-guide-y-top' id={'rel-guide-y-top-' + this.state.font.timeStamp}></hr>
                                        <hr className='rel-guide-y-bottom' id={'rel-guide-y-bottom-' + this.state.font.timeStamp}></hr>
                                        <hr className='rel-guide-y-middle' id={'rel-guide-y-middle-' + this.state.font.timeStamp}></hr>

                                        <hr className='rel-guide-x-top' id={'rel-guide-x-top-' + this.state.font.timeStamp}></hr>
                                        <hr className='rel-guide-x-bottom' id={'rel-guide-x-bottom-' + this.state.font.timeStamp}></hr>
                                        <hr className='rel-guide-x-middle' id={'rel-guide-x-middle-' + this.state.font.timeStamp}></hr>
                                    </React.Fragment>
                                )}

                                {!this.state.font.lock && (this.state.controls || this.state.rotateControls) && (<div className='rotate-handle-container'><div className='rotate-handle' onMouseDownCapture={this.startRotate}><i className="fa-solid fa-rotate-right"></i></div><input className="angle-view" type='number' min='0' max='360' inputMode='numeric' pattern="[0-9]*" value={this.state.angle} onChange={this.updateAngle}></input></div>)}
                                <div className="pull-tab"></div>
                                <div className='table-wrapper' style={{
                                    minHeight: (parseInt(this.state.rows + 1) * 32) + "px",
                                    resize: this.state.controls ? "both" : "none",
                                    overflow: "hidden",
                                    height: this.state.font.elHeight + "px",
                                    width: this.state.font.elWidth + "px",
                                }}>

                                    <table id={"table-" + this.state.font.timeStamp} style={{
                                        fontSize: this.state.font.fontSize + "px",
                                        fontFamily: this.state.font.fontFamily,
                                        color: this.state.font.fontColor,
                                        fontWeight: this.state.font.fontWeight ? "600" : "400",
                                        fontStyle: this.state.font.fontStyle ? "italic" : "normal",
                                        textDecorationLine: this.state.font.textDecorationLine ? "underline" : "none",
                                        textAlign: this.state.font.textAlign,
                                        lineHeight: this.state.font.lineHeight,
                                    }}
                                        onMouseDown={this.handleClicks}
                                        onMouseUp={this.setTableStage}>
                                        <tbody>
                                            {this.state.controls &&
                                                (<tr key="del-col">
                                                    {
                                                        this.state.content[0].map((x, i) => (
                                                            <td id={i} key={i} className='td-delete'><i className="fa-solid fa-circle-xmark" onClick={() => this.deleteColumn(i)}></i></td>
                                                        ))
                                                    }
                                                </tr>)
                                            }
                                            {
                                                this.state.content.map((element, index) => (
                                                    <tr key={"tr-" + index}>
                                                        {
                                                            element.map((x, i) => (
                                                                <td suppressContentEditableWarning={true} contentEditable={this.state.controls} key={"td-" + index + i + this.state.keyStamp} className='inherit' type="text" value={x} onBlur={this.handleSendToParent}>{x}</td>
                                                            ))
                                                        }
                                                        {this.state.controls && (<td id={index} className='td-delete'><i className="fa-solid fa-circle-xmark" onClick={() => this.deleteRow(index)}></i></td>)}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            !this.state.font.lock && this.state.controls && (
                                <div className="table-controls">
                                    <button className='addRow-btn' onClick={this.addRow}>Add Row <i className="fa-solid fa-circle-plus"></i></button>
                                    <button className='addRow-btn' onClick={this.addColumn}>Add Column <i className="fa-solid fa-circle-plus"></i></button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Draggable>
        );
    }
}

export default ElementComponent;