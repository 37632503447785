import React from 'react';
import './css/account.css';
import ProgressLoader from './progress_loader';
import Personal from './account/personal'
import Organisation from './account/organisation'
import Data from './account/data'
import Security from './account/security'
import Payments from './account/payments'
import Verification from './account/verification'
import Api from './account/api'

import axios from 'axios';

class AccountComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: 'personal',
            part: false,
            orgLocked: true,
        };
    }

    toggleSelection = (data) => {
        this.setState({
            selection: data,
        });
    }

    componentDidMount = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const selection = searchParams.get("selection");
        if (selection !== null) {
            this.setState({ selection });
        }

        const part = searchParams.get("part");
        if (part !== null) {
            this.setState({ part });
        }

        if (this.props.user_type === 'organisation') {
            axios.post(this.props.node_server_axios + 'check-if-org-admin', {
                params: {
                    email: this.props.email,
                },
            }).then(res => {
                if (res.data.message === 'admin' || res.data.message === 'organiser') {
                    this.setState({
                        orgLocked: false,
                    })
                }
            });
        }
    }

    createOrganisation = () => {
        if (this.props.user_type === 'user') {
            window.location.href = "/create";
        }
    }

    renderMainBlock = (value) => {
        switch (value) {
            case 'personal':
                return (
                    <Personal email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            case 'organisation':
                return (
                    <Organisation email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            case 'data':
                return (
                    <Data email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            case 'security':
                return (
                    <Security email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            case 'payments':
                return (
                    <Payments email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            case 'verification':
                return (
                    <Verification email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            case 'api':
                return (
                    <Api email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
            default:
                return (
                    <Personal email={this.props.email} user_type={this.props.user_type} user_id={this.props.user_id} storage={this.props.storage} node_server_axios={this.props.node_server_axios} part={this.state.part} />
                )
        }
    }

    render() {
        let { selection } = this.state
        return (
            <React.Fragment>
                <div className="account">
                    <div className='account-nav-container'>
                        <div className='account-nav'>
                            <div className={selection === 'personal' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('personal')}><i className="fa-solid fa-circle-user"></i> <p>Personal Info</p></div>
                            {this.state.orgLocked ?
                                <div className='nav' onClick={this.createOrganisation}><i className="fa-solid fa-building-user"></i> <p>Organisation</p> <i className="fa-solid fa-lock red"></i></div>
                                :
                                <div className={selection === 'organisation' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('organisation')}><i className="fa-solid fa-building-user"></i> <p>Organisation</p></div>
                            }
                            <div className={selection === 'data' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('data')}><i className="fa-solid fa-server"></i> <p>Data & Other Info</p></div>
                            <div className={selection === 'security' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('security')}><i className="fa-solid fa-lock"></i> <p>Security & Account</p></div>
                            <div className={selection === 'payments' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('payments')}><i className="fa-solid fa-credit-card"></i> <p>Payments & Subscription</p></div>
                            <div className={selection === 'verification' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('verification')}><i className="fa-solid fa-shield"></i> <p>Verification</p></div>
                            <div className={selection === 'api' ? 'nav active' : 'nav'} onClick={() => this.toggleSelection('api')}><i className="fa-solid fa-puzzle-piece"></i> <p>Api Keys</p></div>
                        </div>
                        {!this.state.orgLocked &&
                            <div className='account-nav home'>
                                {/* <div className={'nav'} onClick={() => window.location.href = '/'}><i className="fa-solid fa-caret-left"></i> <p>Home</p></div> */}
                                <div className={'nav'} onClick={() => window.location.href = '/organisation'}><i className="fa-solid fa-caret-left"></i> <p>Send Certificates</p></div>
                            </div>
                        }
                    </div>
                    <div className='account-main'>
                        {
                            this.renderMainBlock(this.state.selection)
                        }
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default AccountComponent;