import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';
import MainImage from '../../images/home/Solutions/Ads/ads-1.png';
import InfoImage from '../../images/home/Solutions/Ads/ads.png';
import Image1 from '../../images/home/Solutions/Ads/ads why.png';
import Image2 from '../../images/home/Solutions/Ads/ads benefits.png';
import Image3 from '../../images/home/Solutions/Ads/ads getting started.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='solution intro'>
                            <div className='content'>
                                <h1>Avertisements</h1>
                                <h2>Amplify Your Reach with Pronetin's Advertisement Solutions</h2>
                                <p>In the crowded digital marketplace, effective advertisement placement is key to capturing attention and driving engagement. Pronetin offers robust solutions to optimise your advertising efforts and amplify your reach.</p>
                                <div className='btn-container'>
                                    <button className='alt' onClick={() => window.location = '/help'}>Request a demo</button>
                                    <button onClick={() => window.location = '/signin'}>Explore</button>
                                </div>
                            </div>
                            <div className='rep'>
                                <img src={MainImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution info'>
                            <div className='content'>
                                <h2>What is Pronetin's Advertisement Solution?</h2>
                                <p>Pronetin provides a comprehensive platform for creating, managing, and analysing advertisements. Whether you’re promoting a product, service, event, or educational program, Pronetin ensures your ads reach the right audience at the right time.</p>
                            </div>
                            <div className='rep'>
                                <img src={InfoImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>Why Choose Pronetin's Advertisement Solution?</h2>
                                <ul>
                                    <li><strong>Targeted Advertisement Placement</strong>: Reach your specific audience with precise targeting options, ensuring your ads are seen by those who matter most.</li>
                                    <li><strong>Seamless Integration</strong>: Our platform seamlessly integrates ads within the user interface, providing high visibility without disrupting the user experience.</li>
                                    <li><strong>Data-Driven Insights</strong>: Monitor and optimise your ad performance with robust analytics, gaining valuable insights into reach, engagement, and effectiveness.</li>
                                    <li><strong>Customizable Ad Formats</strong>: Choose from a variety of ad formats, from banner ads to sponsored content, to suit your campaign needs.</li>
                                    <li><strong>Enhanced Visibility for Events and Programs</strong>: Promote your events and programs effectively, increasing attendance and participation through targeted promotion.</li>
                                    <li><strong>Secure and Trustworthy</strong>: Protect your ads from fraud with advanced security measures, ensuring delivery to genuine users.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image1} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution alt'>
                            <div className='content'>
                                <h2>Benefits of Pronetin's Advertisement Solution</h2>
                                <ul>
                                    <li><strong>Precision Targeting</strong>: Ensure your ads reach the right audience, maximising engagement and conversion rates.</li>
                                    <li><strong>Non-Intrusive Experience</strong>: Maintain a positive user experience while ensuring high ad visibility.</li>
                                    <li><strong>Optimization</strong>: Use real-time analytics to refine and enhance your advertising strategy.</li>
                                    <li><strong>Flexibility</strong>: Tailor ad formats to fit your brand and campaign objectives.</li>
                                    <li><strong>Trust and Security</strong>: Rely on Pronetin’s secure platform to protect your ad investments.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image2} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>How to Get Started</h2>
                                <p>
                                    Start leveraging Pronetin’s advertisement solutions by signing up on our platform. Create your ad campaigns, target your audience, and use our powerful tools to track and optimise your performance.<br></br>
                                    <strong>Join Pronetin today and elevate your advertising strategy. Amplify your reach and impact with Pronetin, your trusted partner in effective advertisement management</strong>
                                </p>
                            </div>
                            <div className='rep'>
                                <img src={Image3} alt='pronetin solutions' />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;