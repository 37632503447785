import React from 'react';
import Warning from '../../components/warning';
import WarningAction from '../../components/warning_action';
import Papa from 'papaparse';
import axios from 'axios';

import tinycolor from 'tinycolor2';
import compromise from 'compromise';
import colors from 'color-name-list';

import ProgressLoader from '../../components/progress_loader';

import certBg1 from '../../ai/tem1.png';
import certBg2 from '../../ai/tem2.png';
import certBg3 from '../../ai/tem3.png';
import certBg4 from '../../ai/tem4.png';
import certBg5 from '../../ai/tem5.png';
import certBg6 from '../../ai/tem6.png';
import certBg7 from '../../ai/tem7.png';
import certBg8 from '../../ai/tem8.png';
import certBg9 from '../../ai/tem9.png';
import certBg10 from '../../ai/tem10.png';
import certBg11 from '../../ai/tem11.png';
import certBg12 from '../../ai/tem12.png';
import certBg13 from '../../ai/tem13.png';
import certBg14 from '../../ai/tem14.png';
import certBg15 from '../../ai/tem15.png';
import certBg16 from '../../ai/tem16.png';
import certBg17 from '../../ai/tem17.png';
import certBg18 from '../../ai/tem18.png';
import certBg19 from '../../ai/tem19.png';
import certBg20 from '../../ai/tem20.png';

class EditorSubSidenavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRefs = [
            React.createRef(),
            React.createRef()
        ];
        this.fileInputRef = React.createRef();
        this.images = [
            certBg1,
            certBg2,
            certBg3,
            certBg4,
            certBg5,
            certBg6,
            certBg7,
            certBg8,
            certBg9,
            certBg10,
            certBg11,
            certBg12,
            certBg13,
            certBg14,
            certBg15,
            certBg16,
            certBg17,
            certBg18,
            certBg19,
            certBg20
        ];
        this.renderBackgroundGen = this.renderBackgroundGen.bind(this);
        this.handleSendToParent.bind(this);
        this.handlePDFSelected.bind(this);
        this.handleElementSelect.bind(this);
        this.handleTemplate.bind(this);
        this.handleTemplateAI.bind(this);
        this.state = {
            base64string: false,
            fileName: false,
            pdf: false,
            pdfFilename: false,
            pdfSize: false,
            toAdd: {
                type: false,
            },
            variables: [],
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            certTemplate: "tem1",
            imageList: [],
            csvName: false,
            csvData: [],
            csvLength: 0,
            csvInvalid: [],
            csvFormat: false,
            certTemplateIndex: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
            docTemplateIndex: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
            searchTemplateIndex: [],
            searchTemplateIndexWithFilter: [],
            toggle_cert_type: 'certificates',
            toggle_search_type_landscape: true,
            aiModal: false,
            extractedTitle: "",
            contentTags: [],
            uploadButton: false,
            searchTerm: '',
            searchFocus: false,
            colorCodes: [],
            currentIndex: 0,
            outputText: "Hi, what certificate would you like to generate?",
            aiTextReadOnly: false,
            addSigneeBtn: false,
            addSigneeNextBtn: false,
            hasOrganisationLogo: false,
            organisationLogoImage: false,
            progress_loader: false,
            csvUploadBtn: true,
            signeeModal: false,
            nextSigneeModal: false,
        }
    }

    componentDidMount = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_tag_list_ai').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'tag list') {
                    this.setState({
                        contentTags: res.data.tagDataDefault,
                        contentIDs: res.data.idDataDefault
                    });
                }
            }
        })
        this.listUploadedImages();

        if (this.props.owner) {
            axios.post(this.props.node_server_axios + 'organisation_info_from_organiser_email', {
                params: {
                    email: this.props.email,
                },
            }).then(res => {
                if (res.data.length > 0) {
                    fetch("https://pronetin-new.s3.amazonaws.com/organisation/" + res.data[0].domain + "/ogranisationLogo.png?cacheblock=" + Date.now())
                        .then((response) => {
                            if (response.ok) {
                                this.setState({
                                    hasOrganisationLogo: true,
                                    organisationLogoImage: "https://pronetin-new.s3.amazonaws.com/organisation/" + res.data[0].domain + "/ogranisationLogo.png?cacheblock=" + Date.now(),
                                })
                            } else {
                                this.setState({
                                    hasOrganisationLogo: false,
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        }
        // When certificate templates needed to be fetched from db in future add orientation column for landscape and portrait
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.cacheTs === this.state.cacheTs && this.props.step !== '4' && this.state.pdf) {
            this.setState({
                cacheTs: Date.now(),
                pdf: this.updateCacheBlockQueryParam(this.state.pdf),
            })
        }

        function areArraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) {
                return false;
            }
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) {
                    return false;
                }
            }
            return true;
        }

        if (!areArraysEqual(prevState.variables, this.state.variables)) {
            this.setState({
                csvFormat: false,
                csvName: false,
            })
        }
    }

    updateCacheBlockQueryParam(inputString) {
        const timestamp = Date.now();
        const cacheBlockPattern = /\?cacheblock=\d+$/;

        if (cacheBlockPattern.test(inputString)) {
            return inputString.replace(cacheBlockPattern, `?cacheblock=${timestamp}`);
        } else {
            return inputString + `?cacheblock=${timestamp}`;
        }
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    handleSigneeDelete = (values) => {
        this.props.fetchElementToDelete(values);
    }

    handleSendToParent = (e) => {
        if (e.currentTarget.id !== 'textField') {
            this.setState({
                toAdd: {
                    type: e.currentTarget.id,
                    timeStamp: Date.now(),
                    fontSize: 16,
                    fontFamily: 'Arimo, sans-serif',
                    fontColor: '#000',
                    fontWeight: false,
                    fontStyle: false,
                    textDecorationLine: false,
                    lineHeight: '1',
                    textAlign: 'left',
                    dataType: e.currentTarget.dataset.type !== undefined ? e.currentTarget.dataset.type : "textarea",
                    content: e.currentTarget.dataset.value !== undefined ? e.currentTarget.dataset.value : "",
                    required: false,
                    readOnly: e.currentTarget.dataset.readonly !== undefined ? true : false,
                    placeholder: e.currentTarget.dataset.placeholder !== undefined ? e.currentTarget.dataset.placeholder : 'Type here...',
                    elHeight: 26,
                    elWidth: 300,
                    x: 0,
                    y: 50,
                    span: e.currentTarget.dataset.span !== undefined ? e.currentTarget.dataset.span : false,
                },
            }, () => {
                this.props.fetchChildState(this.state.toAdd)
            })
        } else {
            let textFieldCount = this.props.elementIndex.filter(element => element.type === 'textField').length
            if (textFieldCount < 10) {
                this.setState({
                    toAdd: {
                        type: e.currentTarget.id,
                        timeStamp: Date.now(),
                        fontSize: 25,
                        fontFamily: 'Arimo, sans-serif',
                        fontColor: '#000',
                        fontWeight: false,
                        fontStyle: false,
                        textDecorationLine: false,
                        lineHeight: '1',
                        textAlign: 'center',
                        dataType: "textarea",
                        content: "Your Text Here",
                        required: false,
                        readOnly: false,
                        placeholder: 'Type your text here...',
                        elHeight: 26,
                        elWidth: 300,
                        x: 0,
                        y: 50,
                    },
                }, () => {
                    this.props.fetchChildState(this.state.toAdd)
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, You can only use 10 text elements.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        }
    }

    dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    handleImageToParent = () => {
        this.setState({
            uploadButton: false
        });
        if (this.state.base64string) {
            var imageTimestamp = Date.now();

            const blob = this.dataURItoBlob(document.getElementById("preview-image").src);
            const formData = new FormData();
            formData.append('file', blob, imageTimestamp + '.png');
            formData.append('textField', this.props.email);
            axios.post(this.props.node_server_axios + 'saved_upload_image/' + this.props.saveId, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'image uploaded') {
                        console.log("updated");
                    }
                    let divisor = document.getElementById('preview-image').naturalHeight / 90;
                    this.setState({
                        toAdd: {
                            type: "image",
                            resize: "both",
                            timeStamp: imageTimestamp,
                            required: false,
                            elHeight: document.getElementById('preview-image').naturalHeight / divisor,
                            elWidth: document.getElementById('preview-image').naturalWidth / divisor,
                            content: this.state.base64string,
                            resizeLock: true,
                            x: 0,
                            y: 50,
                            zIndex: 1,
                        },
                        base64string: false,
                        fileName: false,
                    }, () => {
                        this.props.fetchChildState(this.state.toAdd)
                        this.listUploadedImages();
                    })
                }
            })
        }
    }

    handleCsvToParent = () => {
        this.setState({
            csvUploadBtn: false,
        }, () => {
            setTimeout(() => {
                if (this.state.csvData && this.state.csvData.length) {
                    this.props.fetchCsvState(this.state.csvData);
                    this.setState({
                        csvUploadBtn: true,
                    });
                }
            }, 1000);
        });
    }

    async handleUploadedToParent(event, item) {
        if (event.target.className === 'del-btn') {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Permanently Delete",
                    subtext: "Deleting the uploaded image will remove the image from the design for future use. Continue?",
                    type: "warning",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: true,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
            axios.post(this.props.node_server_axios + 'delete_saved_uploaded_images', {
                params: {
                    email: this.props.email,
                    toDelete: item,
                }
            }).then((res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'image deleted') {
                        console.log("deleted");
                    }
                }
                this.listUploadedImages();
            })
        } else {
            var imageTimestamp = item.split("/").slice(-1).join();
            imageTimestamp = parseInt(imageTimestamp.split(".")[0]);

            async function getBase64(email, imageTimestamp) {
                const response = await fetch("https://pronetin-new.s3.amazonaws.com/saved/" + encodeURIComponent(email) + "/" + encodeURIComponent(imageTimestamp + ".png"));
                const data = await response.arrayBuffer();
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(new Blob([data]));
                    reader.onloadend = () => {
                        let base64data = reader.result;
                        resolve(base64data);
                    }
                });
            }

            const base64string = await getBase64(this.props.saveId, imageTimestamp);

            this.setState({
                toAdd: {
                    type: "image",
                    resize: "both",
                    timeStamp: imageTimestamp,
                    required: false,
                    elHeight: 80,
                    elWidth: "auto",
                    content: base64string,
                    resizeLock: true,
                    x: 0,
                    y: 50,
                    zIndex: 1,
                },
                base64string: false,
                fileName: false,
            }, () => {
                this.props.fetchChildState(this.state.toAdd)
            })
        }
    }

    async handleUploadedToParentOrganisationLogo(url) {
        this.setState({
            progress_loader: true,
        })
        var imageTimestamp = "ogranisationLogo";
        let logoExistInDraft = false;

        async function getBase64(url) {
            const response = await fetch(url.replace(/\d+$/, Date.now()));
            const data = await response.arrayBuffer();
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsDataURL(new Blob([data]));
                reader.onloadend = () => {
                    let base64data = reader.result;
                    resolve(base64data);
                }
            });
        }

        fetch("https://pronetin-new.s3.amazonaws.com/saved/" + this.props.saveId + "/ogranisationLogo.png?cacheblock=" + Date.now())
            .then((response) => {
                if (response.ok) {
                    logoExistInDraft = true;
                } else {
                    logoExistInDraft = false;
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (!logoExistInDraft) {
            const base64string = await getBase64(url);
            const blob = this.dataURItoBlob(base64string);
            const formData = new FormData();
            formData.append('file', blob, imageTimestamp + '.png');
            formData.append('textField', this.props.email);
            axios.post(this.props.node_server_axios + 'saved_upload_image/' + this.props.saveId, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'image uploaded') {
                        console.log("updated");
                    }
                    this.setState({
                        toAdd: {
                            type: "image",
                            resize: "both",
                            timeStamp: imageTimestamp,
                            required: false,
                            elHeight: 80,
                            elWidth: "auto",
                            content: base64string,
                            resizeLock: true,
                            x: 0,
                            y: 50,
                            zIndex: 1,
                        },
                        base64string: false,
                        fileName: false,
                        progress_loader: false,
                    }, () => {
                        this.props.fetchChildState(this.state.toAdd)
                    })
                }
            })
        } else {
            const base64string = await getBase64(url);
            this.setState({
                toAdd: {
                    type: "image",
                    resize: "both",
                    timeStamp: imageTimestamp,
                    required: false,
                    elHeight: 80,
                    elWidth: "auto",
                    content: base64string,
                    resizeLock: true,
                    x: 0,
                    y: 50,
                    zIndex: 1,
                },
                base64string: false,
                fileName: false,
                progress_loader: false,
            }, () => {
                this.props.fetchChildState(this.state.toAdd)
            })
        }
    }

    handleShapeToParent = (e) => {
        switch (e.currentTarget.id) {
            case "square":
                this.setState({
                    toAdd: {
                        type: "image",
                        resize: "both",
                        timeStamp: Date.now(),
                        required: false,
                        elHeight: 120,
                        elWidth: 120,
                        content: "shape",
                        shape: "square",
                        resizeLock: false,
                        x: 50,
                        y: 50,
                        zIndex: 1,
                    },
                    base64string: false,
                    fileName: false,
                }, () => {
                    this.props.fetchChildState(this.state.toAdd)
                })
                break;
            case "circle":
                this.setState({
                    toAdd: {
                        type: "image",
                        resize: "both",
                        timeStamp: Date.now(),
                        required: false,
                        elHeight: 120,
                        elWidth: 120,
                        content: "shape",
                        shape: "circle",
                        resizeLock: false,
                        x: 50,
                        y: 50,
                        zIndex: 1,
                    },
                    base64string: false,
                    fileName: false,
                }, () => {
                    this.props.fetchChildState(this.state.toAdd)
                })
                break;
            default:
                this.setState({
                    toAdd: {
                        type: "image",
                        resize: "both",
                        timeStamp: Date.now(),
                        required: false,
                        elHeight: e.target.id === "hLine" ? 7 : 90,
                        elWidth: e.target.id === "hLine" ? 90 : 7,
                        content: "shape",
                        shape: e.target.id,
                        resizeLock: false,
                        x: 50,
                        y: 50,
                        zIndex: 1,
                    },
                    base64string: false,
                    fileName: false,
                }, () => {
                    this.props.fetchChildState(this.state.toAdd)
                })
                break;
        }
    }

    handleTableToParent = (e) => {
        if (parseInt(document.getElementById('table_rows').value) < 9 && parseInt(document.getElementById('table_columns').value) < 9) {
            this.setState({
                toAdd: {
                    type: "table",
                    rows: parseInt(document.getElementById('table_rows').value),
                    columns: parseInt(document.getElementById('table_columns').value),
                    timeStamp: Date.now(),
                    fontSize: 16,
                    fontFamily: 'Arimo, sans-serif',
                    fontColor: '#000',
                    fontWeight: false,
                    fontStyle: false,
                    textDecorationLine: false,
                    lineHeight: '1',
                    textAlign: 'left',
                    required: false,
                    readOnly: false,
                    placeholder: 'Type here...',
                    x: 0,
                    y: 50,
                    content: Array(parseInt(document.getElementById('table_rows').value)).fill(null).map(() => Array(parseInt(document.getElementById('table_columns').value)).fill('')),
                },
                base64string: false,
                fileName: false,
            }, () => {
                this.props.fetchChildState(this.state.toAdd)
            })
        } else {
            this.setState({
                warning: {
                    message: "Sorry, Invalid table size.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    handleSignatureToParent = () => {
        const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };

        const checkSignatureDuplicate = (value) => {
            for (let i = 0; i < this.props.elementIndex.length; i++) {
                const element = this.props.elementIndex[i];
                if (element.type === 'signature' && element.content.split('|')[0] === value) {
                    return true;
                }
            }
            return false;
        }

        let signatureCount = this.props.elementIndex.filter(element => element.type === 'signature').length
        if (validateEmail(document.getElementById('signee_email').value) && document.getElementById('signee_designation').value.length > 1) {
            if (signatureCount < 4) {
                if (checkSignatureDuplicate(document.getElementById('signee_email').value)) {
                    this.setState({
                        warning: {
                            message: "Sorry, this signee has already been added.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                            signeeModal: false,
                        }
                    })
                } else {
                    this.setState({
                        toAdd: {
                            type: "signature",
                            signee_email: document.getElementById('signee_email').value,
                            signee_designation: document.getElementById('signee_designation').value,
                            signee_name: document.getElementById('signee_name').value,
                            timeStamp: Date.now(),
                            required: false,
                            readOnly: false,
                            resize: "none",
                            resizeLock: false,
                            placeholder: 'Type here...',
                            x: 0,
                            y: 50,
                            zIndex: 3,
                            content: document.getElementById('signee_email').value + "|" + document.getElementById('signee_designation').value,
                        },
                        base64string: false,
                        fileName: false,
                    }, () => {
                        this.props.fetchChildState(this.state.toAdd)
                        document.getElementById('signee_email').value = "";
                        document.getElementById('signee_designation').value = "";
                        this.setState({
                            signeeModal: false,
                        })
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, You can only add 4 signees.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                        signeeModal: false,
                    }
                })
            }
        } else {
            this.setState({
                warning: {
                    message: "Sorry, Invalid signee email or designation.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    handleElementSelect = (e) => {
        if (e.currentTarget.id !== 'textField') {
            this.setState({
                selectedElement: this.props.elementIndex.filter(element => element.type === e.currentTarget.id)[0].timeStamp,
            }, () => {
                if (document.contains(document.querySelector('#box' + this.state.selectedElement)) && !document.querySelector('#box' + this.state.selectedElement).classList.contains('selectFocus')) {
                    var elems = document.querySelectorAll(".box");
                    [].forEach.call(elems, function (el) {
                        el.classList.remove("selectFocus");
                    });
                    // add class selectFocus
                    document.querySelector('#box' + this.state.selectedElement).classList.add('selectFocus')
                }
            })
        }
    }

    handleFileSelected = async (e) => {
        var file = e.target.files[0];
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.setState({
                warning: {
                    message: "Please select a JPG or PNG image file.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        } else {
            function getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
            let base64string = await getBase64(file);
            this.setState({
                base64string: base64string,
                fileName: e.target.files[0].name,
                uploadButton: true,
            }, () => {
                document.getElementById('add-image-form').reset();
            })
        }
    }

    handleCsvSelected = async (e) => {
        var file = e.target.files[0];
        if (file && (file.type === 'text/csv' || file.name.endsWith('.csv'))) {
            Papa.parse(file, {
                header: true,
                download: true,
                dynamicTyping: false,
                skipEmptyLines: 'greedy',
                encoding: 'UTF-8',
                complete: (results) => {
                    const emailRegex = /\S+@\S+\.\S+/;
                    let invalidEmailArray = [];
                    // Clean email addresses in results.data
                    results.data = results.data.map(function (item, index) {
                        const cleanedEmail = item["[Email]"].trim();
                        if (!emailRegex.test(cleanedEmail)) {
                            invalidEmailArray.push((index + 1) + ". " + cleanedEmail);
                        }
                        return {
                            ...item,
                            "[Email]": cleanedEmail
                        };
                    });
                    let headers = ["[Email]"].concat(this.state.variables)
                    if (JSON.stringify(results.meta.fields) === JSON.stringify(headers)) {
                        if (results.data.length === 0) {
                            this.setState({
                                warning: {
                                    message: "The uploaded file is empty.",
                                    type: "warning",
                                    learnmore: false,
                                    autoclose: true,
                                }
                            })
                            if (this.fileInputRef.current) {
                                this.fileInputRef.current.value = null; // This clears the csv input field
                            }
                        } else {
                            this.setState({
                                csvData: results.data,
                                csvName: e.target.files[0].name,
                                csvLength: results.data.length,
                                csvInvalid: invalidEmailArray,
                                csvFormat: true,
                                csvUploadBtn: true,
                            }, () => {
                                document.getElementById('add-csv-form').reset();
                            })
                        }
                    } else {
                        this.setState({
                            csvName: e.target.files[0].name,
                            csvFormat: false,
                            csvLength: 0,
                        }, () => {
                            document.getElementById('add-csv-form').reset();
                        })
                    }
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Please upload a CSV file.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
        }
    }

    handlePDFSelected = (e) => {
        if (this.props.owner) {
            var file = e.target.files[0];
            if (file && file.type !== 'application/pdf') {
                this.setState({
                    warning: {
                        message: "Invalid file format. Only PDF supported",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            } else if (file && file.type === 'application/pdf' && ((file.size) / 1000000).toPrecision(3) < 5) {
                this.setState({
                    pdf: URL.createObjectURL(file) + "#toolbar=0&navpanes=0&scrollbar=0",
                    pdfData: file,
                    pdfFilename: file.name,
                    pdfSize: ((file.size) / 1000000).toPrecision(3) + " mb"
                }, () => {
                    this.props.fetchPdfState(this.state)
                })
            } else {
                this.setState({
                    warning: {
                        message: "Only documents upto 5 mb supported.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                });
            }
        } else {
            this.setState({
                warning: {
                    message: "You do not have access to this feature.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
        }
    }

    removePDF = () => {
        this.setState({
            pdfData: null,
            pdf: false,
            pdfFilename: false,
            pdfSize: false,
        }, () => {
            this.props.fetchPdfState(this.state)
        })
    }

    handleTemplate = (e) => {
        this.setState({
            certTemplate: e.target.alt
        }, () => {
            this.props.fetchTemplate(this.state.certTemplate)
        })
    }

    handleTemplateAI = (id) => {
        this.setState({
            certTemplate: id
        }, () => {
            this.props.fetchTemplate(this.state.certTemplate)
        })
    }

    listUploadedImages = () => {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_saved_uploaded_images', {
            params: {
                email: this.props.email,
                save_id: this.props.saveId,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'list' && res.data.elements !== undefined) {
                    const updatedData = await Promise.all(res.data.elements.map(async (image) => {
                        return image;
                    }));
                    this.setState({
                        imageList: updatedData,
                    })
                } else {
                    this.setState({
                        imageList: [],
                    })
                }
            }
        })
    }

    downloadFormat = () => {
        // const data = this.state.csvData;
        const csv = Papa.unparse({
            fields: ["[Email]"].concat(this.state.variables),
            // data: data
        });

        const csvWithBom = '\uFEFF' + csv;

        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'pronetin_format.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static getDerivedStateFromProps(props, state) {
        let variablesLocal = props.variableIndex.map(element => element.variables)
        let mergedVariablesLocal = [].concat.apply([], variablesLocal);
        let uniqueVariablesLocal = mergedVariablesLocal.filter((item, i, ar) => ar.indexOf(item) === i);
        uniqueVariablesLocal = uniqueVariablesLocal.filter(element => element !== null && element !== "" && element !== undefined);
        if (uniqueVariablesLocal !== state.variables) {
            if (props.step !== '3') {
                return {
                    variables: uniqueVariablesLocal,
                }
            } else {
                return {
                    variables: uniqueVariablesLocal,
                }
            }
        } else {
            return null
        }
    }

    toggleCertType = (value) => {
        document.getElementById('search-template').value = '';
        this.setState({
            toggle_cert_type: value,
            toggle_search_type_landscape: value === 'certificates' ? true : false,
        })
    }

    openAiModal = () => {
        if (this.state.aiModal) {
            document.getElementById('gen-container').classList.remove('active');
        }
        this.setState({
            aiModal: !this.state.aiModal,
            colorCodes: [],
            outputText: "Hi, what certificate would you like to generate?",
            currentIndex: 0,
            aiTextReadOnly: false,
            extractedTitle: "",
        })
    }

    extractColorsFromSentence(sentence) {
        const doc = compromise(sentence);

        const colorNames = colors.map(color => color.name.toLowerCase());

        const extractedColors = doc
            .nouns()
            .concat(doc.adjectives())
            .out('array')
            .map(word => word.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").trim())
            .filter(word => colorNames.includes(word.toLowerCase()));

        return extractedColors;
    }

    extractTitleFromSentence(sentence) {
        const doc = compromise(sentence);
        const nounPhrases = doc.nouns().data().map(wordObject => wordObject.text.toLowerCase());
        const title = nounPhrases[0];

        return title;
    }

    clearBackgroundGen = () => {
        if (document.getElementById('gen-container').classList.contains('active')) {
            this.setState({
                outputText: "",
                currentIndex: 0,
                aiTextReadOnly: true,
            },
                () => {
                    this.typeText("Specify the type of certificate you would like to genterate");
                })
        }
        document.getElementById('gen-container').classList.remove('active');
    }

    adjustTextAreaHeight() {
        const textArea = document.getElementById('chatgen-textarea');
        if (textArea !== null) {
            textArea.style.height = 'auto'; // Reset height to auto
            textArea.style.height = (textArea.scrollHeight + 20) + 'px'; // Set height to scroll height
        }
    }

    renderBackgroundGen() {
        const sentence = document.getElementById("ai-text").value;
        const extractedTitle = this.state.extractedTitle === "" ? this.extractTitleFromSentence(sentence) : this.state.extractedTitle;
        let colorName = "";
        let colorCodes = [];

        this.setState({
            extractedTitle: extractedTitle,
        })

        if (this.state.colorCodes.length > 0) {
            colorCodes = this.state.colorCodes;
        } else {
            const extractedColors = this.extractColorsFromSentence(sentence);
            colorCodes = extractedColors.map(color => {
                const colorObject = colors.find(c => c.name.toLowerCase() === color.toLowerCase());
                colorName = colorObject.name;
                return colorObject.hex;
            });
        }

        function checkWordsInArray(string, array) {
            const words = string.split(' ');
            for (let i = 0; i < words.length; i++) {
                const wordIndex = array.indexOf(words[i].toLowerCase());
                if (wordIndex !== -1) {
                    return wordIndex;
                }
            }
            return -1;
        }

        let indexOfArray = checkWordsInArray(sentence, this.state.contentTags);

        if (colorCodes[0] === undefined && indexOfArray === -1) {
            this.setState({
                outputText: "",
                currentIndex: 0,
                aiTextReadOnly: true,
            },
                () => {
                    this.typeText("Sorry, I couldn't understand the kind of certificate you require. Maybe I could help you more if I knew what color you prefer your certificate to be.");
                })
            return null;
        } else if (extractedTitle === undefined || indexOfArray !== -1) {
            if (colorCodes[0] !== undefined && indexOfArray !== -1) {
                this.setState({
                    outputText: "",
                    currentIndex: 0,
                    aiTextReadOnly: true,
                    colorCodes: colorCodes,
                    extractedTitle: "Certificate of " + this.state.contentTags[indexOfArray],
                },
                    () => {
                        this.typeText("Please Choose a theme from below...");
                    })
            } else if (colorCodes[0] !== undefined && this.state.colorCodes.length === 0) {
                this.setState({
                    colorCodes: colorCodes,
                    outputText: "",
                    currentIndex: 0,
                    aiTextReadOnly: true,
                },
                    () => {
                        document.getElementById("ai-text").value = "";
                        this.typeText("Great! I have set the color theme to " + colorName + ", If you need i also can create content for you, what do you need the title of this certificate to be.");
                    })
                return null;
            } else if (indexOfArray !== -1) {
                this.setState({
                    outputText: "",
                    currentIndex: 0,
                    aiTextReadOnly: true,
                    extractedTitle: "Certificate of " + this.state.contentTags[indexOfArray],
                },
                    () => {
                        document.getElementById("ai-text").value = "";
                        this.typeText("Okey, I understood that u require a certificate of " + this.state.contentTags[indexOfArray] + ", What color theme would you prefer for it");
                    })
                return null;
            } else {
                this.setState({
                    outputText: "",
                    currentIndex: 0,
                    aiTextReadOnly: true,
                },
                    () => {
                        document.getElementById("ai-text").value = "";
                        this.typeText("Sorry, I couldnt not generate content for " + sentence + ". Try using our editor");
                    })
            }
        } else {
            if (checkWordsInArray(extractedTitle, this.state.contentTags) === -1) {
                this.setState({
                    outputText: "",
                    currentIndex: 0,
                    aiTextReadOnly: true,
                },
                    () => {
                        document.getElementById("ai-text").value = "";
                        this.typeText("Sorry, I couldnt not generate content for " + extractedTitle + ". But i have generated few themes for you.");
                    })
            } else {
                this.setState({
                    outputText: "",
                    currentIndex: 0,
                    aiTextReadOnly: true,
                    colorCodes: [],
                },
                    () => {
                        this.typeText("Please Choose a theme from below");
                    })
            }
        }

        // Shuffle array function
        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }
        // Shuffle the images array
        const shuffledImages = shuffleArray(this.images);

        shuffledImages.slice(0, 2).forEach((imageSrc, index) => {
            const image = new Image();
            const template_name = imageSrc.split("/")[3].split(".")[0];
            image.src = imageSrc;

            image.onload = () => {
                const canvas = this.canvasRefs[index].current;
                if (!canvas) return; // Handle null canvas

                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                canvas.id = template_name;

                ctx.drawImage(image, 0, 0);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const { data } = imageData;

                let greenSum = 0;
                for (let i = 1; i < data.length; i += 4) {
                    greenSum += data[i]; // Accumulate green component
                }
                const greenAvg = greenSum / (data.length / 4); // Calculate average green component

                for (let i = 0; i < data.length; i += 4) {
                    const red = data[i];
                    const green = data[i + 1];
                    const blue = data[i + 2];

                    // Check if pixel has green color gradient (modify condition as needed)
                    if (green > red && green > blue) {
                        // Replace green with red color gradient based on original image intensity
                        const intensity = green / greenAvg;
                        const baseColor = tinycolor(colorCodes[0]);
                        const gradientColor = baseColor.clone().lighten(intensity * 20); // Adjust gradient intensity as needed
                        const rgb = gradientColor.toRgb();
                        data[i] = rgb.r; // Red
                        data[i + 1] = rgb.g; // Green
                        data[i + 2] = rgb.b; // Blue
                    }
                }

                ctx.putImageData(imageData, 0, 0);
            };
        });

        document.getElementById('gen-container').classList.add('active');
    }

    chooseAITemplate = (event) => {
        const canvas = event.target;
        const canvasId = canvas.id + "_" + this.props.user_id;

        canvas.toBlob((blob) => {
            const formData = new FormData();
            formData.append('image', blob, canvasId + '.png');

            axios.post(this.props.node_server_axios + 'upload_ai_template', formData)
                .then(res => {
                    if (res.data.message === 'success') {
                        this.openAiModal();

                        const sentence = document.getElementById("ai-text").value;
                        let found = false;
                        for (let i = 0; i < this.state.contentTags.length; i++) {
                            if (sentence.toLowerCase().includes(this.state.contentTags[i]) || this.state.extractedTitle.toLowerCase().includes(this.state.contentTags[i])) {
                                found = this.state.contentIDs[i];
                                break;
                            }
                        }

                        this.handleTemplateAI(canvasId + "|" + found + "|" + this.state.extractedTitle);
                    }
                });
        }, 'image/png');
    }

    searchCertificates = (e) => {
        axios.post(this.props.node_server_axios + 'search_certificates_by_tags', {
            params: {
                searchTerm: JSON.stringify(e.toLowerCase()),
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        searchTemplateIndex: res.data.searchTemplateIndex,
                        searchTemplateIndexWithFilter: this.state.toggle_cert_type === 'certificates' ? res.data.searchTemplateIndex.filter((str) => !str.endsWith('p')) : res.data.searchTemplateIndex.filter((str) => str.endsWith('p')),
                        toggle_search_type_landscape: this.state.toggle_cert_type === 'certificates' ? true : false,
                        toggle_cert_type: "search",
                        searchTerm: e,
                        searchFocus: false,
                    });
                } else {
                    this.setState({
                        searchFocus: false,
                    });
                }
            } else {
                this.setState({
                    searchFocus: false,
                });
            }
        })
    }

    toggleCertSearchType = () => {
        this.setState({
            searchTemplateIndexWithFilter: !this.state.toggle_search_type_landscape ? this.state.searchTemplateIndex.filter((str) => !str.endsWith('p')) : this.state.searchTemplateIndex.filter((str) => str.endsWith('p')),
            toggle_search_type_landscape: !this.state.toggle_search_type_landscape,
        });
    }

    toggleSearchSuggestions = () => {
        this.setState({
            searchFocus: !this.state.searchFocus,
        });
    }

    addSearchInput = (e) => {
        document.getElementById('search-template').value = e.target.innerHTML;
        this.searchCertificates(e.target.innerHTML);
    }

    handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            this.searchCertificates(event.target.value);
        }
    };

    typeText = (outputText) => {
        const { currentIndex } = this.state;
        if (currentIndex < outputText.length) {
            setTimeout(() => {
                this.setState((prevState) => ({
                    outputText: currentIndex === 0 ? outputText[currentIndex] : prevState.outputText + outputText[currentIndex],
                    currentIndex: prevState.currentIndex + 1,
                }));
                this.typeText(outputText);
                this.adjustTextAreaHeight();
            }, 40); // Adjust typing speed here
        } else {
            this.setState({
                aiTextReadOnly: false,
            });
        }
    };

    checkSigneeInput = (e) => {
        var regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value === "") {
            this.setState({
                addSigneeBtn: false,
                addSigneeNextBtn: false,
            });
        } else if (document.getElementById('signee_name').value !== "" && regEmail.test(document.getElementById('signee_email').value) && document.getElementById('signee_designation').value !== "") {
            this.setState({
                addSigneeBtn: true,
            });
        } else if (document.getElementById('signee_name').value !== "" && document.getElementById('signee_designation').value !== "") {
            this.setState({
                addSigneeNextBtn: true,
            });
        }
    }

    toggleSigneeModal = () => {
        this.setState({
            signeeModal: !this.state.signeeModal,
            nextSigneeModal: false,
        });
    }

    handleSigneeNextStep = () => {
        this.setState({
            nextSigneeModal: true,
        });
    }

    render() {
        switch (this.props.step) {
            case '1':
                return (
                    <div className='container'>
                        {this.state.aiModal && (
                            <div className='ai-modal'>
                                <div className='chatgen-container'>
                                    <textarea id="chatgen-textarea" value={this.state.outputText} readOnly></textarea>
                                </div>
                                <div className='gen-container' id='gen-container'>
                                    <h2>Choose Theme</h2>
                                    {this.canvasRefs.map((canvasRef, index) => (
                                        <canvas key={index} ref={canvasRef} onClick={this.chooseAITemplate} />
                                    ))}
                                </div>
                                <div className='input-container'>
                                    <button className='back-btn' onClick={this.openAiModal}><i className="fa-solid fa-chevron-left"></i> Back</button>
                                    <div className='input'>
                                        <input type='text' placeholder='Create certificate of participation in blue theme' id="ai-text" onChange={this.clearBackgroundGen} readOnly={this.state.aiTextReadOnly} />
                                        {this.state.aiTextReadOnly && (<div className='generating'><i className="fa-solid fa-spinner"></i>Genereating</div>)}
                                    </div>
                                    <button onClick={this.renderBackgroundGen}>Generate <i className="fa-solid fa-wand-magic-sparkles"></i></button>
                                </div>
                            </div>
                        )}
                        <div className='add-new ai' onClick={this.openAiModal}>
                            <i className="fa-solid fa-wand-magic-sparkles"></i>
                            <div>
                                <h1>Create New</h1>
                                <p>Create a certificate with few words</p>
                            </div>
                        </div>
                        {/* <p>Presets</p> */}
                        <div className={this.state.searchFocus ? 'search suggestive-search focused' : 'search suggestive-search'}>
                            <div className='search-bg-blur' onClick={this.toggleSearchSuggestions}></div>
                            <input type='text' placeholder='Search backgrounds' style={{ backgroundColor: "#F9F9F9" }} id="search-template" onFocus={this.toggleSearchSuggestions} onKeyDown={this.handleEnterKey} />
                            <button onClick={() => this.searchCertificates(document.getElementById('search-template').value)}><i className="fa-solid fa-magnifying-glass"></i></button>
                            <div className='suggestions'>
                                <div className='sug-tile' onClick={this.addSearchInput}>School</div>
                                <div className='sug-tile' onClick={this.addSearchInput}>Art</div>
                                <div className='sug-tile' onClick={this.addSearchInput}>Minimal</div>
                                <div className='sug-tile' onClick={this.addSearchInput}>Classic</div>
                                <div className='sug-tile' onClick={this.addSearchInput}>Award</div>
                            </div>
                        </div>
                        {this.state.toggle_cert_type === 'search' ?
                            <div className='toggle'>
                                <div className='switch' onClick={() => this.toggleCertType('certificates')}>
                                    All
                                </div>
                                <div className={this.state.toggle_search_type_landscape ? 'switch active' : 'switch'} onClick={this.toggleCertSearchType}>
                                    Landscape
                                </div>
                                <div className={!this.state.toggle_search_type_landscape ? 'switch active' : 'switch'} onClick={this.toggleCertSearchType}>
                                    Portrait
                                </div>
                            </div>
                            :
                            <div className='toggle'>
                                <div className={this.state.toggle_cert_type === 'certificates' ? 'switch active' : 'switch'} onClick={() => this.toggleCertType('certificates')}>
                                    Landscape
                                </div>
                                <div className={this.state.toggle_cert_type === 'documents' ? 'switch active' : 'switch'} onClick={() => this.toggleCertType('documents')}>
                                    Portrait
                                </div>
                            </div>
                        }
                        {this.state.toggle_cert_type === 'certificates' && (
                            <div className='template-wrapper'>
                                {this.state.certTemplateIndex.map((number) => (
                                    <div className='template-tile' onClick={this.handleTemplate} key={number}><img src={"https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/preview/tem" + encodeURIComponent(number) + ".png"} alt={"tem" + number} /></div>
                                ))}
                            </div>
                        )}
                        {this.state.toggle_cert_type === 'documents' && (
                            <div className='template-wrapper'>
                                {this.state.docTemplateIndex.map((number) => (
                                    <div className='template-tile' onClick={this.handleTemplate} key={number}><img src={"https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/preview/tem" + encodeURIComponent(number) + "p.png"} alt={"tem" + number + "p"} /></div>
                                ))}
                            </div>
                        )}
                        {this.state.toggle_cert_type === 'search' && (
                            <div className='template-wrapper'>
                                {this.state.searchTemplateIndexWithFilter.map((number) => (
                                    <div className='template-tile' onClick={this.handleTemplate} key={number}><img src={"https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/preview/" + encodeURIComponent(number) + ".png"} alt={number} /></div>
                                ))}
                                {this.state.searchTemplateIndexWithFilter.length <= 0 && (<p className='message'><i className="fa-regular fa-face-frown-open"></i>No backgrounds matched your query <span>"{this.state.searchTerm}"</span></p>)}
                            </div>
                        )}
                    </div>
                );
            case '2':
                let text_count = 0;
                return (
                    <div className='container text-editor-nav'>
                        <p>Attributes</p>
                        <div className='attributes'>
                            <div className={this.props.elementIndex.filter(element => element.type === 'paragraph').length === 0 ? 'container' : 'container active'} id="paragraph" data-placeholder="Enter a paragraph here..." onClick={this.props.elementIndex.filter(element => element.type === 'paragraph').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>Paragraph</p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'paragraph').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            <div className={this.props.elementIndex.filter(element => element.type === 'issuingOrganisation').length === 0 ? 'container' : 'container active'} id="issuingOrganisation" data-type="text" data-placeholder="Issuing Organisation" data-readonly={true} data-value={this.props.org_name} onClick={this.props.elementIndex.filter(element => element.type === 'issuingOrganisation').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>Issuing Organization </p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'issuingOrganisation').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            <div className={this.props.elementIndex.filter(element => element.type === 'url').length === 0 ? 'container' : 'container active'} id="url" data-span="URL" data-type="text" data-placeholder="Pronetin @https://www.pronetin.com/" data-value="Pronetin @https://www.pronetin.com/" onClick={this.props.elementIndex.filter(element => element.type === 'url').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>URL</p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'url').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            <div className={this.props.elementIndex.filter(element => element.type === 'validity').length === 0 ? 'container' : 'container active'} id="validity" data-span="Validity" data-type="date" data-placeholder="dd-mm-yyy" onClick={this.props.elementIndex.filter(element => element.type === 'validity').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>Validity</p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'validity').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            <div className={this.props.elementIndex.filter(element => element.type === 'uuid').length === 0 ? 'container' : 'container active'} id="uuid" data-span="Certificate ID" data-type="text" data-placeholder="XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX" data-readonly={true} data-value="XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX" onClick={this.props.elementIndex.filter(element => element.type === 'uuid').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>Certificate UUID</p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'uuid').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            <div className={this.props.elementIndex.filter(element => element.type === 'recepientEmail').length === 0 ? 'container' : 'container active'} id="recepientEmail" data-span="Issued to" data-type="text" data-placeholder="[Email]" data-value="[Email]" onClick={this.props.elementIndex.filter(element => element.type === 'recepientEmail').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>Recipient Email </p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'recepientEmail').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            <div className={this.props.elementIndex.filter(element => element.type === 'description').length === 0 ? 'container' : 'container active'} id="description" data-placeholder="Enter a paragraph here..." onClick={this.props.elementIndex.filter(element => element.type === 'description').length === 0 ? this.handleSendToParent : this.handleElementSelect}>
                                <p>Description</p>
                                <button>{this.props.elementIndex.filter(element => element.type === 'description').length === 0 ? 'Use' : 'Used'}</button>
                            </div>
                            {
                                this.props.elementIndex.map((element, index) => {
                                    if (element.type === 'textField') {
                                        text_count++
                                        return <div className='container active' key={index}><p>Text  {text_count} / 10</p><button>Used</button></div>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </div>
                        <p>Add Table</p>
                        <div className={this.props.elementIndex.filter(element => element.type === 'table').length === 0 ? 'table-input' : 'table-input active'}>
                            <div><input type="number" id="table_rows" min='1' max='9' defaultValue={'3'} readOnly={this.props.elementIndex.filter(element => element.type === 'table').length === 0 ? false : true} /> Rows</div>
                            <div><input type="number" id="table_columns" min='1' max='9' defaultValue={'2'} readOnly={this.props.elementIndex.filter(element => element.type === 'table').length === 0 ? false : true} /> Columns</div>
                            <button onClick={this.props.elementIndex.filter(element => element.type === 'table').length === 0 ? this.handleTableToParent : null}>{this.props.elementIndex.filter(element => element.type === 'table').length === 0 ? 'Use' : 'Used'}</button>
                        </div>
                        <div className='selected-variables'>
                            <p>Selected Variables</p>
                            {
                                this.state.variables.map((element, index) => {
                                    if (element !== undefined && element !== null) {
                                        return <p className='variable' key={index}>{element.replace(/\[|\]/g, '')}</p>
                                    }
                                    return null
                                })
                            }
                        </div>
                        <div className='flex-grow'></div>
                        <div className='add-new sticky-bottom' id="textField" data-placeholder="Type your text here..." onClick={this.handleSendToParent}>
                            <i className="fa-solid fa-square-plus pe-none"></i>
                            <div className='pe-none'>
                                <h1>Add Text</h1>
                                <p>Add a blank text field</p>
                            </div>
                        </div>
                        {
                            this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                        }
                    </div>
                );
            case '3':
                return (
                    <div className='container image-editor-nav'>
                        <p>Add Image/Logo</p>
                        <form className='add-image sticky-top' id="add-image-form">
                            <div className='container'>
                                <label className='add-new' htmlFor="file">
                                    <div className='chosen-image'>
                                        {
                                            this.state.base64string ? <img src={this.state.base64string} alt="uploaded logo" id='preview-image' /> : <i className="fa-solid fa-image"></i>
                                        }
                                    </div>
                                    <div>
                                        {
                                            this.state.base64string ? <p>{this.state.fileName}</p> : <div><h1>Upload</h1><p>Choose a file to upload</p></div>
                                        }
                                    </div>
                                </label>
                                <input onChange={this.handleFileSelected} type='file' id='file' accept="image/jpg, image/jpeg, image/png" />
                            </div>
                        </form>
                        {this.state.base64string && this.state.uploadButton && (<button id='upload-btn' onClick={this.handleImageToParent}><i className="fa-solid fa-upload"></i> Upload Image</button>)}
                        <p>Uploaded Images</p>
                        <div className='user-uploaded-images'>
                            <div className='user-uploaded-images-wrapper'>
                                {this.state.hasOrganisationLogo && (
                                    <div className="image-container" onClick={() => this.handleUploadedToParentOrganisationLogo(this.state.organisationLogoImage)}><img src={this.state.organisationLogoImage} alt="uploaded" id="organisation-logo" /></div>
                                )}
                                {this.state.imageList.map((item, index) => (
                                    item.split('/').pop() !== "ogranisationLogo.png" && (
                                        <div key={index} className="image-container" onClick={(e) => this.handleUploadedToParent(e, item)}><button className="del-btn"><i className="fa-solid fa-xmark"></i></button><img src={"https://pronetin-new.s3.amazonaws.com/" + item + "?cacheblock=" + this.state.cacheTs} alt="uploaded" /></div>
                                    )
                                ))}
                            </div>
                        </div>
                        <p>Add Shapes</p>
                        <div className='shapes'>
                            {/* <div className='container' id="vLine" onClick={this.handleShapeToParent}><div className='vLine'></div></div> */}
                            {/* <div className='container' id="hLine" onClick={this.handleShapeToParent}><div className='hLine'></div></div> */}
                            <div className='container' id="square" onClick={this.handleShapeToParent}><div className='square'></div></div>
                            <div className='container' id="circle" onClick={this.handleShapeToParent}><div className='circle'></div></div>
                        </div>
                        {
                            this.state.progress_loader && (<ProgressLoader />)
                        }
                        {
                            this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                        }
                    </div>
                );
            case '4':
                return (
                    <div className='container attach-editor-nav'>
                        <p>Attach Document</p>
                        <div className='add-pdf'>
                            <label htmlFor="file" className='add-new'>
                                <i className="fa-solid fa-file-circle-plus"></i>
                                <div>
                                    <h1>Upload Document</h1>
                                    <p>Choose a PDF attachment</p>
                                </div>
                            </label>
                            <input onChange={this.handlePDFSelected} type='file' id='file' accept=".pdf" />
                        </div>
                        <div className='info border-box'>
                            <i className="fa-solid fa-circle-info"></i>
                            <p>Uploaded document will be lost when exited or reloaded from this page</p>
                        </div>
                        <p>Preview {this.state.pdf && (<div className='del-btn' onClick={this.removePDF}><i className="fa-regular fa-trash-can"></i> Delete</div>)}</p>
                        {
                            this.state.pdf ?
                                <object data={this.state.pdf} type="application/pdf" width="100%" height="100%">
                                    <div className='preview'>
                                        <i className="fa-solid fa-file-circle-xmark"></i>
                                        Preview<br></br>not available
                                    </div>
                                </object>
                                :
                                <div className='preview'>
                                    <i className="fa-solid fa-file-pdf"></i>
                                    Document<br></br>Preview
                                </div>
                        }
                        {
                            this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                        }
                    </div>
                );
            case '5':
                let signeeCount = 0;
                return (
                    <React.Fragment>
                        {this.state.signeeModal && (
                            <div className='editor-signee-modal'>
                                <div className='content'>
                                    <button className='close-btn' onClick={this.toggleSigneeModal}><i className="fa-solid fa-x"></i></button>
                                    <div className='container'>
                                        <h1>Add New Signing Authority</h1>
                                    </div>
                                    <div className="container" style={this.state.nextSigneeModal ? { display: "none" } : { display: "flex" }}>
                                        <p>Name</p>
                                        <input type='text' placeholder='John Doe' id='signee_name' onChange={this.checkSigneeInput} />
                                        <p>Designation</p>
                                        <textarea placeholder='Designation, Company' id='signee_designation' onChange={this.checkSigneeInput}></textarea>
                                        {this.state.addSigneeNextBtn ?
                                            <button onClick={this.handleSigneeNextStep}>Next <i className="fa-solid fa-arrow-right-long"></i></button>
                                            :
                                            <button className='inactive'>Next <i className="fa-solid fa-arrow-right-long"></i></button>
                                        }
                                    </div>
                                    <div className="container" style={!this.state.nextSigneeModal ? { display: "none" } : { display: "flex" }}>
                                        <p>Email</p>
                                        <input type='email' placeholder='johndoe@gmail.com' id='signee_email' onChange={this.checkSigneeInput} />
                                        <div className='info'>
                                            <i className="fa-solid fa-circle-info"></i>
                                            <p>Notification email to approve the certificate & add signature will be sent to the signing authority after the certificate batch is created. His/Her signature will automatically appear in the certificate when they approve the request.</p>
                                        </div>
                                        {this.state.addSigneeBtn ?
                                            <button onClick={this.handleSignatureToParent}>Proceed <i className="fa-solid fa-arrow-right-long"></i></button>
                                            :
                                            <button className='inactive'>Proceed <i className="fa-solid fa-arrow-right-long"></i></button>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='container signature-editor-nav'>
                            <p>Add Signing Authority</p>
                            <div className='add-signature'>
                                <button onClick={this.toggleSigneeModal}><i className="fa-solid fa-pen-clip"></i> Add Signee</button>
                                <div className='info'>
                                    <i className="fa-solid fa-circle-info"></i>
                                    <p>Signatures will <b>appear</b> in the certificate when approved from the <b>signing authorities pronetin profile</b> after the certificate batch is created for sending.</p>
                                </div>
                            </div>
                            <p>Signees Awaiting Approval</p>
                            <div className='added-signees'>
                                {
                                    this.props.elementIndex.map((element, index) => {
                                        if (element.type === 'signature') {
                                            signeeCount = signeeCount + 1;
                                            return (<div className={this.props.animateSignatureToDelete && signeeCount === 1 && element.content.split("|")[0] === 'help@pronetin.com' ? 'container active toDelete' : 'container active'} key={index}>
                                                <h1>{element.content.split("|")[1]}<p>{element.content.split("|")[0]}</p></h1>
                                                <button onClick={() => this.handleSigneeDelete(element.timeStamp)}><i className="fa-regular fa-trash-can"></i></button>
                                            </div>)
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </React.Fragment>
                );
            case '6':
                return (
                    <div className='container recepient-editor-nav'>
                        <p>Upload Recipients</p>
                        <div className='add-recepient'>
                            <button id='format_download' onClick={this.downloadFormat}><i className="fa-solid fa-table"></i> Download Format</button>
                            <div className='info'>
                                <i className="fa-solid fa-circle-info"></i>
                                <p>Upload files only using the <b>specified CSV format</b> above.</p>
                            </div>
                            <form className='add-image' id="add-csv-form">
                                <div className='container'>
                                    <label className='add-new' htmlFor="file">
                                        <div className='chosen-image'>
                                            <i className="fa-solid fa-file-csv"></i>
                                        </div>
                                        <div>
                                            {
                                                this.state.csvName !== false ? <p className='ellipsis' title={this.state.csvName}>{this.state.csvName}<br></br>{this.state.csvFormat ? <b className='success'>Format verified</b> : <b className='fail'>Format does not match</b>}<label>Choose another file</label></p> : <div><h1>Upload</h1><p>Choose recipient list to upload</p></div>
                                            }
                                        </div>
                                    </label>
                                    <input onChange={this.handleCsvSelected} type='file' id='file' accept=".csv" ref={this.fileInputRef} />
                                </div>
                            </form>
                            <br></br>
                            {this.state.csvFormat ?
                                <button onClick={this.state.csvUploadBtn ? this.handleCsvToParent : null}><i className="fa-solid fa-arrow-up-from-bracket"></i> {this.state.csvUploadBtn ? "Upload" : "Uploading..."}</button>
                                :
                                ""
                            }
                            <div className='info border-box'>
                                <i className="fa-solid fa-circle-info"></i>
                                <p>Recipient data will be lost when exited or reloaded from this page</p>
                            </div>
                        </div>
                        <p>Recipient Info</p>
                        {this.state.csvLength > 0 ?
                            <div className='recepient-info'>
                                <p className='found'>{this.state.csvLength} emails found</p>
                                <p className='validated'>{this.state.csvLength - this.state.csvInvalid.length} <span>emails validated</span></p>
                                {this.state.csvInvalid.length > 0 && (
                                    <div className='failed-emails'>
                                        <p><i className="fa-solid fa-triangle-exclamation"></i> Failed Emails</p>
                                        {this.state.csvInvalid.map(function (item, index) {
                                            return <b key={index}>{item}</b>;
                                        })}
                                    </div>
                                )}
                                {/* <p className='available-credits'>Available Credits <span>363</span></p>
                                <p className='used-credits'>Used Credits <span>{this.state.csvLength - this.state.csvInvalid.length}</span></p> */}
                            </div>
                            :
                            <div className='recepient-info'>
                                <p>No csv uploaded</p>
                            </div>
                        }
                        {
                            this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                        }
                    </div>
                );
            default:
                return (
                    <div className='container'>
                        <p>Invalid Stage Selector</p>
                    </div>
                );
        }
    }
}

export default EditorSubSidenavComponent;