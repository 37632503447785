import React from 'react';
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email,
            code: this.props.code,
        };
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'auth-email-verification', {
            params: {
                email: this.state.email,
                code: this.state.code,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                window.location = '../../signin?o=verified'
            } else {
                window.location = '../../signin?o=failed'
            }
        });
    }

    render() {
        return null;
    }
};

export default App;