import React from 'react';
import Header from '../components/header';

function App() {
    return (
        <div className='main'>
            <Header />
            <div className="privacy-policy">
                <h1>Refund Policy</h1>
                <p>Effective Date: 15th Oct 2023</p>
                <p>Thank you for using Pronetin. This Refund Policy outlines the terms and conditions regarding refunds for our services. By using Pronetin, you agree to comply with and be bound by this Refund Policy.</p>
                <h2>1. Refund Eligibility</h2>

                <p>1.1 Service Fees: Pronetin offers paid services, and refunds may be available for these services, subject to the terms outlined in this policy.</p>

                <p>1.2 Eligibility Period: Refund requests must be submitted within 7 days of the initial purchase of a service.</p>

                <h2>2. Refund Process</h2>

                <p>2.1 Request for Refund: To request a refund, please contact our customer support team at help@pronetin.com. Provide your account information, details of the service for which you are seeking a refund, and the reason for the request.</p>

                <p>2.2 Refund Review: We will review your refund request and assess its eligibility. Refunds are typically processed within 7 to 14 business days.</p>

                <h2>3. Eligible Refund Situations</h2>

                <p>3.1 Service Issues: If you encounter technical issues or problems with the service that prevent you from using it as intended, you may be eligible for a refund. We may request additional information to investigate the issue.</p>

                <p>3.2 Cancellation: You can downgrade your plan/service to free plan at any time as cancellation of your previous plan. If you cancel a paid service within the first 7 days of purchase, you may be eligible for a full refund, if the service provided under the plan is not utilised in any manner.</p>

                <h2>4. Non-Eligible Refund Situations</h2>

                <p>4.1 Change of Mind: Refunds will not be granted if you simply change your mind or decide you no longer want to use the service.</p>

                <p>4.2 Usage: If you have used the service beyond the initial 7-day period, refunds will not be granted.</p>

                <p>4.3 Terms Violation: Refunds will not be granted if you have violated our Terms of Service.</p>

                <h2>5. Payment Method</h2>

                <p>5.1 Third-Party Payments: If you made the payment through a third-party provider (e.g., a payment gateway), the refund process may be subject to their policies and procedures.</p>

                <h2>6. Changes to the Refund Policy</h2>

                <p>Pronetin reserves the right to modify this Refund Policy at any time. Any updates will be posted on our website, and we encourage you to review this policy periodically.</p>

                <h2>7. Contact Information</h2>

                <p>If you have questions or concerns about this Refund Policy, please contact us at support@pronetin.com.</p>

                <p>Thank you for using Pronetin!</p>
            </div>
        </div>
    );
}

export default App;
