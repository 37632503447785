import React from 'react';
import './css/element.css';
import axios from 'axios';

import userImage from '../images/defaultuser.png';
import Warning from '../components/warning';
import WarningAction from '../components/warning_action';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ProgressLoader from '../components/progress_loader';

import countryCodes from '../data/country_codes'

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            document_count: 0,
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            name: '',
            title: '',
            phone: '',
            dob: '',
            promo_code: '',
            gender: '',
            user_category: '',
            user_position: '',
            organisation_name: '',
            shareModal: false,
            file: null,
            imagePreviewUrl: '',
            profileImage: false,
            refreshKey: 0,
            profileImageIcon: userImage,
            total_used_file_size: 0,
            selectedCountryCode: '+91',
            changesDetected: false,
            progress_loader: false,
            transferModal: false,
            transferTo: '',
            transferToValid: false,
            transfer_consent: false,
            transfer_summary: false,
            delete_prompt: false,
            reset_password: false,
        };
        this.cropperRef = React.createRef();
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    industries = [
        "Aerospace and Aviation",
        "Agriculture and Agribusiness",
        "Architecture and Design",
        "Art and Creative",
        "Automotive",
        "Biotechnology",
        "Chemical and Petrochemical",
        "Civil Engineering and Construction",
        "Computer Science",
        "Consulting",
        "Education",
        "Education Technology (EdTech)",
        "Electrical",
        "Electronics",
        "Energy and Utilities",
        "Entertainment and Media",
        "Environmental and Sustainability",
        "Fashion and Apparel",
        "Financial Services",
        "Food and Beverage",
        "Gaming and Interactive Entertainment",
        "Government and Public Administration",
        "Information Technology (IT)",
        "Legal and Law Services",
        "Machine Learning (ML)",
        "Manufacturing",
        "Marine and Maritime",
        "Mechanical Engineering",
        "Medical and Healthcare",
        "Mining and Natural Resources",
        "Nonprofit and Social Services",
        "Pharmaceuticals",
        "Real Estate and Property Development",
        "Renewable Energy",
        "Research and Development",
        "Retail",
        "Robotics",
        "Social Media and Digital Marketing",
        "Sports and Fitness",
        "Telecommunications",
        "Tourism and Hospitality",
        "Transportation and Logistics",
        "Quantum Computing",
        "Play School",
        "Kindergarten",
        "Primary",
        "Upper Primary",
        "High School",
        "Higher Secondary",
        "Home Schooling",
        "Under Graduate",
        "Post Graduate",
        "Post-Doctoral Studies.",
        "Others"
    ];

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'uploaded_document_list', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                document_count: res.data.length > 0 ? res.data.length : 0,
                total_used_file_size: res.data.length > 0 ? res.data.reduce((sum, item) => sum + item.file_size, 0) : 0,
            })
        });
        axios.post(this.props.node_server_axios + 'profile_info', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            let profileComplete = 0;
            let user_category = res.data[0].user_info ? JSON.parse(res.data[0].user_info).user_category : undefined;
            let position = res.data[0].user_info ? JSON.parse(res.data[0].user_info).position : undefined;
            // console.log(position);
            if (res.data[0].user_info !== null && res.data[0].user_info !== '' && user_category !== undefined && user_category.length > 0) {
                profileComplete++;
            }
            if (res.data[0].user_info !== null && res.data[0].user_info !== '' && position !== undefined && position.length > 0) {
                profileComplete++;
            }
            if (res.data[0].phone !== null && res.data[0].phone !== '') {
                profileComplete++;
            }
            if (res.data[0].dob !== null && res.data[0].dob !== '') {
                profileComplete++;
            }
            let profilePercentage = parseInt(profileComplete / 4 * 100);
            document.documentElement.style.setProperty('--circle-percentage', (profilePercentage / 100) * 180 + "deg");

            this.setState({
                name: res.data[0].name,
                title: res.data[0].designation === null ? '' : res.data[0].designation,
                phone: res.data[0].phone === null ? '' : res.data[0].phone.split("-").pop(),
                selectedCountryCode: res.data[0].phone === null ? '' : res.data[0].phone.split("-")[0],
                dob: res.data[0].dob === null ? '' : res.data[0].dob,
                gender: res.data[0].gender === null ? '' : res.data[0].gender,
                user_category: res.data[0].user_info !== null && JSON.parse(res.data[0].user_info).user_category !== undefined ? JSON.parse(res.data[0].user_info).user_category : '',
                user_position: res.data[0].user_info !== null && JSON.parse(res.data[0].user_info).position !== undefined ? JSON.parse(res.data[0].user_info).position : '',
                organisation_name: res.data[0].user_info !== null && JSON.parse(res.data[0].user_info).organisation_name !== undefined ? JSON.parse(res.data[0].user_info).organisation_name : '',
                last_reset: res.data[0].last_reset,
                profilePercentage: profilePercentage,
            })
        });

        fetch("https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + Date.now())
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        profileImage: true,
                        profileImageIcon: "https://pronetin-new.s3.amazonaws.com/user/" + this.props.email + "/profileimage.jpg?cacheblock=" + Date.now(),
                    })
                } else {
                    this.setState({
                        profileImage: false,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });

        // Set input date max to todays date
        var input = document.getElementById('date-dob');
        var today = new Date().toISOString().split('T')[0];
        input.setAttribute('max', today);

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if (this.state.changesDetected) {
            event.preventDefault();
            const customMessage = 'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = customMessage;
        }
    };

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    changeDesignation = (e) => {
        this.setState({
            title: e.target.value.slice(0, 128),
            changesDetected: true,
        })
    }
    changePhone = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.setState({
            phone: numericValue,
            changesDetected: true,
        })
    }
    changeGender = (e) => {
        this.setState({
            gender: e.target.value,
            changesDetected: true,
        })
    }
    changeDob = (e) => {
        this.setState({
            dob: e.target.value,
            changesDetected: true,
        })
    }
    changePromoCode = (e) => {
        this.setState({
            promo_code: e.target.value,
        })
    }
    changeName = (e) => {
        this.setState({
            name: e.target.value,
        })
    }
    changeUserCategory = (e) => {
        this.setState({
            user_category: e.target.value,
            changesDetected: true,
        })
    }
    changeUserPosition = (e) => {
        this.setState({
            user_position: e.target.value,
            changesDetected: true,
        })
    }
    changeOrganisationName = (e) => {
        this.setState({
            organisation_name: e.target.value,
            changesDetected: true,
        })
    }
    handleCountryCodeChange = (e) => {
        this.setState({
            selectedCountryCode: e.target.value,
            changesDetected: true,
        });
    };

    updateProfile = () => {
        axios.post(this.props.node_server_axios + 'update_profile_info', {
            params: {
                email: this.props.email,
                title: this.state.title,
                phone: this.state.selectedCountryCode + "-" + this.state.phone,
                gender: this.state.gender,
                dob: this.state.dob,
                user_category: this.state.user_category,
                position: this.state.user_position,
                organisation_name: this.state.organisation_name,
            },
        }).then(res => {
            if (res.data.message === 'updated') {
                let profileComplete = 0
                if (this.state.user_category !== null && this.state.user_category !== '') {
                    profileComplete++;
                }
                if (this.state.user_position !== null && this.state.user_position !== '') {
                    profileComplete++;
                }
                if (this.state.phone !== null && this.state.phone !== '') {
                    profileComplete++;
                }
                if (this.state.dob !== null && this.state.dob !== '') {
                    profileComplete++;
                }
                let profilePercentage = parseInt(profileComplete / 4 * 100);
                document.documentElement.style.setProperty('--circle-percentage', (profilePercentage / 100) * 180 + "deg");

                this.setState({
                    warning: {
                        message: "Profile Updated.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    profilePercentage: profilePercentage,
                    changesDetected: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        });
    }

    requestNameChange = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Change Name",
                subtext: "The Name can be changed only once in 90 days, Are you sure you want to continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        let currentDate = new Date();
        let year = currentDate.getFullYear().toString();
        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        let day = currentDate.getDate().toString().padStart(2, '0');
        let formattedDate = year + month + day;

        if (this.state.last_reset === null || parseInt(formattedDate) - parseInt(this.state.last_reset) > 90) {
            axios.post(this.props.node_server_axios + 'update_profile_name', {
                params: {
                    email: this.props.email,
                    name: this.state.name,
                    last_reset: formattedDate,
                },
            }).then(res => {
                if (res.data.message === 'updated') {
                    this.setState({
                        warning: {
                            message: "Profile Name Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                    this.props.fetchDataUpdate({ type: "name", value: this.state.name });
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Sorry, Name can be change only after " + (90 - (parseInt(formattedDate) - parseInt(this.state.last_reset))) + " days.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            })
        }
    }

    toggleSharePanel = () => {
        this.setState({
            shareModal: !this.state.shareModal,
        })
    }

    handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        // check file type
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.setState({
                warning: {
                    message: "Please select a JPG or PNG image file.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        } else if (file.size / 1024 > 3000) {
            this.setState({
                warning: {
                    message: "Max file size allowed is 3Mb.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                }
            });
            return;
        }

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };

    handleSubmit = async (e) => {
        this.setState({
            progress_loader: true,
        })
        e.preventDefault();
        let reader = new FileReader();
        const cropper = this.cropperRef.current.cropper;
        if (!cropper) {
            return;
        }

        const croppedCanvas = cropper.getCroppedCanvas();
        croppedCanvas.toBlob((blob) => {
            const croppedFile = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
            this.setState({ file: croppedFile });
            const file = croppedFile;
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('image', file);
            axios.post(this.props.node_server_axios + 'update_profile_image', formData
            ).then(res => {
                if (res.data.message === 'updated') {
                    this.setState((prevState) => ({
                        warning: {
                            message: "Profile Image Updated.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        shareModal: false,
                        refreshKey: prevState.refreshKey + 1,
                        profileImageIcon: reader.result,
                        progress_loader: false,
                    }))
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        }, 'image/jpeg');
    };

    applyPromo = () => {
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                    org_id: res.data.org_id,
                });
                if (res.data.user_type === 'organisation') {
                    axios.post(this.props.node_server_axios + 'apply_promo', {
                        params: {
                            email: res.data.email,
                            user_type: res.data.user_type,
                            org_id: res.data.org_id,
                            promo_code: this.state.promo_code,
                        }
                    }).then((res) => {
                        if (res.data !== undefined && res.data.message === 'success') {
                            //---------------------Logging Data (Organisation) Start---------------------
                            axios.post(this.props.node_server_axios + 'update_organisation_log', {
                                params: {
                                    email: this.props.email,
                                    dateTS: this.logFormattedDate(new Date()),
                                    creditsConsumed: '+' + this.state.promo_code.match(/\d+$/)[0],
                                    content: "Promo Code: " + this.state.promo_code + " was applied",
                                },
                            })
                            //----------------------Logging Data (Organisation) End----------------------
                            this.setState({
                                warning: {
                                    message: res.data.value,
                                    type: "success",
                                    learnmore: false,
                                    autoclose: true,
                                }
                            })
                            this.props.fetchDataUpdate({ type: "credits", value: res.data.new_credits });
                        } else if (res.data !== undefined) {
                            this.setState({
                                warning: {
                                    message: res.data.value,
                                    type: "warning",
                                    learnmore: false,
                                    autoclose: true,
                                }
                            })
                        }
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, You need an organisation account to redeem promo codes.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })
    }

    deleteUserPermanently = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete User",
                subtext: "This action will delete all your data including certificates and documents, Are you sure you want to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'delete_user_permanently').then(res => {
            if (res.data.message === 'user deleted') {
                localStorage.removeItem('user_info_skip');
                localStorage.removeItem('profile-icon-color');
                axios.defaults.withCredentials = true;
                axios.post(this.props.node_server_axios + 'signout').then((res) => {
                    if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                        window.location = res.data.redirect;
                    }
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    sendTransferConsentEmail = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.transferTo !== this.props.email && this.state.transferToValid) {
            axios.post(this.props.node_server_axios + 'consent_for_transfer', {
                params: {
                    transferTo: this.state.transferTo,
                },
            }).then(res => {
                if (res.data.message === 'consent sent') {
                    this.setState({
                        warning: {
                            message: "Consent email has been sent to " + this.state.transferTo + ".",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        progress_loader: false,
                        transferTo: '',
                        transferToValid: false,
                        transferModal: false,
                        transfer_consent: false,
                    })
                } else if (res.data.message === 'already sent') {
                    this.setState({
                        warning: {
                            message: "You have already send consent email to this user.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                        transferTo: '',
                        transferToValid: false,
                        transferModal: false,
                        transfer_consent: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Cannot transfer to this email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    transferUserPermanently = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.transferTo !== this.props.email && this.state.transferToValid) {
            axios.post(this.props.node_server_axios + 'transfer_user_permanently', {
                params: {
                    transferTo: this.state.transferTo,
                },
            }).then(res => {
                if (res.data.message === 'user transfered') {
                    this.setState({
                        warning: {
                            message: "All data has been transfered to " + this.state.transferTo + ".",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        progress_loader: false,
                        transferTo: '',
                        transferToValid: false,
                        transferModal: false,
                        transfer_summary: res.data.transfer_state,
                        delete_prompt: true,
                    })
                } else if (res.data.message === 'few failed') {
                    this.setState({
                        warning: {
                            message: "Few things have failed to transfer.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                        transferTo: '',
                        transferToValid: false,
                        transferModal: false,
                        transfer_summary: res.data.transfer_state,
                        delete_prompt: false,
                    })
                } else if (res.data.message === 'user not found') {
                    this.setState({
                        warning: {
                            message: "No user found at this email address.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else if (res.data.message === 'no consent') {
                    this.setState({
                        warning: {
                            message: "This user has not yet given consent for this transfer.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                        transfer_consent: true,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Cannot transfer to this email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    toggleTransferModal = (e) => {
        this.setState({
            transferModal: !this.state.transferModal,
        })
    }

    updateTransferToEmail = (e) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.setState({
            transferTo: e.target.value,
            transferToValid: emailRegex.test(e.target.value),
        })
    }

    closeTransferSummary = () => {
        this.setState({
            transfer_summary: false,
        })
    }

    changePassword = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'forgot', {
            params: {
                data: {
                    email: this.props.email.toLowerCase().replace(/\s+/g, ''),
                }
            },
        }).then(res => {
            if (res.data.message === 'Check your inbox for reset email') {
                this.setState({
                    warning: {
                        message: "Link to reset password has been sent to your email.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                    reset_password: true,
                })
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    render() {
        let percent_storage_used = Math.floor((this.state.total_used_file_size / (this.props.storage * 1024)) * 100);
        percent_storage_used = percent_storage_used > 0 ? percent_storage_used : 0;
        let css_level = Math.floor(70 - percent_storage_used);

        let { imagePreviewUrl } = this.state;

        return (
            <React.Fragment>
                <div className="element">
                    <div className="container settings-container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Edit<label>Personal profile</label></h1>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles settings'>
                            {this.state.shareModal && (
                                <div className='element-modal'>
                                    <div className='content'>
                                        <button className='close-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-x"></i></button>
                                        <div className='container'>
                                            <h1>Upload Image</h1>
                                        </div>
                                        {imagePreviewUrl ?
                                            <Cropper
                                                style={{ height: 400, width: "100%" }}
                                                ref={this.cropperRef}
                                                src={imagePreviewUrl}
                                                aspectRatio={1} // Square shape
                                                guides={true}
                                                dragMode="crop"
                                            />
                                            :
                                            <p>Choose your profile image</p>
                                        }
                                        <div className='container'>
                                            <input type='file' placeholder='Email' accept=".jpg,.png" onChange={this.handleImageChange} />
                                            <button type="button" onClick={this.handleSubmit}>Upload</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.transferModal && (
                                <div className='element-modal transfer-modal'>
                                    <div className='content'>
                                        <button className='close-btn' onClick={this.toggleTransferModal}><i className="fa-solid fa-x"></i></button>
                                        <div className='container'>
                                            <h1>Transfer Certificates To</h1>
                                        </div>
                                        <form className='container'>
                                            <input type='text' placeholder='Email' value={this.state.transferTo} onChange={this.updateTransferToEmail} required />
                                            {this.state.transferToValid && (<p className='email-validated'><i className="fa-solid fa-circle-check"></i> Email validated</p>)}
                                            {!this.state.transfer_consent ?
                                                <p>Transferring the account will move/transfer all certificates permanently to a new account with the above email. This action cannot be reverted.</p>
                                                :
                                                <p>Before initiating transfer the receiver should consent this. Please ask the reciver to click on consent button sent via email</p>
                                            }
                                            {!this.state.transfer_consent ?
                                                <button type="button" onClick={this.transferUserPermanently}>Transfer <i className="fa-solid fa-arrow-right"></i></button>
                                                :
                                                <button type="button" onClick={this.sendTransferConsentEmail}>Send Consent Email <i className="fa-solid fa-arrow-right"></i></button>
                                            }
                                        </form>
                                    </div>
                                </div>
                            )}
                            {this.state.transfer_summary && (
                                <div className='element-modal transfer-modal'>
                                    <div className='content'>
                                        <button className='close-btn' onClick={this.closeTransferSummary}><i className="fa-solid fa-x"></i></button>
                                        <div className='container'>
                                            <h1>Transfer Summary</h1>
                                        </div>
                                        <div className='container transfer-summary'>
                                            <p>Certificates : {this.state.transfer_summary.certificates ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.certificates}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                            <p>Appreciations : {this.state.transfer_summary.appreciation ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.appreciation}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                            <p>Documents : {this.state.transfer_summary.documents ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.documents}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                            <p>Signature : {this.state.transfer_summary.signature ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.signature}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                        </div>
                                        <hr></hr>
                                        {this.state.delete_prompt && (
                                            <div className='container'>
                                                <p>Would you like to delete this account?</p>
                                                <button className='red-btn' onClick={this.deleteUserPermanently}>Delete Account</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='sub-nav'>
                                <div className='profile-progress'>
                                    <div className={percent_storage_used > 90 ? "wave red" : "wave"} style={{ "--storage-var": css_level + "%" }}></div>
                                </div>
                                <div className='profile-storage'>
                                    Storage
                                    <label>{percent_storage_used > 100 ? 100 : percent_storage_used}%<b>consumed</b></label>
                                    <button onClick={() => window.location.href = "/buy"}>Buy Storage</button>
                                </div>
                            </div>
                            <div className='wrapper'>
                                {this.state.profilePercentage < 100 && (
                                    <div className='complete-profile'>
                                        <div className='content'>
                                            <h2>Your Profile is almost complete !</h2>
                                            <p>To successfully complete your profile</p>
                                            <p className='pointer'>{this.state.phone !== null && this.state.phone.length > 0 ? <i className="fa-solid fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Update your contact number</p>
                                            <p className='pointer'>{this.state.dob !== null && this.state.dob.length > 0 ? <i className="fa-solid fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Update your date of birth</p>
                                            <p className='pointer'>{this.state.user_category !== null && this.state.user_category.length > 0 ? <i className="fa-solid fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Update your about section</p>
                                            <p className='pointer'>{this.state.user_position !== null && this.state.user_position.length > 0 ? <i className="fa-solid fa-circle-check green"></i> : <i className="fa-regular fa-circle-xmark red"></i>} Update your Industry / Sector</p>
                                        </div>

                                        <div className="circle-wrap">
                                            <div className="circle">
                                                <div className="mask full">
                                                    <div className="fill"></div>
                                                </div>
                                                <div className="mask half">
                                                    <div className="fill"></div>
                                                </div>
                                                <div className="inside-circle">
                                                    {this.state.profilePercentage}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='row-container'>
                                    <div className='profile-icon-edit'>
                                        <img key={this.state.refreshKey} src={this.state.profileImageIcon} alt="user icon"></img>
                                        <button className='edit-btn' onClick={this.toggleSharePanel}><i className="fa-solid fa-pen"></i></button>
                                    </div>
                                    <div className='column-container'>
                                        <p>Account Type</p>
                                        <h1>{this.props.user_type === 'organisation' ? 'organiser' : this.props.user_type}</h1>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Name</p>
                                    <div className='row-container'>
                                        <input className='gray' type='text' placeholder='Full Name' name='name' value={this.state.name} onChange={this.changeName}></input>
                                        <button onClick={this.requestNameChange}>Request Change</button>
                                    </div>
                                    <p className='info'>Name can be only changed once in 90 days</p>
                                </div>

                                <div className='column-container'>
                                    <p>Password</p>
                                    <div className='row-container'>
                                        {this.state.reset_password ? <div className='profile-message'>Reset password link has been sent to your email (<b>{this.props.email}</b>), you can continue to change your password from that link.</div> : <button onClick={this.changePassword}>Change Password</button>}
                                    </div>
                                </div>


                                <div className='column-container'>
                                    <p>Bio</p>
                                    <div className='row-container'>
                                        <input type='text' placeholder='Few things unique to you...' name='designation' value={this.state.title} onChange={this.changeDesignation}></input>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Phone</p>
                                    <div className='row-container'>
                                        <select
                                            value={this.state.selectedCountryCode}
                                            onChange={this.handleCountryCodeChange}
                                        >
                                            <option value="" disabled>Select Country Code</option>
                                            {countryCodes.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {`${country.name} (${country.code})`}
                                                </option>
                                            ))}
                                        </select>
                                        <input type='text' inputMode="numeric" pattern="\d*" placeholder='Phone' name='phone' value={this.state.phone} onChange={this.changePhone}></input>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Date of birth</p>
                                    <div className='row-container'>
                                        <input type='date' placeholder='DOB' name='dob' id='date-dob' value={this.state.dob} onChange={this.changeDob}></input>
                                    </div>
                                </div>


                                <div className='column-container'>
                                    <p>Gender</p>
                                    <div className='row-container'>
                                        <select type='text' placeholder='Gender' name='gender' value={this.state.gender} onChange={this.changeGender}>
                                            <option value={'male'}>Male</option>
                                            <option value={'female'}>Female</option>
                                            <option value={'other'}>Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Registered Email</p>
                                    <div className='row-container'>
                                        <input className='gray' type='text' placeholder='Email' name='email' value={this.props.email} readOnly></input>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>About Me</p>
                                    <div className='row-container'>
                                        <select type='text' placeholder='User Category' value={this.state.user_category} onChange={this.changeUserCategory}>
                                            <option value='' disabled>Choose</option>
                                            <option value={'student'}>Student</option>
                                            <option value={'professional'}>Professional</option>
                                            <option value={'others'}>Other</option>
                                        </select>
                                        <input type='text' placeholder='Organisation Name' name='organisation_name' value={this.state.organisation_name} onChange={this.changeOrganisationName}></input>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Industry / Sector</p>
                                    <div className='row-container'>
                                        {Array.isArray(this.industries) && (
                                            <select placeholder='Industry' value={this.state.user_position} onChange={this.changeUserPosition}>
                                                <option value='' disabled>Choose</option>
                                                {this.industries.map((element, index) =>
                                                    <option value={element} key={index}>{element}</option>
                                                )}
                                            </select>
                                        )}
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Offers</p>
                                    <div className='row-container'>
                                        <input type='text' placeholder='Promo Code' name='promoCode' onChange={this.changePromoCode}></input>
                                        <button onClick={this.applyPromo}>Redeem</button>
                                    </div>
                                </div>

                                <div className='row-container danger-zone'>
                                    <p>Warning</p><hr></hr>
                                </div>

                                <div className='column-container'>
                                    <p>Delete Account</p>
                                    <div className='row-container'>
                                        <p className='text'>Once deleted, it will be gone forever.<br></br>Please be certain.</p>
                                        <button className='red-btn' onClick={this.deleteUserPermanently}>Delete Account</button>
                                    </div>
                                </div>

                                <div className='column-container'>
                                    <p>Transfer Account</p>
                                    <div className='row-container'>
                                        <p className='text'>Once transfered, it will only be linked to new account.<br></br>Please be certain.</p>
                                        <button className='red-btn' onClick={this.toggleTransferModal}>Transfer Account</button>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                        <div className='apply-btn'>
                            <button onClick={this.updateProfile}><i className="fa-regular fa-floppy-disk"></i> <p>Save</p></button>
                        </div>
                    </div>
                </div>
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default ProfileComponent;