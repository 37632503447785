import React, { Component } from 'react';

class CodeBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false
        };
    }

    copyCodeToClipboard = () => {
        if (this.props.code) {
            if (window.isSecureContext) {
                // If the page is served over HTTPS
                navigator.clipboard.writeText(this.props.code)
                    .then(() => {
                        this.setState({ copied: true });
                        setTimeout(() => {
                            this.setState({ copied: false });
                        }, 2000);
                    })
                    .catch(err => {
                        console.error('Failed to copy:', err);
                    });
            } else {
                // If the page is not served over HTTPS
                const textArea = document.createElement('textarea');
                textArea.value = this.props.code;
                textArea.style.position = 'fixed';
                textArea.style.top = 0;
                textArea.style.left = 0;
                textArea.style.width = '2em';
                textArea.style.height = '2em';
                textArea.style.padding = 0;
                textArea.style.border = 'none';
                textArea.style.outline = 'none';
                textArea.style.boxShadow = 'none';
                textArea.style.background = 'transparent';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        this.setState({ copied: true });
                        setTimeout(() => {
                            this.setState({ copied: false });
                        }, 2000);
                    } else {
                        console.error('Fallback: Copying text command was unsuccessful');
                    }
                } catch (err) {
                    console.error('Fallback: Unable to copy', err);
                }
                document.body.removeChild(textArea);
            }
        }
    }

    render() {
        return (
            <div className='code-block'>
                <div className='head'>
                    <p>{this.props.language}</p>
                    <button onClick={this.copyCodeToClipboard}>
                        <i className="fa-regular fa-copy"></i> {this.state.copied ? 'Copied!' : 'Copy'}
                    </button>
                </div>
                {this.props.inline ?
                    <p>
                        {this.props.content}
                        <code>{this.props.code}</code>
                    </p>
                    :
                    <pre>
                        <code>
                            {this.props.code}
                        </code>
                    </pre>
                }
            </div>
        );
    }
}

export default CodeBlock;
