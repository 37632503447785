import React from 'react';
import './css/element.css';
import './css/organisation.css';
import axios from 'axios';

import Warning from './warning';

class EventsList extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            eventList: [],
            uniqueEvents: [],
            filteredEvent: false,
            filteredEventValue: '',
            moreBtn: 'events',
            loadingEvents: true,
            selectedIndex: null,
            sortBy: 'date',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_events', {
            params: {
                query: "all",
            },
        }).then(res => {
            if (res.data.eventList) {
                this.setState({
                    eventList: res.data.eventList,
                    uniqueEvents: this.state.sortBy === 'name' ?
                        Array.from(new Set(res.data.eventList.filter(obj => obj.batches_status).map(obj => obj.event)))
                        :
                        Array.from(new Set(res.data.eventList
                            .filter(obj => obj.batches_status)
                            .sort((a, b) => {
                                // Custom sort function to compare modified_on dates
                                const dateA = this.convertDateToSortableFormat(a.modified_on);
                                const dateB = this.convertDateToSortableFormat(b.modified_on);
                                return dateB.localeCompare(dateA);
                            })
                            .map(obj => obj.event))),
                    loadingEvents: false,
                })
            } else {
                this.setState({
                    loadingEvents: false,
                })
            }
        });
    }

    convertDateToSortableFormat(dateString) {
        const parts = dateString.split('/');
        return `${parts[2]}/${parts[1].padStart(2, '0')}/${parts[0].padStart(2, '0')}`;
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    handleEventClick = (index) => {
        this.setState({ selectedIndex: index });
    }

    handleScrollRight = (event) => {
        // Find the parent caret-container of the clicked button
        const caretContainer = event.target.closest('.caret-container');
        // Find the corresponding .subevent-template-wrapper element
        const subeventTemplateWrapper = caretContainer.querySelector('.subevent-template-wrapper');
        // Scroll the .subevent-template-wrapper 220px to the right
        if (subeventTemplateWrapper) {
            subeventTemplateWrapper.scrollLeft += 220;
        }
    };

    handleScrollLeft = (event) => {
        // Find the parent caret-container of the clicked button
        const caretContainer = event.target.closest('.caret-container');
        // Find the corresponding .subevent-template-wrapper element
        const subeventTemplateWrapper = caretContainer.querySelector('.subevent-template-wrapper');
        // Scroll the .subevent-template-wrapper 220px to the right
        if (subeventTemplateWrapper) {
            subeventTemplateWrapper.scrollLeft -= 220;
        }
    };

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'events',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    updateSort = (e) => {
        this.setState({
            sortBy: e.target.value,
            uniqueEvents: e.target.value === 'name' ?
                Array.from(new Set(this.state.eventList.filter(obj => obj.batches_status).map(obj => obj.event)))
                :
                Array.from(new Set(this.state.eventList
                    .filter(obj => obj.batches_status)
                    .sort((a, b) => {
                        // Custom sort function to compare modified_on dates
                        const dateA = this.convertDateToSortableFormat(a.modified_on);
                        const dateB = this.convertDateToSortableFormat(b.modified_on);
                        return dateB.localeCompare(dateA);
                    })
                    .map(obj => obj.event))),
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>All Events<label>View all events in your organisation</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace batches-new'>
                            <div className='headboard'>
                                <div className='head-container'>
                                    <h2>Created Events <span>and it's groups</span></h2>
                                </div>
                                <div className='sort-container'>
                                    <p>Sort&nbsp;by</p>
                                    <hr></hr>
                                    <select onChange={this.updateSort} value={this.state.sortBy}>
                                        <option value={'name'}>Name</option>
                                        <option value={'date'}>Date</option>
                                    </select>
                                </div>
                            </div>
                            <div className='wrapper pad5'>
                                {
                                    this.state.uniqueEvents.length > 0 ?
                                        this.state.uniqueEvents.map((element, index) => {
                                            return (
                                                <div className='event-wrapper' key={index}>
                                                    <div className={this.state.selectedIndex === index ? 'event-name-container active' : 'event-name-container'} onClick={() => this.handleEventClick(index)}>
                                                        <p key={index}>{this.state.selectedIndex === index && (<i className="fa-regular fa-circle-dot"></i>)}{element}</p>
                                                        <label>Groups : {this.state.eventList.filter(obj => obj.event === element).length}</label>
                                                        <label>Created on : {this.state.eventList.filter(obj => obj.event === element)[0].modified_on} {this.state.eventList.some(obj => obj.event === element && obj.batches_status.includes('ready')) && (<div className='ready'>Ready to Send</div>)}</label>
                                                    </div>
                                                    <div className='caret-container' style={{ display: this.state.selectedIndex === index ? 'block' : 'none' }}>
                                                        <div className='left-caret' onClick={this.handleScrollLeft}>
                                                            <i className="fa-solid fa-caret-left"></i>
                                                        </div>
                                                        <div className='subevent-template-wrapper' ref={this.containerRef}>
                                                            <div className='template-wrapper'>
                                                                {
                                                                    this.state.eventList.filter(obj => obj.event === element).map((item, i) => {
                                                                        let pending_count = item.batches_status.filter(obj => obj === 'ready').length
                                                                        return (
                                                                            <div className='template-subevent-new' key={i} onClick={() => { window.location.href = '/subevents/' + item.event.replace(/\s+/g, '-').replace(/-+/g, '-').trim() + '/' + item.subevent.replace(/\s+/g, '-').replace(/-+/g, '-').trim() }}>
                                                                                <div className='subevent'>{item.subevent}</div>
                                                                                <div className='data'>
                                                                                    <div className='content'>
                                                                                        <p>Batches : {item.batches.length}</p>
                                                                                        <p>Certificates : {item.batches_count.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)}</p>
                                                                                        <p>Modified on : {item.modified_on}</p>
                                                                                    </div>
                                                                                    {pending_count > 0 && (
                                                                                        <div className='notification'><i className="fa-solid fa-check"></i> {pending_count}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='right-caret' onClick={this.handleScrollRight}>
                                                            <i className="fa-solid fa-caret-right"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        !this.state.loadingEvents ?
                                            <div className='create-certificate-message'>
                                                <button className='btn-issue' onClick={this.checkEligibility}>Create your first certificate</button>
                                                <p>After creating the certificate, come back here to distribute the same !</p>
                                            </div>
                                            :
                                            <div className='create-certificate-message'>
                                                <p>Loading...</p>
                                            </div>
                                }
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EventsList;