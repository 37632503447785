import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

import candidate from '../../images/home/Features/Hiring/access profiles.png';
import search from '../../images/home/Features/Hiring/advanced filter.png';
import verify from '../../images/home/Features/Hiring/quick verification.png';
import recruitment from '../../images/home/Features/Hiring/efficient recruitment.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        const { activeAnchor } = this.state;

        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>For Hiring</h2>
                            <p>build your identity with pronetin</p>
                        </div>
                        <div className='features-page'>
                            <div className='bg-pattern'></div>
                            <div className='navigation'>
                                <a href='#foo' className={activeAnchor === 'n1' ? 'active' : ''} data='n1' onClick={this.handleAnchorClick}>Verify Certificates Instantly</a>
                                <a href='#foo' className={activeAnchor === 'n3' ? 'active' : ''} data='n3' onClick={this.handleAnchorClick}>Access Candidate Profiles</a>
                                {/* <a href='#foo' className={activeAnchor === 'n5' ? 'active' : ''} data='n5' onClick={this.handleAnchorClick}></a> */}
                                <a href='#foo' className={activeAnchor === 'n2' ? 'active' : ''} data='n2' onClick={this.handleAnchorClick}>Efficient Recruitment Process</a>
                                <a href='#foo' className={activeAnchor === 'n4' ? 'active' : ''} data='n4' onClick={this.handleAnchorClick}>Advanced Search and Filters</a>
                                {/* <a href='#foo' className={activeAnchor === 'n6' ? 'active' : ''} data='n6' onClick={this.handleAnchorClick}></a> */}
                            </div>
                            <div className='container'>
                                <div className='col'>
                                    <div className='wrapper' id='n1'>
                                        <div className='image'><img src={verify} alt="verify" /></div>
                                        <div className='content'>
                                            <h3>Verify Certificates Instantly</h3>
                                            <p>Use QR code verification to instantly validate the authenticity of certificates submitted by candidates.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n3'>
                                        <div className='image'><img src={candidate} alt="candidate" /></div>
                                        <div className='content'>
                                            <h3>Access Candidate Profiles</h3>
                                            <p>View detailed profiles of job applicants, including their stored certificates and professional documents.</p>
                                        </div>
                                    </div>
                                    {/* <div className='wrapper' id='n5'>
                                        <div className='image'></div>
                                        <div className='content'>
                                            <h3></h3>
                                            <p></p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='col'>
                                    <div className='wrapper' id='n2'>
                                        <div className='image'><img src={recruitment} alt="recruitment" /></div>
                                        <div className='content'>
                                            <h3>Efficient Recruitment Process</h3>
                                            <p>Streamline the hiring process by accessing verified documents and certificates directly through Pronetin.</p>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n4'>
                                        <div className='image'><img src={search} alt="search" /></div>
                                        <div className='content'>
                                            <h3>Advanced Search and Filters</h3>
                                            <p>Use advanced search and filtering options to find candidates with specific certifications or qualifications.</p>
                                        </div>
                                    </div>
                                    {/* <div className='wrapper' id='n6'>
                                        <div className='image'></div>
                                        <div className='content'>
                                            <h3></h3>
                                            <p></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;