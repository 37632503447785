import React from 'react';
import axios from 'axios';

import Warning from '../warning';
import WarningAction from '../warning_action';

import ProgressLoader from '../../components/progress_loader';

class DataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            transferModal: false,
            transferTo: '',
            transferToValid: false,
            transfer_consent: false,
            transfer_summary: false,
            delete_prompt: false,
            reset_password: false,
            progress_loader: false,
            otp: '',
            pass: '',
            passAuthModal: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    deleteUserPermanently = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete User",
                subtext: "This action will delete all your data including certificates and documents, Are you sure you want to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        if (this.props.user_type === 'organisation') {
            this.setState({
                warning: {
                    message: "Sorry, You are part of an organisation. Transfer / Delete / Leave the organisation to delete your account.",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                },
                progress_loader: false,
            })
        } else {
            axios.post(this.props.node_server_axios + 'delete_user_permanently').then(res => {
                if (res.data.message === 'user deleted') {
                    localStorage.removeItem('user_info_skip');
                    localStorage.removeItem('profile-icon-color');
                    axios.defaults.withCredentials = true;
                    axios.post(this.props.node_server_axios + 'signout').then((res) => {
                        if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                            window.location = res.data.redirect;
                        }
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        }
    }

    sendTransferConsentEmail = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.transferTo !== this.props.email && this.state.transferToValid) {
            axios.post(this.props.node_server_axios + 'consent_for_transfer', {
                params: {
                    transferTo: this.state.transferTo,
                },
            }).then(res => {
                if (res.data.message === 'consent sent') {
                    this.setState({
                        warning: {
                            message: "Consent email has been sent to " + this.state.transferTo + ".",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        progress_loader: false,
                        transfer_consent: true,
                    })
                } else if (res.data.message === 'already sent') {
                    this.setState({
                        warning: {
                            message: "You have already send consent email to this user.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                        transfer_consent: true,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Cannot transfer to this email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    transferUserPermanently = () => {
        this.setState({
            progress_loader: true,
        })
        if (this.state.transferTo !== this.props.email && this.state.transferToValid) {
            axios.post(this.props.node_server_axios + 'transfer_user_permanently', {
                params: {
                    transferTo: this.state.transferTo,
                },
            }).then(res => {
                if (res.data.message === 'user transfered') {
                    this.setState({
                        warning: {
                            message: "All data has been transfered to " + this.state.transferTo + ".",
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        progress_loader: false,
                        transferTo: '',
                        transferToValid: false,
                        transferModal: false,
                        transfer_summary: res.data.transfer_state,
                        delete_prompt: true,
                    })
                } else if (res.data.message === 'few failed') {
                    this.setState({
                        warning: {
                            message: "Few things have failed to transfer.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                        transferTo: '',
                        transferToValid: false,
                        transferModal: false,
                        transfer_summary: res.data.transfer_state,
                        delete_prompt: false,
                    })
                } else if (res.data.message === 'user not found') {
                    this.setState({
                        warning: {
                            message: "No Pronetin account found at this email address.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                } else if (res.data.message === 'no consent') {
                    this.setState({
                        warning: {
                            message: "This user has not yet given consent for this transfer.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                    this.sendTransferConsentEmail();
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Cannot transfer to this email.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
                progress_loader: false,
            })
        }
    }

    toggleTransferModal = (e) => {
        this.setState({
            transferModal: !this.state.transferModal,
        })
    }

    togglePassAuthModal = (modal) => {
        this.setState({
            passAuthModal: !this.state.passAuthModal,
            actionModal: modal,
        })
    }

    updateTransferToEmail = (e) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.setState({
            transferTo: e.target.value,
            transferToValid: emailRegex.test(e.target.value),
        })
    }

    closeTransferSummary = () => {
        this.setState({
            transfer_summary: false,
        })
    }

    updatePass = (e) => {
        this.setState({
            pass: e.target.value,
        })
    }

    updateOtp = (e) => {
        this.setState({
            otp: e.target.value,
        })
    }

    authenticateLogin = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'authenticate_login', {
            params: {
                data: {
                    email: this.props.email.toLowerCase().replace(/\s+/g, ''),
                    password: this.state.pass,
                }
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    passAuth: true,
                    progress_loader: false,
                    pass: '',
                    passAuthModal: false,
                })
                if (this.state.actionModal === 'delete') {
                    this.deleteUserPermanently();
                } else if (this.state.actionModal === 'transfer') {
                    this.setState({
                        transferModal: true
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "Incorrect Password.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    pass: '',
                    progress_loader: false,
                })
            }
        });
    }

    changePassword = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'forgot', {
            params: {
                data: {
                    email: this.props.email.toLowerCase().replace(/\s+/g, ''),
                }
            },
        }).then(res => {
            if (res.data.message === 'Check your inbox for reset email') {
                this.setState({
                    warning: {
                        message: "Link to reset password has been sent to your email.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                    reset_password: true,
                })
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    verifyOtp = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'auth-transfer-consent-verification', {
            params: {
                old_email: this.props.email,
                new_email: this.state.transferTo,
                code: this.state.otp,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.transferUserPermanently();
            } else {
                this.setState({
                    warning: {
                        message: "Incorrect OTP.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    render() {
        return (
            <React.Fragment>
                <h1><span>Security </span>& Account</h1>
                <p>Manage your account, update password.</p>
                {this.state.transferModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleTransferModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Transfer Certificates</h1>
                            </div>
                            {!this.state.transfer_consent ?
                                <React.Fragment>
                                    <label>From</label>
                                    <p>{this.props.email}</p>
                                    <label>To</label>
                                    <form className='container' onKeyPress={this.handleKeyPress}>
                                        <input type='text' placeholder='Email' value={this.state.transferTo} onChange={this.updateTransferToEmail} required />
                                        {this.state.transferToValid && (<p className='email-validated'><i className="fa-solid fa-circle-check"></i> Email validated</p>)}
                                        <p>Transferring the account will move/transfer all certificates permanently to a new account with the above email. This action cannot be reverted.</p>
                                        <button type="button" onClick={this.transferUserPermanently}>Transfer <i className="fa-solid fa-arrow-right"></i></button>
                                    </form>
                                </React.Fragment>
                                :
                                <form className='container' onKeyPress={this.handleKeyPress}>
                                    <input type='text' placeholder='0000-000000-0000000000000' value={this.state.otp} onChange={this.updateOtp} required />
                                    <p>An otp has been send to {this.state.transferTo} via email</p>
                                    <button type="button" onClick={this.verifyOtp}>Submit OTP <i className="fa-solid fa-arrow-right"></i></button>
                                </form>
                            }
                        </div>
                    </div>
                )}
                {this.state.passAuthModal && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.togglePassAuthModal}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Re-enter your password</h1>
                                <p>Enter your password to continue</p>
                            </div>
                            <form className='container' onKeyPress={this.handleKeyPress}>
                                <input type='text' name='email' autoComplete='username' value={this.props.value} hidden />
                                <input type='password' placeholder='Password' autoComplete='new-password' value={this.state.pass} onChange={this.updatePass} required />
                                <button type="button" onClick={this.authenticateLogin}>Authenticate <i className="fa-solid fa-arrow-right"></i></button>
                            </form>
                            <p className='reset'>Forgot password? <button onClick={this.changePassword}>Reset password</button></p>
                        </div>
                    </div>
                )}
                {this.state.transfer_summary && (
                    <div className='element-modal transfer-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.closeTransferSummary}><i className="fa-solid fa-x"></i></button>
                            <div className='container'>
                                <h1>Transfer Summary</h1>
                            </div>
                            <div className='container transfer-summary'>
                                <p>Certificates : {this.state.transfer_summary.certificates ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.certificates}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                <p>Appreciations : {this.state.transfer_summary.appreciation ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.appreciation}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                <p>Documents : {this.state.transfer_summary.documents ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.documents}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                                <p>Signature : {this.state.transfer_summary.signature ? <b style={{ color: '#21937B' }}>{this.state.transfer_summary.signature}</b> : <b style={{ color: '#D5415A' }}>Failed</b>}</p>
                            </div>
                            <hr></hr>
                            {this.state.delete_prompt && (
                                <div className='container'>
                                    <p>Would you like to delete this account?</p>
                                    <button className='red-btn' onClick={this.deleteUserPermanently}>Delete Account</button>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className='account-container'>
                    <div className='container'>
                        <h2>Password</h2>
                        <p className='small-font'>You can change your password here.</p>
                        <div className='column-container'>
                            <p>Reset Password</p>
                            {this.state.reset_password ? <p className='small-font'>Reset password link has been sent to your email (<b>{this.props.email}</b>), you can continue to change your password from that link.</p>
                                :
                                <React.Fragment>
                                    <button onClick={this.changePassword}>Receive Reset Link <i className="fa-solid fa-arrow-right-long"></i></button>
                                    <p className='small-font'>Once you click Receive Reset Link, you will recive an email with instructions to reset your password</p>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className='container'>
                        <h2>Personal</h2>
                        <p className='small-font'>Manage your personal account.</p>
                        <div className='column-container'>
                            <p>Delete Account</p>
                            <div className='row-container'>
                                <p className='small-font'>Once deleted, it will be gone forever.<br></br>Please be certain.</p>
                                {this.state.passAuth ?
                                    <button className='red-btn' onClick={this.deleteUserPermanently}>Delete Account</button>
                                    :
                                    <button className='red-btn' onClick={() => this.togglePassAuthModal('delete')}>Delete Account</button>
                                }
                            </div>
                        </div>

                        <div className='column-container'>
                            <p>Transfer Account</p>
                            <div className='row-container'>
                                <p className='small-font'>Once transfered, it will only be linked to new account.<br></br>Please be certain.</p>
                                {this.state.passAuth ?
                                    <button className='red-btn' onClick={this.toggleTransferModal}>Transfer Account</button>
                                    :
                                    <button className='red-btn' onClick={() => this.togglePassAuthModal('transfer')}>Transfer Account</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default DataComponent;