import React from 'react';
import Draggable from 'react-draggable';
import '../css/element.css';

class ElementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
        this.handleDragStop.bind(this);
        this.handleResize.bind(this);
        this.outsideClickRef = React.createRef(null)
        this.clickTimeout = null
        this.state = {
            font: false,
            image: this.props.data,
            notDraggable: false,
            controls: false,
            id: this.props.element_id,
            toDelete: false,
            x: this.props.data.x,
            y: this.props.data.y,
            angle: this.props.data.angle ? this.props.data.angle : 0,
            textarea: {
                height: this.props.data.elHeight,
                width: this.props.data.elWidth,
            },
            zIndex: this.props.data.zIndex,
            enableGrid: this.props.enableGrid,
            mount: true,
            gridLevel: parseInt(this.props.grid_level) * 10,
            alignRange: 1,
            isMouseDown: false,
            rotateControls: false,
            mouseX: 0,
            mouseY: 0,
            prevMouseX: 0,
            prevMouseY: 0,
            selectedElement: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.textarea.height !== props.data.elHeight || state.textarea.width !== props.data.elWidth) {
            return {
                image: props.data,
                id: state.id,
                zIndex: props.data.zIndex,
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        } else {
            return {
                image: props.data,
                id: state.id,
                zIndex: props.data.zIndex,
                textarea: {
                    height: props.data.elHeight,
                    width: props.data.elWidth,
                },
                enableGrid: props.enableGrid,
                gridLevel: parseInt(props.grid_level) * 10,
            };
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.last_selected_element === this.state.id) {
            this.findGrid(0, { x: this.state.image.x, y: this.state.image.y })
        }
        if (this.props.data.x !== prevProps.data.x) {
            if (this.props.data.x !== this.state.x) {
                this.setState({
                    x: this.props.data.x,
                    y: this.props.data.y,
                })
            }
        }
        if (this.state.mount) {
            this.setState({
                mount: false,
            })
        }
        if (prevState.image.angle !== this.state.image.angle) {
            this.setState({
                angle: this.state.image.angle,
            });
        }
    }

    hideControls = () => {
        this.setState({
            controls: false,
            notDraggable: false,
        })
    }

    handleSendToParent = (e) => {
        if (e.currentTarget.id === "delete" && this.state.toDelete !== 'required') {
            this.setState({
                toDelete: this.state.image.timeStamp,
            }, () => {
                this.props.fetchChildState(this.state)
            })
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("mouseup", this.stopRotate);
        document.addEventListener('mousemove', this.handleRotate);
        if (this.state.textarea.width === "auto") {
            const img = document.querySelector("#box" + this.state.image.timeStamp + " img");
            img.addEventListener('load', this.handleImageLoad);
        }
    }

    handleImageLoad = () => {
        const box = document.querySelector("#box" + this.state.image.timeStamp);
        const offsetWidth = box.offsetWidth;
        this.setState({
            textarea: {
                height: this.state.textarea.height,
                width: offsetWidth,
            }
        }, () => {
            this.props.fetchChildSize(this.state)
        })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("mouseup", this.stopRotate);
        document.removeEventListener('mousemove', this.handleRotate);
    }

    handleClickOutside = (e) => {
        if (this.state.controls && !this.outsideClickRef.current.contains(e.target)) {
            this.hideControls();
        } else if (!this.outsideClickRef.current.contains(e.target)) {
            this.setState({
                selectedElement: false,
            })
        }
    }

    handleDragStop = (e, dragElement) => {
        this.setState({
            x: dragElement.x,
            y: dragElement.y,
            textarea: {
                height: e.target.offsetHeight,
                width: e.target.offsetWidth,
            },
        }, () => {
            this.props.fetchChildDrag(this.state)
        })
    };

    handleHistory = (event, dragElement) => {
        this.props.fetchHistory(this.state.id)
    };

    handleClicks = (e) => {
        if (this.clickTimeout !== null && !this.state.image.required) {
            this.setState({
                controls: true,
                notDraggable: true,
            });
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else if (!this.state.notDraggable) {
            this.setState({
                selectedElement: true
            });
            this.clickTimeout = setTimeout(() => {
                e.preventDefault()
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 500)
        }
    }

    handleResize = (e) => {
        if (e.target.offsetHeight !== this.state.textarea.height && e.target.offsetWidth !== this.state.textarea.width) {
            if (this.state.image.resizeLock) {
                e.target.offsetHeight - this.state.textarea.height > e.target.offsetWidth - this.state.textarea.width ?
                    this.setState({
                        textarea: {
                            height: e.target.offsetHeight,
                            width: parseInt(e.target.offsetHeight * (this.state.textarea.width / this.state.textarea.height)),
                        },
                    }, () => {
                        this.props.fetchChildSize(this.state)
                    })
                    :
                    this.setState({
                        textarea: {
                            height: parseInt(e.target.offsetWidth * (this.state.textarea.height / this.state.textarea.width)),
                            width: e.target.offsetWidth,
                        },
                    }, () => {
                        this.props.fetchChildSize(this.state)
                    })
            } else {
                this.setState({
                    textarea: {
                        height: e.target.offsetHeight,
                        width: e.target.offsetWidth,
                    },
                }, () => {
                    this.props.fetchChildSize(this.state)
                })
            }
        } else {
            //this causes image to resize automatically when entering edit mode
            // this.props.fetchChildSize(this.state)
            this.setState({
                textarea: {
                    height: this.state.textarea.height,
                    width: this.state.textarea.width,
                },
            }, () => {
                this.props.fetchChildSize(this.state)
            })
        }

        //To add to history when resized
        this.handleHistory();

        setTimeout(() => {
            this.props.fetchCenterGrid(false, false)
        }, 1000);
    }

    findGrid = (event, dragElement) => {
        let centerGridX = false;
        let centerGridY = false;
        if (Math.ceil(parseInt(dragElement.x + (parseInt(this.state.textarea.width / 2))) / 2) === this.props.certWidth / 4) {
            centerGridX = true
        }
        if (Math.ceil(parseInt(dragElement.y + (parseInt(this.state.textarea.height / 2))) / 2) === this.props.certHeight / 4) {
            centerGridY = true
        }
        this.props.fetchCenterGrid(centerGridX, centerGridY)

        this.alignLines(dragElement)
    }

    setAsBackground = () => {
        this.setState({
            textarea: {
                height: this.props.certHeight,
                width: this.props.certWidth,
            },
        }, () => {
            this.props.fetchSetAsBackground(this.state)
        })
    }

    mouseDownCapture = () => {
        this.setState({
            isMouseDown: true,
        })
    }

    mouseUpCapture = () => {
        this.setState({
            isMouseDown: false,
        })
    }

    checkObject_Y_Top = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.y - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_Y_Bottom = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs((obj.y + obj.elHeight) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_Y_Middle = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.y + Math.round(obj.elHeight / 2) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }

    checkObject_X_Top = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.x - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_X_Bottom = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs((obj.x + obj.elWidth) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }
    checkObject_X_Middle = (value, excludedTimeStamp) => {
        for (let i = 0; i < this.props.elementPositions.length; i++) {
            const obj = this.props.elementPositions[i];
            if (Math.abs(obj.x + Math.round(obj.elWidth / 2) - value) <= this.state.alignRange && obj.timeStamp !== excludedTimeStamp) {
                return i;
            }
        }
        return -1;
    }

    alignLines = (dragElement) => {
        if (document.getElementById('rel-guide-y-top-' + this.state.image.timeStamp)) {
            const checkObject_Y_Top = this.checkObject_Y_Top(dragElement.y, this.state.image.timeStamp);
            const checkObject_Y_Bottom = this.checkObject_Y_Bottom(dragElement.y + this.state.textarea.height, this.state.image.timeStamp);
            const checkObject_Y_Middle = this.checkObject_Y_Middle(dragElement.y + Math.round(this.state.textarea.height / 2), this.state.image.timeStamp);

            const checkObject_X_Top = this.checkObject_X_Top(dragElement.x, this.state.image.timeStamp);
            const checkObject_X_Bottom = this.checkObject_X_Bottom(dragElement.x + this.state.textarea.width, this.state.image.timeStamp);
            const checkObject_X_Middle = this.checkObject_X_Middle(dragElement.x + Math.round(this.state.textarea.width / 2), this.state.image.timeStamp);

            if (this.state.isMouseDown) {
                if (checkObject_Y_Top >= 0) {
                    document.getElementById('rel-guide-y-top-' + this.state.image.timeStamp).style.display = 'block';
                } else if (checkObject_Y_Top < 0) {
                    document.getElementById('rel-guide-y-top-' + this.state.image.timeStamp).style.display = 'none';
                }
                if (checkObject_Y_Bottom >= 0) {
                    document.getElementById('rel-guide-y-bottom-' + this.state.image.timeStamp).style.display = 'block';
                } else if (checkObject_Y_Bottom < 0) {
                    document.getElementById('rel-guide-y-bottom-' + this.state.image.timeStamp).style.display = 'none';
                }
                if (checkObject_Y_Middle >= 0) {
                    document.getElementById('rel-guide-y-middle-' + this.state.image.timeStamp).style.display = 'block';
                } else if (checkObject_Y_Middle < 0) {
                    document.getElementById('rel-guide-y-middle-' + this.state.image.timeStamp).style.display = 'none';
                }

                if (checkObject_X_Top >= 0) {
                    document.getElementById('rel-guide-x-top-' + this.state.image.timeStamp).style.display = 'block';
                } else if (checkObject_X_Top < 0) {
                    document.getElementById('rel-guide-x-top-' + this.state.image.timeStamp).style.display = 'none';
                }
                if (checkObject_X_Bottom >= 0) {
                    document.getElementById('rel-guide-x-bottom-' + this.state.image.timeStamp).style.display = 'block';
                } else if (checkObject_X_Bottom < 0) {
                    document.getElementById('rel-guide-x-bottom-' + this.state.image.timeStamp).style.display = 'none';
                }
                if (checkObject_X_Middle >= 0) {
                    document.getElementById('rel-guide-x-middle-' + this.state.image.timeStamp).style.display = 'block';
                } else if (checkObject_X_Middle < 0) {
                    document.getElementById('rel-guide-x-middle-' + this.state.image.timeStamp).style.display = 'none';
                }
            } else {
                document.getElementById('rel-guide-y-top-' + this.state.image.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-y-bottom-' + this.state.image.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-y-middle-' + this.state.image.timeStamp).style.display = 'none';

                document.getElementById('rel-guide-x-top-' + this.state.image.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-x-bottom-' + this.state.image.timeStamp).style.display = 'none';
                document.getElementById('rel-guide-x-middle-' + this.state.image.timeStamp).style.display = 'none';
            }
        }
    }

    startRotate = () => {
        this.setState({
            rotateControls: true,
            controls: false,
        });
    };

    handleRotate = (event) => {
        if (this.state.rotateControls) {
            const { clientX, clientY } = event;
            const { mouseX, mouseY, prevMouseX } = this.state;

            if (clientX !== mouseX || clientY !== mouseY) {
                if (clientX > prevMouseX) {
                    this.setState(prevState => ({
                        angle: parseInt((prevState.angle < 0 ? 360 : prevState.angle) - 1),
                    }));
                } else if (clientX < prevMouseX) {
                    this.setState(prevState => ({
                        angle: parseInt((prevState.angle > 360 ? 0 : prevState.angle) + 1),
                    }));
                }

                this.setState({
                    mouseX: clientX,
                    mouseY: clientY,
                    prevMouseX: mouseX,
                    prevMouseY: mouseY,
                });
            }
        }
    };

    stopRotate = () => {
        if (this.state.rotateControls) {
            this.setState({
                rotateControls: false,
                controls: true,
            }, () => {
                this.props.fetchChildDrag(this.state)
                this.handleHistory();
            })
        }
    }

    updateAngle = (e) => {
        if (e.target.value && e.target.value >= 0 && e.target.value <= 360) {
            this.setState({
                angle: parseInt(e.target.value),
            }, () => {
                this.props.fetchChildDrag(this.state)
                this.handleHistory();
            })
        } else {
            this.setState({
                angle: 0,
            }, () => {
                this.props.fetchChildDrag(this.state)
                this.handleHistory();
            })
        }
    }

    unlockElement = () => {
        this.props.fetchUnlockElement(false)
    }

    render() {
        return (
            <Draggable position={{ x: this.state.image.x, y: this.state.image.y }} disabled={this.state.image.lock ? true : this.state.notDraggable} cancel={this.state.notDraggable ? '.imagearea,select,button' : 'select,button'} onStop={this.handleDragStop} grid={this.state.enableGrid ? [this.state.gridLevel, this.state.gridLevel] : null} onStart={this.handleHistory} onDrag={this.findGrid} scale={this.state.enableGrid ? 1 : this.props.certificate_zoom}>
                <div className={this.state.notDraggable ? 'box pinned' : 'box'} ref={this.outsideClickRef} style={{ zIndex: this.state.controls ? 999 : this.state.zIndex, pointerEvents: this.props.move_canvas ? "all" : "none" }} id={"box" + this.state.image.timeStamp} onMouseDownCapture={this.mouseDownCapture} onMouseUpCapture={this.mouseUpCapture}>
                    <div className='rotate-wrap' style={{ transformOrigin: 'center', transform: `rotate(${this.state.angle}deg)` }}>
                        {this.state.controls && (
                            !this.state.image.lock ?
                                <div className='variable-controls'>
                                    {
                                        this.state.image.required ? "" : <button className='variable' id="set-bg" onClick={this.setAsBackground} title='Set as background'><i className="fa-regular fa-images"></i></button>
                                    }
                                    {
                                        this.state.image.required ? "" : <button className='delete' id="delete" onClick={this.handleSendToParent}><i className="fa-solid fa-trash pe-none"></i></button>
                                    }
                                </div>
                                :
                                <div className='variable-controls lock-controls'>
                                    <button className='delete' onClick={this.unlockElement}><i className="fa-solid fa-lock"></i></button>
                                </div>
                        )}
                        <div className="wrap">

                            {this.state.angle === 0 && (
                                <React.Fragment>
                                    <hr className='rel-guide-y-top' id={'rel-guide-y-top-' + this.state.image.timeStamp}></hr>
                                    <hr className='rel-guide-y-bottom' id={'rel-guide-y-bottom-' + this.state.image.timeStamp}></hr>
                                    <hr className='rel-guide-y-middle' id={'rel-guide-y-middle-' + this.state.image.timeStamp}></hr>

                                    <hr className='rel-guide-x-top' id={'rel-guide-x-top-' + this.state.image.timeStamp}></hr>
                                    <hr className='rel-guide-x-bottom' id={'rel-guide-x-bottom-' + this.state.image.timeStamp}></hr>
                                    <hr className='rel-guide-x-middle' id={'rel-guide-x-middle-' + this.state.image.timeStamp}></hr>
                                </React.Fragment>
                            )}

                            {!this.state.image.lock && (this.state.controls || this.state.rotateControls) && (<div className='rotate-handle-container'><div className='rotate-handle' onMouseDownCapture={this.startRotate}><i className="fa-solid fa-rotate-right"></i></div><input className="angle-view" type='number' min='0' max='360' inputMode='numeric' pattern="[0-9]*" value={this.state.angle} onChange={this.updateAngle}></input></div>)}

                            <div className="pull-tab"></div>
                            <div className={this.state.image.shape !== undefined ? 'imagearea ' + this.state.image.shape : this.state.selectedElement ? 'imagearea selected' : 'imagearea'}
                                style={{
                                    resize: this.state.notDraggable ? this.state.image.resize : "none",
                                    height: this.state.image.elHeight + "px",
                                    width: this.state.image.elWidth === "auto" ? "auto" : this.state.image.elWidth + "px",
                                }}
                                onMouseDown={this.handleClicks}
                                onMouseUp={this.handleResize}
                            >
                                {
                                    this.state.image.content === "shape" ? <div className='shape'></div> : <img src={this.state.image.content} alt="element was deleted from uploads" style={this.state.mount ? { width: "auto" } : {}}></img>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    }
}

export default ElementComponent;