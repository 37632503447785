import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

const ReactPage = ReactDOM.createRoot(document.getElementById('root'));

ReactPage.render(
  <GoogleOAuthProvider clientId="245021852067-kpv5m5gabflp8tavibb6cokq8jgb16n5.apps.googleusercontent.com">
    <React.Fragment>
      {/* (Localhost Only) */}
      {/* <App node_server_axios={'http://localhost:8080/api/'} rzp_auth_key={'rzp_test_Kz9tk6jEtrtyoe'} /> */}
      {/* <App node_server_axios={'http://192.168.137.22:8080/api/'} rzp_auth_key={'rzp_test_Kz9tk6jEtrtyoe'} /> */}
      <App node_server_axios={'https://pronetin.in:8080/api/'} rzp_auth_key={'rzp_live_wQIyiCcUav1rrx'} />
    </React.Fragment>
  </GoogleOAuthProvider>
);