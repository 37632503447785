import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages';
import Landing from './pages/home';
import Features from './pages/home_pages';
import MyDocuments from './pages/mydocuments';
import Signin from './pages/signin';
import Signup from './pages/signup';
import Issue from './pages/issue';
import Marketplace from './pages/marketplace';
import MyDesign from './pages/mydesign';
import MySignature from './pages/mysignature';
import Profile from './pages/profile';
import Settings from './pages/settings';
import Viewer from './pages/viewer';
import Appreciation from './pages/appreciation';
import ViewerPublic from './pages/public';
import ViewerPreview from './pages/preview';
import AppreciationPublic from './pages/appreciation_public';
import ViewerDoc from './pages/viewerdoc';
import Approvals from './pages/approvals';
import ApprovalsHistory from './pages/approvals_history';
import Organisation from './pages/organisation';
import Email from './pages/email';
import Subevents from './pages/subevents';
import Batch from './pages/batch';
import Create from './pages/create';
import EditSaved from './pages/editsaved';
import Verify from './pages/verify';
import TransferConsent from './pages/transfer_consent';
import Premium from './pages/premium';
import VerifyQR from './pages/verifyqr';
import Forgot from './pages/forgot';
import Reset from './pages/reset';
import PrivacyPolicy from './pages/privacypolicy';
import Tos from './pages/tos';
import RefundPolicy from './pages/refundpolicy';
import Help from './pages/help';
import Kicked from './pages/kicked';
import ActionCenter from './pages/action_center';
import Buy from './pages/buy';
import ActivityLogs from './pages/activity_logs';
import DownloadBatch from './pages/download_batch';
import Completed from './pages/completed';
import Data from './pages/account';
import Distributed from './pages/distributed';
import Tutorial from './pages/funtutorial';
import ESign from './pages/esign';
import EVerify from './pages/esign_verify';
import Invoice from './pages/invoice';
import ThankYou from './pages/thankyou';

// -------------Docs-----------------
import ApiBasics from './pages/api/basics';
import EmbeddBadge from './pages/api/embedd-badge';

function App(props) {
    useEffect(() => {
        const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (deviceWidth <= 1920 && deviceWidth > 820) {
            const viewportContent = 'width=device-width, initial-scale=0.8';
            const existingMeta = document.querySelector('meta[name="viewport"]');
            if (existingMeta) {
                existingMeta.setAttribute('content', viewportContent);
            } else {
                const newMeta = document.createElement('meta');
                newMeta.name = 'viewport';
                newMeta.content = viewportContent;
                document.head.appendChild(newMeta);
            }
        }
    }, []);

    const MetaTags = ({ title, description, image }) => {
        useEffect(() => {
            const ogTitle = document.createElement('meta');
            ogTitle.setAttribute('property', 'og:title');
            ogTitle.content = title;

            const ogDescription = document.createElement('meta');
            ogDescription.setAttribute('property', 'og:description');
            ogDescription.content = description;

            const ogImage = document.createElement('meta');
            ogImage.setAttribute('property', 'og:image');
            ogImage.content = image;

            const ogImageSecureUrl = document.createElement('meta');
            ogImageSecureUrl.setAttribute('property', 'og:image:secure_url');
            ogImageSecureUrl.content = image;

            const ogType = document.createElement('meta');
            ogType.setAttribute('property', 'og:type');
            ogType.content = 'website';

            document.head.appendChild(ogTitle);
            document.head.appendChild(ogDescription);
            document.head.appendChild(ogImage);
            document.head.appendChild(ogImageSecureUrl);
            document.head.appendChild(ogType);

            // Cleanup on component unmount
            return () => {
                document.head.removeChild(ogTitle);
                document.head.removeChild(ogDescription);
                document.head.removeChild(ogImage);
                document.head.removeChild(ogImageSecureUrl);
                document.head.removeChild(ogType);
            };
        }, [title, description, image]);

        return null;
    };

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home node_server_axios={props.node_server_axios} />} />
                <Route path='/home' element={<Landing node_server_axios={props.node_server_axios} />} />
                <Route path='/features/user' element={<Features node_server_axios={props.node_server_axios} section={'user'} />} />
                <Route path='/features/organisation' element={<Features node_server_axios={props.node_server_axios} section={'organisation'} />} />
                <Route path='/features/signee' element={<Features node_server_axios={props.node_server_axios} section={'signee'} />} />
                <Route path='/features/hr' element={<Features node_server_axios={props.node_server_axios} section={'hr'} />} />
                <Route path='/features/organisation' element={<Features node_server_axios={props.node_server_axios} section={'organisation'} />} />
                <Route path='/features/signee' element={<Features node_server_axios={props.node_server_axios} section={'signee'} />} />
                <Route path='/features/academics' element={<Features node_server_axios={props.node_server_axios} section={'academics'} />} />
                <Route path='/pricing' element={<Features node_server_axios={props.node_server_axios} section={'pricing'} />} />
                <Route path='/checkout' element={<Features node_server_axios={props.node_server_axios} section={'checkout'} rzp_auth_key={props.rzp_auth_key} />} />
                <Route path='/solutions/post-quantum-certificates' element={<Features node_server_axios={props.node_server_axios} section={'post-quantum-certificates'} />} />
                <Route path='/solutions/advertisements' element={<Features node_server_axios={props.node_server_axios} section={'advertisements'} />} />
                <Route path='/solutions/events' element={<Features node_server_axios={props.node_server_axios} section={'events'} />} />
                <Route path='/solutions/e-signs' element={<Features node_server_axios={props.node_server_axios} section={'e-signs'} />} />
                <Route path='/resources/api' element={<Features node_server_axios={props.node_server_axios} section={'api'} />} />

                <Route path='/docs/api/basics' element={<ApiBasics node_server_axios={props.node_server_axios} />} />
                <Route path='/docs/api/embedd-badge' element={<EmbeddBadge node_server_axios={props.node_server_axios} />} />

                <Route path='/mydocuments' element={<MyDocuments node_server_axios={props.node_server_axios} />} />
                <Route path='/signin' element={<Signin node_server_axios={props.node_server_axios} />} />
                <Route path='/signup' element={<Signup node_server_axios={props.node_server_axios} />} />
                <Route path='/issue' element={<Issue node_server_axios={props.node_server_axios} />} />
                <Route path='/issue/:slug' element={<Issue node_server_axios={props.node_server_axios} />} />
                <Route path='/profile' element={<Profile node_server_axios={props.node_server_axios} />} />
                <Route path='/settings' element={<Settings node_server_axios={props.node_server_axios} />} />
                <Route path='/marketplace' element={<Marketplace node_server_axios={props.node_server_axios} />} />
                <Route path='/mydesign' element={<MyDesign node_server_axios={props.node_server_axios} />} />
                <Route path='/mysignature' element={<MySignature node_server_axios={props.node_server_axios} />} />
                <Route path='/viewer/:slug' element={<Viewer node_server_axios={props.node_server_axios} />} />
                <Route path='/appreciation/:slug' element={<Appreciation node_server_axios={props.node_server_axios} />} />
                <Route path='/doc/:email/:slug' element={<ViewerDoc node_server_axios={props.node_server_axios} />} />
                <Route path='/certificate/:slug' element={
                    <>
                        <MetaTags
                            title='Pronetin Document'
                            description='See my pronetin verified achievement'
                            image='https://cdn-aerobits-old.s3.ap-south-1.amazonaws.com/pronetin/images/badge.png'
                        />
                        <ViewerPublic node_server_axios={props.node_server_axios} />
                    </>
                } />
                <Route path='/preview/:slug' element={<ViewerPreview node_server_axios={props.node_server_axios} />} />
                <Route path='/awards/:slug' element={
                    <>
                        <MetaTags
                            title='Pronetin Awards'
                            description='See my pronetin verified achievement'
                            image='https://cdn-aerobits-old.s3.ap-south-1.amazonaws.com/pronetin/images/badge.png'
                        />
                        <AppreciationPublic node_server_axios={props.node_server_axios} />
                    </>
                } />
                <Route path='/approvals' element={<Approvals node_server_axios={props.node_server_axios} />} />
                <Route path='/approvalhistory' element={<ApprovalsHistory node_server_axios={props.node_server_axios} />} />
                <Route path='/organisation' element={<Organisation node_server_axios={props.node_server_axios} />} />
                <Route path='/email/:slug/:subevent' element={<Email node_server_axios={props.node_server_axios} />} />
                <Route path='/email' element={<Email node_server_axios={props.node_server_axios} />} />
                <Route path='/subevents/:slug/:subevent' element={<Subevents node_server_axios={props.node_server_axios} />} />
                <Route path='/batch/:slug' element={<Batch node_server_axios={props.node_server_axios} />} />
                <Route path='/batch' element={<Batch node_server_axios={props.node_server_axios} />} />
                <Route path='/create' element={<Create node_server_axios={props.node_server_axios} />} />
                <Route path='/edit/:slug' element={<EditSaved node_server_axios={props.node_server_axios} />} />
                <Route path='/verify/:slug/:code' element={<Verify node_server_axios={props.node_server_axios} />} />
                <Route path='/transferconsent/:slug/:email/:code' element={<TransferConsent node_server_axios={props.node_server_axios} />} />
                <Route path='/premium' element={<Premium node_server_axios={props.node_server_axios} rzp_auth_key={props.rzp_auth_key} />} />
                <Route path='/v/:slug' element={<VerifyQR node_server_axios={props.node_server_axios} />} />
                <Route path='/forgot' element={<Forgot node_server_axios={props.node_server_axios} />} />
                <Route path='/reset/:slug/:otp' element={<Reset node_server_axios={props.node_server_axios} />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy node_server_axios={props.node_server_axios} />} />
                <Route path='/tos' element={<Tos node_server_axios={props.node_server_axios} />} />
                <Route path='/refundpolicy' element={<RefundPolicy node_server_axios={props.node_server_axios} />} />
                <Route path='/help' element={<Help node_server_axios={props.node_server_axios} />} />
                <Route path='/kicked' element={<Kicked node_server_axios={props.node_server_axios} />} />
                <Route path='/actioncenter' element={<ActionCenter node_server_axios={props.node_server_axios} />} />
                <Route path='/buy' element={<Buy node_server_axios={props.node_server_axios} rzp_auth_key={props.rzp_auth_key} />} />
                <Route path='/activitylogs' element={<ActivityLogs node_server_axios={props.node_server_axios} />} />
                <Route path='/download/:slug' element={<DownloadBatch node_server_axios={props.node_server_axios} />} />
                <Route path='/completed' element={<Completed node_server_axios={props.node_server_axios} />} />
                <Route path='/account' element={<Data node_server_axios={props.node_server_axios} />} />
                <Route path='/distributed' element={<Distributed node_server_axios={props.node_server_axios} />} />
                <Route path='/tutorial' element={<Tutorial node_server_axios={props.node_server_axios} />} />
                <Route path='/e-sign' element={<ESign node_server_axios={props.node_server_axios} />} />
                <Route path='/e/:email/:slug' element={<EVerify node_server_axios={props.node_server_axios} />} />

                <Route path='/invoice' element={<Invoice node_server_axios={props.node_server_axios} />} />
                <Route path='/thankyou' element={<ThankYou node_server_axios={props.node_server_axios} />} />

                <Route path="/edit" element={<Navigate to="/" />} />
                <Route path="/viewer" element={<Navigate to="/" />} />
                {/* <Route path="/batch" element={<Navigate to="/" />} /> */}
                <Route path='/subevents/:slug' element={<Navigate to="/" />} />
                {/* Redirect all unknown routes to the home page */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
