import React from 'react';
// import staticImage from '../images/static/Group 252.png';
import staticGif from '../images/static/siginin_tut.gif';
import UserImage from '../images/static/User Signin.png';
import OrganisationImage from '../images/static/Organization Signin.png';
import SigneeImage from '../images/static/Signee Signin.png';
import Signup from '../components/signup';
import Header from '../components/header';

import '../components/css/main.css';

const SignupComponent = (props) => {

    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    let gifImage = false;

    let imageToDisplay;

    switch (action) {
        case 'createcertificate':
            imageToDisplay = OrganisationImage;
            break;
        case 'findcertificate':
            imageToDisplay = UserImage;
            break;
        case 'signeerequest':
            imageToDisplay = SigneeImage;
            break;
        default:
            gifImage = true;
            imageToDisplay = staticGif;
    }

    return (
        <React.Fragment>
            <div className="signin signin-v2">
                <Header signin={true} />
                <img src={imageToDisplay} alt="action-based" className={gifImage ? 'landing-img gif-img' : 'landing-img'} />
                <Signup node_server_axios={props.node_server_axios} />
            </div>
        </React.Fragment>
    );
};

export default SignupComponent;
