import React from 'react';
import './css/element.css';
import './css/organisation.css';
import axios from 'axios';
import ViewSignee from './viewer/signee';

import Warning from './warning';
import WarningAction from './warning_action';
import sampleEmail from '../images/sampleEmail.png';
import ProgressLoader from './progress_loader';

import io from 'socket.io-client';

// Utility function for array subtraction
// const subtractArrays = (array1, array2) => {
//     const result = [];
//     array1.forEach((item) => {
//         const index = array2.findIndex((elem) => elem === item);
//         if (index === -1) {
//             result.push(item);
//         } else {
//             array2.splice(index, 1);
//         }
//     });
//     return result;
// };

class BatchData extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        const currentDate = new Date();
        const currentDateString = currentDate.toISOString().split('T')[0];
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            csvData: [],
            signees: [],
            signed_by: [],
            rejected_by: [],
            docsInBatches: null,
            nextPageToken: null,
            docsInBounces: [],
            subtractedData: [],
            unsend_certificates: null,
            more_info: false,
            templatePreviewModal: false,
            userCheck: "",
            userStatus: "Unknown",
            loadingComponent: false,
            scaleFactor: 1,
            progress_loader: false,
            moreBtn: 'more',
            scheduleModal: false,
            issueModal: false,
            minDate: currentDateString,
            selectedDate: currentDateString,
            issuedToggle: false,
            issueSearch: "",
            agree: 0,
            reloadModal: false,
        };
        this.socket = io.connect(this.props.node_server_axios.replace("8080/api/", "8090"), { reconnectionAttempts: 3 });
        this.debouncedSearch = this.debounce(this.performSearch, 300);
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    async componentDidMount() {
        this.setState({
            progress_loader: true,
            batchNumber: window.location.hash.substring(1),
        })
        axios.defaults.withCredentials = true;
        await axios.post(this.props.node_server_axios + 'get_batch_data', {
            params: {
                batchId: this.props.batchId,
            },
        }).then(res => {
            this.setState({
                csvData: JSON.parse(res.data.csvData),
                cert_bg: res.data.cert_bg.replace('.png', '_preview.png'),
                org_id: res.data.org_id,
                event_name: res.data.event_name,
                sub_event_name: res.data.sub_event_name,
                signeeStatus: res.data.signeeStatus,
                issue_date: res.data.issue_date,
                signees: JSON.parse(res.data.signees),
                signed_by: res.data.signed_by,
                rejected_by: res.data.rejected_by,
                created_by: res.data.created_by,
                loadingComponent: true,
                elements: res.data.elements,
                withdrawn: res.data.withdrawn,
                docsInBatches: res.data.issue_date === null ? null : [],
            })
        }).catch(err => { console.log(err); window.location = '/'; })
        await axios.post(this.props.node_server_axios + 'get_cert_status', {
            params: {
                batchId: this.props.batchId,
                nextPageToken: null,
            },
        }).then(res => {
            if (res.data.docsInBatches) {
                this.setState({
                    docsInBatches: res.data.docsInBatches,
                    nextPageToken: res.data.nextPageToken,
                    initialDocsInBatches: res.data.docsInBatches,
                    docsInBounces: res.data.docsInBatches.filter(item => item.bounce === true),
                    schedule_date: res.data.schedule_date,
                    progress_loader: false,
                }, () => {
                    this.props.documentLoaded(true);
                })
            } else {
                this.setState({
                    progress_loader: false,
                }, () => {
                    this.props.documentLoaded(true);
                })
            }
        }).catch(err => { console.log(err) })
    }

    componentDidUpdate(prevProps, prevState) {
        //---------------------------------Old Methord---------------------------
        // if (this.state.unsend_certificates === null && this.state.docsInBatches && this.state.docsInBatches.length > 0) {
        //     this.setState({
        //         progress_loader: true,
        //     })
        //     const { csvData, docsInBatches } = this.state;
        //     // Use the utility function to perform one-to-one subtraction
        //     const subtractedData = subtractArrays(csvData.map((item) => item['[Email]']), docsInBatches.map((item) => item.email));
        //     // Map the subtracted data to the original structure
        //     const result = subtractedData.map((email) => csvData.find((item) => item['[Email]'] === email));
        //     this.setState({
        //         unsend_certificates: result,
        //         issuedToggle: "issued",
        //         progress_loader: false,
        //     })
        // }

        if (this.state.docsInBatches && this.state.docsInBatches.length > 0 && prevState.docsInBatches !== this.state.docsInBatches) {
            this.setState({
                progress_loader: true,
            })
            const result = this.state.docsInBatches.filter(item => item.email_send === false);
            this.setState({
                unsend_certificates: result,
                issuedToggle: "issued",
                progress_loader: false,
            })
        }
    }

    componentWillUnmount() {
        this.socket.disconnect();
    }

    toggleIssueData = (toggle) => {
        if (!this.state.docsInBatches || this.state.docsInBatches.length === 0) {
            this.setState({
                warning: {
                    message: "This feature is not available for this batch.",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        } else {
            this.setState({
                issuedToggle: toggle,
            })
        }
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    logFormattedDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${dayOfMonth}-${day} (${hours}:${minutes})`;
    }

    issueCertificates = () => {
        if (this.state.agree === 3) {
            this.setState({
                progress_loader: true,
                scheduleModal: false,
                issueModal: false,
            })
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'issue_certificates', {
                params: {
                    batchId: this.props.batchId,
                    org_name: this.props.org_name,
                    scheduled_date: this.state.selectedDate,
                    sub_org: localStorage.getItem('selected_subOrganisation') && localStorage.getItem('selected_subOrganisation').length > 2 && localStorage.getItem('selected_subOrganisation') !== 'Nil' ? localStorage.getItem('selected_subOrganisation') : null,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    const recepient_count = this.state.csvData.length;
                    //---------------------Logging Data (Organisation) Start---------------------
                    axios.post(this.props.node_server_axios + 'update_organisation_log', {
                        params: {
                            email: this.props.email,
                            dateTS: this.logFormattedDate(new Date()),
                            creditsConsumed: '-' + (recepient_count + 10),
                            content: recepient_count + " certificates issued for " + this.state.sub_event_name + " in event " + this.state.event_name,
                        },
                    })
                    //----------------------Logging Data (Organisation) End----------------------

                    this.state.signees.map(signee =>
                        this.socket.emit('sendToUser', { email: signee.split("|")[0], data: { message: 'A certificate batch for ' + this.state.event_name + ' has been issued' } })
                    )

                    this.setState({
                        warning: {
                            message: "Certificates Issued.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        issue_date: true,
                        progress_loader: false,
                    }, () => {
                        setTimeout(this.reloadModalPromt, 2000);
                    })
                } else {
                    let valueMessage = '';
                    if (res.data.value !== undefined) {
                        valueMessage = res.data.value
                    }
                    this.setState({
                        warning: {
                            message: "Failed to issue certificates." + valueMessage,
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            });
        } else {
            this.setState({
                warning: {
                    message: "Please tick all the checkboxes to continue",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        }
    }

    reloadModalPromt = () => {
        this.setState({
            reloadModal: true,
        })
    }

    sendSample = () => {
        this.setState({
            progress_loader: true,
            scheduleModal: false,
            issueModal: false,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'sample_issue_certificates', {
            params: {
                batchId: this.props.batchId,
                org_name: this.props.org_name,
                sub_org: localStorage.getItem('selected_subOrganisation') && localStorage.getItem('selected_subOrganisation').length > 2 && localStorage.getItem('selected_subOrganisation') !== 'Nil' ? localStorage.getItem('selected_subOrganisation') : null,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "Sample Certificate Issued.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            } else {
                let valueMessage = '';
                if (res.data.value !== undefined) {
                    valueMessage = res.data.value
                }
                this.setState({
                    warning: {
                        message: "Failed to issue sample certificate." + valueMessage,
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    moreInfo = () => {
        this.setState({
            more_info: !this.state.more_info
        })
    }

    openTemplatePreview = () => {
        this.setState({
            templatePreviewModal: true,
        })
    }

    closeTemplatePreview = (e) => {
        if (e.target.classList.contains('batchcert-preview-modal') || e.target.classList.contains('close-btn')) {
            this.setState({
                templatePreviewModal: false,
            })
        }
    }

    updateUserCheck = (e) => {
        this.setState({
            userCheck: e.target.value,
        })
    }

    submitUserCheck = () => {
        const existsInArray = this.state.csvData.some(item => item['[Email]'] === this.state.userCheck);
        if (existsInArray) {
            axios.post(this.props.node_server_axios + 'check_user_accept_status', {
                params: {
                    batchId: this.props.batchId,
                    email: this.state.userCheck,
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        userStatus: res.data.status,
                    })
                } else {
                    this.setState({
                        userStatus: "deleted",
                    })
                }
            });
        } else {
            this.setState({
                userStatus: "Not in batch",
            })
        }
    }

    updateScale = () => {
        const divWidth = this.divRef.offsetWidth;
        const screenWidth = window.innerWidth - 40;
        const scaleFactor = screenWidth / divWidth;
        if (scaleFactor < 1) {
            this.setState({ scaleFactor });
        }
    };

    toggleScheduleIssueModal = () => {
        if (this.state.agree === 3) {
            this.setState({
                issueModal: false,
                scheduleModal: !this.state.scheduleModal,
            })
        } else {
            this.setState({
                warning: {
                    message: "Please tick all the checkboxes to continue",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        }
    }

    toggleIssueModal = () => {
        this.setState({
            issueModal: !this.state.issueModal,
            agree: 0,
        })
    }

    updateScheduledDate = (event) => {
        this.setState({
            selectedDate: event.target.value,
        });
    };

    withdrawBatch = async (e) => {
        e.preventDefault();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Withdraw Batch",
                subtext: "All certificates in this batch will be marked as withdrawn. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'withdraw_batch', {
            params: {
                batchId: this.props.batchId,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "This batch is marked as withdrawn",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    withdrawn: 'true',
                })
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Certificate batch with batch Id : " + this.props.batchId + " under the event " + this.state.event_name + " was withdrawn",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
            } else if (res.data.message === 'not admin') {
                this.setState({
                    warning: {
                        message: "Only admin of this organisation can perform this action.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            }
        });
    }

    reissueBatch = (e) => {
        e.preventDefault();
        axios.post(this.props.node_server_axios + 'reissue_batch', {
            params: {
                batchId: this.props.batchId,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "This batch is re-issued",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    withdrawn: null,
                })
                //---------------------Logging Data (Organisation) Start---------------------
                axios.post(this.props.node_server_axios + 'update_organisation_log', {
                    params: {
                        email: this.props.email,
                        dateTS: this.logFormattedDate(new Date()),
                        creditsConsumed: '0',
                        content: "Certificate batch with batch Id : " + this.props.batchId + " under the event " + this.state.event_name + " was re-issued",
                    },
                })
                //----------------------Logging Data (Organisation) End----------------------
            } else if (res.data.message === 'not admin') {
                this.setState({
                    warning: {
                        message: "Only admin of this organisation can perform this action.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                })
            }
        });
    }

    resendCertificate = (element) => {
        this.setState({
            warning: {
                message: "This feature is not enabled yet.",
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
        })
    }

    resendEmailToPending = () => {
        this.setState({
            warning: {
                message: "This feature is not enabled yet.",
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
        })
    }

    updateEmailResend = (index, newEmailResendValue) => {
        const newArray = [...this.state.docsInBatches];
        if (newArray[index]) {
            newArray[index].email_resend = newEmailResendValue;
            this.setState({
                docsInBatches: newArray,
            });
        } else {
            console.error("Index out of bounds");
        }
    };

    updateWithdrawnCertificate = (index) => {
        const newArray = [...this.state.docsInBatches];
        if (newArray[index]) {
            newArray[index].withdrawn = true;
            this.setState({
                docsInBatches: newArray,
            });
        } else {
            console.error("Index out of bounds");
        }
    };

    resendEmail = (document_id, id) => {
        this.setState({
            progress_loader: true,
        })
        const formattedDate = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
        axios.post(this.props.node_server_axios + 'resend_email_to_pending_by_id', {
            params: {
                batchId: this.props.batchId,
                document_id: document_id,
                event_name: this.state.event_name,
                sub_event_name: this.state.sub_event_name,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "Email has been resend to " + res.data.email,
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
                this.updateEmailResend(id, formattedDate);
            } else {
                this.setState({
                    warning: {
                        message: "Email could not be send.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    withdrawCertificate = async (email, document_id, id) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Withdraw Certificate",
                subtext: "Once withdrawn this certificate cannot be reissued to [" + email + "]. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        this.setState({
            progress_loader: true,
        })
        axios.post(this.props.node_server_axios + 'withdraw_certificate_by_email', {
            params: {
                batchId: this.props.batchId,
                email: email,
                document_id: document_id,
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "Certificate has been withdrawn from " + email,
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
                this.updateWithdrawnCertificate(id);
            } else {
                this.setState({
                    warning: {
                        message: "An Error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    progress_loader: false,
                })
            }
        });
    }

    fetchMoreDocsInBatches = async () => {
        try {
            await axios.post(this.props.node_server_axios + 'get_cert_status', {
                params: {
                    batchId: this.props.batchId,
                    nextPageToken: this.state.nextPageToken,
                },
            }).then(res => {
                if (res.data.docsInBatches) {
                    this.setState((prevState) => ({
                        docsInBatches: [...prevState.docsInBatches, ...res.data.docsInBatches],
                        nextPageToken: res.data.nextPageToken,
                        initialDocsInBatches: res.data.docsInBatches,
                        docsInBounces: res.data.docsInBatches.filter(item => item.bounce === true),
                        progress_loader: false,
                    }));
                } else {
                    this.setState({
                        progress_loader: false,
                        nextPageToken: res.data.nextPageToken,
                    })
                }
            }).catch(err => { console.log(err) })
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    }

    downloadBouncedList = () => {
        const { docsInBounces } = this.state;
        // Create CSV content
        const csvContent = "id, email, name\n" +
            docsInBounces.map(item => `${item.document_id}, ${item.email}, ${item.name}`).join("\n");
        // Create a Blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });
        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = '(bounced) ' + this.state.sub_event_name + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    formattedConvertDate(dateStr) {
        const [day, month, year] = dateStr.split('/');
        return `${year}${month}${day}`;
    }

    renderContent = () => {
        const renderType = this.state.docsInBatches && this.state.docsInBatches.length > 0 ? this.state.issuedToggle : false;
        const formattedDate = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
        const schedule_date = this.state.schedule_date && this.state.schedule_date.length > 1 ? this.formattedConvertDate(this.state.schedule_date) : formattedDate;

        switch (renderType) {
            case 'issued':
                return (
                    <div className='issue-failed'>
                        {this.state.nextPageToken !== null && (<button onClick={this.fetchMoreDocsInBatches} className='load-more'>Load More <i className="fa-solid fa-plus"></i></button>)}
                        {this.state.docsInBatches.map((row, id) => (
                            <div className='wrap' key={id}>
                                <div className='sub'>
                                    <h1>{row.name ? row.name : 'Sample Certificate'}</h1>
                                    <h2>{row.email ? row.email : this.props.email}</h2>
                                    <h3>ID : {row.document_id}</h3>
                                    <div className='status-icons'>
                                        <i className={row.email_send ? "fa-solid fa-envelope-circle-check active" : "fa-solid fa-envelope-circle-check"} title="email has been sent"></i>
                                        <i className={row.bounce ? "fa-solid fa-triangle-exclamation active" : "fa-solid fa-triangle-exclamation"} title="email has bounced"></i>
                                        <i className={row.email_view ? "fa-solid fa-envelope-open-text active" : "fa-solid fa-envelope-open-text"} title="email was opened"></i>
                                        {row.accepted === null ?
                                            <i className="fa-solid fa-circle-check" title="certificate accepted"></i>
                                            :
                                            row.accepted === 'true' ?
                                                <i className="fa-solid fa-circle-check active" title="certificate accepted"></i>
                                                :
                                                <i className="fa-solid fa-circle-xmark active" title="certificate rejected"></i>
                                        }
                                        {row.accepted === 'deleted' && (<div className='deleted'><i className="fa-regular fa-trash-can active"></i> Deleted</div>)}
                                    </div>
                                </div>
                                <div className='actions'>
                                    {row.withdrawn !== true && row.accepted !== 'false' && (
                                        <button onClick={() => this.withdrawCertificate(row.email, row.document_id, id)} className='withdraw'><i className="fa-regular fa-trash-can"></i> Withdraw</button>)}
                                    {(row.email_send && row.accepted !== null) || row.email_resend === formattedDate || row.bounce || parseInt(schedule_date) > parseInt(formattedDate) ?
                                        <button className='deactive'><i className="fa-regular fa-paper-plane"></i> Resend Email</button>
                                        :
                                        <button onClick={() => this.resendEmail(row.document_id, id)}><i className="fa-regular fa-paper-plane"></i> Resend Email</button>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                );
            case 'failed':
                return (
                    <div className='issue-failed'>
                        {this.state.unsend_certificates.map((element, id) =>
                            <div className='wrap' key={id}>
                                <div className='sub'>
                                    <h1>{element['[Recepient Name]']}</h1>
                                    <h2>{element['[Email]']}</h2>
                                </div>
                                <div className='actions'>
                                    <button onClick={() => this.resendCertificate(element)}>Send Again</button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 'bounced':
                return (
                    <div className='issue-failed'>
                        <div className='actions'>
                            <button onClick={() => this.downloadBouncedList()}><i className="fa-regular fa-circle-down"></i> Download Bounces</button>
                        </div>
                        {this.state.docsInBounces.map((row, id) => (
                            <div className='wrap' key={id}>
                                <div className='sub'>
                                    <h1>{row.name}</h1>
                                    <h2>{row.email}</h2>
                                    <h3>ID : {row.document_id}</h3>
                                    <div className='status-icons'>
                                        <i className={row.email_send ? "fa-solid fa-envelope-circle-check active" : "fa-solid fa-envelope-circle-check"} title="email has been sent"></i>
                                        <i className="fa-solid fa-triangle-exclamation active"></i>
                                        <i className="fa-solid fa-envelope-open-text"></i>
                                        {row.accepted === null ?
                                            <i className="fa-solid fa-circle-check" title="certificate accepted"></i>
                                            :
                                            row.accepted === 'true' ?
                                                <i className="fa-solid fa-circle-check active" title="certificate accepted"></i>
                                                :
                                                <i className="fa-solid fa-circle-xmark active" title="certificate rejected"></i>
                                        }
                                        {row.accepted === 'deleted' && (<div className='deleted'><i className="fa-regular fa-trash-can active"></i> Deleted</div>)}
                                    </div>
                                </div>
                                <div className='actions'>
                                    {row.withdrawn !== true && row.accepted !== 'false' && (
                                        <button onClick={() => this.withdrawCertificate(row.email, row.document_id, id)} className='withdraw'><i className="fa-regular fa-trash-can"></i> Withdraw</button>)}
                                </div>
                            </div>
                        ))}
                    </div>
                );
            case 'pending':
                return (
                    <div className='issue-failed'>
                        <div className='actions'>
                            <button onClick={() => this.resendEmailToPending()}><i className="fa-regular fa-paper-plane"></i> Resend Email To All</button>
                        </div>
                        {this.state.docsInBatches.map((row, id) => (
                            row.accepted === null && (
                                <div className='wrap' key={id}>
                                    <div className='sub'>
                                        <h1>{row.name}</h1>
                                        <h2>{row.email}</h2>
                                        <h3>ID : {row.document_id}</h3>
                                        <div className='status-icons'>
                                            <i className={row.email_send ? "fa-solid fa-envelope-circle-check active" : "fa-solid fa-envelope-circle-check"} title="email has been sent"></i>
                                            <i className={row.bounce ? "fa-solid fa-triangle-exclamation active" : "fa-solid fa-triangle-exclamation"} title="email has bounced"></i>
                                            <i className={row.email_view ? "fa-solid fa-envelope-open-text active" : "fa-solid fa-envelope-open-text"} title="email was opened"></i>
                                            <i className="fa-solid fa-circle-check"></i>
                                        </div>
                                    </div>
                                    <div className='actions'>
                                        {row.withdrawn !== true && row.accepted !== 'false' && (
                                            <button onClick={() => this.withdrawCertificate(row.email, row.document_id, id)} className='withdraw'><i className="fa-regular fa-trash-can"></i> Withdraw</button>)}
                                        {(row.email_send && row.accepted !== null) || row.email_resend === formattedDate || row.bounce || parseInt(schedule_date) > parseInt(formattedDate) ?
                                            <button className='deactive'><i className="fa-regular fa-paper-plane"></i> Resend Email</button>
                                            :
                                            <button onClick={() => this.resendEmail(row.document_id, id)}><i className="fa-regular fa-paper-plane"></i> Resend Email</button>
                                        }
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                );
            default:
                return (
                    <div className='csv-table'>
                        {this.state.csvData[0] && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sl.</th>
                                        {Object.keys(this.state.csvData[0]).map((key, index) => (
                                            <th key={index}>{key}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.csvData.map((row, id) => (
                                        <tr key={id}>
                                            <td>{id + 1}</td>
                                            {Object.values(row).map((value, index) => (
                                                <td key={index}>{value}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                );
        }
    }

    performSearch = async () => {
        const searchResults = await this.fakeSearchOperation(this.state.issueSearch);
        this.setState({ docsInBatches: searchResults });
    };

    fakeSearchOperation = async (query) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                // Replace this with your actual search logic
                const results = this.state.initialDocsInBatches.filter(
                    (item) =>
                        item.name.toLowerCase().includes(query.toLowerCase()) ||
                        item.email.toLowerCase().includes(query.toLowerCase())
                );
                resolve(results);
            }, 500);
        });
    };

    debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    toggleIssueSearch = (e) => {
        if (this.state.issuedToggle !== 'issued') {
            this.toggleIssueData('issued')
        }
        this.setState({
            issueSearch: e.target.value
        })
        this.debouncedSearch();
    }

    handleCopyBatchId = () => {
        const textarea = document.createElement('textarea');
        textarea.value = this.props.batchId;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.setState({
            warning: {
                message: "Batch ID copied to clipboard.",
                type: "success",
                learnmore: false,
                autoclose: true,
            },
        })
    };

    refreshPage = (e) => {
        e.preventDefault();
        window.location.reload();
    }

    openEmailPreview = () => {
        window.location.href = '/subevents/' + this.state.event_name + '/' + this.state.sub_event_name + '#email';
    }

    updateCheckbox = (e) => {
        this.setState({
            agree: e.target.checked ? this.state.agree + 1 : this.state.agree - 1,
        })
    }

    reloadWithTutorial = async () => {
        localStorage.removeItem('onboarding_batch');
        window.location.reload()
    }

    toggleSubOrganisationModal = (e) => {
        e.stopPropagation();
        this.setState({
            issueModal: false,
        }, () => {
            this.props.fetchSubOrganisationModal()
        })
    }

    render() {
        const { scaleFactor } = this.state;

        return (
            <React.Fragment>
                <div className="element">
                    {this.state.templatePreviewModal && (
                        <div className='batchcert-preview-modal' onClick={this.closeTemplatePreview}>
                            <div className='preview-rel' ref={ref => (this.divRef = ref)} style={{ transform: `scale(${scaleFactor})` }} onLoad={this.updateScale}>
                                <button className='close-btn'><i className="fa-solid fa-x"></i></button>
                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_bg)} alt='certificate template' />
                                {
                                    this.state.elements.map((element, index) => {
                                        if (element.type === "signature") {
                                            return <ViewSignee element_id={"view-" + index} key={"view-" + element.timeStamp + "-" + index} data={element} node_server_axios={this.state.signeeStatus ? this.props.node_server_axios : undefined} batch_id={this.props.batchId} />
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        </div>
                    )}
                    {this.state.scheduleModal && (
                        <div className='element-modal transfer-modal'>
                            <div className='content'>
                                <button className='close-btn' onClick={this.toggleScheduleIssueModal}><i className="fa-solid fa-x"></i></button>
                                <div className='container'>
                                    <h1>Schedule Issue On</h1>
                                </div>
                                <input type='date' min={this.state.minDate} value={this.state.selectedDate} onChange={this.updateScheduledDate} />
                                <p>Recepients will receive the email and associated notification instantly, but the certificate will not be available to the recepients and cannot be viewed by the recepients till the above said date.</p>
                                <button type="button" onClick={this.issueCertificates}>Schedule & Issue <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    )}
                    {this.state.reloadModal && (
                        <div className='element-modal transfer-modal'>
                            <div className='content'>
                                <div className='container'>
                                    <h1>View Status</h1>
                                </div>
                                <p>Once reloaded the updated status of your batch can be viewed.</p>
                                <div className='side-by-side'>
                                    <button type="button" onClick={this.reloadWithTutorial}>Reload with Tutorial<i className="fa-solid fa-arrow-right"></i></button>
                                    <button type="button" onClick={() => window.location.reload()}>Reload <i className="fa-solid fa-rotate-right"></i></button>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.issueModal && (
                        <div className='element-modal transfer-modal'>
                            <div className='content'>
                                <button className='close-btn' onClick={this.toggleIssueModal}><i className="fa-solid fa-x"></i></button>
                                <div className='container'>
                                    <h1>Actions</h1>
                                </div>
                                <div className='issue-preview'>
                                    <div>
                                        <div className='batch-data-email-preview' onClick={this.openTemplatePreview}>
                                            <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_bg)} alt='certificate template' />
                                        </div>
                                        <div className='agree'>
                                            <input type='checkbox' onChange={this.updateCheckbox}></input><p>I have checked the preview</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='batch-data-email-preview' onClick={this.openEmailPreview}>
                                            <img src={sampleEmail} alt="email preview" />
                                        </div>
                                        <div className='agree'>
                                            <input type='checkbox' onChange={this.updateCheckbox}></input><p>I confirm this email template</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='sub-org-consent'>
                                    <div className='agree'>
                                        <input type='checkbox' onChange={this.updateCheckbox}></input><p>I consent to issuing this batch of certificates as <b>{!localStorage.getItem('selected_subOrganisation') || localStorage.getItem('selected_subOrganisation') === 'Nil' ? this.props.org_name : (localStorage.getItem('selected_subOrganisation') + ", " + this.props.org_name)}</b></p>
                                        <div className='sub-org-name' onClick={this.toggleSubOrganisationModal}>Change</div>
                                    </div>
                                </div>
                                <div className='issue-actions'>
                                    <div className='cnt'>
                                        <p>If you want to see how your recepients receive the certificate, sample will be send to your account</p>
                                        <button onClick={this.sendSample}>Send Sample <i className="fa-solid fa-vial"></i></button>
                                    </div>
                                    <div className='cnt'>
                                        <p>Schedule your certificate to be viewed in a particular date</p>
                                        <button onClick={this.toggleScheduleIssueModal}>Schedule <i className="fa-regular fa-calendar-days"></i></button>
                                    </div>
                                    <div className='cnt'>
                                        <p>Certificates will be be distributed to your recepients, this action cannot be reverted</p>
                                        <button onClick={this.issueCertificates}>Issue Instantly <i className="fa-regular fa-circle-check"></i></button>
                                    </div>
                                </div>
                                <p><i className="fa-solid fa-circle-info"></i> Once issued actions cannot be reverted</p>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Batch Data<label>View recepients within batch</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace batches-new batches-v2'>
                            <div className='headboard'>
                                <div className='head-container'>
                                    <h2>Continue <span>to distribute</span></h2>
                                </div>
                                <div className='content-container'>
                                    <div className='content'>
                                        <a className='prev-btn refresh' href='#foo' onClick={this.refreshPage}>Refresh <i className="fa-solid fa-rotate-right"></i></a>
                                    </div>
                                    <div className='sort-container'>
                                        <p style={{ fontSize: '12px' }}>{this.state.issue_date !== null ? "Issued : " + this.state.issue_date : 'Not Issued'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='tiles marketplace feedback-new-att'>
                                <div className='sub-nav' style={{ paddingTop: '0px' }}>
                                    <button className='back-btn' onClick={() => window.location.href = "/subevents/" + this.state.event_name.replace(/\s+/g, '-').replace(/-+/g, '-').trim() + "/" + this.state.sub_event_name.replace(/\s+/g, '-').replace(/-+/g, '-').trim()}><i className="fa-solid fa-arrow-left-long"></i> Back</button>
                                    <p>Issued by : {this.state.created_by}</p>
                                    {this.state.loadingComponent && (
                                        this.state.issue_date === null ?
                                            this.state.signeeStatus ?
                                                <div className='plan-container issue-box'>
                                                    <h1>Proceed to</h1>
                                                    <button onClick={this.toggleIssueModal}>Issue Certificates <i className="fa-solid fa-arrow-right-long"></i></button>
                                                </div>
                                                :
                                                <div className='plan-container issue-box'>
                                                    <h1>Issue Certificates</h1>
                                                    <button className='deactive'>Confirm <i className="fa-regular fa-circle-check"></i></button>
                                                    {this.state.rejected_by ?
                                                        <React.Fragment>
                                                            <p className='font-red'><i className="fa-solid fa-circle-info"></i> Batch rejected by signees</p>
                                                            <button className='more-info' onClick={this.moreInfo}>{this.state.more_info ? "View Preview" : "View Signees"} <i className="fa-solid fa-circle-info"></i></button>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <p><i className="fa-solid fa-circle-info"></i> All Signees have not yet signed</p>
                                                            <div className='stat-container'>
                                                                <i className="fa-solid fa-user-tag"></i><span>{this.state.signees ? (this.state.signed_by ? this.state.signed_by.length : '0') + '/' + this.state.signees.length : 'No signees found'}</span>{this.state.signees && ('signees signed')}
                                                            </div>
                                                            <button className='more-info' onClick={this.moreInfo}>{this.state.more_info ? "View Preview" : "View Signees"} <i className="fa-solid fa-circle-info"></i></button>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            :
                                            <div className='check-accept-status'>
                                                {this.state.withdrawn !== 'true' && (<a href={'/download/' + this.props.batchId} target='_blank' rel='noreferrer'>Download Certificates <i className="fa-regular fa-circle-down"></i></a>)}
                                                <div className='batch-id-sec'>
                                                    <p><i className="fa-solid fa-fingerprint"></i> {this.props.batchId}</p>
                                                    <button onClick={this.handleCopyBatchId}>Copy <i className="fa-regular fa-copy"></i></button>
                                                </div>
                                                {/* <p><i className="fa-solid fa-list-check"></i> Check if recepients have accepted the document</p>
                                            <p className='label'>Email</p>
                                            <input type="email" placeholder='johndoe@gmail.com' value={this.state.userCheck} onChange={this.updateUserCheck} />
                                            <button onClick={this.submitUserCheck}><i className="fa-solid fa-magnifying-glass-arrow-right"></i></button>
                                            <p className={'status ' + this.state.userStatus}>Status : <b>{this.state.userStatus}</b></p> */}
                                                {this.state.withdrawn === 'true' ?
                                                    <a href='foo' className='reissue-batch-btn' onClick={this.reissueBatch}>Re-Issue Batch</a>
                                                    :
                                                    <a href='foo' className='withdraw-batch-btn' onClick={this.withdrawBatch}>Withdraw Batch</a>
                                                }
                                            </div>
                                    )}
                                    <div className='flex-grow break-20px'></div>
                                    <h1><i className="fa-solid fa-award"></i> Certificate Details</h1>
                                    {this.state.more_info ?
                                        <div className='more-info-block'>
                                            {/* <h3>Signees</h3> */}
                                            {this.state.signees.map((obj, index) => {
                                                if (this.state.signed_by && this.state.signed_by.length > 0 && this.state.signed_by.includes(obj.split("|")[0])) {
                                                    return (
                                                        <p key={index} className='signed'><label>Signed</label><b>{obj.split("|")[0]}</b>{obj.split("|")[1]}</p>
                                                    );
                                                } else if (obj.split("|")[0] === this.state.rejected_by) {
                                                    return (
                                                        <p key={index} className='rejected'><label>Rejected</label><b>{obj.split("|")[0]}</b>{obj.split("|")[1]}</p>
                                                    );
                                                } else {
                                                    return (
                                                        <p key={index} className='pending'><label>Pending</label><b>{obj.split("|")[0]}</b>{obj.split("|")[1]}</p>
                                                    );
                                                }
                                            })}
                                        </div>
                                        :
                                        <div>
                                            <div className='batch-data-email-preview' onClick={this.openTemplatePreview}>
                                                <img src={"https://pronetin-new.s3.amazonaws.com/batch/" + encodeURIComponent(this.state.org_id) + "/" + encodeURIComponent(this.state.cert_bg)} alt='certificate template' />
                                                <button>Preview Template</button>
                                            </div>
                                        </div>
                                    }
                                    {/* <h3>Status</h3>
                                    <div className='stat-container'>
                                        <i className="fa-solid fa-user-tag"></i><span>{this.state.signees ? this.state.signees.length : '0'}</span> Signee
                                    </div>
                                    <div className='stat-container accepted'>
                                        <i className="fa-solid fa-user-check"></i><span>{this.state.signed_by ? this.state.signed_by.length : '0'}</span> Accepted
                                    </div>
                                    <div className='stat-container rejected'>
                                        <i className="fa-solid fa-user-xmark"></i><span>{this.state.rejected_by ? '1' : '0'}</span> Rejected
                                    </div> */}
                                </div>
                                <div className='wrapper'>
                                    <div className='event-name-display' style={{ boxShadow: 'none' }}>
                                        <p><a href={'/distributed?event=' + this.state.event_name}>{this.state.sub_event_name}</a> <i className="fa-solid fa-angle-right"></i> <span onClick={() => window.location.href = "/subevents/" + this.state.event_name.replace(/\s+/g, '-').replace(/-+/g, '-').trim() + "/" + this.state.sub_event_name.replace(/\s+/g, '-').replace(/-+/g, '-').trim()}>{this.state.sub_event_name}</span> <i className="fa-solid fa-angle-right"></i> <span>Batch {this.state.batchNumber}</span></p>
                                    </div>
                                    {this.state.docsInBatches && (
                                        <div className='issued-failed-btn'>
                                            <button className={this.state.issuedToggle === false ? 'batch-btn-js active' : 'batch-btn-js recepients'} onClick={() => this.toggleIssueData(false)}><i className="fa-regular fa-rectangle-list"></i> Recepients</button>
                                            <button className={this.state.issuedToggle === 'issued' ? 'batch-btn-js active' : 'batch-btn-js issued'} onClick={() => this.toggleIssueData('issued')}><i className="fa-solid fa-circle-check"></i> Issued</button>
                                            <button className={this.state.issuedToggle === 'failed' ? 'batch-btn-js failed active' : 'batch-btn-js failed'} onClick={() => this.toggleIssueData('failed')}><i className="fa-regular fa-circle-xmark"></i> Failed <span>{this.state.unsend_certificates !== null && (this.state.unsend_certificates.length)}</span></button>
                                            <button className={this.state.issuedToggle === 'bounced' ? 'batch-btn-js bounced active' : 'batch-btn-js bounced'} onClick={() => this.toggleIssueData('bounced')}><i className="fa-solid fa-circle-check"></i> Bounced <span>{this.state.docsInBounces !== null && (this.state.docsInBounces.length)}</span></button>
                                            <button className={this.state.issuedToggle === 'pending' ? 'batch-btn-js active' : 'batch-btn-js pending'} onClick={() => this.toggleIssueData('pending')}><i className="fa-solid fa-circle-check"></i> Pending</button>
                                            <div className='batch-btn-js search-input'>
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <input type='text' placeholder='Search' value={this.state.issueSearch} onChange={this.toggleIssueSearch} />
                                            </div>
                                        </div>
                                    )}
                                    {this.renderContent()}
                                </div>
                                {
                                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment >
        );
    }
}

export default BatchData;