import React from 'react';
import './css/element.css';

class VerifyESignComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cacheTs: Date.now(),
            validityDifference: this.props.data.valid_upto !== 'false' ? this.calculateDifference(this.props.data.valid_upto) : '',
            authorisedUsers: false,
        };
    }

    calculateDifference = (inputDate) => {
        if (inputDate) {
            const currentDate = new Date();
            const inputDateObject = new Date(inputDate);
            const diffInTime = inputDateObject - currentDate;

            if (isNaN(diffInTime)) {
                this.setState({ difference: 'Invalid date' });
                return;
            }

            const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
            const diffInMonths = Math.floor(diffInDays / 30);
            const diffInYears = Math.floor(diffInMonths / 12);

            let displayDifference;
            if (diffInYears > 0) {
                const remainingMonths = diffInMonths % 12;
                const remainingDays = diffInDays % 30;
                displayDifference = `${diffInYears} years, ${remainingMonths} months and ${remainingDays} days`;
            } else if (diffInMonths > 0) {
                const remainingDays = diffInDays % 30;
                displayDifference = `${diffInMonths} months and ${remainingDays} days`;
            } else {
                displayDifference = `${diffInDays} days`;
            }

            return (displayDifference)
        } else {
            return ('0 days');
        }
    };

    toggleAuthorisedUsers = () => {
        this.setState({ authorisedUsers: !this.state.authorisedUsers });
    }

    render() {
        return (
            <div className='esign-modal scanned-view'>
                <div className='container'>
                    <div className='preview'>
                        <div className='wrapper'>
                            <div className='user-icons'>
                                {this.props.data.profile_icon ?
                                    <img src={"https://pronetin-new.s3.amazonaws.com/user/" + this.props.data.email + "/profileimage.jpg?cacheblock=" + this.state.cacheTs} alt="profile icon"></img>
                                    :
                                    <i className="fa-solid fa-user"></i>
                                }
                                <i className="fa-solid fa-circle-check"></i>
                            </div>
                            <div className='basic'>
                                <h2>{this.props.data.name}</h2>
                                <label>{this.props.data.designation.length > 0 ? this.props.data.designation : 'Designation: Nil'}</label>
                                <label>{this.props.data.org_name.length > 0 ? this.props.data.org_name : 'Organisation Name: Nil'}</label>
                                {!this.state.authorisedUsers && (
                                    <React.Fragment>
                                        <p>Purpose : {this.props.data.purpose.length > 0 ? <span>{this.props.data.purpose}</span> : <span>Not Mentioned</span>}</p>
                                        {this.props.data.use_by ?
                                            <div className='use'>this signature can be used by<span>Anyone can use</span>{this.props.data.valid_upto !== 'false' && (<b>{this.state.validityDifference.includes('-') ? "Expired" : "Expires in " + this.state.validityDifference}</b>)}</div>
                                            :
                                            <div className='use' onClick={this.toggleAuthorisedUsers}>this signature can be used by<span className='red'>Authorised Users <i className="fa-solid fa-arrow-right-long"></i></span>{this.props.data.valid_upto !== 'false' && (<b>{this.state.validityDifference.includes('-') ? "Expired" : "Expires in " + this.state.validityDifference}</b>)}</div>
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                            {!this.state.authorisedUsers ?
                                <div className='basic advanced'>
                                    <div className='use'>description</div>
                                    {this.props.data.description.length > 0 ? this.props.data.description : "Nil"}
                                </div>
                                :
                                <div className='basic advanced'>
                                    <button className='back-btn' onClick={this.toggleAuthorisedUsers}><i className="fa-solid fa-arrow-left-long"></i> back</button>
                                    <div className='use'>Authorised Users</div>
                                    {this.props.data.access_to}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VerifyESignComponent;