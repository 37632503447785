import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';

import APIicon from '../../images/home/API/API.png';
import Badgeicon from '../../images/home/API/Badge API.png';
import Eventsicon from '../../images/home/API/events API.png';
import Formsicon from '../../images/home/API/forms API.png';
import NFCicon from '../../images/home/API/NFC API.png';
import QRicon from '../../images/home/API/QR API.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        const { activeAnchor } = this.state;

        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='heading'>
                            <h2>API Integration</h2>
                            <p>All you need to know</p>
                        </div>
                        <div className='features-page'>
                            <div className='bg-pattern'></div>
                            <div className='navigation'>
                                <a href='#foo' className={activeAnchor === 'n1' ? 'active' : ''} data='n1' onClick={this.handleAnchorClick}>Basics</a>
                                <a href='#foo' className={activeAnchor === 'n2' ? 'active' : ''} data='n2' onClick={this.handleAnchorClick}>Embedd Pronetin Badge</a>
                                <a href='#foo' className={activeAnchor === 'n3' ? 'active' : ''} data='n3' onClick={this.handleAnchorClick}>Registration Forms</a>
                                <a href='#foo' className={activeAnchor === 'n4' ? 'active' : ''} data='n4' onClick={this.handleAnchorClick}>Events Data</a>
                                <a href='#foo' className={activeAnchor === 'n5' ? 'active' : ''} data='n5' onClick={this.handleAnchorClick}>NFC API</a>
                                <a href='#foo' className={activeAnchor === 'n6' ? 'active' : ''} data='n6' onClick={this.handleAnchorClick}>QR Verification</a>
                            </div>
                            <div className='container'>
                                <div className='col'>
                                    <div className='wrapper' id='n1'>
                                        <div className='image'><img src={APIicon} alt="APIicon" /></div>
                                        <div className='content'>
                                            <h3>Basics</h3>
                                            <p>Learn how to seamlessly integrate Pronetin's API into your applications. This section covers essential setup steps, authentication, and basic usage guidelines.</p>
                                            <a href='/docs/api/basics'>See Documentation <i className="fa-solid fa-arrow-right-long"></i></a>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n3'>
                                        <div className='image'><img src={Formsicon} alt="Formsicon" /></div>
                                        <div className='content'>
                                            <h3>Registration Forms</h3>
                                            <p>Explore the API for creating and managing registration forms. This section provides details on form fields, data submission, and response handling.</p>
                                            <a href='#foo' style={{ pointerEvents: 'none' }}>Coming Soon</a>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n5'>
                                        <div className='image'><img src={NFCicon} alt="NFCicon" /></div>
                                        <div className='content'>
                                            <h3>NFC API</h3>
                                            <p>Integrate Near Field Communication (NFC) capabilities with Pronetin. This section explains how to read, write, and manage NFC data for secure interactions.</p>
                                            <a href='#foo' style={{ pointerEvents: 'none' }}>Coming Soon</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='wrapper' id='n2'>
                                        <div className='image'><img src={Badgeicon} alt="Badgeicon" /></div>
                                        <div className='content'>
                                            <h3>Embedd Pronetin Badge</h3>
                                            <p>Discover how to embed Pronetin Badges in your platforms. This section includes badge creation, embedding code, and customization options for seamless integration.</p>
                                            <a href='/docs/api/embedd-badge'>See Documentation <i className="fa-solid fa-arrow-right-long"></i></a>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n4'>
                                        <div className='image'><img src={Eventsicon} alt="Eventsicon" /></div>
                                        <div className='content'>
                                            <h3>Events Data</h3>
                                            <p>Access and manage event-related data through Pronetin's API. This section covers event creation, attendee management, and analytics management.</p>
                                            <a href='#foo' style={{ pointerEvents: 'none' }}>Coming Soon</a>
                                        </div>
                                    </div>
                                    <div className='wrapper' id='n6'>
                                        <div className='image'><img src={QRicon} alt="QRicon" /></div>
                                        <div className='content'>
                                            <h3>QR Verification</h3>
                                            <p>Learn how to implement QR code verification using Pronetin's API. This section details generating, scanning, and verifying QR codes for enhanced security.</p>
                                            <a href='#foo' style={{ pointerEvents: 'none' }}>Coming Soon</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;