import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';
import coinIcon from '../images/static/Component 397 – 4.png';

import comingSoon1 from '../images/static/comingSoon1.png';
import comingSoon2 from '../images/static/comingSoon2.png';
import comingSoon3 from '../images/static/comingSoon3.png';

class MarketplaceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            certTemplateIndex: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
            certViewer: false,
            designRequestReceived: false,
            moreBtn: 'more',
            running_plan: 'free',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_organisation_data', {
            params: {
                org_id: this.props.org_id,
            }
        }).then((res) => {
            if (res.data !== undefined && res.data.message === 'success') {
                let save_slots = 3;
                if (res.data.running_plan !== null && res.data.running_plan !== 'free') {
                    save_slots = 6;
                } else {
                    save_slots = 3;
                }
                this.setState({
                    running_plan: res.data.running_plan,
                    save_slots: save_slots,
                });
            } else {
                window.location = "/signin";
            }
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'more',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'back',
            });
        }
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    handleTemplate = (slug) => {
        this.setState({
            certViewer: slug,
        })
    }

    viewAllTemplates = () => {
        this.setState({
            certViewer: false,
        })
    }

    useTemplate = async (slug) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Overwrite Draft",
                subtext: "Choosing this template will overwrite your current design in draft. Do you want to continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: false,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        window.location.href = '/issue/' + slug;
    }

    requestDesignAssistance = () => {
        axios.post(this.props.node_server_axios + 'request_design_assistance', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data && res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "Our agent will get in touch with you soon via " + this.props.email,
                        type: "success",
                        learnmore: false,
                        autoclose: false,
                    },
                    designRequestReceived: true,
                })
            }
        });
    }

    designRequestReceived = () => {
        this.setState({
            warning: {
                message: "Request already received, We will contact you via " + this.props.email,
                type: "warning",
                learnmore: false,
                autoclose: true,
            },
            designRequestReceived: true,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Marketplace<label>Buy custom made templates</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} <i className="fa-solid fa-ellipsis-vertical"></i></button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace'>
                            <div className='sub-nav'>
                                <h1>Credits</h1>
                                <h2>{localStorage.getItem('displyCredits')} <img src={coinIcon} className="coin-icon" alt="user icon"></img></h2>
                                <button onClick={() => window.location = './premium'}>Buy Credits</button>
                                <br></br>
                                <p><i className="fa-solid fa-circle-info"></i> All available templates will also appear in <a href='./issue'>certificate editor</a></p>
                                <div className='flex-grow'></div>
                                <div className='plan-container'>
                                    <h1 className='capitalize'>{this.state.running_plan.replace("-y", "")} Plan</h1>
                                    <button onClick={() => { window.location = './premium' }}>Upgrade <i className="fa-solid fa-square-plus"></i></button>
                                    <p>{this.state.running_plan.includes("-y") ? "Yearly Subscription" : "Monthly Subscription"}</p>
                                </div>
                            </div>
                            {this.state.certViewer ?
                                <div className='wrapper'>
                                    <div className='btn-wrapper'>
                                        <button className='back-btn' onClick={this.viewAllTemplates}><i className="fa-solid fa-arrow-left-long"></i> Back</button>
                                        <button className='use-btn' onClick={() => this.useTemplate("tem" + this.state.certViewer)}>Use this Template</button>
                                    </div>
                                    <img className='marketplace-view' src={"https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/background/tem" + encodeURIComponent(this.state.certViewer) + ".png"} alt={"tem" + this.state.certViewer} />
                                </div>
                                :
                                <div className='wrapper'>
                                    <p>Organisation Templates</p>
                                    <div className='template-wrapper'>
                                        {this.state.designRequestReceived ?
                                            <div className='template-tile button' onClick={this.designRequestReceived}>
                                                <i className="fa-regular fa-circle-check"></i>
                                                Design Request<br></br>Received
                                                <p>Kindly be ready with your references and suggestions, when our agent connects with you.</p>
                                            </div>
                                            :
                                            <div className='template-tile button' onClick={this.requestDesignAssistance}>
                                                <i className="fa-solid fa-headset"></i>
                                                Request Design<br></br>Assistance
                                                <p>Kindly be ready with your references and suggestions, when our agent connects with you.</p>
                                            </div>
                                        }
                                    </div>

                                    <p>Free</p>
                                    <div className='template-wrapper'>
                                        {this.state.certTemplateIndex.map((number) => (
                                            <div className='template-tile' onClick={() => this.handleTemplate(number)} key={number}><img src={"https://pronetin-new.s3.ap-south-1.amazonaws.com/templates/certificates/preview/tem" + encodeURIComponent(number) + ".png"} alt={"tem" + number} /></div>
                                        ))}
                                    </div>

                                    <p>Premium</p>
                                    <div className='template-wrapper'>
                                        <div className='template-tile'><img src={comingSoon1} alt="coming soon 1" /></div>
                                        <div className='template-tile'><img src={comingSoon2} alt="coming soon 2" /></div>
                                        <div className='template-tile'><img src={comingSoon3} alt="coming soon 3" /></div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MarketplaceComponent;