import React from 'react';
import '../css/home_new.css';
import Header from '../landing_header';
import Footer from '../landing_footer';
import MainImage from '../../images/home/Solutions/E-Signature/e-signature.png';
import InfoImage from '../../images/home/Solutions/E-Signature/e-signature @ pronetin.png';
import Image1 from '../../images/home/Solutions/E-Signature/e-signature why.png';
import Image2 from '../../images/home/Solutions/E-Signature/e-signature benefits.png';
import Image3 from '../../images/home/Solutions/E-Signature/e-signature get started.png';

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            activeAnchor: 'n1',
        };
    }

    componentDidMount() {
        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            const targetElement = document.querySelector('#' + cleanHashValue);
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleAnchorClick = (e) => {
        e.preventDefault();
        const anchor = e.target.getAttribute('data');
        const targetElement = document.querySelector('#' + anchor);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            this.setState({ activeAnchor: anchor });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    <section>
                        <div className='solution intro'>
                            <div className='content'>
                                <h1>Pronetin E-Signatures</h1>
                                <h2>Secure Your Documents with Pronetin e-Signatures</h2>
                                <p>In an era where document security is paramount, Pronetin introduces a revolutionary e-signature system designed to safeguard your signatures and provide comprehensive verification. Pronetin e-Signatures ensure that every document you sign is secure, authenticated, and trusted.</p>
                                <div className='btn-container'>
                                    <button className='alt' onClick={() => window.location = '/help'}>Request a demo</button>
                                    <button onClick={() => window.location = '/signin'}>Explore</button>
                                </div>
                            </div>
                            <div className='rep'>
                                <img src={MainImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution info'>
                            <div className='content'>
                                <h2>What is Pronetin e-Signatures?</h2>
                                <p>Pronetin e-Signatures is an advanced digital signature solution that not only facilitates easy and secure online signing but also integrates a unique QR code verification system. This innovative feature guarantees the authenticity and integrity of your signatures, whether used online or on printed documents.</p>
                            </div>
                            <div className='rep'>
                                <img src={InfoImage} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>Why Choose Pronetin e-Signatures?</h2>
                                <ul>
                                    <li><strong>Enhanced Security</strong>: Each e-signature comes with a QR code that provides detailed information about the signature, including the owner, the purpose, the signing date, and the intended recipients. This makes unauthorised use of your signature virtually impossible.</li>
                                    <li><strong>Verification and Authenticity</strong>: The QR code ensures that signatures can be easily verified, giving recipients confidence in the document's legitimacy.</li>
                                    <li><strong>Informed Signing</strong>: With Pronetin, signing authorities can review and authorise each document, knowing exactly where and how their signature will be used.</li>
                                    <li><strong>Versatility</strong>: Use Pronetin e-Signatures for both digital documents and printed materials. For offline documents, simply print the e-signature along with its QR code.</li>
                                    <li><strong>Time Efficiency</strong>: Streamline your signing process by eliminating the need for physical signatures and reducing administrative overhead.</li>
                                    <li><strong>Tamper-Proof</strong>: The integration of a unique QR code with each signature prevents tampering and ensures the document's integrity.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image1} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution alt'>
                            <div className='content'>
                                <h2>Benefits of Pronetin e-Signatures</h2>
                                <ul>
                                    <li><strong>Ultimate Security</strong>: Protect your signature from misuse and fraud with our advanced QR code verification system.</li>
                                    <li><strong>Trust and Credibility</strong>: Instil trust in your recipients by providing verifiable, authenticated signatures.</li>
                                    <li><strong>Efficiency</strong>: Save time and effort with a seamless, digital signing process that can be used for both online and offline documents.</li>
                                    <li><strong>Detailed Insights</strong>: Access comprehensive details about the signature's usage, enhancing transparency and accountability.</li>
                                </ul>
                            </div>
                            <div className='rep'>
                                <img src={Image2} alt='pronetin solutions' />
                            </div>
                        </div>
                        <div className='solution'>
                            <div className='content'>
                                <h2>How to Get Started</h2>
                                <p>
                                    Adopting Pronetin e-Signatures is simple. Sign up on our platform, and start signing your documents with the peace of mind that comes with enhanced security and verification.<br></br>
                                    <strong>Join Pronetin today and secure your documents with our cutting-edge e-signature technology. Experience the future of secure document signing with Pronetin, your trusted partner in digital signature solutions.</strong>
                                </p>
                            </div>
                            <div className='rep'>
                                <img src={Image3} alt='pronetin solutions' />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;