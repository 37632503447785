import React from 'react';
import { useParams } from 'react-router-dom';

import EmailVerification from '../components/verify_transfer_consent';

const MailerComponent = (props) => {
    let { slug } = useParams();
    let { email } = useParams();
    let { code } = useParams();

    return (
        <EmailVerification old_email={slug} new_email={email} code={code} node_server_axios={props.node_server_axios} />
    );
};

export default MailerComponent;