import Tile from './tileV2';
import AppreciationTile from './tileV2_appreciation'
import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';
import certLoading from '../images/static/cert_loading.gif';
import sparkle from '../images/static/sparkle.gif';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            view_type: 'all',
            response: [],
            docList: [],
            appreciationList: [],
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            docView: 'all',
            searchByValue: "Event Name",
            searchValue: "",
            documentLoading: true,
            redirectedSearch: false,
        };

        this.debounceTimer = null;
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'document_list', {
            params: {
                view_type: this.state.view_type,
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                response: res.data,
                docList: res.data,
                documentLoading: false,
            }, () => {
                this.props.documentLoaded(true);
            })
        });
        axios.post(this.props.node_server_axios + 'appreciation_document_list', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                appreciationList: res.data,
                documentLoading: false,
            }, () => {
                this.props.documentLoaded(true);
            })
        });

        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(currentUrl.split('?')[1]);
        const searchValue = urlParams.get('search');
        const state = { redirectedSearch: searchValue };
        window.history.pushState(state, '', '/');
        if (searchValue === 'true') {
            this.setState({ redirectedSearch: true });
        }
    }

    componentWillUnmount() {
        // Clear the timer when the component is unmounted
        clearTimeout(this.debounceTimer);
    }

    updateSearchByValue = (e) => {
        this.setState({
            searchByValue: e.target.value,
        })
    }

    updateSearchValue = (e) => {
        const searchValue = e.target.value;
        this.setState({
            searchValue: searchValue,
            docView: 'all',
        });
        // Clear the previous timer
        clearTimeout(this.debounceTimer);
        // Set up a new timer to perform the action after a delay
        this.debounceTimer = setTimeout(() => {
            this.handleDebouncedSearchValue(searchValue);
        }, 500);
    }

    handleDebouncedSearchValue = (searchValue) => {
        let searchResults = this.state.response;
        switch (this.state.searchByValue) {
            case "event":
                searchResults = this.state.response.filter(item =>
                    item.event.toLowerCase().includes(searchValue.toLowerCase())
                );
                break;
            case "title":
                searchResults = this.state.response.filter(item =>
                    item.title.toLowerCase().includes(searchValue.toLowerCase())
                );
                break;
            case "issuer_name":
                searchResults = this.state.response.filter(item =>
                    item.issuer_name.toLowerCase().includes(searchValue.toLowerCase())
                );
                break;
            default:
                searchResults = this.state.response.filter(item =>
                    item.event.toLowerCase().includes(searchValue.toLowerCase())
                );
        }

        this.setState({
            docList: searchResults
        });
    };

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    toggleDocView = (type) => {
        if (type === 'all') {
            this.setState({
                docList: this.state.response,
                docView: type,
            })
        } else {
            this.setState({
                docList: this.state.response.filter(item => item.view_type === type),
                docView: type,
            })
        }
    }

    toggleSearch = (e) => {
        console.log(e.target.classList);
        if (e.target.classList.contains('cert-search') || e.target.classList.contains('search-btn')) {
            var subNav = document.getElementsByClassName('cert-search')[0];
            subNav.classList.remove('show');
            subNav.classList.add('hide');
        }
    }

    clearSearch = () => {
        this.setState({
            searchValue: '',
            docList: this.state.response,
            docView: 'all',
        })
    }

    render() {
        const achievementLvL = this.state.appreciationList.length > 0 ? this.state.appreciationList.length : 1
        const badgeLvL = Math.floor(achievementLvL / 9);
        let badgeName = 'unknown';

        switch (badgeLvL) {
            case 0:
                badgeName = "iron";
                break;
            case 1:
                badgeName = "bronze";
                break;
            case 2:
                badgeName = "silver";
                break;
            case 3:
                badgeName = "gold";
                break;
            case 4:
                badgeName = "platinum";
                break;
            case 5:
                badgeName = "emerald";
                break;
            default:
                badgeName = "emerald";
        }

        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <div className="badge-container">
                                    {achievementLvL > 5 ? <img src={sparkle} alt='s' /> : null}
                                    <div className={badgeName + ' badge'} title={badgeName + ' level'}>
                                        <span>{achievementLvL}</span>
                                    </div>
                                </div>
                                <h1>Certificates<label>{this.state.searchValue === "" ? this.state.docView + ' Certificates' : <div className='search-tag' onClick={this.clearSearch}><p>{this.state.searchValue}</p> <i className="fa-regular fa-circle-xmark"></i></div>}</label></h1>
                                <div className='toggle'>
                                    <button className={this.state.docView === 'all' ? 'active' : ''} onClick={() => { this.toggleDocView('all') }}>All&nbsp;Certificates</button>
                                    <button className={this.state.docView === 'public' ? 'active' : ''} onClick={() => { this.toggleDocView('public') }}>Public</button>
                                    <button className={this.state.docView === 'private' ? 'active' : ''} onClick={() => { this.toggleDocView('private') }}>Private</button>
                                </div>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className={this.state.redirectedSearch ? 'cert-search show' : 'cert-search'} onClick={this.toggleSearch}>
                            <input type="text" placeholder='Search Certificates' value={this.state.searchValue} onChange={this.updateSearchValue} />
                            {/* <span>By</span> */}
                            <select onChange={this.updateSearchByValue}>
                                <option value="event">Event Name</option>
                                <option value="title">Certificate Title</option>
                                <option value="issuer_name">Organisation Name</option>
                            </select>
                            <button className='search-btn'><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                        <div className='tiles'>
                            {this.state.documentLoading ?
                                <div className='empty-message'>
                                    <img src={certLoading} alt='certificate loading' />
                                </div>
                                :
                                this.state.docList.length > 0 ?
                                    this.state.docList.map(tiles => {
                                        return (
                                            <Tile key={tiles.document_id} data={tiles} />
                                        )
                                    })
                                    :
                                    (!this.state.appreciationList || this.state.docView === 'private') && (
                                        <div className='empty-message'>
                                            <i className="fa-solid fa-ghost"></i>
                                            You do not have any {this.state.docView !== 'all' && (this.state.docView)} certificates
                                        </div>
                                    )
                            }

                            {this.state.searchValue === '' && this.state.appreciationList && this.state.docView !== 'private' && (
                                this.state.appreciationList.map(tiles => {
                                    return (
                                        <AppreciationTile key={tiles.document_id} data={tiles} />
                                    )
                                })
                            )}
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                    <div className='fadeout'></div>
                </div>
            </React.Fragment>
        );
    }
}

export default TileComponent;