import React from 'react';
import './css/element.css';
import axios from 'axios';

import SignatureEditor from './editor/signature';

import Warning from '../components/warning';
import WarningAction from '../components/warning_action';

import icon1 from '../images/icons/signature/file_clarity.png';
import icon2 from '../images/icons/signature/file_format.png';
import icon3 from '../images/icons/signature/file_size.png';
import icon4 from '../images/icons/signature/file_dimensions.png';
import sampleSignature from '../images/signature-image-john-doe.png';
import sampleCertificate from '../images/sampleCertificate.png';

class MySignatureComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            base64string: "",
            signature_image: "",
            zoom: 1,
            threshold: 120,
            moreBtn: 'more',
            signaturePreview: false,
            hasCamera: false,
            approvals: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        this.setState({
            window_width: window.innerWidth,
        })

        const queryParams = new URLSearchParams(window.location.search);
        const o = queryParams.get('o');

        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'get_signature', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            this.setState({
                current_signature: res.data.signature,
            })
            if (res.data.signature && res.data.signature !== '' && res.data.signature !== null && o === 'uploaded') {
                axios.post(this.props.node_server_axios + 'get_notifications').then((res) => {
                    if (res.data && res.data.message === 'success' && res.data.notifications.approvals > 0) {
                        this.setState({
                            approvals: true,
                        })
                    }
                })
            }
        });

        if (o != null) {
            if (o === 'success') {
                this.setState({
                    warning: {
                        message: "Signature Uploaded.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            } else if (o === 'toolarge') {
                this.setState({
                    warning: {
                        message: "Image size too large (Max size: 250kb)",
                        type: "warning",
                        learnmore: false,
                        autoclose: false,
                    }
                })
            } else if (o === 'deleted') {
                this.setState({
                    warning: {
                        message: "Signature Deleted.",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        }

        this.checkCameraAvailability();
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    handleFileSelected = async (e) => {
        var file = e.target.files[0];
        var fileExtension = file.name.split('.').pop();

        if (fileExtension === 'jpeg' || fileExtension === 'jpg') {
            function getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }

            let base64string = await getBase64(file);
            let converted_img = await this.imageLoader(base64string, this.state.threshold);

            this.setState({
                base64string: base64string,
                signature_image: "data:image/jpeg;base64," + converted_img
            })
            if (window.innerWidth < 820) {
                var subNav = document.getElementsByClassName('sub-nav')[0];
                subNav.classList.remove('show');
                subNav.classList.add('hide');
                this.setState({
                    moreBtn: 'upload',
                });
            }
        } else {
            this.setState({
                warning: {
                    message: "Only jpg and jpeg image formats are supported",
                    type: "warning",
                    learnmore: false,
                    autoclose: false,
                }
            })
        }
    }

    imageLoader(base64string, threshold) {
        return new Promise((resolve, reject) => {
            var ctx = document.createElement("canvas").getContext("2d"),
                image = new Image();
            image.onload = function () {
                var w = ctx.canvas.width = image.width,
                    h = ctx.canvas.height = image.height;

                ctx.drawImage(image, 0, 0, w, h);
                var d = ctx.getImageData(0, 0, w, h);

                for (var i = 0; i < d.data.length; i += 4) {
                    d.data[i] = d.data[i + 1] = d.data[i + 2] = d.data[i + 1] > parseInt(threshold) ? 255 : 0;
                }

                ctx.putImageData(d, 0, 0);
                const converted_img = ctx.canvas.toDataURL("image/jpeg").split(';base64,')[1];
                resolve(converted_img);
            };
            image.src = base64string;
        })
    }

    updateZoom = (e) => {
        this.setState({
            zoom: e.target.value
        })
    }

    updateThreshold = async (e) => {
        let converted_img = await this.imageLoader(this.state.base64string, this.state.threshold);
        this.setState({
            threshold: e.target.value,
            signature_image: "data:image/jpeg;base64," + converted_img
        })
    }

    increaseThreshold = async () => {
        let new_threshold = parseInt(this.state.threshold) + 5;
        if (new_threshold <= 140) {
            let converted_img = await this.imageLoader(this.state.base64string, new_threshold);
            this.setState({
                threshold: new_threshold,
                signature_image: "data:image/jpeg;base64," + converted_img
            })
        }
    }

    decreaseThreshold = async () => {
        let new_threshold = parseInt(this.state.threshold) - 5;
        if (new_threshold >= 70) {
            let converted_img = await this.imageLoader(this.state.base64string, new_threshold);
            this.setState({
                threshold: new_threshold,
                signature_image: "data:image/jpeg;base64," + converted_img
            })
        }
    }

    discardSignature = () => {
        const fileInput = document.getElementById('file');
        fileInput.value = '';
        this.setState({
            signature_image: "",
        })
    }

    toggleMore = () => {
        var subNav = document.getElementsByClassName('sub-nav')[0];
        if (subNav.classList.contains('show')) {
            subNav.classList.remove('show');
            subNav.classList.add('hide');
            this.setState({
                moreBtn: 'upload',
            });
        } else {
            subNav.classList.add('show');
            subNav.classList.remove('hide');
            this.setState({
                moreBtn: 'more',
            });
        }
    }

    toggleSignaturePreview = () => {
        this.setState({
            signaturePreview: !this.state.signaturePreview,
        })
    }

    checkCameraAvailability = async () => {
        const userAgent = navigator.userAgent;
        if (userAgent.match(/Android/i) || userAgent.match(/iPhone|iPad|iPod/i)) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                this.setState({ hasCamera: true });
                stream.getTracks().forEach(track => track.stop());
            } catch (error) {
                this.setState({ hasCamera: false });
                console.error('Error accessing camera:', error);
            }
        }
    };

    deleteSignature = async () => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Signature",
                subtext: "Once deleted this signature cannot be recovered. Continue to delete?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'upload_signature', {
            params: {
                email: this.props.email,
                signature: '',
            },
        }).then(res => {
            window.location.replace("/mysignature?o=deleted");
        });
    }

    toggleApprovalModal = () => {
        this.setState({
            approvals: !this.state.approvals,
        })
    }

    render() {
        const { hasCamera } = this.state;
        return (
            <React.Fragment>
                <div className="element">
                    {this.state.approvals && (
                        <div className='element-modal transfer-modal'>
                            <div className='content'>
                                <button className='close-btn' onClick={this.toggleApprovalModal}><i className="fa-solid fa-x"></i></button>
                                <div className='container'>
                                    <h1>Preview</h1>
                                </div>
                                <div className='container' style={{ border: '1px solid #000', borderRadius: '5px', width: 'fit-content', display: 'block', margin: 'auto' }}>
                                    <div className='template-tile'>
                                        <div className='signature-preview'>
                                            <img src={sampleCertificate} alt="sample certificate" />
                                            <img src={this.state.current_signature} className="user-signature" alt="user signature" />
                                        </div>
                                    </div>
                                </div>
                                <div className='container'>
                                    <h1>You have certificates to sign</h1>
                                    <p>Since you have uploaded your signature, you can proceed to sign the certificates available to you.</p>
                                </div>
                                <button type="button" onClick={() => window.location.href = "/approvals"} className='blue-button'>Approvals <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>My Signature<label>Upload signature</label></h1>
                                <button className='more-action-btn' onClick={this.toggleMore}>{this.state.moreBtn} {this.state.moreBtn === 'upload' ? <i className="fa-solid fa-cloud-arrow-up"></i> : <i className="fa-solid fa-ellipsis-vertical"></i>}</button>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='tiles marketplace signature-tile'>
                            <div className='sub-nav show'>
                                <h1><i className="fa-solid fa-signature"></i> Current Signature</h1>
                                {this.state.current_signature ?
                                    <div className='template-tile'>
                                        <button className='delete-btn' onClick={this.deleteSignature}><i className="fa-regular fa-trash-can"></i></button>
                                        <button className='preview-btn' onClick={this.toggleSignaturePreview}><i className="fa-regular fa-image"></i> Preview</button>
                                        {this.state.signaturePreview ?
                                            <div className='signature-preview'>
                                                <img src={sampleCertificate} alt="sample certificate" />
                                                <img src={this.state.current_signature} className="user-signature" alt="user signature" />
                                            </div>
                                            :
                                            <img src={this.state.current_signature} alt="user signature" />
                                        }
                                    </div>
                                    :
                                    <h3>No Signature Uploaded</h3>
                                }
                                {this.state.current_signature && (
                                    <p><i className="fa-solid fa-circle-info"></i> Once new signature is uploaded current signature will be replaced</p>
                                )}
                                {this.state.window_width < 820 && (
                                    <button className='signature-privacy-btn' onClick={this.toggleMore}>Privacy policy & instructions</button>
                                )}
                                <div className='flex-grow'></div>
                                <div className='upload-signature'>
                                    <p><i className="fa-solid fa-upload"></i> Upload New Signature</p>
                                    {hasCamera && (
                                        <div className='relative'>
                                            <input onChange={this.handleFileSelected} type='file' id='file_cam' accept="image/*" capture="camera" />
                                            <label htmlFor="file_cam"><i className="fa-solid fa-camera"></i> Take a photo</label>
                                        </div>
                                    )}
                                    <div className='relative'>
                                        <input onChange={this.handleFileSelected} type='file' id='file' accept=".jpg,.jpeg" />
                                        <label htmlFor="file"><i className="fa-regular fa-image"></i> Choose an image file</label>
                                        {this.state.signature_image !== '' && (
                                            <button onClick={this.discardSignature} className="discard-btn">Discard Signature</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='wrapper'>
                                <button className='back-btn' onClick={() => window.location = '/approvals'}><i className="fa-solid fa-arrow-left-long"></i> Back to Approvals</button>
                                {/* <p>Signature Editor</p> */}
                                {this.state.window_width < 820 && this.state.signature_image === "" && (
                                    <button className='signature-privacy-btn' onClick={this.toggleMore}>Upload Signature</button>
                                )}
                                <div className='cropper-rel'>
                                    {
                                        this.state.signature_image !== "" ? <SignatureEditor signature_image={this.state.signature_image} zoom={this.state.zoom} email={this.props.email} node_server_axios={this.props.node_server_axios} />
                                            :
                                            <div className='wrap-1'>
                                                <div className='privacy'>
                                                    <h3>Privacy Policy</h3>
                                                    <b>By uploading your signature, you are implicitly agreeing to the below privacy policy.</b>
                                                    <p>The privacy policy for uploading a signature states that the signature will only be used for the intended purpose of signing the certificates sent via Pronetin when approved by the owner of the signature. It also ensures that the signature will not be used or shared without the user's explicit consent.</p>
                                                </div>
                                                <h2>Things to note before uploading signature</h2>
                                                <div className='icon'>
                                                    <img src={icon1} alt="signature clarity" />
                                                    <p>Clear image of your signature</p>
                                                </div>
                                                <div className='icon'>
                                                    <img src={icon2} alt="signature format" />
                                                    <p>Use jpeg / jpg format</p>
                                                </div>
                                                <div className='icon'>
                                                    <img src={icon3} alt="signature size" />
                                                    <p>Upload file size max 250kb</p>
                                                </div>
                                                <div className='icon'>
                                                    <img src={icon4} alt="signature dimension" />
                                                    <p>Try to upload square images</p>
                                                </div>
                                                <h2>Sample Signature</h2>
                                                <div className='icon sample-signature'>
                                                    <img src={sampleSignature} alt="signature sample" />
                                                </div>
                                            </div>
                                    }
                                </div>
                                {this.state.signature_image !== "" && (
                                    <div className='cropper-zoom'>
                                        <div className='container'>
                                            <p>Zoom</p>
                                            <input type="range" min="1" max="3" step="0.1" className="slider" defaultValue={this.state.zoom} onChange={this.updateZoom}></input>
                                        </div>
                                        <div className='container'>
                                            <p>Threshold</p>
                                            {/* <input type="range" min="70" max="160" step="5" className="slider" defaultValue={this.state.threshold} onChange={this.updateThreshold}></input> */}
                                            <div className='threshold-btns'>
                                                <button onClick={this.decreaseThreshold}><i className="fa-solid fa-minus"></i></button>
                                                <p>{(parseInt(this.state.threshold) / 5) - 14}/14</p>
                                                <button onClick={this.increaseThreshold}><i className="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MySignatureComponent;