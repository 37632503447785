import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header';
// import SmartAds from '../components/smartads';
import Element from '../components/certificate_preview';

const Viewer = (props) => {
    let { slug } = useParams();

    return (
        <React.Fragment>
            <div className="main no-margin overflow-auto public">
                <Header login={false} signin={true} node_server_axios={props.node_server_axios} />
                {/* <SmartAds node_server_axios={props.node_server_axios} /> */}
                <Element documentId={slug} node_server_axios={props.node_server_axios} />
            </div>
        </React.Fragment>
    )
};

export default Viewer;