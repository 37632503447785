import React from 'react';
import './css/home_new.css';

import Header from './landing_header';
import Footer from './landing_footer';

import PadlockIcon from '../images/home/Secure.png';
import AIIcon from '../images/home/AI.png';
import QRIcon from '../images/home/QR.png';
import ComprehensiveIcon from '../images/home/Comprehensive.png';

// import User from '../images/home/user.png';
// import Quotes from '../images/home/quotes.png';
import Laptop from '../images/home/bg.png';

import Partner1 from '../images/home/ced.png';
import Partner2 from '../images/home/ieee.png';
import Partner3 from '../images/home/iiit.png';
import Partner4 from '../images/home/ksum.png';
import Partner5 from '../images/home/Makervillage.png';
import Partner6 from '../images/home/Topknot.png';
import Certificate from '../images/home/certificate_home.png';

import LaptopScreen1 from '../images/home/landing_features/bulk certificate.png';
import LaptopScreen2 from '../images/home/landing_features/ai powered.png';
import LaptopScreen3 from '../images/home/landing_features/secure authentic.png';
import LaptopScreen4 from '../images/home/landing_features/social presence.png';
import LaptopScreen5 from '../images/home/landing_features/informed authorization.png';

import axios from 'axios';
import $ from 'jquery';

class TxtType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txt: '',
            isDeleting: false,
            loopNum: 0,
            toRotate: props.toRotate,
            period: parseInt(props.period, 10) || 2000
        };
    }

    componentDidMount() {
        this.tick();
    }

    tick = () => {
        let i = this.state.loopNum % this.state.toRotate.length;
        let fullTxt = this.state.toRotate[i];

        if (this.state.isDeleting) {
            this.setState(prevState => ({
                txt: fullTxt.substring(0, prevState.txt.length - 1)
            }));
        } else {
            this.setState(prevState => ({
                txt: fullTxt.substring(0, prevState.txt.length + 1)
            }));
        }

        let delta = 200 - Math.random() * 100;

        if (this.state.isDeleting) {
            delta /= 2;
        }

        if (!this.state.isDeleting && this.state.txt === fullTxt) {
            delta = this.state.period;
            this.setState({ isDeleting: true });
        } else if (this.state.isDeleting && this.state.txt === '') {
            this.setState(prevState => ({
                isDeleting: false,
                loopNum: prevState.loopNum + 1
            }));
            delta = 500;
        }

        setTimeout(this.tick, delta);
    }

    render() {
        return (
            <span>
                <span className="wrap">{this.state.txt}</span>
                <span className="cursor">|</span>
            </span>
        );
    }
}

class LandingHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPronetinModal: false,
            topCard: 1,
            facingUp: true,
            featureTile: 1,
            divAtCenter: false,
            prevDivCenterY: null,
        };
        this.flipCard = this.flipCard.bind(this);
        this.startInterval = this.startInterval.bind(this);
        this.stopInterval = this.stopInterval.bind(this);
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                this.setState({
                    loading: false,
                    name: res.data.name,
                    email: res.data.email,
                    user_type: res.data.user_type,
                });
                window.location = "./";
            }
        })

        const hashValue = window.location.hash;
        const cleanHashValue = hashValue.slice(1);
        if (cleanHashValue !== '') {
            if (cleanHashValue === 'old') {
                this.setState({
                    oldPronetinModal: true,
                })
            } else {
                var element = document.getElementById(cleanHashValue);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }

        const frontFace = $('.face1');
        const frontContent = $('.store li:first-child').html();
        frontFace.html(frontContent);

        this.startInterval();
    }

    componentWillUnmount() {
        this.stopInterval();
    }

    toggleOldPronetin = () => {
        this.setState({
            oldPronetinModal: !this.state.oldPronetinModal,
        })
    }

    flipCard(n) {
        const { topCard, facingUp } = this.state;
        if (topCard === n) return;
        const curBackFace = $(facingUp ? '.face2' : '.face1');
        const nextContent = $('.store li:nth-child(' + n + ')').html();
        curBackFace.html(nextContent);
        $('.card').toggleClass('flipped');
        this.setState({
            topCard: n,
            facingUp: !facingUp
        });
    }

    startInterval() {
        // Start interval to flip cards every 3 seconds
        this.interval = setInterval(() => {
            const nextCard = this.state.topCard < 4 ? this.state.topCard + 1 : 1;
            this.flipCard(nextCard);
            const nextFeature = this.state.featureTile < 5 ? this.state.featureTile + 1 : 1;
            this.setState({
                featureTile: nextFeature,
            })
        }, 6000);
    }

    stopInterval() {
        // Stop interval
        clearInterval(this.interval);
    }

    resetInterval() {
        // Stop interval and start again
        this.stopInterval();
        this.startInterval();
    }

    renderDescription = (n) => {
        switch (JSON.stringify(n)) {
            case '1':
                return (
                    <div className='desc'>
                        <h2>AI powered</h2>
                        <p>Use the help of AI to create innovative and interesting designs.</p>
                        <a href='/features/organisation'><i className="fa-solid fa-angle-down"></i> More</a>
                    </div>
                );
            case '2':
                return (
                    <div className='desc'>
                        <h2>Secure</h2>
                        <p>Post Quantum Ledgers / Blockchain to safeguard your certificates.</p>
                        <a href='/features/organisation'><i className="fa-solid fa-angle-down"></i> More</a>
                    </div>
                );
            case '3':
                return (
                    <div className='desc'>
                        <h2>Authentic</h2>
                        <p>QR verification provides all true information about the certificates.</p>
                        <a href='/features/organisation'><i className="fa-solid fa-angle-down"></i> More</a>
                    </div>
                );
            case '4':
                return (
                    <div className='desc'>
                        <h2>Comprehensive</h2>
                        <p>Store all your certificates and achievements in one place.</p>
                        <a href='/features/organisation'><i className="fa-solid fa-angle-down"></i> More</a>
                    </div>
                );
            default:
                return (
                    <div className='desc'>
                        <h2>AI powered</h2>
                        <p>Use the help of AI to create innovative and interesting designs.</p>
                        <a href='/features/organisation'><i className="fa-solid fa-angle-down"></i> More</a>
                    </div>
                );
        }
    }

    changeFeatureTile = (n) => {
        if (n > 5) {
            n = 1;
        } else if (n < 1) {
            n = 5;
        }
        this.setState({
            featureTile: n,
        });
    }

    render() {
        const rotation = `rotate(${360 - (this.state.featureTile - 1) * 20}deg)`;
        const toRotate = ["Design", "Approve", "Distribute", "Verified"];
        const period = "2000";
        return (
            <React.Fragment>
                <div className='landing-v1'>
                    <Header />
                    {this.state.oldPronetinModal && (
                        <div className='element-modal old-pronetin'>
                            <div className='content container'>
                                {/* <button className='close-btn' onClick={this.toggleOldPronetin}><i className="fa-solid fa-x"></i></button> */}
                                <p style={{ "font-size": "2rem" }}>Welcome to the New Pronetin<span>Where your experience in receiving or distributing certificates is enhanced to a whole new level.</span><span>If you are an <b>Old Pronetin</b> user and would like to backup your data, <a href="https://old.pronetin.com" target='_blank' rel='noreferrer'>Click here</a> to use the Old version of Pronetin.</span></p>
                                <button onClick={this.toggleOldPronetin} className='explore-btn'>Explore New Pronetin<i className="fa-solid fa-chevron-right"></i></button>
                            </div>
                            {/* <img src={welcome} alt='By Vlad Chețan' /> */}
                        </div>
                    )}
                    <div className='first'>
                        <div className='row'>
                            <div className='left'>
                                <h1>
                                    <a href="#foo" className="typewrite" data-period={period} data-type={JSON.stringify(toRotate)}>
                                        <TxtType toRotate={toRotate} period={period} />
                                    </a>
                                </h1>
                                <h2>credible certificates with Pronetin</h2>
                                <p>stands as a beacon of assurance, where your certificates are not just symbols, but verifiable testaments to your expertise</p>
                                <div className='cta-btns'>
                                    <button className='create-btn' onClick={() => window.location = '/signup?action=createcertificate'}>Create Certificate <label>Free</label></button>
                                    <button className='find-btn' onClick={() => window.location = '/signup?action=findcertificate'}>Find My Certificate</button>
                                </div>
                                <p className='small-font'>Choose Pronetin and contribute to a greener planet by cutting down around 50g of CO2 emissions per credential. <a href="/help#20_General">learn how</a></p>
                            </div>
                            <div className='right'>
                                <div className='animation'>
                                    <img className='cert' src={Certificate} alt='dummy-certificate' />
                                    <div className='shield'>
                                        <div className="container">
                                            <div className="card">
                                                <div className="face face1"></div>
                                                <div className="face face2"></div>
                                            </div>

                                            <ul className="store">
                                                <li><img src={AIIcon} alt='shield icons' /></li>
                                                <li><img src={PadlockIcon} alt='shield icons' /></li>
                                                <li><img src={QRIcon} alt='shield icons' /></li>
                                                <li><img src={ComprehensiveIcon} alt='shield icons' /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='content'>
                                    {this.renderDescription(this.state.topCard)}
                                    <div className="buttons">
                                        <input type="button" className={this.state.topCard === 1 ? "active" : ""} onClick={() => { this.flipCard(1); this.resetInterval(); }} />
                                        <input type="button" className={this.state.topCard === 2 ? "active" : ""} onClick={() => { this.flipCard(2); this.resetInterval(); }} />
                                        <input type="button" className={this.state.topCard === 3 ? "active" : ""} onClick={() => { this.flipCard(3); this.resetInterval(); }} />
                                        <input type="button" className={this.state.topCard === 4 ? "active" : ""} onClick={() => { this.flipCard(4); this.resetInterval(); }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='logos'>
                            <img src={Partner5} alt='client-logo' />
                            <img src={Partner2} alt='client-logo' />
                            <img src={Partner6} alt='client-logo' />
                            <img src={Partner4} alt='client-logo' />
                            <img src={Partner1} alt='client-logo' />
                            <img src={Partner3} alt='client-logo' />
                        </div>
                    </div>

                    <div className='gap'></div>

                    <div className='second'>
                        <div className='banner'>
                            <h2>distribution with <span>unprecedented speed</span></h2>
                            <h3>10k+ certificates in a <span>minute</span></h3>
                        </div>
                        <div className='feature'>
                            <div className='left'>
                                <div className={this.state.featureTile === 1 ? 'content active' : 'content'} onClick={() => { this.changeFeatureTile(1); this.resetInterval(); }}>
                                    <h4>Bulk Certificate Distribution:</h4>
                                    <p>Pronetin simplifies the process of certificate distribution, allowing users to design, create, and send certificates to thousands of recipients.</p>
                                </div>
                                <div className={this.state.featureTile === 2 ? 'content active' : 'content'} onClick={() => { this.changeFeatureTile(2); this.resetInterval(); }}>
                                    <h4>AI-powered Certificate Design</h4>
                                    <p>Pronetin offers a dedicated certificate design editor with AI-powered support for certificate design, based on simple suggestions.</p>
                                </div>
                                <div className={this.state.featureTile === 3 ? 'content active' : 'content'} onClick={() => { this.changeFeatureTile(3); this.resetInterval(); }}>
                                    <h4>Secure & Authentic Certificates</h4>
                                    <p>Pronetin ensures the authenticity of certificates through a QR code verification system. With post quantum ledgers.</p>
                                </div>
                                <div className={this.state.featureTile === 4 ? 'content active' : 'content'} onClick={() => { this.changeFeatureTile(4); this.resetInterval(); }}>
                                    <h4>Social Presence</h4>
                                    <p>Pronetin facilitates easy sharing of achievements on professional networks like LinkedIn, enhancing your professional and organisation profiles.</p>
                                </div>
                                <div className={this.state.featureTile === 5 ? 'content active' : 'content'} onClick={() => { this.changeFeatureTile(5); this.resetInterval(); }}>
                                    <h4>Informed Authorization of Certificates</h4>
                                    <p>Pronetin allows signees to make informed decisions by knowing complete details about the certificates and authorising them personally.</p>
                                </div>
                            </div>
                            <div className='middle'>
                                <div className='c-shape'><div className='guide' style={{ transform: rotation }}><div className='filler'></div></div></div>
                                <div className='dots'>
                                    <div className='caret' onClick={() => { this.changeFeatureTile(this.state.featureTile - 1); this.resetInterval(); }}><i className="fa-solid fa-angle-left"></i></div>
                                    <div className='wrap'>
                                        <button className={this.state.featureTile === 1 ? 'dot active' : 'dot'} onClick={() => { this.changeFeatureTile(1); this.resetInterval(); }}></button>
                                        <button className={this.state.featureTile === 2 ? 'dot active' : 'dot'} onClick={() => { this.changeFeatureTile(2); this.resetInterval(); }}></button>
                                        <button className={this.state.featureTile === 3 ? 'dot active' : 'dot'} onClick={() => { this.changeFeatureTile(3); this.resetInterval(); }}></button>
                                        <button className={this.state.featureTile === 4 ? 'dot active' : 'dot'} onClick={() => { this.changeFeatureTile(4); this.resetInterval(); }}></button>
                                        <button className={this.state.featureTile === 5 ? 'dot active' : 'dot'} onClick={() => { this.changeFeatureTile(5); this.resetInterval(); }}></button>
                                    </div>
                                    <div className='caret' onClick={() => { this.changeFeatureTile(this.state.featureTile + 1); this.resetInterval(); }}><i className="fa-solid fa-angle-right"></i></div>
                                </div>
                            </div>
                            <div className='right'>
                                <img src={Laptop} alt='pronetin laptop' />
                                {this.state.featureTile === 1 && (<img src={LaptopScreen1} alt='pronetin laptop screen' className='laptop-screen' />)}
                                {this.state.featureTile === 2 && (<img src={LaptopScreen2} alt='pronetin laptop screen' className='laptop-screen' />)}
                                {this.state.featureTile === 3 && (<img src={LaptopScreen3} alt='pronetin laptop screen' className='laptop-screen' />)}
                                {this.state.featureTile === 4 && (<img src={LaptopScreen4} alt='pronetin laptop screen' className='laptop-screen' />)}
                                {this.state.featureTile === 5 && (<img src={LaptopScreen5} alt='pronetin laptop screen' className='laptop-screen' />)}
                            </div>
                        </div>
                    </div>

                    <div className='gap'></div>

                    {/* <div className='third' id="testimonials">
                        <h2>Testimonials</h2>
                        <div className='testimonials'>
                            <div className='container'>
                                <div className='description'>
                                    <img src={Quotes} alt='quotes' />
                                    <p>I'm incredibly impressed with how effortlessly I could distribute certificates to my team using this platform. It saved me hours of manual work, and the recipients loved the professional design of their certificates. Highly recommended!</p>
                                </div>
                                <div className='user'>
                                    <img src={User} alt='user' />
                                    <div className='user-info'>
                                        <h3>Isabella Rivera</h3>
                                        <p>Dean of Graduate Studies<br></br>Phoenix Academy</p>
                                    </div>
                                </div>
                            </div>

                            <div className='container'>
                                <div className='description'>
                                    <img src={Quotes} alt='quotes' />
                                    <p>I appreciated the customizable options and the ease of bulk distribution. It's a game-changer! Ensuring attendees receive their certificates promptly is crucial</p>
                                </div>
                                <div className='user'>
                                    <img src={User} alt='user' />
                                    <div className='user-info'>
                                        <h3>Isabella Rivera</h3>
                                        <p>Dean of Graduate Studies<br></br>Phoenix Academy</p>
                                    </div>
                                </div>
                            </div>

                            <div className='container'>
                                <div className='description'>
                                    <img src={Quotes} alt='quotes' />
                                    <p>I'm incredibly impressed with how effortlessly I could distribute certificates to my team using this platform. It saved me hours of manual work, and the recipients loved the professional design of their certificates. Highly recommended!</p>
                                </div>
                                <div className='user'>
                                    <img src={User} alt='user' />
                                    <div className='user-info'>
                                        <h3>Isabella Rivera</h3>
                                        <p>Dean of Graduate Studies<br></br>Phoenix Academy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='gap'></div>

                </div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default LandingHome;