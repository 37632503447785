import React, { Component } from 'react';
import axios from 'axios';
import '../components/css/invoice.css';
import Header from '../components/header';

import tutorialVideo from '../images/videos/Signing Authority Video.mp4';

class Thankyou extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceNumber: null,
            login: false,
            isLoading: true,
        };
    }

    componentDidMount = async () => {
        const params = new URLSearchParams(window.location.search);
        const invoice = params.get('invoice');

        if (invoice) {
            this.setState({
                invoiceNumber: invoice,
            });

            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'session').then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                    this.setState({
                        login: true,
                        name: res.data.name,
                        email: res.data.email,
                        header_user_data: res.data,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            })
        } else {
            window.location.href = '/'
        }
    }

    render() {
        return (
            <div className='main no-margin overflow-auto public'>
                {!this.state.isLoading && (
                    <React.Fragment>
                        <Header login={this.state.login} signup={true} user_data={this.state.header_user_data} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                        <div className='thank-you-modal'>
                            <h2>Thankyou!</h2>
                            <h3>You will receive your <b>License Key</b> via email</h3>
                            <p>Invoice number: #{this.state.invoiceNumber} | <a href={'/invoice?id=' + this.state.invoiceNumber} target="_blank" rel="noreferrer">View Receipt</a></p>
                            <div className='video'>
                                <p><i className="fa-solid fa-circle-info"></i> How to use license key? watch video.</p>
                                <video src={tutorialVideo} controls={true}></video>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default Thankyou;
