import React from 'react';
import './css/signin.css';
import SigninIcon from './resources/lf-1.png';
// import GoogleButton from './googleSigin';
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: false,
            messageValue: '',
        };
    }

    handleSubmit = event => {
        event.preventDefault();

        axios.defaults.withCredentials = true;

        axios.post(this.props.node_server_axios + 'forgot', {
            params: {
                data: {
                    email: event.target.email.value.toLowerCase().replace(/\s+/g, ''),
                }
            },
        }).then(res => {
            this.setState({
                message: true,
                messageValue: res.data.message,
                redirect: res.data.redirect,
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <header className="wrapper">
                    <form method='POST' onSubmit={this.handleSubmit}>
                        <div className="container">
                            <img src={SigninIcon} className="userimage" alt="user signin"></img>
                            <h1>Reset Password</h1>
                            {!this.state.message && (
                                <div className='relative block w-100 flex-cr'>
                                    <input type="email" name='email' className='lowercase' placeholder="johndoe@pronetin.com" />
                                    <label>Email</label>
                                </div>
                            )}
                            {!this.state.message && (<button type='submit'>Send Reset Link</button>)}
                            {
                                this.state.message && (
                                    <div style={{ "width": "100%" }}>
                                        <p>{this.state.messageValue}</p><br></br>
                                        {this.state.redirect === '/signin' ?
                                            <p><a className='secondary' href='/signin'>Sign in <i className="fa-solid fa-arrow-right-to-bracket"></i></a></p>
                                            :
                                            <p><a className='secondary' href='/signup'>Sign up <i className="fa-solid fa-arrow-right-to-bracket"></i></a></p>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </form>
                </header>
            </React.Fragment>
        );
    }
};

export default App;