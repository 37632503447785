import React from 'react';
import { useParams } from 'react-router-dom';

import EmailVerification from '../components/verify_email';

const MailerComponent = (props) => {
    let { slug } = useParams();
    let { code } = useParams();

    return (
        <EmailVerification email={slug} code={code} node_server_axios={props.node_server_axios} />
    );
};

export default MailerComponent;