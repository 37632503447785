import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
import Loading from '../components/loading';
// import SmartAds from '../components/smartads';
import Element from '../components/certificate_public';

const Viewer = (props) => {
    let { slug } = useParams();
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [header_user_data, setHeaderUserData] = useState(null);

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                setEmail(res.data.email);
                setHeaderUserData(res.data);
            }
        });
        setLoading(false);
    }, [props.node_server_axios]);

    return loading ? (
        <React.Fragment>
            <div className="main">
                <Header />
                <Loading />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="main no-margin overflow-auto public">
                {email ?
                    <Header login={true} user_data={header_user_data} email={email} node_server_axios={props.node_server_axios} />
                    :
                    <Header login={false} node_server_axios={props.node_server_axios} />
                }
                {/* <SmartAds node_server_axios={props.node_server_axios} /> */}
                <Element documentId={slug} node_server_axios={props.node_server_axios} viewer_email={email} />
            </div>
        </React.Fragment>
    );
};

export default Viewer;