import React from 'react';
import './css/signin.css';
import SigninIcon from './resources/lf-1.png';
import verifyEmailImage from '../images/static/User Verify.png';
import staticGif from '../images/static/siginin_tut.gif';
import GoogleButton from './googleSigin';
import axios from 'axios';

import ReCAPTCHA from "react-google-recaptcha";

class App extends React.Component {
    constructor(props) {
        super(props);
        const searchParams = new URLSearchParams(window.location.search);
        const page = searchParams.get('page');
        const id = searchParams.get('id');
        this.state = {
            email: '',
            password: '',
            isError: {
                email: '',
                password: '',
            },
            response: '',
            warningActive: '',
            redirectStatus: '',
            showPassword: false,
            signingup: false,
            effectiveType: 'unknown',
            rtt: 0,
            downlink: 10,
            redirect_page: page,
            redirect_id: id,
            isResendDisabled: true,
            countdown: 30,
            isResendRemoved: false,
            timer: null,
            disableTimer: null,
            recaptchaToken: null,
            recaptchaKey: Math.random().toString(36).substring(2),
        };
    }

    checkValidation = (e) => {
        var regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { name, value } = e.target;
        let isError = { ...this.state.isError };
        switch (name) {
            case "email":
                isError.email = value.length > 0 ? regEmail.test(value) ? "" : "Enter a valid email id" : "Email field cannot be empty";
                break;
            case "password":
                isError.password = value.length < 8 ? "Atleast 8 characaters required" : "";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault();

        const { recaptchaToken } = this.state;
        axios.post(this.props.node_server_axios + 'verify-recaptcha', { token: recaptchaToken })
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        signingup: true
                    })
                    axios.defaults.withCredentials = true;
                    axios.post(this.props.node_server_axios + 'signin', {
                        params: {
                            data: {
                                email: event.target.email.value.toLowerCase().replace(/\s+/g, ''),
                                password: event.target.password.value,
                            },
                            error: this.state.isError
                        },
                    }).then(async (res) => {
                        if (res.data.message !== '') {
                            if (this.state.timer) {
                                clearInterval(this.state.timer);
                            }
                            if (this.state.disableTimer) {
                                clearTimeout(this.state.disableTimer);
                            }
                            this.setState({
                                response: res.data.message,
                                warningActive: true,
                                signingup: false,
                                countdown: 30,
                                timer: null,
                                disableTimer: null,
                            });
                            if (this.state.isResendDisabled && res.data.message === 'An email has been sent to your inbox. Please verify your email to log in.') {
                                const disableTimer = setTimeout(() => {
                                    this.setState({
                                        isResendDisabled: false,
                                    });
                                }, 30000);
                                const timer = setInterval(() => {
                                    this.setState((prevState) => ({
                                        countdown: prevState.countdown - 1,
                                    }));
                                    if (this.state.countdown === 0) {
                                        clearInterval(timer);
                                    }
                                }, 1000);

                                this.setState({
                                    disableTimer: disableTimer,
                                    timer: timer,
                                });
                            }
                        }
                        if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                            if (this.state.redirect_page && this.state.redirect_id) {
                                window.location = '/' + this.state.redirect_page + '/' + this.state.redirect_id;
                            } else if (this.state.redirect_page) {
                                window.location = '/' + this.state.redirect_page;
                            } else {
                                window.location = res.data.redirect;
                            }
                        }
                    });
                } else {
                    this.setState({
                        response: "reCaptcha error !",
                        warningActive: true,
                        signingup: false,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    response: "Verify captcha to continue",
                    warningActive: true,
                    signingup: false,
                });
                this.resetRecaptcha();
            });
    }

    resendVerification = (event) => {
        event.preventDefault();
        if (!this.state.isResendDisabled) {
            this.setState({ isResendDisabled: true, isResendRemoved: true });
            axios.post(this.props.node_server_axios + 'mailer-email-verification', {
                params: {
                    data: {
                        email: this.state.email,
                    },
                },
            }).then(res => {
                if (res.data.message === 'success') {
                    this.setState({ response: 'Email Sent', warningActive: false })
                }
            });
        }
    }

    getPageName(url) {
        const segments = url.split('/');
        const lastSegment = segments[segments.length - 1];
        return lastSegment;
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const redirectStatus = searchParams.get("o");
        this.setState({ redirectStatus });

        //Check network speed
        if (navigator.connection) {
            const connection = navigator.connection;

            this.setState({
                effectiveType: connection.effectiveType,
                rtt: connection.rtt,
                downlink: connection.downlink,
            });

            connection.addEventListener('change', this.handleConnectionChange);
        }

        const previousPageUrl = document.referrer;
        const previousPageName = this.getPageName(previousPageUrl);

        if (previousPageName === '') {
            console.log("session check");
            axios.defaults.withCredentials = true;
            axios.post(this.props.node_server_axios + 'session').then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                    if (this.state.redirect_page && this.state.redirect_id) {
                        window.location = '/' + this.state.redirect_page + '/' + this.state.redirect_id;
                    } else if (this.state.redirect_page) {
                        window.location = '/' + this.state.redirect_page;
                    } else {
                        window.location = res.data.redirect;
                    }
                }
            })
        }
    }

    componentWillUnmount() {
        if (navigator.connection) {
            navigator.connection.removeEventListener('change', this.handleConnectionChange);
        }
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    }

    handleConnectionChange = () => {
        if (navigator.connection) {
            const connection = navigator.connection;

            this.setState({
                effectiveType: connection.effectiveType,
                rtt: connection.rtt,
                downlink: connection.downlink,
            });
        }
    };

    handleRecaptchaChange = (token) => {
        this.setState({ recaptchaToken: token });
    };

    resetRecaptcha = () => {
        this.setState({ recaptchaKey: Math.random().toString(36).substring(2), recaptchaToken: null });
    };

    render() {
        const { isError } = this.state;
        return (
            <React.Fragment>
                {this.state.redirectStatus === 'verified' && (<div className='redirect-status'><i className="fa-regular fa-circle-check"></i> Email verified. Signin to continue</div>)}
                {this.state.redirectStatus === 'emailsend' && (<div className='redirect-status'><i className="fa-regular fa-circle-check"></i> Verify your email to continue. Please check your inbox</div>)}
                {this.state.redirectStatus === 'emailfailed' && (<div className='redirect-status failed'><i className="fa-regular fa-circle-xmark"></i> Failed to send Verification email</div>)}
                {this.state.redirectStatus === 'failed' && (<div className='redirect-status failed'><i className="fa-regular fa-circle-xmark"></i> Verification link is invalid or has expired</div>)}

                <div className='signin-video'>
                    {this.state.redirectStatus === null && !this.props.noMainGif && (<img src={staticGif} alt="Animated GIF" className='landing-img gif-img' />)}
                    {this.state.redirectStatus === 'emailsend' && (<img src={verifyEmailImage} alt="verify email" className='landing-img' />)}
                </div>
                <header className="wrapper">
                    <form method='POST' onSubmit={this.handleSubmit}>
                        <div className="container">
                            <img src={SigninIcon} className="userimage" alt="user signin"></img>
                            {this.state.downlink < 3 && (
                                <div className='network-check'>
                                    You are on a slow network, it might affect your experience in the platform
                                </div>
                            )}
                            <h1>Sign In</h1>
                            <div className='relative block w-100 flex-cr'>
                                {isError.email.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.email}</span></div>
                                )}
                                <input type="email" name='email' className={this.state.response !== '' && this.state.email === '' ? 'error lowercase' : 'lowercase'} placeholder="johndoe@pronetin.com" onChange={this.checkValidation} />
                                <label>Email</label>
                            </div>
                            {this.state.redirectStatus === 'organisation' && (<p className='warning'>Work email required for issuing certificate</p>)}
                            <div className='relative block w-100 flex-cr'>
                                {isError.password.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.password}</span></div>
                                )}
                                <div className='password-visibility' onClick={this.togglePasswordVisibility}>{this.state.showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</div>
                                <input type={this.state.showPassword ? 'text' : 'password'} name='password' autoComplete='new-password' placeholder="********" className={this.state.response !== '' && this.state.password === '' ? 'error' : ''} onChange={this.checkValidation} />
                                <label>Password</label>
                            </div>
                            <p className={this.state.warningActive ? 'warning block' : 'warning none'}><i className='fa-solid fa-circle-exclamation'></i> {this.state.response}</p>
                            {
                                !this.state.isResendRemoved && this.state.response === 'An email has been sent to your inbox. Please verify your email to log in.' && (<a href='foo' className={`action-btn ${this.state.isResendDisabled ? 'disabled' : ''}`} onClick={this.resendVerification}>Resend Email {this.state.isResendDisabled && (<span>({this.state.countdown < 0 ? 0 : this.state.countdown} seconds)</span>)}</a>)
                            }
                            {
                                this.state.response === 'Email Sent' && (<p className='success'><i className="fa-regular fa-circle-check"></i> Email Sent</p>)
                            }
                            <p><a className='secondary' href='/forgot'>Forgot Password</a></p>
                            <ReCAPTCHA
                                key={this.state.recaptchaKey}
                                sitekey="6Le6ccgpAAAAAHHBr4UZ0vbrHsRE-ibYi9jSvxz1"
                                onChange={this.handleRecaptchaChange}
                                className="g-recaptcha"
                            />
                            {this.state.signingup ?
                                <button type='button'>Signing In...</button>
                                :
                                <button type='submit'>Sign In</button>
                            }
                            <div id="googleButton"><GoogleButton node_server_axios={this.props.node_server_axios} /></div>
                            <p>Don't Have an account?&nbsp;&nbsp;<a className='secondary' href='/signup'>Sign Up <i className='fa-solid fa-user-plus'></i></a></p>
                            <p><a className='secondary' href='https://old.pronetin.com'>Visit Old Pronetin</a></p>
                            <p><a href='foo'>Introduction</a> . <a href='/help' target='_blank'>Help</a> . <a href='./privacypolicy' target='_blank'>Privacy Policy</a></p>
                        </div>
                    </form>
                </header>
            </React.Fragment>
        );
    }
};

export default App;