import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/email_editor';
import Loading from '../components/loading';

import '../components/css/main.css';

const Marketplace = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user');
    const [orgId, setOrgId] = useState('');
    const [storage, setStorage] = useState('');
    const [header_user_data, setHeaderUserData] = useState(null);
    let { slug } = useParams();
    let { subevent } = useParams();

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'session')
            .then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '') {
                    setLoading(false);
                    setName(res.data.name);
                    setEmail(res.data.email);
                    setUserType(res.data.user_type);
                    setOrgId(res.data.org_id);
                    setStorage(res.data.storage);
                    setHeaderUserData(res.data);
                } else {
                    window.location = '/signin';
                }
            })
            .catch(() => {
                window.location = '/signin';
            });
    }, [props.node_server_axios]);

    return loading ? (
        <React.Fragment>
            <div className="main">
                <Header />
                <Loading />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="main">
                <Header login={true} user_data={header_user_data} email={email} node_server_axios={props.node_server_axios} />
                <div className='body-wrapper'>
                    <SideNav name={name} selected="emailtemplate" user_type={userType} node_server_axios={props.node_server_axios} storage={storage} />
                    {(slug !== undefined && subevent !== undefined) ?
                        <Element email={email} user_type={userType} org_id={orgId} event={slug.replace(/-/g, ' ')} subevent={subevent.replace(/-/g, ' ')} node_server_axios={props.node_server_axios} />
                        :
                        <Element email={email} user_type={userType} org_id={orgId} node_server_axios={props.node_server_axios} />
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default Marketplace;