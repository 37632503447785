import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import QRIcon from '../../images/static/pronetin-logo-s.png';

class QRCodeGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "http://pronetin.in/v/" + this.props.data,
            showQRCode: false,
            position: this.props.position ? this.props.position : { x: 0, y: 0 },
            landscape: false,
            certSize: { top: 904, left: 640 }
        };
    }

    componentDidMount() {
        this.setState({
            showQRCode: true
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                value: "http://pronetin.in/v/" + this.props.data,
            });
        }
        if (!this.state.landscape && document.getElementsByClassName('elements')[0].offsetWidth > document.getElementsByClassName('elements')[0].offsetHeight) {
            this.setState({
                landscape: true,
                certSize: { top: 640, left: 904 }
            });
        } else if (this.state.landscape && document.getElementsByClassName('elements')[0].offsetWidth < document.getElementsByClassName('elements')[0].offsetHeight) {
            this.setState({
                landscape: false,
                certSize: { top: 904, left: 640 }
            });
        }
    }

    render() {
        return (
            <div className="box wrap viewer-wrap" style={{ top: this.state.certSize.top - (this.state.position.y * -0.1) + "px", left: this.state.certSize.left - (this.state.position.x * -0.1) + "px", zIndex: 997 }}>
                {this.props.printPreset ?
                    <div className="qr-div" id="qr-div" style={{ scale: '1', transformOrigin: 'bottom right', width: '71.71px', height: '71.71px' }}></div>
                    :
                    <div className="qr-div" id="qr-div" style={{ scale: '0.1', transformOrigin: 'bottom right' }}>
                        {this.state.showQRCode && (
                            <div className="container">
                                <QRCodeCanvas value={this.state.value} size={this.props.size * 10} level={'H'} imageSettings={{ excavate: true, height: 65, width: 65 }} />
                                <img src={QRIcon} alt="QR Icon" id='qr-icon' />
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default QRCodeGenerator;