import './css/loading.css';

function LoadingComponent() {
    return (
        <div className='loading'>
            <h1>Loading</h1>
            <p>We are fetching your profile from servers</p>
        </div>
    );
}

export default LoadingComponent;