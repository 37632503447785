import React from 'react';
import staticGif from '../images/static/astro.gif';
import Signin from '../components/signin';
import Header from '../components/header';

import '../components/css/main.css';

const SigninComponent = (props) => {
    return (
        <React.Fragment>
            <div className="signin signin-v2">
                <Header />
                <div className='kicked'>
                    <h1>You have been removed from this Organisation</h1>
                    <p>Please contact organisation admin for any queries</p>
                    <img src={staticGif} alt="Animated GIF" />
                    <h1>"Out you go, like a shooting star<br></br>leaving its trail of awesomeness behind!"</h1>
                </div>
                <Signin node_server_axios={props.node_server_axios} noMainGif={true} />
            </div>
        </React.Fragment>
    );

};

export default SigninComponent;