import React from 'react';
import './css/element.css';
import './css/viewer.css';
import axios from 'axios';

import Warning from './warning';
import WarningAction from '../components/warning_action';

class CertificateViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            certView: false,
            view_type: '',
            cert_attachment: null,
            pdfUrl: null,
            pdf: '',
            previousPageName: '',
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        const previousPageUrl = document.referrer;
        const previousPageName = this.getPageName(previousPageUrl);

        axios.post(this.props.node_server_axios + 'uploaded_document_view', {
            params: {
                documentId: this.props.documentId,
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'document found') {
                    if (res.data.view_type === 'public' || this.props.authEmail === res.data.contact) {
                        this.setState({
                            certView: true,
                            date: res.data.date,
                            event_name: res.data.event_name,
                            cert_title: res.data.cert_title,
                            pdf: res.data.pdf,
                            view_type: res.data.view_type,
                            contact: res.data.contact,
                            pdfUrl: "https://pronetin-new.s3.amazonaws.com/userdocs/" + this.props.email + "/" + encodeURIComponent(res.data.pdf),
                            previousPageName: previousPageName,
                        })
                    } else {
                        this.setState({
                            certView: true,
                            date: res.data.date,
                            event_name: res.data.event_name,
                            cert_title: res.data.cert_title,
                            pdf: false,
                            view_type: res.data.view_type,
                            contact: res.data.contact,
                            pdfUrl: false,
                            previousPageName: previousPageName,
                        })
                    }
                } else {
                    window.location = '../../'
                }
            } else {
                window.location = '../../'
            }
        })
    }

    copyUrl = (e) => {
        e.preventDefault();
        const url = window.location.href;
        const textarea = document.createElement("textarea");
        textarea.value = url;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);

        this.setState({
            warning: {
                message: "URL copied to clipboard.",
                type: "success",
                learnmore: false,
                autoclose: true,
            }
        })
    }

    deleteDocument = async (e) => {
        e.preventDefault();
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Delete Document",
                subtext: "Once the document is deleted it cannot be recovered. Do you want to Continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: false,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'uploaded_document_delete', {
            params: {
                documentId: this.props.documentId,
                pdf: this.state.pdf,
                email: this.props.email,
            },
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'deleted') {
                    window.location = '../../mydocuments'
                } else {
                    this.setState({
                        warning: {
                            message: "Failed to delete document.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        }
                    })
                }
            } else {
                this.setState({
                    warning: {
                        message: "An error has occoured.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        })
    }

    getPageName(url) {
        const segments = url.split('/');
        const lastSegment = segments[segments.length - 1];
        return lastSegment;
    }

    toggleViewType = (event) => {
        event.preventDefault();
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'toggle_document_view_type', {
            params: {
                document_id: this.props.documentId,
                email: this.props.email,
                view_type: this.state.view_type === 'private' ? 'public' : 'private',
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({
                    warning: {
                        message: "Document is now " + (this.state.view_type === 'private' ? 'public' : 'private') + ".",
                        type: "success",
                        learnmore: false,
                        autoclose: true,
                    },
                    view_type: this.state.view_type === 'private' ? 'public' : 'private',
                })
            } else {
                this.setState({
                    warning: {
                        message: "Failed to update document view.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    }
                })
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>User Uploaded Document<label>{this.state.view_type}</label></h1>
                            </div>
                        </div>
                        <div className='tiles marketplace mydocuments'>
                            <div className='sub-nav'>
                                {this.state.previousPageName === 'mydocuments' && (
                                    <a className='nav' href={'../../mydocuments'}>
                                        <i className="fa-solid fa-arrow-left"></i>
                                        Back
                                    </a>
                                )}
                                <br></br>
                                <h1><i className="fa-solid fa-circle-info"></i> About</h1>
                                <div className='nav-container'>
                                    <div className='info-container'>
                                        <h3>Document Information</h3>
                                        <p>Title : {this.state.cert_title}</p>
                                        <p>Event : {this.state.event_name}</p>
                                        <p>Upload Date : {this.state.date}</p>
                                    </div>
                                    <div className='info-container'>
                                        <h3>Owner Information</h3>
                                        <p>Email : {this.state.contact}</p>
                                    </div>
                                    {this.props.authEmail !== this.props.email && (
                                        <a className='nav' href={"mailto:" + this.state.contact} target="_blank" rel="noreferrer">
                                            <i className="fa-regular fa-envelope"></i>
                                            Contact
                                        </a>
                                    )}
                                    <a className='nav' href={'foo'} onClick={this.copyUrl}>
                                        <i className="fa-regular fa-copy"></i>
                                        Copy Url
                                    </a>
                                    {this.props.authEmail === this.props.email && (
                                        <a className='nav' href={this.state.pdfUrl} download={this.state.pdf}>
                                            <i className="fa-regular fa-file-pdf"></i>
                                            Download
                                        </a>
                                    )}
                                    {this.props.authEmail === this.props.email && (
                                        <a className='nav warn' href={'foo'} onClick={this.deleteDocument}>
                                            <i className="fa-solid fa-trash-can"></i>
                                            Delete
                                        </a>
                                    )}
                                    {this.props.authEmail === this.props.email && (
                                        <a className={this.state.view_type === 'public' ? 'nav green' : 'nav warn'} href="foo" onClick={this.toggleViewType}>
                                            {this.state.view_type === 'public' ?
                                                <i className="fa-solid fa-unlock"></i>
                                                :
                                                <i className="fa-solid fa-lock"></i>
                                            }
                                            {this.state.view_type === 'public' ?
                                                'Public'
                                                :
                                                'Private'
                                            }
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className='wrapper'>
                                {this.state.certView && this.state.pdfUrl ?
                                    <object data={this.state.pdfUrl + '#view=FitH'} type="application/pdf" className="pdf-viewer">
                                        <embed src={this.state.pdfUrl} type="application/pdf" />
                                    </object>
                                    :
                                    <div className='private-doc-message'>
                                        <h1>This Document is private</h1>
                                        <p>Please contact the owner to view this document</p>
                                        <i className="fa-solid fa-lock"></i>
                                    </div>
                                }
                            </div>

                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                        <div className='marketplace doc-alt-marketplace'>
                            <div className="mob-actions mobile-only fixed-controls">
                                <div className='nav-container'>
                                    {this.state.previousPageName === 'mydocuments' && (
                                        <a className='nav' href={'../../mydocuments'}>
                                            <i className="fa-solid fa-arrow-left"></i>
                                            Back
                                        </a>
                                    )}
                                    <a className='nav' href={"mailto:" + this.state.contact} target="_blank" rel="noreferrer">
                                        <i className="fa-regular fa-envelope"></i>
                                        Contact
                                    </a>
                                    <a className='nav' href={'foo'} onClick={this.copyUrl}>
                                        <i className="fa-regular fa-copy"></i>
                                        Copy Url
                                    </a>
                                    {this.props.authEmail === this.props.email && (
                                        <a className='nav' href={this.state.pdfUrl} download={this.state.pdf}>
                                            <i className="fa-regular fa-file-pdf"></i>
                                            Download
                                        </a>
                                    )}
                                    {this.props.authEmail === this.props.email && (
                                        <a className='nav warn' href={'foo'} onClick={this.deleteDocument}>
                                            <i className="fa-solid fa-trash-can"></i>
                                            Delete
                                        </a>
                                    )}
                                    {this.props.authEmail === this.props.email && (
                                        <a className={this.state.view_type === 'public' ? 'nav green' : 'nav warn'} href="foo" onClick={this.toggleViewType}>
                                            {this.state.view_type === 'public' ?
                                                <i className="fa-solid fa-unlock"></i>
                                                :
                                                <i className="fa-solid fa-lock"></i>
                                            }
                                            {this.state.view_type === 'public' ?
                                                'Public'
                                                :
                                                'Private'
                                            }
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                }
            </React.Fragment>
        );
    }
}

export default CertificateViewerComponent;