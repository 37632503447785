import React from "react";
import Draggable from 'react-draggable';
import { QRCodeCanvas } from "qrcode.react";
import QRIcon from '../../images/static/pronetin-logo-s.png';

class QRCodeGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "http://pronetin.in/v/" + this.props.data,
            showQRCode: false,
            position: this.props.position ? this.props.position : { x: 0, y: 0 },
            fadeOut: false,
            landscape: false,
            bounds: { top: -8330, left: -5680, right: 0, bottom: 0 }
        };
    }

    componentDidMount() {
        this.setState({
            showQRCode: true
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                value: "http://pronetin.in/v/" + this.props.data,
            });
        }
        if (prevProps.position !== this.props.position) {
            this.setState({
                position: this.props.position ? this.props.position : { x: 0, y: 0 }
            });
        }
        if (!this.state.landscape && document.getElementsByClassName('elements')[0].offsetWidth > document.getElementsByClassName('elements')[0].offsetHeight) {
            this.setState({
                landscape: true,
                bounds: { top: -5680, left: -8330, right: 0, bottom: 0 }
            });
        } else if (this.state.landscape && document.getElementsByClassName('elements')[0].offsetWidth < document.getElementsByClassName('elements')[0].offsetHeight) {
            this.setState({
                landscape: false,
                bounds: { top: -8330, left: -5680, right: 0, bottom: 0 }
            });
        }
    }

    startDrag = () => {
        this.setState({
            fadeOut: true,
        });
    }

    handlePositionUpdate = (event, dragElement) => {
        this.setState({
            position: {
                x: dragElement.x,
                y: dragElement.y
            },
            fadeOut: false,
        }, () => {
            this.props.fetchQRPosition(this.state.position)
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.fadeOut && (<div className="QR-move-fadeout"></div>)}
                <Draggable position={{ x: this.state.position.x, y: this.state.position.y }} scale={0.1 * this.props.certificate_zoom} onStop={this.handlePositionUpdate} bounds={this.state.bounds} onStart={this.startDrag}>
                    <div className="qr-div" id="qr-div" style={{ scale: '0.1', transformOrigin: 'bottom right' }}>
                        {this.state.showQRCode && (
                            <div className="container">
                                <QRCodeCanvas value={this.state.value} size={this.props.size * 10} level={'H'} imageSettings={{ excavate: true, height: 65, width: 65 }} />
                                <img src={QRIcon} alt="QR Icon" id='qr-icon' />
                            </div>
                        )}
                    </div>
                </Draggable>
            </React.Fragment>
        );
    }
}

export default QRCodeGenerator;